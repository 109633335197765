import { lambda_type, option_type, record_type, int32_type, class_type } from "../../app/client/src/.fable/fable-library.3.1.16/Reflection.js";
import { Record } from "../../app/client/src/.fable/fable-library.3.1.16/Types.js";

export class StreamResult {
    constructor() {
    }
}

export function StreamResult$reflection() {
    return class_type("Bindings.StreamResult", void 0, StreamResult);
}

export class HubConnection {
    constructor() {
    }
}

export function HubConnection$reflection() {
    return class_type("Bindings.HubConnection", void 0, HubConnection);
}

export class RetryContext extends Record {
    constructor(previousRetryCount, elapsedMilliseconds, retryReason) {
        super();
        this.previousRetryCount = (previousRetryCount | 0);
        this.elapsedMilliseconds = (elapsedMilliseconds | 0);
        this.retryReason = retryReason;
    }
}

export function RetryContext$reflection() {
    return record_type("Bindings.RetryContext", [], RetryContext, () => [["previousRetryCount", int32_type], ["elapsedMilliseconds", int32_type], ["retryReason", class_type("System.Exception")]]);
}

export class IRetryPolicy extends Record {
    constructor(nextRetryDelayInMilliseconds) {
        super();
        this.nextRetryDelayInMilliseconds = nextRetryDelayInMilliseconds;
    }
}

export function IRetryPolicy$reflection() {
    return record_type("Bindings.IRetryPolicy", [], IRetryPolicy, () => [["nextRetryDelayInMilliseconds", lambda_type(RetryContext$reflection(), option_type(int32_type))]]);
}

