import { singleton } from "../.fable/fable-library.3.1.16/AsyncBuilder.js";
import { createTransaction, PublicKeyDefault, TransactionInstructionInput, TransactionInstructionKey, transactionInstruction, bn, getTokenAccountBalance, getAssociatedTokenAddress, publicKeyBackEndToSolana, findProgramAddress } from "../../../../lib/Solana/Solana.fs.js";
import { encodeU32, encodePublicKey2, encodeString2NoLength } from "../../../../lib/Solana/Layout.fs.js";
import { Holding__GetDepositMint } from "../../../../lib/Domain/Types/Holding.fs.js";
import { Api } from "../Server.fs.js";
import { PKey } from "../../../shared/Api.fs.js";
import { PublicKey } from "../../../../lib/Domain/Types/Common.fs.js";
import { ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { some } from "../.fable/fable-library.3.1.16/Option.js";
import { signAndSendTransaction } from "../../../../lib/Solana/Transactions.fs.js";

export function getSingleStringPda(string, program) {
    return singleton.Delay(() => singleton.Bind(findProgramAddress([encodeString2NoLength(string)], program), (_arg1) => singleton.Return(_arg1)));
}

export function getKeyStringPda(key, s, program) {
    return singleton.Delay(() => singleton.Bind(findProgramAddress([encodePublicKey2(key), encodeString2NoLength(s)], program), (_arg1) => singleton.Return(_arg1)));
}

export function getKeyTwoStringPda(key, string1, string2, program) {
    return singleton.Delay(() => singleton.Bind(findProgramAddress([encodePublicKey2(key), encodeString2NoLength(string1), encodeString2NoLength(string2)], program), (_arg1) => singleton.Return(_arg1)));
}

export function getTwoKeyStringPda(key1, key2, s, program) {
    return singleton.Delay(() => singleton.Bind(findProgramAddress([encodePublicKey2(key1), encodePublicKey2(key2), encodeString2NoLength(s)], program), (_arg1) => singleton.Return(_arg1)));
}

export function getAuthorityPda(holding, holdingProgram) {
    return getKeyStringPda(holding, "holding-authority", holdingProgram);
}

export function getDepositBuyTokenAccPda(holding, holdingProgram) {
    return getKeyTwoStringPda(holding, "deposit", "buy", holdingProgram);
}

export function getDepositSellTokenAccPda(holding, holdingProgram) {
    return getKeyTwoStringPda(holding, "deposit", "sell", holdingProgram);
}

export function getPositionDataPda(holding, walletPublicKey, program) {
    return getTwoKeyStringPda(holding, walletPublicKey, "position", program);
}

export function getMemberAccPda(walletPublicKey, pool, stakeProgram) {
    return singleton.Delay(() => singleton.Bind(findProgramAddress([encodePublicKey2(walletPublicKey), encodePublicKey2(pool), encodeString2NoLength("member")], stakeProgram), (_arg1) => singleton.Return(_arg1)));
}

export function getExoticMainPool(stakeProgram) {
    return getSingleStringPda("exo-main-pool", stakeProgram);
}

export function getPoolAuthority(pool, stakeProgram) {
    return getKeyStringPda(pool, "exo-main-pool", stakeProgram);
}

export function getFeeStatePda(pool, stakeProgram) {
    return getKeyStringPda(pool, "fee-state", stakeProgram);
}

export function getUserStakeVaultPda(memberAcc, stakeProgram) {
    return getKeyStringPda(memberAcc, "stake-vault", stakeProgram);
}

export function withdraw(connection, environment, holdingProgramPubkey, stakeProgramPubkey, walletPublicKey, wallet, holding) {
    return singleton.Delay(() => {
        const holdingProgram = publicKeyBackEndToSolana(holdingProgramPubkey);
        const exoticStakeProgram = publicKeyBackEndToSolana(stakeProgramPubkey);
        const holdingAccount = publicKeyBackEndToSolana(holding.PublicKey);
        const product = publicKeyBackEndToSolana(holding.Product);
        const userDepositMintPda = publicKeyBackEndToSolana(Holding__GetDepositMint(holding));
        const depositBuyMint = publicKeyBackEndToSolana(holding.DepositBuyMint);
        const depositSellMint = publicKeyBackEndToSolana(holding.DepositSellMint);
        return singleton.Bind(getDepositBuyTokenAccPda(holdingAccount, holdingProgram), (_arg1) => singleton.Bind(getDepositSellTokenAccPda(holdingAccount, holdingProgram), (_arg2) => singleton.Bind(getAssociatedTokenAddress(depositBuyMint, walletPublicKey), (_arg3) => {
            const userDepositBuyTokenAcc = _arg3;
            return singleton.Bind(getAssociatedTokenAddress(depositSellMint, walletPublicKey), (_arg4) => {
                const userDepositSellTokenAcc = _arg4;
                return singleton.Bind(getPositionDataPda(holdingAccount, walletPublicKey, holdingProgram), (_arg5) => singleton.Bind(getAuthorityPda(holdingAccount, holdingProgram), (_arg6) => singleton.Bind(getExoticMainPool(exoticStakeProgram), (_arg7) => {
                    const exoticStakePoolPda = _arg7;
                    return singleton.Bind(getFeeStatePda(exoticStakePoolPda[0], exoticStakeProgram), (_arg8) => {
                        const feeStatePda = _arg8;
                        return singleton.Bind(getMemberAccPda(walletPublicKey, exoticStakePoolPda[0], exoticStakeProgram), (_arg9) => {
                            const memberPda = _arg9;
                            return singleton.Bind(Api.GetExoticFeeState(environment, new PKey(feeStatePda[0].toBase58())), (_arg10) => {
                                const feeStateAcc = _arg10;
                                let performanceFeeRecipient;
                                if (feeStateAcc.tag === 1) {
                                    throw (new Error("Fail to get fee state account"));
                                }
                                else {
                                    performanceFeeRecipient = publicKeyBackEndToSolana(feeStateAcc.fields[0].PerformanceFeeRecipient);
                                }
                                return singleton.Bind(getAssociatedTokenAddress(depositBuyMint, performanceFeeRecipient), (_arg11) => {
                                    const feeRecipientBuyTokenAccount = _arg11;
                                    return singleton.Bind(getAssociatedTokenAddress(depositSellMint, performanceFeeRecipient), (_arg12) => {
                                        const feeRecipientSellTokenAccount = _arg12;
                                        return singleton.Bind(getUserStakeVaultPda(memberPda[0], exoticStakeProgram), (_arg13) => {
                                            const exoTokenKey = publicKeyBackEndToSolana(new PublicKey(0, "ExoMuixummoXLw5BrhAQSwBAmTPG91hSMF2MiYJgJCtB"));
                                            return singleton.Bind(getPoolAuthority(exoticStakePoolPda[0], exoticStakeProgram), (_arg14) => singleton.Combine(singleton.TryWith(singleton.Delay(() => singleton.Bind(getTokenAccountBalance(connection, feeRecipientBuyTokenAccount), (_arg15) => {
                                                return singleton.Zero();
                                            })), (_arg16) => {
                                                throw (new Error("Fee Recipient Buy Token Account Does Not Exist."));
                                                return singleton.Zero();
                                            }), singleton.Delay(() => singleton.Combine(singleton.TryWith(singleton.Delay(() => singleton.Bind(getTokenAccountBalance(connection, feeRecipientSellTokenAccount), (_arg17) => {
                                                return singleton.Zero();
                                            })), (_arg18) => {
                                                throw (new Error("Fee Recipient Sell Token Account Does Not Exist."));
                                                return singleton.Zero();
                                            }), singleton.Delay(() => {
                                                const n2 = 581004692 >>> 0;
                                                const instxBuf1 = encodeU32(bn(2621838007));
                                                const instxBuf2 = encodeU32(bn(n2));
                                                const instructionWithdraw = transactionInstruction(new TransactionInstructionInput(holdingProgram, Buffer.concat([instxBuf1, instxBuf2]), [new TransactionInstructionKey(walletPublicKey, true, true), new TransactionInstructionKey(holdingAccount, false, true), new TransactionInstructionKey(_arg5[0], false, true), new TransactionInstructionKey(product, false, false), new TransactionInstructionKey(_arg1[0], false, true), new TransactionInstructionKey(_arg2[0], false, true), new TransactionInstructionKey(userDepositBuyTokenAcc, false, true), new TransactionInstructionKey(userDepositSellTokenAcc, false, true), new TransactionInstructionKey(_arg6[0], false, false), new TransactionInstructionKey(exoticStakePoolPda[0], false, false), new TransactionInstructionKey(feeStatePda[0], false, false), new TransactionInstructionKey(memberPda[0], false, false), new TransactionInstructionKey(feeRecipientBuyTokenAccount, false, true), new TransactionInstructionKey(feeRecipientSellTokenAccount, false, true), new TransactionInstructionKey(TOKEN_PROGRAM_ID, false, false)]));
                                                const createInstructionTokenAcc = (mint, tokenAcc) => {
                                                    const rent = publicKeyBackEndToSolana(new PublicKey(0, "SysvarRent111111111111111111111111111111111"));
                                                    return transactionInstruction(new TransactionInstructionInput(ASSOCIATED_TOKEN_PROGRAM_ID, Buffer.alloc(0), [new TransactionInstructionKey(walletPublicKey, true, true), new TransactionInstructionKey(tokenAcc, false, true), new TransactionInstructionKey(walletPublicKey, false, false), new TransactionInstructionKey(mint, false, false), new TransactionInstructionKey(PublicKeyDefault, false, false), new TransactionInstructionKey(TOKEN_PROGRAM_ID, false, false), new TransactionInstructionKey(rent, false, false)]));
                                                };
                                                let InstructionCreateMemberAccount;
                                                const rent_1 = publicKeyBackEndToSolana(new PublicKey(0, "SysvarRent111111111111111111111111111111111"));
                                                const b1 = encodeU32(bn(4046270001));
                                                const b2 = encodeU32(bn(1233686394 >>> 0));
                                                InstructionCreateMemberAccount = transactionInstruction(new TransactionInstructionInput(exoticStakeProgram, Buffer.concat([b1, b2]), [new TransactionInstructionKey(walletPublicKey, true, true), new TransactionInstructionKey(exoticStakePoolPda[0], false, false), new TransactionInstructionKey(memberPda[0], false, true), new TransactionInstructionKey(_arg13[0], false, true), new TransactionInstructionKey(exoTokenKey, false, false), new TransactionInstructionKey(_arg14[0], false, false), new TransactionInstructionKey(PublicKeyDefault, false, false), new TransactionInstructionKey(TOKEN_PROGRAM_ID, false, false), new TransactionInstructionKey(rent_1, false, false)]));
                                                const transaction = createTransaction();
                                                return singleton.Combine(singleton.TryWith(singleton.Delay(() => singleton.Bind(getTokenAccountBalance(connection, userDepositBuyTokenAcc), (_arg19) => {
                                                    return singleton.Zero();
                                                })), (_arg20) => {
                                                    transaction.add(createInstructionTokenAcc(depositBuyMint, userDepositBuyTokenAcc));
                                                    return singleton.Zero();
                                                }), singleton.Delay(() => singleton.Combine(singleton.TryWith(singleton.Delay(() => singleton.Bind(getTokenAccountBalance(connection, userDepositSellTokenAcc), (_arg21) => {
                                                    return singleton.Zero();
                                                })), (_arg22) => {
                                                    transaction.add(createInstructionTokenAcc(depositSellMint, userDepositSellTokenAcc));
                                                    return singleton.Zero();
                                                }), singleton.Delay(() => singleton.Bind(Api.MemberAccountAlreadyInit(environment, new PKey(memberPda[0].toBase58())), (_arg23) => {
                                                    const memberAcc = _arg23;
                                                    return singleton.Combine((memberAcc.tag === 1) ? ((() => {
                                                        throw (new Error("Failed to get member account."));
                                                    })(), singleton.Zero()) : (memberAcc.fields[0] ? (void 0, singleton.Zero()) : (transaction.add(InstructionCreateMemberAccount), singleton.Zero())), singleton.Delay(() => {
                                                        transaction.add(instructionWithdraw);
                                                        const matchValue = wallet.wallet;
                                                        if (matchValue == null) {
                                                            return singleton.Return((() => {
                                                                throw (new Error("Wallet Not Found."));
                                                            })());
                                                        }
                                                        else {
                                                            console.log(some("sending to wallet"));
                                                            console.log(some(walletPublicKey.toBase58()));
                                                            console.log(some("connection"));
                                                            console.log(some(connection.toString()));
                                                            return singleton.Bind(signAndSendTransaction(wallet, walletPublicKey, connection, [], transaction), (_arg24) => {
                                                                const result = _arg24;
                                                                console.log(some(result));
                                                                return singleton.Return(result);
                                                            });
                                                        }
                                                    }));
                                                })))));
                                            })))));
                                        });
                                    });
                                });
                            });
                        });
                    });
                })));
            });
        })));
    });
}

