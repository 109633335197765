import { Record } from "../client/src/.fable/fable-library.3.1.16/Types.js";
import { Holding$reflection } from "../../lib/Domain/Types/Holding.fs.js";
import { Product$reflection } from "../../lib/Domain/Types/Product.fs.js";
import { ExoticUnderlying$reflection } from "../../lib/Domain/Types/ExoticUnderlying.fs.js";
import { lambda_type, class_type, option_type, record_type, array_type } from "../client/src/.fable/fable-library.3.1.16/Reflection.js";
import { InjectiveHolding$reflection } from "../../lib/Domain/Types/InjectiveHolding.fs.js";
import { InjectiveProduct__GetKind, InjectiveProduct$reflection } from "../../lib/Domain/Types/InjectiveProduct.fs.js";
import { Option$reflection } from "../../lib/Domain/Types/InjectiveOption.fs.js";
import { Rfq$reflection } from "../../lib/Domain/Types/InjectiveRfq.fs.js";
import { pow, op_Subtraction, op_Addition, round, compare, op_Division, op_Multiply, fromParts, equals } from "../client/src/.fable/fable-library.3.1.16/Decimal.js";
import Decimal from "../client/src/.fable/fable-library.3.1.16/Decimal.js";
import { FlexProduct$reflection, Yield } from "../../lib/Domain/Types/FlexProduct.fs.js";
import { estimatedInjectiveHoldingYield } from "../../lib/Injective/Injective.fs.js";
import { toDecimal } from "../client/src/.fable/fable-library.3.1.16/BigInt.js";
import { toNumber } from "../client/src/.fable/fable-library.3.1.16/Long.js";
import { min } from "../client/src/.fable/fable-library.3.1.16/Util.js";
import { Escrow$reflection } from "../../lib/Domain/Types/InjectiveEscrow.fs.js";
import { SolanaEnvironment$reflection } from "../../lib/Domain/Types/Common.fs.js";

export const StreamingHubEndpoint = "/hub";

export class ExoticProduct extends Record {
    constructor(Holding, Product, Underlying) {
        super();
        this.Holding = Holding;
        this.Product = Product;
        this.Underlying = Underlying;
    }
}

export function ExoticProduct$reflection() {
    return record_type("ExoticMarkets.Shared.SignalRRemoting.ExoticProduct", [], ExoticProduct, () => [["Holding", Holding$reflection()], ["Product", Product$reflection()], ["Underlying", array_type(ExoticUnderlying$reflection())]]);
}

export class InjectiveHoldingProduct extends Record {
    constructor(Holding, Product) {
        super();
        this.Holding = Holding;
        this.Product = Product;
    }
}

export function InjectiveHoldingProduct$reflection() {
    return record_type("ExoticMarkets.Shared.SignalRRemoting.InjectiveHoldingProduct", [], InjectiveHoldingProduct, () => [["Holding", InjectiveHolding$reflection()], ["Product", InjectiveProduct$reflection()]]);
}

export class InjectiveHoldingProductOptionRfq extends Record {
    constructor(Holding, Product, Option, Rfq) {
        super();
        this.Holding = Holding;
        this.Product = Product;
        this.Option = Option;
        this.Rfq = Rfq;
    }
}

export function InjectiveHoldingProductOptionRfq$reflection() {
    return record_type("ExoticMarkets.Shared.SignalRRemoting.InjectiveHoldingProductOptionRfq", [], InjectiveHoldingProductOptionRfq, () => [["Holding", InjectiveHolding$reflection()], ["Product", InjectiveProduct$reflection()], ["Option", option_type(Option$reflection())], ["Rfq", option_type(Rfq$reflection())]]);
}

export function InjectiveHoldingProductOptionRfq__ToHoldingProduct(this$) {
    return new InjectiveHoldingProduct(this$.Holding, this$.Product);
}

export function InjectiveHoldingProductOptionRfq__GetYield_32C73145(this$, spot) {
    let expectedYield;
    const matchValue = this$.Option;
    if (matchValue == null) {
        expectedYield = (equals(spot, fromParts(0, 0, 0, false, 0)) ? (new Yield(0, fromParts(0, 0, 0, false, 0), fromParts(0, 0, 0, false, 0))) : (new Yield(0, estimatedInjectiveHoldingYield(this$.Product, this$.Holding.LimitPrice, spot, void 0), estimatedInjectiveHoldingYield(this$.Product, this$.Holding.FairPrice, spot, void 0))));
    }
    else {
        const op = matchValue;
        expectedYield = (equals(spot, fromParts(0, 0, 0, false, 0)) ? (new Yield(0, fromParts(0, 0, 0, false, 0), fromParts(0, 0, 0, false, 0))) : (new Yield(0, estimatedInjectiveHoldingYield(this$.Product, this$.Holding.LimitPrice, spot, op.Strike), estimatedInjectiveHoldingYield(this$.Product, this$.Holding.FairPrice, spot, op.Strike))));
    }
    const matchValue_1 = this$.Rfq;
    if (matchValue_1 == null) {
        return expectedYield;
    }
    else {
        const matchValue_2 = matchValue_1.Status;
        if (matchValue_2.tag === 0) {
            return expectedYield;
        }
        else {
            return new Yield(1, op_Multiply(op_Division(op_Multiply(op_Division(toDecimal(matchValue_2.fields[0].SelectedBid[1]), toDecimal(this$.Holding.TotalDepositedAmount)), new Decimal(31557600)), new Decimal(toNumber(this$.Product.Duration))), fromParts(100, 0, 0, false, 0)));
        }
    }
}

export function InjectiveHoldingProductOptionRfq__GetPnl(this$) {
    let matchValue_4;
    const matchValue = [this$.Rfq, this$.Option];
    let pattern_matching_result, op, rfq;
    if (matchValue[0] != null) {
        if (matchValue[1] != null) {
            pattern_matching_result = 0;
            op = matchValue[1];
            rfq = matchValue[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            const matchValue_1 = rfq.Status;
            if (matchValue_1.tag === 0) {
                return void 0;
            }
            else {
                const bidderPremium = toDecimal(matchValue_1.fields[0].SelectedBid[1]);
                const one = fromParts(1, 0, 0, false, 0);
                const strike = op.Strike;
                let patternInput_1;
                const matchValue_2 = op.SettlementPrice;
                if (matchValue_2 == null) {
                    patternInput_1 = [fromParts(0, 0, 0, false, 0), false, false];
                }
                else {
                    const x_1 = matchValue_2;
                    patternInput_1 = [x_1, compare(x_1, fromParts(0, 0, 0, false, 0)) > 0, compare(strike, fromParts(0, 0, 0, false, 0)) > 0];
                }
                const settlementPrice = patternInput_1[0];
                let collateralAmount;
                const matchValue_3 = op.CollateralAmount;
                if (matchValue_3.tag === 0) {
                    collateralAmount = toDecimal(matchValue_3.fields[0].Amount);
                }
                else {
                    throw (new Error("Invalid cw20 for collateral"));
                }
                const totalBuyAmount = toDecimal(this$.Holding.TotalDepositedAmount);
                return matchValue_4 = InjectiveProduct__GetKind(this$.Product), (matchValue_4.tag === 1) ? (patternInput_1[2] ? round(op_Multiply(op_Division(op_Addition(bidderPremium, op_Multiply(collateralAmount, op_Subtraction(min((x_3, y_1) => compare(x_3, y_1), one, op_Division(settlementPrice, strike)), one))), totalBuyAmount), fromParts(100, 0, 0, false, 0)), 2) : fromParts(0, 0, 0, false, 0)) : (patternInput_1[1] ? round(op_Multiply(op_Division(op_Addition(bidderPremium, op_Multiply(collateralAmount, op_Subtraction(min((x_2, y) => compare(x_2, y), one, op_Division(strike, settlementPrice)), one))), totalBuyAmount), fromParts(100, 0, 0, false, 0)), 2) : fromParts(0, 0, 0, false, 0));
            }
        }
        case 1: {
            return void 0;
        }
    }
}

export function InjectiveHoldingProductOptionRfq__GetBuySidePayoff(this$, buyerNbOption, collateralTokenDecimal) {
    let matchValue_4;
    const matchValue = [this$.Rfq, this$.Option];
    let pattern_matching_result, op, rfq;
    if (matchValue[0] != null) {
        if (matchValue[1] != null) {
            pattern_matching_result = 0;
            op = matchValue[1];
            rfq = matchValue[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            if (rfq.Status.tag === 0) {
                return void 0;
            }
            else {
                const one = fromParts(1, 0, 0, false, 0);
                const strike = op.Strike;
                let patternInput;
                const matchValue_2 = op.SettlementPrice;
                if (matchValue_2 == null) {
                    patternInput = [fromParts(0, 0, 0, false, 0), false, false];
                }
                else {
                    const x = matchValue_2;
                    patternInput = [x, compare(x, fromParts(0, 0, 0, false, 0)) > 0, compare(strike, fromParts(0, 0, 0, false, 0)) > 0];
                }
                const settlementPrice = patternInput[0];
                let collateralAmount;
                const matchValue_3 = op.CollateralAmount;
                if (matchValue_3.tag === 0) {
                    collateralAmount = toDecimal(matchValue_3.fields[0].Amount);
                }
                else {
                    throw (new Error("Invalid cw20 for collateral"));
                }
                return op_Division(op_Multiply((matchValue_4 = InjectiveProduct__GetKind(this$.Product), (matchValue_4.tag === 1) ? (patternInput[2] ? op_Division(op_Multiply(collateralAmount, op_Subtraction(one, min((x_2, y_1) => compare(x_2, y_1), one, op_Division(settlementPrice, strike)))), pow(fromParts(10, 0, 0, false, 0), collateralTokenDecimal)) : fromParts(0, 0, 0, false, 0)) : (patternInput[1] ? op_Division(op_Multiply(collateralAmount, op_Subtraction(one, min((x_1, y) => compare(x_1, y), one, op_Division(strike, settlementPrice)))), pow(fromParts(10, 0, 0, false, 0), collateralTokenDecimal)) : fromParts(0, 0, 0, false, 0))), buyerNbOption), op.NbOption);
            }
        }
        case 1: {
            return void 0;
        }
    }
}

export class InjectiveEscrowData extends Record {
    constructor(Escrow, Product) {
        super();
        this.Escrow = Escrow;
        this.Product = Product;
    }
}

export function InjectiveEscrowData$reflection() {
    return record_type("ExoticMarkets.Shared.SignalRRemoting.InjectiveEscrowData", [], InjectiveEscrowData, () => [["Escrow", Escrow$reflection()], ["Product", InjectiveHoldingProductOptionRfq$reflection()]]);
}

export class IStreamingHub extends Record {
    constructor(GetFlexProducts, GetExoticProducts) {
        super();
        this.GetFlexProducts = GetFlexProducts;
        this.GetExoticProducts = GetExoticProducts;
    }
}

export function IStreamingHub$reflection() {
    return record_type("ExoticMarkets.Shared.SignalRRemoting.IStreamingHub", [], IStreamingHub, () => [["GetFlexProducts", lambda_type(SolanaEnvironment$reflection(), class_type("Fable.Remoting.IStream`1", [array_type(FlexProduct$reflection())]))], ["GetExoticProducts", lambda_type(SolanaEnvironment$reflection(), class_type("Fable.Remoting.IStream`1", [array_type(ExoticProduct$reflection())]))]]);
}

