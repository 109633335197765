import { createElement } from "react";
import { Interop_reactApi } from "../../.fable/Feliz.1.62.0/Interop.fs.js";

export function BurgerMenu(burgerMenuInputProps) {
    const isOpen = burgerMenuInputProps.isOpen;
    return createElement("svg", {
        width: 40,
        height: 24,
        viewBox: (((((0 + " ") + 0) + " ") + 40) + " ") + 24,
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
        children: Interop_reactApi.Children.toArray([createElement("path", {
            d: "M0 2H40",
            stroke: "currentColor",
            strokeWidth: 3,
        }), createElement("path", {
            d: "M0 12H40",
            stroke: "currentColor",
            strokeWidth: 3,
        }), createElement("path", {
            d: "M0 22H40",
            stroke: "currentColor",
            strokeWidth: 3,
        })]),
    });
}

export function WalletLogo() {
    return createElement("svg", {
        width: 18,
        height: 18,
        viewBox: (((((0 + " ") + 0) + " ") + 18) + " ") + 18,
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
        children: Interop_reactApi.Children.toArray([createElement("path", {
            d: "M1.80775 17.5C1.30908 17.5 0.883083 17.3234 0.52975 16.9703C0.176583 16.6169 0 16.1909 0 15.6923V2.30775C0 1.80908 0.176583 1.38308 0.52975 1.02975C0.883083 0.676583 1.30908 0.5 1.80775 0.5H15.1923C15.6909 0.5 16.1169 0.676583 16.4703 1.02975C16.8234 1.38308 17 1.80908 17 2.30775V5.029H15.5V2.30775C15.5 2.21792 15.4712 2.14417 15.4135 2.0865C15.3558 2.02883 15.2821 2 15.1923 2H1.80775C1.71792 2 1.64417 2.02883 1.5865 2.0865C1.52883 2.14417 1.5 2.21792 1.5 2.30775V15.6923C1.5 15.7821 1.52883 15.8558 1.5865 15.9135C1.64417 15.9712 1.71792 16 1.80775 16H15.1923C15.2821 16 15.3558 15.9712 15.4135 15.9135C15.4712 15.8558 15.5 15.7821 15.5 15.6923V12.971H17V15.6923C17 16.1909 16.8234 16.6169 16.4703 16.9703C16.1169 17.3234 15.6909 17.5 15.1923 17.5H1.80775ZM9.80775 13.5C9.30908 13.5 8.88308 13.3234 8.52975 12.9703C8.17658 12.6169 8 12.1909 8 11.6923V6.30775C8 5.80908 8.17658 5.38308 8.52975 5.02975C8.88308 4.67658 9.30908 4.5 9.80775 4.5H16.1923C16.6909 4.5 17.1169 4.67658 17.4703 5.02975C17.8234 5.38308 18 5.80908 18 6.30775V11.6923C18 12.1909 17.8234 12.6169 17.4703 12.9703C17.1169 13.3234 16.6909 13.5 16.1923 13.5H9.80775ZM16.1923 12C16.2821 12 16.3558 11.9712 16.4135 11.9135C16.4712 11.8558 16.5 11.7821 16.5 11.6923V6.30775C16.5 6.21792 16.4712 6.14417 16.4135 6.0865C16.3558 6.02883 16.2821 6 16.1923 6H9.80775C9.71792 6 9.64417 6.02883 9.5865 6.0865C9.52883 6.14417 9.5 6.21792 9.5 6.30775V11.6923C9.5 11.7821 9.52883 11.8558 9.5865 11.9135C9.64417 11.9712 9.71792 12 9.80775 12H16.1923ZM12.5 10.5C12.9167 10.5 13.2708 10.3542 13.5625 10.0625C13.8542 9.77083 14 9.41667 14 9C14 8.58333 13.8542 8.22917 13.5625 7.9375C13.2708 7.64583 12.9167 7.5 12.5 7.5C12.0833 7.5 11.7292 7.64583 11.4375 7.9375C11.1458 8.22917 11 8.58333 11 9C11 9.41667 11.1458 9.77083 11.4375 10.0625C11.7292 10.3542 12.0833 10.5 12.5 10.5Z",
            fill: "currentColor",
            strokeWidth: 3,
        })]),
    });
}

