import { printf, toText, split, isNullOrWhiteSpace, join } from "./.fable/fable-library.3.1.16/String.js";
import { ofArray, collect, filter } from "./.fable/fable-library.3.1.16/List.js";
import { IServerApi$reflection, routerPaths } from "../../shared/Api.fs.js";
import { RemotingModule_createApi, RemotingModule_withRouteBuilder, Remoting_buildProxy_Z15584635 } from "./.fable/Fable.Remoting.Client.7.18.0/Remoting.fs.js";
import { Client_buildProxy_Z7D14C7A6 } from "../../../lib/Fable.Remoting.SignalR/SignalR.fs.js";
import { IStreamingHub$reflection, StreamingHubEndpoint } from "../../shared/SignalRRemoting.fs.js";

export const virtualPath = eval("window.location.pathname");

export function combine(paths) {
    const arg10 = join("/", filter((arg) => (!isNullOrWhiteSpace(arg)), filter((segment) => (!(segment.indexOf(".") >= 0)), collect((path) => ofArray(split(path, ["/"], null, 0)), paths))));
    return toText(printf("/%s"))(arg10);
}

export function normalize(path) {
    return combine(ofArray([virtualPath, path]));
}

export function normalizeRoutes(typeName, methodName) {
    return normalize(routerPaths(typeName, methodName));
}

export const Api = Remoting_buildProxy_Z15584635(RemotingModule_withRouteBuilder((typeName, methodName) => normalizeRoutes(typeName, methodName), RemotingModule_createApi()), {
    ResolveType: IServerApi$reflection,
});

const patternInput$004040 = Client_buildProxy_Z7D14C7A6(StreamingHubEndpoint, {
    ResolveType: IStreamingHub$reflection,
});

export const signalRproxy = patternInput$004040[1];

export const signalRconnection = patternInput$004040[0];

