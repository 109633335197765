import { Union } from "./.fable/fable-library.3.1.16/Types.js";
import { class_type, union_type } from "./.fable/fable-library.3.1.16/Reflection.js";
import { riskTriangle, marketViewUpActive, marketViewStableActive, marketViewDownActive, riskLowActive, riskHighActive, riskMediumActive } from "./Img.fs.js";
import utils$002Emodule from "./styles/utils.module.scss";
import { createElement } from "react";
import { Interop_reactApi } from "./.fable/Feliz.1.62.0/Interop.fs.js";
import { equals, createObj } from "./.fable/fable-library.3.1.16/Util.js";
import risk$002Emodule from "./styles/risk.module.scss";
import { append as append_1, singleton as singleton_1, ofArray } from "./.fable/fable-library.3.1.16/List.js";
import { printf, interpolate, toText, join } from "./.fable/fable-library.3.1.16/String.js";
import card$002Emodule from "./styles/card.module.scss";
import { empty, singleton, append, delay, toList } from "./.fable/fable-library.3.1.16/Seq.js";
import { fromParts, compare, toNumber } from "./.fable/fable-library.3.1.16/Decimal.js";
import { Helpers_combineClasses } from "./.fable/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import single$002Dproducts$002Emodule from "./styles/single-products.module.scss";
import pools$002Emodule from "./styles/pools.module.scss";
import { defaultArg } from "./.fable/fable-library.3.1.16/Option.js";
import { Col } from "./UtilComponents.fs.js";
import { CurrencyIcon } from "./Currency.fs.js";

export class RiskLevel extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Low", "Medium", "High"];
    }
}

export function RiskLevel$reflection() {
    return union_type("RiskLevel.RiskLevel", [], RiskLevel, () => [[], [], []]);
}

export class GradientColors extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Red", "LightRed", "Yellow", "LightGreen", "Green"];
    }
}

export function GradientColors$reflection() {
    return union_type("RiskLevel.GradientColors", [], GradientColors, () => [[], [], [], [], []]);
}

export function text(level) {
    switch (level.tag) {
        case 1: {
            return "Medium";
        }
        case 2: {
            return "High";
        }
        default: {
            return "Low";
        }
    }
}

export function img(level) {
    switch (level.tag) {
        case 1: {
            return riskMediumActive;
        }
        case 2: {
            return riskHighActive;
        }
        default: {
            return riskLowActive;
        }
    }
}

export function color(level) {
    switch (level.tag) {
        case 1: {
            return utils$002Emodule["text-medium-risk"];
        }
        case 2: {
            return utils$002Emodule["text-high-risk"];
        }
        default: {
            return utils$002Emodule["text-low-risk"];
        }
    }
}

export function borderColor(level) {
    switch (level.tag) {
        case 1: {
            return utils$002Emodule["border-medium-risk"];
        }
        case 2: {
            return utils$002Emodule["border-high-risk"];
        }
        default: {
            return utils$002Emodule["border-low-risk"];
        }
    }
}

export function marketDirectionImage(direction) {
    switch (direction.tag) {
        case 1: {
            return marketViewDownActive;
        }
        case 2: {
            return marketViewStableActive;
        }
        default: {
            return marketViewUpActive;
        }
    }
}

export function riskLevelFromFlexProduct(riskLevel) {
    let risk_2;
    if (riskLevel <= (85 & 0xFF)) {
        return new RiskLevel(0);
    }
    else if (risk_2 = riskLevel, (risk_2 > (85 & 0xFF)) ? (risk_2 <= (170 & 0xFF)) : false) {
        return new RiskLevel(1);
    }
    else if (riskLevel > (170 & 0xFF)) {
        return new RiskLevel(2);
    }
    else {
        return new RiskLevel(2);
    }
}

export class Components {
    constructor() {
    }
}

export function Components$reflection() {
    return class_type("RiskLevel.Components", void 0, Components);
}

export function Components_$ctor() {
    return new Components();
}

export function Components_RiskLevel_E9CE1CB(level) {
    let value, value_2;
    return createElement("div", {
        children: Interop_reactApi.Children.toArray([createElement("div", createObj(ofArray([(value = risk$002Emodule.level, ["className", value]), ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(ofArray([(value_2 = risk$002Emodule["risk-icon"], ["className", value_2]), ["children", Interop_reactApi.Children.toArray([createElement("img", {
            src: img(level),
        })])]]))), createElement("div", {
            className: join(" ", [utils$002Emodule.col, utils$002Emodule.center]),
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: join(" ", [card$002Emodule["strategy-header"]]),
                children: text(level),
            })]),
        })])]])))]),
    });
}

export function Components_MarketView(marketLevel, widthView) {
    const patternInput = [(10 - Math.abs(marketLevel[0])) * 5, (10 - Math.abs(marketLevel[1])) * 5];
    const value2 = patternInput[1] | 0;
    const value1 = patternInput[0] | 0;
    const clip = toText(interpolate("inset(0%% %P()%% 0%% %P()%% round 10px)", [value2, value1]));
    const borderOffset = 1;
    const clipBorder = toText(interpolate("inset(0%% %P()%% 0%% %P()%% round 10px)", [value2 - borderOffset, value1 - borderOffset]));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: join(" ", []),
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(createElement("div", {
                style: {
                    width: widthView,
                },
                className: join(" ", [risk$002Emodule["market-view-outer"]]),
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", [risk$002Emodule["market-view-inner-border"]]),
                    style: {
                        clipPath: clipBorder,
                    },
                    children: Interop_reactApi.Children.toArray([createElement("div", {
                        className: join(" ", [risk$002Emodule["market-view-inner"]]),
                        style: {
                            clipPath: clip,
                        },
                    })]),
                })]),
            })), delay(() => {
                const legendClasses = (widthView > 150) ? singleton_1(utils$002Emodule["boldness-500"]) : singleton_1(utils$002Emodule["text-xs"]);
                return singleton(createElement("div", {
                    className: join(" ", [risk$002Emodule["market-view-legend-row"]]),
                    children: Interop_reactApi.Children.toArray([createElement("div", {
                        className: join(" ", append_1(singleton_1(risk$002Emodule["market-view-legend-col1"]), legendClasses)),
                        children: Interop_reactApi.Children.toArray(["Bearish"]),
                    }), createElement("div", {
                        className: join(" ", append_1(ofArray([risk$002Emodule["market-view-legend-col2"], utils$002Emodule["text-end"]]), legendClasses)),
                        children: Interop_reactApi.Children.toArray(["Bullish"]),
                    })]),
                }));
            })))))),
        })]),
    });
}

export function Components_RemainingSize(remainingSize, widthView) {
    let props;
    return createElement("div", {
        children: Interop_reactApi.Children.toArray([(props = ofArray([["className", "is-primary"], ["value", toNumber(remainingSize)], ["max", 100]]), createElement("progress", createObj(Helpers_combineClasses("progress", props)))), createElement("div", {
            className: join(" ", []),
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: join(" ", [utils$002Emodule.center]),
                children: Interop_reactApi.Children.toArray([toText(printf("%0.1f %%"))(remainingSize)]),
            })]),
        })]),
    });
}

export function Components_RiskBlock_E9CE1CB(selectedLevel) {
    const level_1 = (level, marginRight) => {
        let value_1;
        return createElement("div", {
            className: join(" ", toList(delay(() => append(singleton(risk$002Emodule.block), delay(() => append(singleton(utils$002Emodule["h-center"]), delay(() => append(singleton(utils$002Emodule["v-center"]), delay(() => append(equals(selectedLevel, level) ? singleton(color(level)) : empty(), delay(() => (equals(selectedLevel, level) ? singleton(borderColor(level)) : empty())))))))))))),
            children: Interop_reactApi.Children.toArray([createElement("span", createObj(ofArray([(value_1 = utils$002Emodule["v-center"], ["className", value_1]), ["children", Interop_reactApi.Children.toArray([createElement("img", {
                style: createObj(toList(delay(() => append(singleton(["marginRight", marginRight + "px"]), delay(() => (equals(level, selectedLevel) ? singleton(["color", color(level)]) : empty())))))),
                className: join(" ", toList(delay(() => ((!equals(selectedLevel, level)) ? singleton(risk$002Emodule["block-icon"]) : empty())))),
                src: img(level),
            })])]]))), createElement("span", {
                children: text(level),
            })]),
        });
    };
    const children = ofArray([level_1(new RiskLevel(2), 16), level_1(new RiskLevel(1), 16), level_1(new RiskLevel(0), 16)]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function Components_RiskGuage_5BE52527(title, percent, props) {
    let value_7, value_11, value_27, value_37;
    return createElement("div", {
        style: {
            width: 100 + "%",
            position: "relative",
            paddingBottom: 30,
        },
        children: Interop_reactApi.Children.toArray([createElement("h4", createObj(ofArray([(value_7 = risk$002Emodule.title, ["className", value_7]), ["children", title]]))), createElement("div", createObj(ofArray([(value_11 = risk$002Emodule.scale, ["className", value_11]), ["children", Interop_reactApi.Children.toArray([createElement("div", {
            children: "0",
        }), createElement("div", {
            children: "2",
        }), createElement("div", {
            children: "4",
        }), createElement("div", {
            children: "6",
        }), createElement("div", {
            children: "8",
        }), createElement("div", {
            children: "10",
        })])]]))), createElement("div", {
            className: join(" ", [risk$002Emodule.gauge]),
            children: Interop_reactApi.Children.toArray([createElement("div", createObj(ofArray([(value_27 = risk$002Emodule.marks, ["className", value_27]), ["children", Interop_reactApi.Children.toArray([createElement("div", {
                className: join(" ", [risk$002Emodule.mark]),
            }), createElement("div", {
                className: join(" ", [risk$002Emodule.mark]),
            }), createElement("div", {
                className: join(" ", [risk$002Emodule.mark]),
            }), createElement("div", {
                className: join(" ", [risk$002Emodule.mark]),
            }), createElement("div", {
                className: join(" ", [risk$002Emodule.mark]),
            }), createElement("div", {
                className: join(" ", [risk$002Emodule.mark]),
            })])]])))]),
        }), createElement("img", createObj(ofArray([(value_37 = risk$002Emodule.marker, ["className", value_37]), ["src", riskTriangle], ["style", {
            left: percent + "%",
        }]])))]),
    });
}

export function Components_MaturityDetail(days, classes) {
    let value, value_2;
    return createElement("div", {
        children: Interop_reactApi.Children.toArray([createElement("div", createObj(ofArray([(value = single$002Dproducts$002Emodule["product-info-badge"], ["className", value]), ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(ofArray([(value_2 = single$002Dproducts$002Emodule["product-info-icon"], ["className", value_2]), ["children", Interop_reactApi.Children.toArray([createElement("div", {
            className: join(" ", classes),
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: join(" ", [pools$002Emodule["duration-col"], utils$002Emodule["padding-s"]]),
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", toList(delay(() => append(singleton(pools$002Emodule["duration-marker"]), delay(() => ((compare(days, fromParts(10, 0, 0, false, 1)) >= 0) ? singleton(pools$002Emodule["duration-marker-filled"]) : empty())))))),
                }), createElement("div", {
                    className: join(" ", toList(delay(() => append(singleton(pools$002Emodule["duration-marker"]), delay(() => ((compare(days, fromParts(30, 0, 0, false, 1)) >= 0) ? singleton(pools$002Emodule["duration-marker-filled"]) : empty())))))),
                }), createElement("div", {
                    className: join(" ", toList(delay(() => append(singleton(pools$002Emodule["duration-marker"]), delay(() => ((compare(days, fromParts(70, 0, 0, false, 1)) >= 0) ? singleton(pools$002Emodule["duration-marker-filled"]) : empty())))))),
                })]),
            })]),
        })])]]))), createElement("div", {
            className: join(" ", [utils$002Emodule.col, utils$002Emodule.center]),
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: join(" ", [card$002Emodule["strategy-header"]]),
                children: toText(interpolate("%P() d", [days])),
            })]),
        })])]])))]),
    });
}

export function Components_DespoitCurrency_6A08185(depositMint) {
    let value;
    const patternInput = [depositMint.symbol, depositMint.logoURI];
    return createElement("div", {
        children: Interop_reactApi.Children.toArray([createElement("div", createObj(ofArray([(value = single$002Dproducts$002Emodule["product-info-badge"], ["className", value]), ["children", Interop_reactApi.Children.toArray([createElement("div", {
            className: join(" ", [single$002Dproducts$002Emodule["product-info-icon"], utils$002Emodule["padding-s"]]),
            children: Interop_reactApi.Children.toArray([createElement("img", {
                style: {
                    width: 32,
                    height: 32,
                },
                src: defaultArg(depositMint.logoURI, ""),
            })]),
        }), createElement("div", {
            className: join(" ", [utils$002Emodule.col, utils$002Emodule.center]),
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: join(" ", [card$002Emodule["strategy-header"]]),
                children: toText(interpolate("%P()", [patternInput[0]])),
            })]),
        })])]])))]),
    });
}

export function Components_ProductPair(leftMint, rightMint) {
    let value;
    return createElement("div", {
        children: Interop_reactApi.Children.toArray([createElement("div", createObj(ofArray([(value = single$002Dproducts$002Emodule["product-info-badge"], ["className", value]), ["children", Interop_reactApi.Children.toArray([createElement(Col, {
            classes: singleton_1(utils$002Emodule["gap-xs"]),
            children: ofArray([createElement(CurrencyIcon, {
                depositMint: leftMint,
            }), createElement(CurrencyIcon, {
                depositMint: rightMint,
            })]),
        }), createElement(Col, {
            classes: singleton_1(utils$002Emodule.center),
            children: singleton_1(createElement("div", {
                className: join(" ", [card$002Emodule["strategy-header"]]),
                children: toText(interpolate("%P()-%P()", [leftMint.symbol, rightMint.symbol])),
            })),
        })])]])))]),
    });
}

