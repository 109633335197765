import { createElement } from "react";
import { printf, interpolate, toText, join } from "./.fable/fable-library.3.1.16/String.js";
import single$002Dproducts$002Emodule from "./styles/single-products.module.scss";
import utils$002Emodule from "./styles/utils.module.scss";
import { Interop_reactApi } from "./.fable/Feliz.1.62.0/Interop.fs.js";
import { displayMaturity, Column, TextColumn, CheckOnSolanaExplorer, Header } from "./UtilComponents.fs.js";
import { empty, singleton as singleton_2, ofArray } from "./.fable/fable-library.3.1.16/List.js";
import { some, defaultArg } from "./.fable/fable-library.3.1.16/Option.js";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { useSnackbar } from "notistack";
import { Recoil_useRecoilValue_5D3EEEEA } from "./.fable/Feliz.Recoil.0.19.2/Bindings.fs.js";
import { solanaEnvironment, config as config_2 } from "./Context.fs.js";
import { useReact_useCallback_93353E } from "./.fable/Feliz.1.62.0/React.fs.js";
import { startImmediate } from "./.fable/fable-library.3.1.16/Async.js";
import { singleton } from "./.fable/fable-library.3.1.16/AsyncBuilder.js";
import { ProviderContext__enqueueSnackbar_33B60434, EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../../../lib/Notistack/Notistack.fs.js";
import { withdraw as withdraw_1 } from "./BlockChainInteraction/WithdrawFlexProductV2.fs.js";
import { PublicKey } from "../../../lib/Domain/Types/Common.fs.js";
import { floorDecimal } from "./BlockChainInteraction/InjectiveHoldingDeposit.fs.js";
import { fromParts, equals } from "./.fable/fable-library.3.1.16/Decimal.js";
import Decimal from "./.fable/fable-library.3.1.16/Decimal.js";
import { mintColor } from "./Currency.fs.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "./.fable/fable-library.3.1.16/Seq.js";
import { TimeCountdown } from "./DisplayTime.fs.js";
import { totalSeconds } from "./.fable/fable-library.3.1.16/TimeSpan.js";
import { MuiHelpers_createElement } from "./.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import { withdraw as withdraw_2 } from "./BlockChainInteraction/ExoticProductWithdraw.fs.js";
import { Holding__WithdrawalAllowed, Holding__Yield } from "../../../lib/Domain/Types/Holding.fs.js";
import { estimatedInjectiveHoldingYield, useWalletStore } from "../../../lib/Injective/Injective.fs.js";

export function Headers(headersInputProps) {
    const buttonHeader = headersInputProps.buttonHeader;
    return createElement("div", {
        className: join(" ", [single$002Dproducts$002Emodule["subscription-row"], utils$002Emodule["margin-l-top"], utils$002Emodule["padding-m-left"]]),
        children: Interop_reactApi.Children.toArray([createElement(Header, {
            text: "",
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-1"]]),
        }), createElement(Header, {
            text: "Est. Yield",
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-2"]]),
        }), createElement(Header, {
            text: "Strike Price",
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-3"]]),
        }), createElement(Header, {
            text: "Size",
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-4"]]),
        }), createElement(Header, {
            text: "Maturity",
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-5"]]),
        }), createElement(Header, {
            text: "Ending in",
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-6"]]),
        }), createElement(Header, {
            text: defaultArg(buttonHeader, ""),
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-7"]]),
        })]),
    });
}

export function Subscription(subscriptionInputProps) {
    const isProvisionalPosition = subscriptionInputProps.isProvisionalPosition;
    const button = subscriptionInputProps.button;
    const duration = subscriptionInputProps.duration;
    const maturity = subscriptionInputProps.maturity;
    const size = subscriptionInputProps.size;
    const strikePrice = subscriptionInputProps.strikePrice;
    const token = subscriptionInputProps.token;
    const productName = subscriptionInputProps.productName;
    const product = subscriptionInputProps.product;
    const walletCtx = useWallet();
    const connection = useConnection().connection;
    const snackbar = useSnackbar();
    const config = Recoil_useRecoilValue_5D3EEEEA(config_2);
    const withDrawCallback = useReact_useCallback_93353E(() => {
        startImmediate(singleton.Delay(() => {
            const matchValue = [walletCtx.publicKey, config];
            if (matchValue[0] == null) {
                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "No Wallet Public Key Found.", SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(0, false));
                return singleton.Zero();
            }
            else if (matchValue[1] == null) {
                console.error(some("No config Found."));
                return singleton.Zero();
            }
            else {
                const config_1 = matchValue[1];
                const publicKey = matchValue[0];
                return singleton.Bind(withdraw_1(connection, new PublicKey(0, config_1.VaultProgramId), publicKey, walletCtx, product), (_arg1) => {
                    let children;
                    const result = _arg1;
                    if (result.tag === 1) {
                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, result.fields[0], SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(0, false));
                        return singleton.Zero();
                    }
                    else {
                        const signature = result.fields[0];
                        console.log(some(signature));
                        ProviderContext__enqueueSnackbar_33B60434(snackbar, (children = ofArray([createElement("p", {
                            children: [""],
                        }), createElement(CheckOnSolanaExplorer, {
                            signature: signature,
                        })]), createElement("div", {
                            children: Interop_reactApi.Children.toArray(Array.from(children)),
                        })), SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(0, false));
                        return singleton.Zero();
                    }
                });
            }
        }));
    }, []);
    let size_1;
    let sizeText;
    const rounded = floorDecimal(2, new Decimal(size));
    sizeText = (equals(rounded, fromParts(0, 0, 0, false, 0)) ? "\u003c 0.01" : toText(interpolate("%P()", [rounded])));
    size_1 = createElement("div", {
        children: Interop_reactApi.Children.toArray([toText(interpolate("%P() ", [sizeText])), createElement("span", {
            className: join(" ", [mintColor(token.symbol), utils$002Emodule["boldness-500"]]),
            children: token.symbol,
        })]),
    });
    return createElement("div", {
        className: join(" ", [single$002Dproducts$002Emodule["subscription-row"], utils$002Emodule["margin-s-top"], "card"]),
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(createElement(TextColumn, {
            text: productName,
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-1"]]),
        })), delay(() => append(singleton_1(createElement(TextColumn, {
            text: toText(interpolate("%P()", [product.Yield])),
            classes: ofArray([utils$002Emodule.center, single$002Dproducts$002Emodule["estimated-yield-column"], utils$002Emodule["grid-column-2"]]),
        })), delay(() => append(singleton_1(createElement(TextColumn, {
            text: strikePrice,
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-3"]]),
        })), delay(() => append(singleton_1(createElement(Column, {
            children: singleton_2(size_1),
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-4"]]),
        })), delay(() => append(singleton_1(createElement(Column, {
            children: singleton_2(displayMaturity(maturity, empty(), false)),
            classes: ofArray([utils$002Emodule.center, single$002Dproducts$002Emodule["maturity-column"], utils$002Emodule["grid-column-5"]]),
        })), delay(() => append(singleton_1(createElement(Column, {
            children: singleton_2(createElement(TimeCountdown, {
                seconds: ~(~totalSeconds(duration)),
            })),
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["no-wrap"], utils$002Emodule["grid-column-6"]]),
        })), delay(() => {
            let button_1;
            return append((button == null) ? singleton_1(null) : (button_1 = button, singleton_1(createElement(Column, {
                children: singleton_2(button_1),
                classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-7"]]),
            }))), delay(() => append(product.CollateralCollected ? singleton_1(createElement(Column, {
                children: singleton_2(createElement("button", {
                    className: join(" ", ["button-dropdown"]),
                    children: "Withdraw",
                    onClick: (_arg1_1) => {
                        withDrawCallback();
                    },
                })),
                classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-7"]]),
            })) : empty_1(), delay(() => {
                if (isProvisionalPosition) {
                    const txt = MuiHelpers_createElement(Typography, [["style", {
                        whiteSpace: "normal",
                    }], ["style", {
                        fontSize: 13 + "px",
                    }], ["children", "Awaiting Confirmation"]]);
                    return singleton_1(createElement("div", {
                        className: join(" ", [utils$002Emodule.center, utils$002Emodule["grid-column-8"]]),
                        style: {
                            width: 200,
                        },
                        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Chip, [["label", txt], ["color", "secondary"], ["size", "small"]])]),
                    }));
                }
                else {
                    return singleton_1(createElement(TextColumn, {
                        text: "",
                        classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-8"]]),
                    }));
                }
            }))));
        })))))))))))))))),
    });
}

export function HoldingSubscription(holdingSubscriptionInputProps) {
    const isProvisionalPosition = holdingSubscriptionInputProps.isProvisionalPosition;
    const button = holdingSubscriptionInputProps.button;
    const duration = holdingSubscriptionInputProps.duration;
    const maturity = holdingSubscriptionInputProps.maturity;
    const size = holdingSubscriptionInputProps.size;
    const strikePrice = holdingSubscriptionInputProps.strikePrice;
    const token = holdingSubscriptionInputProps.token;
    const productName = holdingSubscriptionInputProps.productName;
    const product = holdingSubscriptionInputProps.product;
    const walletCtx = useWallet();
    const connection = useConnection().connection;
    const snackbar = useSnackbar();
    const config = Recoil_useRecoilValue_5D3EEEEA(config_2);
    const environment = Recoil_useRecoilValue_5D3EEEEA(solanaEnvironment);
    const withDrawCallback = useReact_useCallback_93353E(() => {
        startImmediate(singleton.Delay(() => {
            const matchValue = [walletCtx.publicKey, config];
            if (matchValue[0] == null) {
                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "No Wallet Public Key Found.", SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(0, false));
                return singleton.Zero();
            }
            else if (matchValue[1] == null) {
                console.error(some("No config Found."));
                return singleton.Zero();
            }
            else {
                const config_1 = matchValue[1];
                const publicKey = matchValue[0];
                return singleton.Bind(withdraw_2(connection, environment, new PublicKey(0, config_1.HoldingProgramId), new PublicKey(0, config_1.ExoticStakeProgramId), publicKey, walletCtx, product.Holding), (_arg1) => {
                    let children;
                    const result = _arg1;
                    if (result.tag === 1) {
                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, result.fields[0], SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(0, false));
                        return singleton.Zero();
                    }
                    else {
                        const signature = result.fields[0];
                        console.log(some(signature));
                        ProviderContext__enqueueSnackbar_33B60434(snackbar, (children = ofArray([createElement("p", {
                            children: [""],
                        }), createElement(CheckOnSolanaExplorer, {
                            signature: signature,
                        })]), createElement("div", {
                            children: Interop_reactApi.Children.toArray(Array.from(children)),
                        })), SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(0, false));
                        return singleton.Zero();
                    }
                });
            }
        }));
    }, []);
    const size_1 = createElement("div", {
        children: Interop_reactApi.Children.toArray([toText(interpolate("%P() ", [size])), createElement("span", {
            className: join(" ", [mintColor(token.symbol), utils$002Emodule["boldness-500"]]),
            children: token.symbol,
        })]),
    });
    return createElement("div", {
        className: join(" ", [single$002Dproducts$002Emodule["subscription-row"], utils$002Emodule["margin-s-top"], "card"]),
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(createElement(TextColumn, {
            text: productName,
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-1"]]),
        })), delay(() => append(singleton_1(createElement(TextColumn, {
            text: toText(interpolate("%P()", [Holding__Yield(product.Holding)])),
            classes: ofArray([utils$002Emodule.center, single$002Dproducts$002Emodule["estimated-yield-column"], utils$002Emodule["grid-column-2"]]),
        })), delay(() => append(singleton_1(createElement(TextColumn, {
            text: strikePrice,
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-3"]]),
        })), delay(() => append(singleton_1(createElement(Column, {
            children: singleton_2(size_1),
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-4"]]),
        })), delay(() => append(singleton_1(createElement(Column, {
            children: singleton_2(displayMaturity(maturity, empty(), false)),
            classes: ofArray([utils$002Emodule.center, single$002Dproducts$002Emodule["maturity-column"], utils$002Emodule["grid-column-5"]]),
        })), delay(() => append(singleton_1(createElement(Column, {
            children: singleton_2(createElement(TimeCountdown, {
                seconds: ~(~totalSeconds(duration)),
            })),
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["no-wrap"], utils$002Emodule["grid-column-6"]]),
        })), delay(() => {
            let button_1;
            return append((button == null) ? singleton_1(null) : (button_1 = button, singleton_1(createElement(Column, {
                children: singleton_2(button_1),
                classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-7"]]),
            }))), delay(() => append(Holding__WithdrawalAllowed(product.Holding) ? singleton_1(createElement(Column, {
                children: singleton_2(createElement("button", {
                    className: join(" ", ["button-dropdown"]),
                    children: "Withdraw",
                    onClick: (_arg1_1) => {
                        withDrawCallback();
                    },
                })),
                classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-7"]]),
            })) : empty_1(), delay(() => {
                if (isProvisionalPosition) {
                    const txt = MuiHelpers_createElement(Typography, [["style", {
                        whiteSpace: "normal",
                    }], ["style", {
                        fontSize: 13 + "px",
                    }], ["children", "Awaiting Confirmation"]]);
                    return singleton_1(createElement("div", {
                        className: join(" ", [utils$002Emodule.center, utils$002Emodule["grid-column-8"]]),
                        style: {
                            width: 200,
                        },
                        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Chip, [["label", txt], ["color", "secondary"], ["size", "small"]])]),
                    }));
                }
                else {
                    return singleton_1(createElement(TextColumn, {
                        text: "",
                        classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-8"]]),
                    }));
                }
            }))));
        })))))))))))))))),
    });
}

export function InjectiveHoldingSubscription(injectiveHoldingSubscriptionInputProps) {
    const spot = injectiveHoldingSubscriptionInputProps.spot;
    const isProvisionalPosition = injectiveHoldingSubscriptionInputProps.isProvisionalPosition;
    const button = injectiveHoldingSubscriptionInputProps.button;
    const duration = injectiveHoldingSubscriptionInputProps.duration;
    const maturity = injectiveHoldingSubscriptionInputProps.maturity;
    const size = injectiveHoldingSubscriptionInputProps.size;
    const strikePrice = injectiveHoldingSubscriptionInputProps.strikePrice;
    const token = injectiveHoldingSubscriptionInputProps.token;
    const productName = injectiveHoldingSubscriptionInputProps.productName;
    const product = injectiveHoldingSubscriptionInputProps.product;
    const wallet = useWalletStore();
    const snackbar = useSnackbar();
    const config = Recoil_useRecoilValue_5D3EEEEA(config_2);
    const withDrawCallback = useReact_useCallback_93353E(() => {
        startImmediate(singleton.Delay(() => {
            return singleton.Zero();
        }));
    }, []);
    let size_1;
    let sizeText;
    const rounded = floorDecimal(2, new Decimal(size));
    sizeText = (equals(rounded, fromParts(0, 0, 0, false, 0)) ? "\u003c 0.01" : toText(interpolate("%P()", [rounded])));
    size_1 = createElement("div", {
        children: Interop_reactApi.Children.toArray([toText(interpolate("%P() ", [sizeText])), createElement("span", {
            className: join(" ", [mintColor(token.symbol), utils$002Emodule["boldness-500"]]),
            children: token.symbol,
        })]),
    });
    return createElement("div", {
        className: join(" ", [single$002Dproducts$002Emodule["subscription-row"], utils$002Emodule["margin-s-top"], "card"]),
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(createElement(TextColumn, {
            text: productName,
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-1"]]),
        })), delay(() => {
            let arg10;
            return append(singleton_1(createElement(TextColumn, {
                text: (arg10 = (equals(spot, fromParts(0, 0, 0, false, 0)) ? fromParts(0, 0, 0, false, 0) : estimatedInjectiveHoldingYield(product.Product, product.Holding.FairPrice, spot, void 0)), toText(printf("%0.01f%%"))(arg10)),
                classes: ofArray([utils$002Emodule.center, single$002Dproducts$002Emodule["estimated-yield-column"], utils$002Emodule["grid-column-2"]]),
            })), delay(() => append(singleton_1(createElement(TextColumn, {
                text: strikePrice,
                classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-3"]]),
            })), delay(() => append(singleton_1(createElement(Column, {
                children: singleton_2(size_1),
                classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-4"]]),
            })), delay(() => append(singleton_1(createElement(Column, {
                children: singleton_2(displayMaturity(maturity, empty(), false)),
                classes: ofArray([utils$002Emodule.center, single$002Dproducts$002Emodule["maturity-column"], utils$002Emodule["grid-column-5"]]),
            })), delay(() => append(singleton_1(createElement(Column, {
                children: singleton_2(createElement(TimeCountdown, {
                    seconds: ~(~totalSeconds(duration)),
                })),
                classes: ofArray([utils$002Emodule.center, utils$002Emodule["no-wrap"], utils$002Emodule["grid-column-6"]]),
            })), delay(() => {
                let button_1;
                return append((button == null) ? singleton_1(null) : (button_1 = button, singleton_1(createElement(Column, {
                    children: singleton_2(button_1),
                    classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-7"]]),
                }))), delay(() => append(empty_1(), delay(() => {
                    if (isProvisionalPosition) {
                        const txt = MuiHelpers_createElement(Typography, [["style", {
                            whiteSpace: "normal",
                        }], ["style", {
                            fontSize: 13 + "px",
                        }], ["children", "Awaiting Confirmation"]]);
                        return singleton_1(createElement("div", {
                            className: join(" ", [utils$002Emodule.center, utils$002Emodule["grid-column-8"]]),
                            style: {
                                width: 200,
                            },
                            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Chip, [["label", txt], ["color", "secondary"], ["size", "small"]])]),
                        }));
                    }
                    else {
                        return singleton_1(createElement(TextColumn, {
                            text: "",
                            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-8"]]),
                        }));
                    }
                }))));
            }))))))))));
        })))))),
    });
}

