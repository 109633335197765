import { isNullOrWhiteSpace } from "../../app/client/src/.fable/fable-library.3.1.16/String.js";
import { toString, Record, Union } from "../../app/client/src/.fable/fable-library.3.1.16/Types.js";
import { record_type, class_type, union_type } from "../../app/client/src/.fable/fable-library.3.1.16/Reflection.js";
import { str as str_1, publicKey, u8, u32, u128, u64, rustEnum } from "@project-serum/borsh";
import { struct } from "buffer-layout";
import { some } from "../../app/client/src/.fable/fable-library.3.1.16/Option.js";
import { map } from "../../app/client/src/.fable/fable-library.3.1.16/Array.js";
import { bn } from "./Solana.fs.js";

export function camelCase(input) {
    let copyOfStruct;
    if (isNullOrWhiteSpace(input)) {
        return "";
    }
    else {
        return (copyOfStruct = input[0], copyOfStruct).toLocaleLowerCase() + input.slice(1, input.length);
    }
}

export function getName(f) {
    return f();
}

export class Direction extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Buy", "Sell"];
    }
}

export function Direction$reflection() {
    return union_type("Layout.Direction", [], Direction, () => [[], []]);
}

export const directionLayout = rustEnum([struct([], "buy"), struct([], "sell")]);

export const directionLayoutSpan = Math.max(...Object.values(directionLayout.registry).map((r) => r.span));

export function encodeDirection(direction) {
    const data = (direction.tag === 1) ? {
        sell: null,
    } : {
        buy: null,
    };
    const buffer = Buffer.alloc(directionLayoutSpan);
    buffer.slice(0, directionLayout.encode(data, buffer));
}

export class LimitOrder extends Record {
    constructor(Direction, Size, Rate) {
        super();
        this.Direction = Direction;
        this.Size = Size;
        this.Rate = Rate;
    }
}

export function LimitOrder$reflection() {
    return record_type("Layout.LimitOrder", [], LimitOrder, () => [["Direction", Direction$reflection()], ["Size", class_type("Solana.BigInt")], ["Rate", class_type("Solana.BigInt")]]);
}

export const limitOrderInitLayout = struct([directionLayout.replicate("dir"), u64("size"), u128("rate")]);

export function encodeLimitOrderInit(data) {
    const data_1 = Object.assign({}, data);
    const buffer = Buffer.alloc(10);
    buffer.slice(0, limitOrderInitLayout.encode(data_1, buffer));
    return buffer;
}

export class MOOrder extends Record {
    constructor(Direction, Size) {
        super();
        this.Direction = Direction;
        this.Size = Size;
    }
}

export function MOOrder$reflection() {
    return record_type("Layout.MOOrder", [], MOOrder, () => [["Direction", Direction$reflection()], ["Size", class_type("Solana.BN")]]);
}

export const marketOrderInitLayout = struct([u64("size")]);

export function encodeMarketOrderInit(data) {
    const data_1 = {
        size: data.Size,
    };
    const buffer = Buffer.alloc(8);
    buffer.slice(0, marketOrderInitLayout.encode(data_1, buffer));
    return buffer;
}

export class Amount extends Record {
    constructor(Size) {
        super();
        this.Size = Size;
    }
}

export function Amount$reflection() {
    return record_type("Layout.Amount", [], Amount, () => [["Size", class_type("Solana.BN")]]);
}

export const amountLayout = struct([u64("size")]);

export class LPOrder extends Record {
    constructor(Direction, Size) {
        super();
        this.Direction = Direction;
        this.Size = Size;
    }
}

export function LPOrder$reflection() {
    return record_type("Layout.LPOrder", [], LPOrder, () => [["Direction", Direction$reflection()], ["Size", class_type("Solana.BigInt")]]);
}

export const lpOrderInitLayout = struct([directionLayout.replicate("dir"), u64(getName((x) => "size"))]);

export function encodeLPOrderInit(data) {
    const data_1 = {
        dir: data.Direction,
        size: data.Size,
    };
    const buffer = Buffer.alloc(8);
    buffer.slice(0, lpOrderInitLayout.encode(data_1, buffer));
    return buffer;
}

export class Order extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LPOrder", "MOOrder", "LimitOrder"];
    }
}

export function Order$reflection() {
    return union_type("Layout.Order", [], Order, () => [[["Item", LPOrder$reflection()]], [["Item", MOOrder$reflection()]], [["Item", LimitOrder$reflection()]]]);
}

export const orderLayout = rustEnum([lpOrderInitLayout.replicate("lp"), marketOrderInitLayout.replicate("mo"), limitOrderInitLayout.replicate("limit")]);

export function encodeOrder(order) {
    const data = (order.tag === 1) ? {
        mo: order.fields[0],
    } : ((order.tag === 2) ? {
        limit: order.fields[0],
    } : {
        lp: order.fields[0],
    });
    const buffer = Buffer.alloc(8);
    buffer.slice(0, orderLayout.encode(data, buffer));
    return buffer;
}

export const u32Layout = struct([u32("u")]);

export function encodeU32(pk) {
    const data = {
        u: pk,
    };
    const buffer = Buffer.alloc(4);
    u32Layout.encode(data, buffer);
    return buffer;
}

export const u8Layout = struct([u8("u")]);

export function encodeU8(pk) {
    const data = {
        u: pk,
    };
    const buffer = Buffer.alloc(1);
    u8Layout.encode(data, buffer);
    return buffer;
}

export const u32Layout2 = struct([u32("u")], "bn");

export function encodeU322(pk) {
    const data = {
        u: {
            bn: pk,
        },
    };
    const buffer = Buffer.alloc(4);
    const limit = u32Layout2.encode(data, buffer);
    return buffer;
}

export const publicKeyLayout = struct([publicKey("pk")], "pkey");

export function encodePublicKey(pk) {
    const data = {
        pkey: {
            pk: pk,
        },
    };
    const buffer = Buffer.alloc(32);
    const limit = publicKeyLayout.encode(data, buffer);
    return buffer;
}

export const u64Layout = struct([u64("u")]);

export function encodeU64(pk) {
    const data = {
        u: pk,
    };
    const buffer = Buffer.alloc(8);
    u64Layout.encode(data, buffer);
    return buffer;
}

export const u64Layout2 = u64("u");

export function encodeU642(pk) {
    const data = {
        u: pk,
    };
    const buffer = Buffer.alloc(8);
    const limit = u64Layout2.encode(data, buffer);
    return buffer;
}

export const stringLayout = str_1("str");

export function encodeString(str) {
    console.log(some("encodeString"));
    const data = {
        str: str,
    };
    const buffer = Buffer.alloc(1000);
    const limit = stringLayout.encode(data, buffer);
    console.log(some("limit: " + toString(limit)));
    buffer.slice(0, limit);
    return buffer;
}

export function encodeString2(str) {
    const arr = map((x) => (Buffer.alloc(1, x)), map((value_1) => (value_1 & 0xFF), map((value) => value.charCodeAt(0), str.split(""), Int32Array), Uint8Array));
    console.log(some("size: " + toString(bn(str.length))));
    return Buffer.concat([encodeU32(bn(str.length)), Buffer.concat(arr)]);
}

export function encodeString2NoLength(str) {
    const arr = map((x) => (Buffer.alloc(1, x)), map((value_1) => (value_1 & 0xFF), map((value) => value.charCodeAt(0), str.split(""), Int32Array), Uint8Array));
    return Buffer.concat(arr);
}

export const publicKeyLayout2 = publicKey("pk");

export function encodePublicKey2(pk) {
    const data = {
        pk: pk,
    };
    const buffer = Buffer.alloc(32);
    const limit = publicKeyLayout.encode(data, buffer);
    return buffer;
}

