import { Union, Record } from "../../../app/client/src/.fable/fable-library.3.1.16/Types.js";
import { PublicKey$reflection } from "./Common.fs.js";
import { union_type, class_type, record_type, uint32_type, array_type } from "../../../app/client/src/.fable/fable-library.3.1.16/Reflection.js";

export class ProductCommon extends Record {
    constructor(Underlying, Maturity, DepositBuyMint, DepositSellMint, QuoteUnderlying) {
        super();
        this.Underlying = Underlying;
        this.Maturity = Maturity;
        this.DepositBuyMint = DepositBuyMint;
        this.DepositSellMint = DepositSellMint;
        this.QuoteUnderlying = QuoteUnderlying;
    }
}

export function ProductCommon$reflection() {
    return record_type("ExoticMarkets.Domain.ProductCommon", [], ProductCommon, () => [["Underlying", array_type(PublicKey$reflection())], ["Maturity", uint32_type], ["DepositBuyMint", PublicKey$reflection()], ["DepositSellMint", PublicKey$reflection()], ["QuoteUnderlying", PublicKey$reflection()]]);
}

export class PutWorstOf extends Record {
    constructor(Strike) {
        super();
        this.Strike = Strike;
    }
}

export function PutWorstOf$reflection() {
    return record_type("ExoticMarkets.Domain.PutWorstOf", [], PutWorstOf, () => [["Strike", class_type("System.UInt64")]]);
}

export class BuyBelowMarket extends Record {
    constructor(Strike, KnockOut) {
        super();
        this.Strike = Strike;
        this.KnockOut = KnockOut;
    }
}

export function BuyBelowMarket$reflection() {
    return record_type("ExoticMarkets.Domain.BuyBelowMarket", [], BuyBelowMarket, () => [["Strike", class_type("System.UInt64")], ["KnockOut", class_type("System.UInt64")]]);
}

export class SellAboveMarket extends Record {
    constructor(Strike, KnockOut) {
        super();
        this.Strike = Strike;
        this.KnockOut = KnockOut;
    }
}

export function SellAboveMarket$reflection() {
    return record_type("ExoticMarkets.Domain.SellAboveMarket", [], SellAboveMarket, () => [["Strike", class_type("System.UInt64")], ["KnockOut", class_type("System.UInt64")]]);
}

export class PutWorstOfKnockIn extends Record {
    constructor(Strike, KnockIn) {
        super();
        this.Strike = Strike;
        this.KnockIn = KnockIn;
    }
}

export function PutWorstOfKnockIn$reflection() {
    return record_type("ExoticMarkets.Domain.PutWorstOfKnockIn", [], PutWorstOfKnockIn, () => [["Strike", class_type("System.UInt64")], ["KnockIn", class_type("System.UInt64")]]);
}

export class SpecificProduct extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["PutWorstOfProduct", "BuyBelowMarketProduct", "SellAboveMarketProduct", "PutWorstOfKnockInProduct"];
    }
}

export function SpecificProduct$reflection() {
    return union_type("ExoticMarkets.Domain.SpecificProduct", [], SpecificProduct, () => [[["Item", PutWorstOf$reflection()]], [["Item", BuyBelowMarket$reflection()]], [["Item", SellAboveMarket$reflection()]], [["Item", PutWorstOfKnockIn$reflection()]]]);
}

export function SpecificProduct__GetStrikes(this$) {
    switch (this$.tag) {
        case 1: {
            const p_1 = this$.fields[0];
            return [p_1.Strike, p_1.KnockOut];
        }
        case 2: {
            const p_2 = this$.fields[0];
            return [p_2.Strike, p_2.KnockOut];
        }
        case 3: {
            const p_3 = this$.fields[0];
            return [p_3.Strike, p_3.KnockIn];
        }
        default: {
            return [this$.fields[0].Strike];
        }
    }
}

export function SpecificProduct__GetStrikesDisplay(this$) {
    switch (this$.tag) {
        case 1: {
            const p_1 = this$.fields[0];
            return [["Strike", p_1.Strike], ["Knock out", p_1.KnockOut]];
        }
        case 2: {
            const p_2 = this$.fields[0];
            return [["Strike", p_2.Strike], ["Knock out", p_2.KnockOut]];
        }
        case 3: {
            const p_3 = this$.fields[0];
            return [["Strike", p_3.Strike], ["Knock in", p_3.KnockIn]];
        }
        default: {
            return [["Stirke", this$.fields[0].Strike]];
        }
    }
}

export function SpecificProduct_GetProductNameDescription_Z592BD26B(p) {
    switch (p.tag) {
        case 1: {
            return "Buy-Below-Market";
        }
        case 2: {
            return "Sell-Above-Market";
        }
        case 3: {
            return "Worst-of Put Knock in";
        }
        default: {
            return "Worst-of Put";
        }
    }
}

export class Product extends Record {
    constructor(PublicKey, Owner, CommonData, SpecificProduct) {
        super();
        this.PublicKey = PublicKey;
        this.Owner = Owner;
        this.CommonData = CommonData;
        this.SpecificProduct = SpecificProduct;
    }
}

export function Product$reflection() {
    return record_type("ExoticMarkets.Domain.Product", [], Product, () => [["PublicKey", PublicKey$reflection()], ["Owner", PublicKey$reflection()], ["CommonData", ProductCommon$reflection()], ["SpecificProduct", SpecificProduct$reflection()]]);
}

