import { record_type, lambda_type, option_type, bool_type, string_type, union_type, class_type } from "../fable-library.3.1.16/Reflection.js";
import { Record, Union } from "../fable-library.3.1.16/Types.js";

export class Effector$2 {
    constructor() {
    }
}

export function Effector$2$reflection(gen0, gen1) {
    return class_type("Feliz.Recoil.Effector`2", [gen0, gen1], Effector$2);
}

export class AtomEffect$2 {
    constructor() {
    }
}

export function AtomEffect$2$reflection(gen0, gen1) {
    return class_type("Feliz.Recoil.AtomEffect`2", [gen0, gen1], AtomEffect$2);
}

export class LoadableState$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["HasValue", "HasError", "Loading"];
    }
}

export function LoadableState$1$reflection(gen0) {
    return union_type("Feliz.Recoil.LoadableState`1", [gen0], LoadableState$1, () => [[["Item", gen0]], [["Item", class_type("System.Exception")]], [["Item", class_type("Fable.Core.JS.Promise`1", [gen0])]]]);
}

export class PersistenceSettings$2 extends Record {
    constructor(Type, Backbutton, Validator) {
        super();
        this.Type = Type;
        this.Backbutton = Backbutton;
        this.Validator = Validator;
    }
}

export function PersistenceSettings$2$reflection(gen0, gen1) {
    return record_type("Feliz.Recoil.PersistenceSettings`2", [gen0, gen1], PersistenceSettings$2, () => [["Type", string_type], ["Backbutton", option_type(bool_type)], ["Validator", lambda_type(gen1, option_type(gen0))]]);
}

