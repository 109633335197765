import { addSeconds, utcNow, op_Subtraction } from "./.fable/fable-library.3.1.16/DateOffset.js";
import { equals as equals_1, fromInteger, compare, toNumber } from "./.fable/fable-library.3.1.16/Long.js";
import { totalSeconds, ticks } from "./.fable/fable-library.3.1.16/TimeSpan.js";
import { createElement } from "react";
import * as react from "react";
import { ButtonProgressLoader, displayRiskLevel, displayMaturity, TextColumn, ModalCloseButton, Column, Col, Row } from "./UtilComponents.fs.js";
import single$002Dproducts$002Emodule from "./styles/single-products.module.scss";
import { item, ofArray, empty, singleton } from "./.fable/fable-library.3.1.16/List.js";
import { join, interpolate, toText } from "./.fable/fable-library.3.1.16/String.js";
import { TimeCountdown } from "./DisplayTime.fs.js";
import { Recoil_useRecoilState_Z74A95619, Recoil_useRecoilValue_5D3EEEEA } from "./.fable/Feliz.Recoil.0.19.2/Bindings.fs.js";
import { tokens as tokens_1, SelectedProductGroup, SetupState, completedSetup, selectedProductGroup, themeConfig as themeConfig_1, SetupStep, setup as setup_2, solanaEnvironment, config as config_2, injectiveEnvironment } from "./Context.fs.js";
import { estimatedInjectiveHoldingYield, getDenomBalance, getOracleList, indexerGrpcOracleApi, getNetworkByInjectiveEnvironment, chainGrpcBankApi, useWalletStore } from "../../../lib/Injective/Injective.fs.js";
import { getNetworkEndpoints } from "@injectivelabs/networks";
import { useReact_useEffectOnce_3A5B6456, useReact_useRef_1505, useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "./.fable/Feliz.1.62.0/React.fs.js";
import { startImmediate } from "./.fable/fable-library.3.1.16/Async.js";
import { DepositButton, AmountAndMaxButton2, HoldingStatus_GetStatus_3AE70073, getTokenBalance } from "./InjectiveHoldingUtils.fs.js";
import { CollapsedPanelAlwaysOpen, infoRow, CollapsedPanel, productRecapRow, productDetailRow } from "./FlexProductDetailGroup.fs.js";
import { Deferred$1, useFeliz_React__React_useDeferred_Static_2344FC52 } from "./.fable/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { singleton as singleton_1 } from "./.fable/fable-library.3.1.16/AsyncBuilder.js";
import { some, map } from "./.fable/fable-library.3.1.16/Option.js";
import { parse } from "./.fable/fable-library.3.1.16/Double.js";
import { mapIndexed, sortBy, map as map_1, tryFind } from "./.fable/fable-library.3.1.16/Array.js";
import { round, op_Subtraction as op_Subtraction_1, op_Multiply, toNumber as toNumber_1, equals as equals_2, op_Division, parse as parse_1, fromParts } from "./.fable/fable-library.3.1.16/Decimal.js";
import Decimal from "./.fable/fable-library.3.1.16/Decimal.js";
import { toString } from "./.fable/fable-library.3.1.16/Types.js";
import { floorDecimal } from "./BlockChainInteraction/InjectiveHoldingDeposit.fs.js";
import { size as size_1, positionSize } from "../../../lib/Domain/Types/InjectiveHolding.fs.js";
import { Interop_reactApi } from "./.fable/Feliz.1.62.0/Interop.fs.js";
import utils$002Emodule from "./styles/utils.module.scss";
import { CurrencyInput, CurrencyIcon } from "./Currency.fs.js";
import { round as round_1, createObj, fastStructuralHash, equals } from "./.fable/fable-library.3.1.16/Util.js";
import { empty as empty_1, singleton as singleton_2, append, delay, toList } from "./.fable/fable-library.3.1.16/Seq.js";
import { InjectiveProductInfoCell, ProductDetailHeader } from "./GroupDetailCommon.fs.js";
import { SettlementRulesGroup } from "./InjectiveHoldingSettlementRules.fs.js";
import { Array_groupBy } from "./.fable/fable-library.3.1.16/Seq2.js";
import { RiskLevel as RiskLevel_1, riskLevelFromFlexProduct } from "./RiskLevel.fs.js";
import { MuiHelpers_createElement } from "./.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Modal from "@material-ui/core/Modal";
import { times2 } from "./Img.fs.js";
import { convertToDecimal, FPDECIMAL_ONE, daysFromSeconds } from "../../../lib/Domain/Types/Common.fs.js";
import { RiskLevel } from "../../../lib/Domain/Types/FlexProduct.fs.js";
import { InjectiveProduct__GetKind, InjectiveProduct__GetFormattedStrike, InjectiveProduct_GetProductNameDescription_Z4C3DC210 } from "../../../lib/Domain/Types/InjectiveProduct.fs.js";
import { toDecimal } from "./.fable/fable-library.3.1.16/BigInt.js";
import { ConnectInjectiveWalletMessageSmall } from "./ConnectWalletMessage.fs.js";
import { compare as compare_1 } from "./.fable/fable-library.3.1.16/Date.js";
import { InjectiveHoldingProductOptionRfq__ToHoldingProduct } from "../../shared/SignalRRemoting.fs.js";
import LinearProgress from "@material-ui/core/LinearProgress";
import { errorBoundary } from "./Error.fs.js";

export const openCloseDetailedViewClassName = "w-full\n    shadow-sm\n    flex\n    items-center\n    justify-center\n    gap-2\n    h-10\n    px-5\n    bg-[length:200%_auto]\n    rounded-md \n    bg-gradient-to-r\n    from-exo-primary\n    via-exo-primary-high\n    via-51%\n    to-exo-primary\n    hover:bg-right\n    transition-all\n    duration-300\n    ease-in-out\n    relative\n    cursor-pointer ";

export function TimerCountdown(timerCountdownInputProps) {
    let value;
    const product = timerCountdownInputProps.product;
    const holding = timerCountdownInputProps.holding;
    const durationDeposits = op_Subtraction(holding.EndCollection, utcNow());
    const durationSettlements = addSeconds(holding.StartOption, toNumber(product.Duration));
    const durationDepositsActive = compare(ticks(durationDeposits), fromInteger(0, false, 2)) > 0;
    return createElement(Row, {
        classes: singleton(single$002Dproducts$002Emodule["modal-product-info-row-recap"]),
        children: ofArray([createElement(Col, {
            classes: singleton(single$002Dproducts$002Emodule["modal-product-info-label"]),
            children: singleton((value = toText(interpolate("", [])), createElement("span", {
                children: [value],
            }))),
        }), createElement(Col, {
            classes: empty(),
            children: singleton(createElement(TimeCountdown, {
                seconds: ~(~totalSeconds(durationDeposits)),
            })),
        })]),
    });
}

export function AvailableBalance(availableBalanceInputProps) {
    let copyOfStruct;
    const isProcessing = availableBalanceInputProps.isProcessing;
    const depositToken = availableBalanceInputProps.depositToken;
    const environment = Recoil_useRecoilValue_5D3EEEEA(injectiveEnvironment);
    const wallet = useWalletStore();
    const api = chainGrpcBankApi(getNetworkEndpoints(getNetworkByInjectiveEnvironment(environment)).grpc, environment);
    const patternInput = useFeliz_React__React_useState_Static_1505("0");
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(getTokenBalance(api, wallet.walletStrategy, patternInput[1], depositToken));
    }, [wallet, environment, isProcessing]);
    return productDetailRow(patternInput[0], toText(interpolate("%P()", [(copyOfStruct = depositToken, copyOfStruct.symbol)])));
}

export function SpotPrice(product) {
    const environment = Recoil_useRecoilValue_5D3EEEEA(injectiveEnvironment);
    const oracleBase = "0x" + product.OracleBase;
    const api = indexerGrpcOracleApi(getNetworkEndpoints(getNetworkByInjectiveEnvironment(environment)).indexer);
    const spot_2 = useFeliz_React__React_useDeferred_Static_2344FC52(singleton_1.Delay(() => singleton_1.TryWith(singleton_1.Delay(() => singleton_1.Bind(getOracleList(api), (_arg1) => {
        const spot = map((s) => parse(s.price), tryFind((o) => {
            if (o.symbol === oracleBase) {
                return o.oracleType === "pyth";
            }
            else {
                return false;
            }
        }, _arg1));
        if (spot == null) {
            console.error(some("Error getting spot for " + product.BaseDenom));
            return singleton_1.Return(fromParts(0, 0, 0, false, 0));
        }
        else {
            return singleton_1.Return(new Decimal(spot));
        }
    })), (_arg2) => {
        console.error(some((("Error getting spot for " + product.BaseDenom) + ": ") + toString(_arg2)));
        return singleton_1.Return(fromParts(0, 0, 0, false, 0));
    })), [environment]);
    return productRecapRow(toText(interpolate("%P()", [(spot_2.tag === 2) ? floorDecimal(6, spot_2.fields[0]) : fromParts(0, 0, 0, false, 0)])), "$", empty());
}

export function UserDeposits(userDepositsInputProps) {
    let position, value_2, provList_1, provList_2, balance, value_10;
    const depositSignature = userDepositsInputProps.depositSignature;
    const depositToken = userDepositsInputProps.depositToken;
    const holding = userDepositsInputProps.holding;
    const wallet = useWalletStore();
    const environment = Recoil_useRecoilValue_5D3EEEEA(injectiveEnvironment);
    const api = chainGrpcBankApi(getNetworkEndpoints(getNetworkByInjectiveEnvironment(environment)).grpc, environment);
    const config = Recoil_useRecoilValue_5D3EEEEA(config_2);
    const patternInput = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const setPositionsOfUser = patternInput[1];
    const positionsOfUser = patternInput[0];
    const provisionalPositions = useFeliz_React__React_useState_Static_1505(new Deferred$1(0))[0];
    const counter = useReact_useRef_1505(0);
    const interval = useReact_useRef_1505(0);
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton_1.Delay(() => {
            const matchValue = [wallet.walletStrategy, config];
            let pattern_matching_result, config_1;
            if (matchValue[0] != null) {
                if (matchValue[1] != null) {
                    pattern_matching_result = 0;
                    config_1 = matchValue[1];
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    if (wallet.injectiveAddress === "") {
                        setPositionsOfUser(new Deferred$1(2, void 0));
                        return singleton_1.Zero();
                    }
                    else {
                        return singleton_1.Bind(getDenomBalance(api, wallet.injectiveAddress, toText(interpolate("factory/%P()/%P()", [config_1.InjectiveHoldingContract, holding.Id]))), (_arg1) => {
                            const userPositionTokenBalance = _arg1;
                            const size = positionSize(userPositionTokenBalance, depositToken.decimals);
                            if (userPositionTokenBalance === "0") {
                                setPositionsOfUser(new Deferred$1(2, void 0));
                                return singleton_1.Zero();
                            }
                            else {
                                setPositionsOfUser(new Deferred$1(2, size));
                                return singleton_1.Zero();
                            }
                        });
                    }
                }
                case 1: {
                    setPositionsOfUser(new Deferred$1(2, void 0));
                    return singleton_1.Zero();
                }
            }
        }));
    }, [wallet, config, holding, depositSignature]);
    const buyAmount = (positionsOfUser.tag === 2) ? ((positionsOfUser.fields[0] != null) ? (position = positionsOfUser.fields[0], position) : fromParts(0, 0, 0, false, 0)) : fromParts(0, 0, 0, false, 0);
    return react.createElement(react.Fragment, {}, createElement("div", {
        className: join(" ", [single$002Dproducts$002Emodule["deposits-row"]]),
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: join(" ", [utils$002Emodule["margin-xs-right"], utils$002Emodule["boldness-400"]]),
            children: Interop_reactApi.Children.toArray([(value_2 = toText(interpolate("%P()", [floorDecimal(2, buyAmount)])), createElement("span", {
                children: [value_2],
            }))]),
        }), createElement(CurrencyIcon, {
            depositMint: depositToken,
        }), createElement("div", {
            className: join(" ", [single$002Dproducts$002Emodule["deposits-label"], utils$002Emodule["margin-xs-left"]]),
            children: Interop_reactApi.Children.toArray([createElement("span", {
                children: ["CONFIRMED"],
            })]),
        })]),
    }), (provisionalPositions.tag === 0) ? null : ((provisionalPositions.tag === 1) ? null : ((provisionalPositions.tag === 2) ? ((provisionalPositions.fields[0] != null) ? (equals(provisionalPositions.fields[0], empty()) ? (provList_1 = provisionalPositions.fields[0], null) : ((provisionalPositions.tag === 2) ? ((provisionalPositions.fields[0] == null) ? null : (provList_2 = provisionalPositions.fields[0], (balance = (toNumber(item(0, provList_2).BuyAmount) / Math.pow(10, depositToken.decimals)), createElement("div", {
        className: join(" ", [single$002Dproducts$002Emodule["deposits-row"]]),
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: join(" ", [utils$002Emodule["margin-xs-right"], utils$002Emodule["boldness-400"]]),
            children: Interop_reactApi.Children.toArray([(value_10 = toText(interpolate("%P()", [balance])), createElement("span", {
                children: [value_10],
            }))]),
        }), createElement(CurrencyIcon, {
            depositMint: depositToken,
        }), createElement("div", {
            className: join(" ", [single$002Dproducts$002Emodule["provisionalpos-label"], utils$002Emodule["margin-xs-left"]]),
            children: Interop_reactApi.Children.toArray([createElement("span", {
                children: ["PENDING"],
            })]),
        })]),
    })))) : ((provisionalPositions.tag === 3) ? (console.log(some(provisionalPositions.fields[0].message)), null) : (() => {
        throw (new Error("Match failure: Feliz.UseDeferred.Deferred`1"));
    })()))) : ((provisionalPositions.tag === 2) ? ((provisionalPositions.fields[0] == null) ? null : (provList_2 = provisionalPositions.fields[0], (balance = (toNumber(item(0, provList_2).BuyAmount) / Math.pow(10, depositToken.decimals)), createElement("div", {
        className: join(" ", [single$002Dproducts$002Emodule["deposits-row"]]),
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: join(" ", [utils$002Emodule["margin-xs-right"], utils$002Emodule["boldness-400"]]),
            children: Interop_reactApi.Children.toArray([(value_10 = toText(interpolate("%P()", [balance])), createElement("span", {
                children: [value_10],
            }))]),
        }), createElement(CurrencyIcon, {
            depositMint: depositToken,
        }), createElement("div", {
            className: join(" ", [single$002Dproducts$002Emodule["provisionalpos-label"], utils$002Emodule["margin-xs-left"]]),
            children: Interop_reactApi.Children.toArray([createElement("span", {
                children: ["PENDING"],
            })]),
        })]),
    })))) : ((provisionalPositions.tag === 3) ? (console.log(some(provisionalPositions.fields[0].message)), null) : (() => {
        throw (new Error("Match failure: Feliz.UseDeferred.Deferred`1"));
    })()))) : ((provisionalPositions.tag === 2) ? ((provisionalPositions.fields[0] == null) ? null : (provList_2 = provisionalPositions.fields[0], (balance = (toNumber(item(0, provList_2).BuyAmount) / Math.pow(10, depositToken.decimals)), createElement("div", {
        className: join(" ", [single$002Dproducts$002Emodule["deposits-row"]]),
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: join(" ", [utils$002Emodule["margin-xs-right"], utils$002Emodule["boldness-400"]]),
            children: Interop_reactApi.Children.toArray([(value_10 = toText(interpolate("%P()", [balance])), createElement("span", {
                children: [value_10],
            }))]),
        }), createElement(CurrencyIcon, {
            depositMint: depositToken,
        }), createElement("div", {
            className: join(" ", [single$002Dproducts$002Emodule["provisionalpos-label"], utils$002Emodule["margin-xs-left"]]),
            children: Interop_reactApi.Children.toArray([createElement("span", {
                children: ["PENDING"],
            })]),
        })]),
    })))) : ((provisionalPositions.tag === 3) ? (console.log(some(provisionalPositions.fields[0].message)), null) : (() => {
        throw (new Error("Match failure: Feliz.UseDeferred.Deferred`1"));
    })())))));
}

export function infoRowMultiple(label, children, children2, classes, textClasses) {
    return createElement("div", {
        className: join(" ", classes),
        children: Interop_reactApi.Children.toArray([createElement("div", {
            children: Interop_reactApi.Children.toArray([label]),
        }), createElement("div", {
            children: Interop_reactApi.Children.toArray([react.createElement(react.Fragment, {}, ...children)]),
        }), createElement("div", {
            className: join(" ", textClasses),
            children: Interop_reactApi.Children.toArray([react.createElement(react.Fragment, {}, ...children2)]),
        })]),
    });
}

export function ContentDetailedView(contentDetailedViewInputProps) {
    const uiFormat = contentDetailedViewInputProps.uiFormat;
    const spot = contentDetailedViewInputProps.spot;
    const amount = contentDetailedViewInputProps.amount;
    const setDetailExpanded = contentDetailedViewInputProps.setDetailExpanded;
    const closeModal = contentDetailedViewInputProps.closeModal;
    const selProduct = contentDetailedViewInputProps.selProduct;
    const wallet = useWalletStore();
    const environment = Recoil_useRecoilValue_5D3EEEEA(solanaEnvironment);
    const environmentInj = Recoil_useRecoilValue_5D3EEEEA(injectiveEnvironment);
    const setup = Recoil_useRecoilState_Z74A95619(setup_2)[0];
    const setupMode = equals(setup.Step, new SetupStep(2)) ? true : equals(setup.Step, new SetupStep(3));
    const config = Recoil_useRecoilValue_5D3EEEEA(config_2);
    const holdingContract = (config == null) ? "" : config.InjectiveHoldingContract;
    if (selProduct == null) {
        return null;
    }
    else {
        const tokens = selProduct[1];
        const holdingProduct = selProduct[0];
        return react.createElement(react.Fragment, {}, createElement("div", {
            className: openCloseDetailedViewClassName + single$002Dproducts$002Emodule["modal-btn-hide"],
            onClick: (_arg1) => {
                setDetailExpanded(false);
            },
            children: Interop_reactApi.Children.toArray([createElement("span", {
                className: "text-white drop-shadow-sm",
                children: "\u003c Hide Detailed View",
            })]),
        }), createElement("div", {
            className: openCloseDetailedViewClassName + single$002Dproducts$002Emodule["modal-btn-close"],
            onClick: (_arg2) => {
                closeModal();
            },
            children: Interop_reactApi.Children.toArray([createElement("span", {
                className: "text-white drop-shadow-sm",
                children: "Close Product View",
            })]),
        }), createElement("div", {
            className: join(" ", [single$002Dproducts$002Emodule["products-detail-modal"]]),
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_2(createElement("div", {
                className: join(" ", [single$002Dproducts$002Emodule["modal-card"], single$002Dproducts$002Emodule["modal-card-bkg"], single$002Dproducts$002Emodule["modal-card-padding-s"]]),
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_2(createElement(ProductDetailHeader, {
                    heading: "Product Details",
                    subheading: "",
                })), delay(() => {
                    const durationDeposits = op_Subtraction(holdingProduct.Holding.EndCollection, utcNow());
                    const durationSettlements = op_Subtraction(addSeconds(holdingProduct.Holding.StartOption, toNumber(holdingProduct.Product.Duration)), utcNow());
                    const durationDepositsActive = compare(ticks(durationDeposits), fromInteger(0, false, 2)) > 0;
                    return singleton_2(createElement(SettlementRulesGroup, {
                        holdingProduct: holdingProduct,
                        underlyingToken: tokens.Deposit,
                        spotToken: tokens.Spot,
                        spot: spot,
                        uiFormat: uiFormat,
                    }));
                })))))),
            })), delay(() => {
                const performancePanel = singleton(createElement("h2", {
                    children: ["Available when more products will be launched"],
                }));
                return append(singleton_2(createElement(CollapsedPanel, {
                    header: "Past Performance",
                    classes: ofArray([single$002Dproducts$002Emodule["modal-past-performance"], utils$002Emodule["margin-s-top"]]),
                    contents: performancePanel,
                })), delay(() => {
                    let addr;
                    const cluster = (environmentInj.tag === 0) ? "testnet." : "";
                    const smartContractDetails = singleton(infoRow("Smart Contract", react.createElement(react.Fragment, {}, createElement("a", {
                        href: toText(interpolate("https://%P()explorer.injective.network/contract/%P()", [cluster, holdingContract])),
                        children: toText(interpolate("%P() \u003e", [(addr = holdingContract, toText(interpolate("%P()...%P()", [addr.slice(0, 5 + 1), addr.slice(-3, addr.length)])))])),
                        target: "_blank",
                    })), ofArray([single$002Dproducts$002Emodule["smart-contract-row"], utils$002Emodule["margin-s-top"]]), empty()));
                    return singleton_2(createElement(CollapsedPanel, {
                        header: "Smart Contract Details",
                        classes: singleton(single$002Dproducts$002Emodule["smart-contract-details"]),
                        contents: smartContractDetails,
                    }));
                }));
            })))))),
        }));
    }
}

export function ShowProducts(showProductsInputProps) {
    const uiFormat = showProductsInputProps.uiFormat;
    const isCollapsedProductRecap = showProductsInputProps.isCollapsedProductRecap;
    const setRefreshDataToggle = showProductsInputProps.setRefreshDataToggle;
    const refreshDataToggle = showProductsInputProps.refreshDataToggle;
    const oracles = showProductsInputProps.oracles;
    const hideProductSelection = showProductsInputProps.hideProductSelection;
    const onClose = showProductsInputProps.onClose;
    const isOpen = showProductsInputProps.isOpen;
    const products = showProductsInputProps.products;
    const themeConfig = Recoil_useRecoilValue_5D3EEEEA(themeConfig_1);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505((products.length === 1) ? products[0][0].Holding.Id : (void 0));
    const setSelectedLocalProductPublicKey = patternInput_1[1];
    const selectedLocalProductPublicKey = patternInput_1[0];
    const patternInput_2 = Recoil_useRecoilState_Z74A95619(selectedProductGroup);
    const maturitiesInList = map_1((tupledArg_2) => tupledArg_2[0], Array_groupBy((tupledArg_1) => tupledArg_1[0].Product.Duration, sortBy((tupledArg) => tupledArg[0].Product.Duration, products, {
        Compare: (x, y) => compare(x, y),
    }), {
        Equals: (x_1, y_1) => equals_1(x_1, y_1),
        GetHashCode: (x_1) => fastStructuralHash(x_1),
    }));
    const patternInput_3 = Recoil_useRecoilState_Z74A95619(setup_2);
    const setup = patternInput_3[0];
    const getProductsForCell = (riskLevel) => mapIndexed((i, m) => {
        const productsInCell = map_1((tupledArg_4) => [tupledArg_4[0], tupledArg_4[1]], products.filter((tupledArg_3) => {
            const p_4 = tupledArg_3[0];
            if (equals(riskLevelFromFlexProduct(p_4.Holding.RiskLevel), riskLevel)) {
                return equals_1(p_4.Product.Duration, m);
            }
            else {
                return false;
            }
        }));
        if (productsInCell.length > 0) {
            const productInCellInfo = productsInCell[0][0];
            let spot;
            const matchValue = [map((s) => parse_1(s.price), tryFind((o) => {
                if (o.symbol === ("0x" + productInCellInfo.Product.OracleBase)) {
                    return o.oracleType === "pyth";
                }
                else {
                    return false;
                }
            }, oracles)), map((s_1) => parse_1(s_1.price), tryFind((o_1) => {
                if (o_1.symbol === ("0x" + productInCellInfo.Product.OracleQuote)) {
                    return o_1.oracleType === "pyth";
                }
                else {
                    return false;
                }
            }, oracles))];
            let pattern_matching_result, b, q;
            if (matchValue[0] != null) {
                if (matchValue[1] != null) {
                    pattern_matching_result = 0;
                    b = matchValue[0];
                    q = matchValue[1];
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    spot = op_Division(b, q);
                    break;
                }
                case 1: {
                    spot = fromParts(0, 0, 0, false, 0);
                    break;
                }
            }
            const apy = equals_2(spot, fromParts(0, 0, 0, false, 0)) ? fromParts(0, 0, 0, false, 0) : estimatedInjectiveHoldingYield(productInCellInfo.Product, productInCellInfo.Holding.FairPrice, spot, void 0);
            return createElement(Column, {
                children: singleton(createElement(InjectiveProductInfoCell, {
                    address: productInCellInfo.Holding.Id,
                    apy: apy,
                    selectedProduct: selectedLocalProductPublicKey,
                    onSelected: setSelectedLocalProductPublicKey,
                })),
                classes: singleton(single$002Dproducts$002Emodule["modal-product-content-row"]),
            });
        }
        else {
            return createElement(Column, {
                children: singleton(null),
                classes: empty(),
            });
        }
    }, maturitiesInList);
    useReact_useEffectOnce_3A5B6456(() => {
        if (completedSetup === false) {
            patternInput_3[1](new SetupState(new SetupStep(0), setup.Amount, setup.ConfirmedDeposit, setup.Positions));
        }
    });
    const wallet = useWalletStore();
    const patternInput_4 = Recoil_useRecoilState_Z74A95619(setup_2);
    const setup_1 = patternInput_4[0];
    const setupMode = equals(setup_1.Step, new SetupStep(2)) ? true : equals(setup_1.Step, new SetupStep(3));
    const setSetupAmount = (amount) => {
        patternInput_4[1](new SetupState(setup_1.Step, amount, setup_1.ConfirmedDeposit, setup_1.Positions));
    };
    const patternInput_5 = useFeliz_React__React_useState_Static_1505("0");
    const setAmount = patternInput_5[1];
    const amount_1 = patternInput_5[0];
    const patternInput_6 = useFeliz_React__React_useState_Static_1505("0");
    const patternInput_7 = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const patternInput_8 = useFeliz_React__React_useState_Static_1505(false);
    const processing = patternInput_8[0];
    const patternInput_9 = useFeliz_React__React_useState_Static_1505(null);
    const patternInput_10 = useFeliz_React__React_useState_Static_1505(false);
    const setDetailExpanded = patternInput_10[1];
    const detailExpanded = patternInput_10[0];
    const environment = Recoil_useRecoilValue_5D3EEEEA(injectiveEnvironment);
    const closeModal = () => {
        patternInput_2[1](new SelectedProductGroup(3));
        setSelectedLocalProductPublicKey(void 0);
        onClose();
    };
    return MuiHelpers_createElement(Modal, [["open", isOpen], ["onClose", (_arg17, v) => {
        closeModal();
    }], ["children", react.createElement(react.Fragment, {}, createElement("div", {
        className: join(" ", [single$002Dproducts$002Emodule.modal, themeConfig]),
        children: Interop_reactApi.Children.toArray([createElement("div", createObj(toList(delay(() => {
            let pubkey_2;
            return append(singleton_2(["className", join(" ", [(selectedLocalProductPublicKey == null) ? "" : (pubkey_2 = selectedLocalProductPublicKey, detailExpanded ? single$002Dproducts$002Emodule["modal-detailed"] : "")])]), delay(() => singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append((!detailExpanded) ? singleton_2(createElement(ModalCloseButton, {
                img: times2,
                classes: singleton(single$002Dproducts$002Emodule["modal-close"]),
                onClick: () => {
                    closeModal();
                },
            })) : empty_1(), delay(() => append(singleton_2(createElement("div", {
                className: join(" ", [single$002Dproducts$002Emodule["products-modal"]]),
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append((!hideProductSelection) ? singleton_2(createElement("div", {
                    className: join(" ", [single$002Dproducts$002Emodule["modal-card"], single$002Dproducts$002Emodule["modal-card-bkg"], single$002Dproducts$002Emodule["modal-apy"], single$002Dproducts$002Emodule["modal-card-padding-m"]]),
                    children: Interop_reactApi.Children.toArray([createElement(ProductDetailHeader, {
                        heading: "Select APY",
                        subheading: "Choose the APY that suits you best based on Risk level and product maturity.",
                    }), createElement("div", {
                        className: join(" ", [single$002Dproducts$002Emodule["modal-product-row-container"], utils$002Emodule["margin-s-top"]]),
                        children: Interop_reactApi.Children.toArray([createElement(TextColumn, {
                            text: "Maturity Level",
                            classes: singleton(single$002Dproducts$002Emodule["modal-product-maturity-header"]),
                        })]),
                    }), createElement("div", {
                        className: join(" ", [single$002Dproducts$002Emodule["modal-product-row-container"], utils$002Emodule["margin-s-top"]]),
                        children: Interop_reactApi.Children.toArray([createElement(TextColumn, {
                            text: "Risk",
                            classes: ofArray([single$002Dproducts$002Emodule["modal-product-row"], single$002Dproducts$002Emodule["modal-product-row-risk-header"]]),
                        }), react.createElement(react.Fragment, {}, ...map_1((m_1) => {
                            const maturity = new Decimal(daysFromSeconds(toNumber(m_1)));
                            return createElement(Column, {
                                children: singleton(displayMaturity(maturity, empty(), true)),
                                classes: singleton(single$002Dproducts$002Emodule["modal-product-row"]),
                            });
                        }, maturitiesInList))]),
                    }), createElement("div", {
                        className: join(" ", [single$002Dproducts$002Emodule["modal-product-row-container"], utils$002Emodule["margin-m-top"]]),
                        children: Interop_reactApi.Children.toArray([createElement(Column, {
                            children: singleton(displayRiskLevel(new RiskLevel(0), empty())),
                            classes: singleton(single$002Dproducts$002Emodule["modal-product-content-row"]),
                        }), react.createElement(react.Fragment, {}, ...getProductsForCell(new RiskLevel_1(0)))]),
                    }), createElement("div", {
                        className: join(" ", [single$002Dproducts$002Emodule["modal-product-row-container"], utils$002Emodule["margin-s-top"]]),
                        children: Interop_reactApi.Children.toArray([createElement(Column, {
                            children: singleton(displayRiskLevel(new RiskLevel(1), empty())),
                            classes: singleton(single$002Dproducts$002Emodule["modal-product-content-row"]),
                        }), react.createElement(react.Fragment, {}, ...getProductsForCell(new RiskLevel_1(1)))]),
                    }), createElement("div", {
                        className: join(" ", [single$002Dproducts$002Emodule["modal-product-row-container"], utils$002Emodule["margin-s-top"]]),
                        children: Interop_reactApi.Children.toArray([createElement(Column, {
                            children: singleton(displayRiskLevel(new RiskLevel(2), empty())),
                            classes: singleton(single$002Dproducts$002Emodule["modal-product-content-row"]),
                        }), react.createElement(react.Fragment, {}, ...getProductsForCell(new RiskLevel_1(2)))]),
                    })]),
                })) : empty_1(), delay(() => {
                    let product, depositToken, holding, remaining, matchValue_3, maxProductAmount, totalDeposited, className, className_1;
                    const api = chainGrpcBankApi(getNetworkEndpoints(getNetworkByInjectiveEnvironment(environment)).grpc, environment);
                    if (selectedLocalProductPublicKey == null) {
                        return singleton_2(null);
                    }
                    else {
                        const pubkey_1 = selectedLocalProductPublicKey;
                        const product_1 = tryFind((tupledArg_5) => (tupledArg_5[0].Holding.Id === pubkey_1), products);
                        if (product_1 == null) {
                            return singleton_2(null);
                        }
                        else {
                            const tokens = product_1[1];
                            const product_2 = product_1[0];
                            const buttonDetails = createElement("div", {
                                className: join(" ", [utils$002Emodule["margin-m-top"]]),
                                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((!detailExpanded) ? singleton_2(createElement("div", {
                                    className: openCloseDetailedViewClassName + single$002Dproducts$002Emodule["modal-btn-close"],
                                    onClick: (_arg7) => {
                                        setDetailExpanded(!detailExpanded);
                                    },
                                    children: Interop_reactApi.Children.toArray([createElement("span", {
                                        className: "text-white drop-shadow-sm",
                                        children: "Detailed View \u003e",
                                    })]),
                                })) : singleton_2(createElement("div", {}))))))),
                            });
                            let spot_1;
                            const matchValue_4 = [map((s_2) => parse_1(s_2.price), tryFind((o_3) => {
                                if (o_3.symbol === ("0x" + product_2.Product.OracleBase)) {
                                    return o_3.oracleType === "pyth";
                                }
                                else {
                                    return false;
                                }
                            }, oracles)), map((s_3) => parse_1(s_3.price), tryFind((o_4) => {
                                if (o_4.symbol === ("0x" + product_2.Product.OracleQuote)) {
                                    return o_4.oracleType === "pyth";
                                }
                                else {
                                    return false;
                                }
                            }, oracles))];
                            let pattern_matching_result_1, b_1, q_1;
                            if (matchValue_4[0] != null) {
                                if (matchValue_4[1] != null) {
                                    pattern_matching_result_1 = 0;
                                    b_1 = matchValue_4[0];
                                    q_1 = matchValue_4[1];
                                }
                                else {
                                    pattern_matching_result_1 = 1;
                                }
                            }
                            else {
                                pattern_matching_result_1 = 1;
                            }
                            switch (pattern_matching_result_1) {
                                case 0: {
                                    spot_1 = op_Division(b_1, q_1);
                                    break;
                                }
                                case 1: {
                                    spot_1 = fromParts(0, 0, 0, false, 0);
                                    break;
                                }
                            }
                            const optionStrike = map((op) => op.Strike, product_2.Option);
                            const productRecap = createElement("div", {
                                className: join(" ", [single$002Dproducts$002Emodule["modal-card"], single$002Dproducts$002Emodule["modal-card-bkg"], single$002Dproducts$002Emodule["modal-grid"], single$002Dproducts$002Emodule["modal-card-margin"], single$002Dproducts$002Emodule["modal-card-padding-m"], single$002Dproducts$002Emodule["modal-center"]]),
                                children: Interop_reactApi.Children.toArray([createElement(Column, {
                                    children: ofArray([infoRow("Product Pair", react.createElement(react.Fragment, {}, productRecapRow(toText(interpolate("%P()-%P()", [tokens.ProductPairLeft.symbol, tokens.ProductPairRight.symbol])), "", empty())), ofArray([single$002Dproducts$002Emodule["modal-product-recap-row"], single$002Dproducts$002Emodule["modal-row-margin"]]), singleton(utils$002Emodule["text-end"])), infoRow("Product Type", react.createElement(react.Fragment, {}, productRecapRow(toText(interpolate("%P()", [InjectiveProduct_GetProductNameDescription_Z4C3DC210(product_2.Product)])), "", ofArray([single$002Dproducts$002Emodule["text-purple"], utils$002Emodule["boldness-400"]]))), ofArray([single$002Dproducts$002Emodule["modal-product-recap-row"], single$002Dproducts$002Emodule["modal-row-margin"]]), singleton(utils$002Emodule["text-end"])), infoRow(toText(interpolate("Offers Valid For", [])), createElement(TimerCountdown, {
                                        holding: product_2.Holding,
                                        product: product_2.Product,
                                    }), ofArray([single$002Dproducts$002Emodule["modal-product-recap-row"], single$002Dproducts$002Emodule["modal-row-margin"]]), singleton(utils$002Emodule["text-end"])), infoRow(toText(interpolate("Current %P()", [tokens.ProductPairLeft.symbol])), createElement(SpotPrice, product_2.Product), ofArray([single$002Dproducts$002Emodule["modal-product-recap-row"], single$002Dproducts$002Emodule["modal-row-margin"]]), singleton(utils$002Emodule["text-end"])), infoRow(toText(interpolate("Strike Price", [])), react.createElement(react.Fragment, {}, productRecapRow(toText(interpolate("%P()", [(product = product_2, (() => {
                                        let copyOfStruct;
                                        try {
                                            const productStrike = InjectiveProduct__GetFormattedStrike(product.Product);
                                            const matchValue_1 = product.Option;
                                            return (matchValue_1 == null) ? productStrike : ((("$ " + (copyOfStruct = toNumber_1(op_Division(matchValue_1.Strike, toDecimal(FPDECIMAL_ONE))), copyOfStruct.toString())) + " ") + tokens.ProductPairRight.symbol);
                                        }
                                        catch (matchValue_2) {
                                            return "$ -";
                                        }
                                    })())])), "", empty())), ofArray([single$002Dproducts$002Emodule["modal-product-recap-row"], single$002Dproducts$002Emodule["modal-row-margin"]]), singleton(utils$002Emodule["text-end"]))]),
                                    classes: empty(),
                                }), createElement(Column, {
                                    children: ofArray([" ", infoRow("Selected APY", react.createElement(react.Fragment, {}, productDetailRow(toText(interpolate("%P()", [equals_2(spot_1, fromParts(0, 0, 0, false, 0)) ? fromParts(0, 0, 0, false, 0) : estimatedInjectiveHoldingYield(product_2.Product, product_2.Holding.FairPrice, spot_1, optionStrike)])), "%")), ofArray([single$002Dproducts$002Emodule["modal-product-recap-row"], single$002Dproducts$002Emodule["modal-row-margin"]]), singleton(utils$002Emodule["text-end"])), infoRow("Min. Deposit", react.createElement(react.Fragment, {}, productDetailRow(toText(interpolate("%P()", [size_1(product_2.Holding.MinDepositPerUser, tokens.Deposit.decimals)])), toText(interpolate("%P()", [tokens.Deposit.symbol])))), ofArray([single$002Dproducts$002Emodule["modal-product-recap-row"], single$002Dproducts$002Emodule["modal-row-margin"]]), singleton(utils$002Emodule["text-end"])), infoRow("Max. Deposit", react.createElement(react.Fragment, {}, productDetailRow(toText(interpolate("%P()", [size_1(product_2.Holding.MaxTotalDeposit, tokens.Deposit.decimals)])), toText(interpolate("%P()", [tokens.Deposit.symbol])))), ofArray([single$002Dproducts$002Emodule["modal-product-recap-row"], single$002Dproducts$002Emodule["modal-row-margin"]]), singleton(utils$002Emodule["text-end"]))]),
                                    classes: empty(),
                                })]),
                            });
                            return append(singleton_2(createElement("div", {
                                className: join(" ", [single$002Dproducts$002Emodule["modal-card"], single$002Dproducts$002Emodule["modal-grid"], single$002Dproducts$002Emodule["modal-card-bkg"], single$002Dproducts$002Emodule["modal-card-margin"], single$002Dproducts$002Emodule["modal-card-padding-m"]]),
                                children: Interop_reactApi.Children.toArray([createElement(Column, {
                                    children: toList(delay(() => append(singleton_2(createElement(ConnectInjectiveWalletMessageSmall, wallet)), delay(() => {
                                        if (wallet.walletStrategy == null) {
                                            return empty_1();
                                        }
                                        else if (wallet.injectiveAddress === "") {
                                            return empty_1();
                                        }
                                        else {
                                            return append((HoldingStatus_GetStatus_3AE70073(product_2).tag === 0) ? ((compare_1(product_2.Holding.EndCollection, utcNow()) >= 0) ? append(singleton_2(createElement(AmountAndMaxButton2, {
                                                api: api,
                                                amount: setupMode ? setup_1.Amount : amount_1,
                                                setAmount: setupMode ? setSetupAmount : setAmount,
                                                setMaxLimit: patternInput_6[1],
                                                product: InjectiveHoldingProductOptionRfq__ToHoldingProduct(product_2),
                                                depositToken: tokens.Deposit,
                                                isProcessing: processing,
                                            })), delay(() => singleton_2(createElement(CurrencyInput, {
                                                mint: tokens.Deposit,
                                                value: setupMode ? setup_1.Amount : amount_1,
                                                dispatch: setupMode ? setSetupAmount : setAmount,
                                                error: void 0,
                                            })))) : (void 0, empty_1())) : (void 0, empty_1()), delay(() => append(singleton_2(createElement(DepositButton, {
                                                product: product_2,
                                                amount: convertToDecimal(amount_1),
                                                depositToken: tokens.Deposit,
                                                spotToken: tokens.Spot,
                                                processing: processing,
                                                setProcessing: patternInput_8[1],
                                                maxLimit: patternInput_6[0],
                                                api: api,
                                                callback: (signature) => {
                                                    patternInput_9[1](signature);
                                                    setRefreshDataToggle(!refreshDataToggle);
                                                },
                                                spotPrice: spot_1,
                                            })), delay(() => singleton_2(infoRow("Your Deposits", createElement(UserDeposits, {
                                                holding: product_2.Holding,
                                                depositToken: tokens.Deposit,
                                                depositSignature: patternInput_9[0],
                                            }), ofArray([single$002Dproducts$002Emodule["modal-product-info-row-lg"], utils$002Emodule["margin-s-top"]]), singleton(utils$002Emodule["text-end"])))))));
                                        }
                                    })))),
                                    classes: empty(),
                                }), createElement(Column, {
                                    children: ofArray([infoRow("Available Balance", createElement(AvailableBalance, {
                                        depositToken: tokens.Deposit,
                                        isProcessing: processing,
                                    }), ofArray([single$002Dproducts$002Emodule["modal-product-info-row"], utils$002Emodule["margin-l-top"]]), singleton(utils$002Emodule["text-end"])), (depositToken = tokens.Deposit, (holding = product_2.Holding, (remaining = (matchValue_3 = holding.MaxTotalDeposit, (matchValue_3 == null) ? fromParts(0, 0, 0, false, 1) : (maxProductAmount = matchValue_3, op_Multiply(op_Division(op_Subtraction_1(toDecimal(maxProductAmount), toDecimal(holding.TotalDepositedAmount)), toDecimal(maxProductAmount)), fromParts(1000, 0, 0, false, 1)))), (totalDeposited = round(op_Subtraction_1(fromParts(1000, 0, 0, false, 1), remaining), 2), createElement("div", {
                                        className: join(" ", [single$002Dproducts$002Emodule["modal-product-info-deposited-container"]]),
                                        children: Interop_reactApi.Children.toArray([createElement(TextColumn, {
                                            text: "Total Deposited",
                                            classes: ofArray([utils$002Emodule["boldness-400"], utils$002Emodule["text-xs"], utils$002Emodule["text-light"], single$002Dproducts$002Emodule["modal-product-info-deposited-item1"]]),
                                        }), createElement(TextColumn, {
                                            text: toText(interpolate("%P()%%", [totalDeposited])),
                                            classes: ofArray([utils$002Emodule["boldness-400"], utils$002Emodule["text-xs"], utils$002Emodule["text-dark"], utils$002Emodule["text-end"], single$002Dproducts$002Emodule["modal-product-info-deposited-item2"]]),
                                        }), createElement("div", {
                                            className: join(" ", [utils$002Emodule["margin-xs-top"], single$002Dproducts$002Emodule["modal-product-info-deposited-pbar"]]),
                                            children: Interop_reactApi.Children.toArray([createElement("div", {
                                                children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(LinearProgress, [(className = single$002Dproducts$002Emodule["modal-product-info-deposited-bar-root"], ["classes.root", className]), ["value", ~(~round_1(toNumber_1(totalDeposited)))], ["variant", "determinate"], (className_1 = single$002Dproducts$002Emodule["modal-product-info-deposited-amount"], ["classes.barColorPrimary", className_1])])]),
                                            })]),
                                        }), createElement(TextColumn, {
                                            text: "Max Capacity",
                                            classes: ofArray([utils$002Emodule["boldness-400"], utils$002Emodule["text-xs"], utils$002Emodule["text-light"], single$002Dproducts$002Emodule["modal-product-info-deposited-item1"]]),
                                        }), createElement(Row, {
                                            classes: singleton(single$002Dproducts$002Emodule["modal-product-info-deposited-item2"]),
                                            children: ofArray([createElement(TextColumn, {
                                                text: toText(interpolate("%P() ", [size_1(holding.MaxTotalDeposit, depositToken.decimals)])),
                                                classes: ofArray([utils$002Emodule["boldness-400"], utils$002Emodule["text-xs"], utils$002Emodule["text-dark"], utils$002Emodule["text-end"]]),
                                            }), createElement(TextColumn, {
                                                text: toText(interpolate("%P()", [depositToken.symbol])),
                                                classes: ofArray([utils$002Emodule["boldness-400"], utils$002Emodule["text-xs"], utils$002Emodule["text-light"], utils$002Emodule["text-end"], utils$002Emodule["margin-xs-left"]]),
                                            })]),
                                        })]),
                                    })))))]),
                                    classes: empty(),
                                })]),
                            })), delay(() => append(isCollapsedProductRecap ? singleton_2(createElement(CollapsedPanel, {
                                header: "Product Recap",
                                classes: ofArray([single$002Dproducts$002Emodule["smart-contract-details"], utils$002Emodule["margin-s-top"]]),
                                contents: [productRecap],
                            })) : singleton_2(createElement(CollapsedPanelAlwaysOpen, {
                                header: "Product Recap",
                                classes: ofArray([single$002Dproducts$002Emodule["smart-contract-details"], utils$002Emodule["margin-s-top"]]),
                                contents: [productRecap],
                            })), delay(() => singleton_2(createElement("div", {
                                children: Interop_reactApi.Children.toArray([buttonDetails]),
                            }))))));
                        }
                    }
                })))))),
            })), delay(() => {
                if (selectedLocalProductPublicKey == null) {
                    return singleton_2(null);
                }
                else {
                    const pubkey_3 = selectedLocalProductPublicKey;
                    const product_3 = tryFind((tupledArg_6) => (tupledArg_6[0].Holding.Id === pubkey_3), products);
                    let spot_2;
                    if (product_3 == null) {
                        spot_2 = fromParts(0, 0, 0, false, 0);
                    }
                    else {
                        const p_8 = product_3[0];
                        const matchValue_7 = [map((s_4) => parse_1(s_4.price), tryFind((o_5) => {
                            if (o_5.symbol === ("0x" + p_8.Product.OracleBase)) {
                                return o_5.oracleType === "pyth";
                            }
                            else {
                                return false;
                            }
                        }, oracles)), map((s_5) => parse_1(s_5.price), tryFind((o_6) => {
                            if (o_6.symbol === ("0x" + p_8.Product.OracleQuote)) {
                                return o_6.oracleType === "pyth";
                            }
                            else {
                                return false;
                            }
                        }, oracles))];
                        let pattern_matching_result_2, b_2, q_2;
                        if (matchValue_7[0] != null) {
                            if (matchValue_7[1] != null) {
                                pattern_matching_result_2 = 0;
                                b_2 = matchValue_7[0];
                                q_2 = matchValue_7[1];
                            }
                            else {
                                pattern_matching_result_2 = 1;
                            }
                        }
                        else {
                            pattern_matching_result_2 = 1;
                        }
                        switch (pattern_matching_result_2) {
                            case 0: {
                                spot_2 = op_Division(b_2, q_2);
                                break;
                            }
                            case 1: {
                                spot_2 = fromParts(0, 0, 0, false, 0);
                                break;
                            }
                        }
                    }
                    return detailExpanded ? singleton_2(createElement(ContentDetailedView, {
                        selProduct: product_3,
                        closeModal: closeModal,
                        setDetailExpanded: setDetailExpanded,
                        amount: amount_1,
                        spot: spot_2,
                        uiFormat: uiFormat,
                    })) : empty_1();
                }
            }))))))))])));
        }))))]),
    }))]]);
}

export function InjectiveHoldingGroupDetail(injectiveHoldingGroupDetailInputProps) {
    let prs;
    const uiFormat = injectiveHoldingGroupDetailInputProps.uiFormat;
    const isCollapsedProductRecap = injectiveHoldingGroupDetailInputProps.isCollapsedProductRecap;
    const setRefreshDataToggle = injectiveHoldingGroupDetailInputProps.setRefreshDataToggle;
    const refreshDataToggle = injectiveHoldingGroupDetailInputProps.refreshDataToggle;
    const hideProductSelection = injectiveHoldingGroupDetailInputProps.hideProductSelection;
    const onClose = injectiveHoldingGroupDetailInputProps.onClose;
    const kind = injectiveHoldingGroupDetailInputProps.kind;
    const underlyingMint = injectiveHoldingGroupDetailInputProps.underlyingMint;
    const oracles = injectiveHoldingGroupDetailInputProps.oracles;
    const products = injectiveHoldingGroupDetailInputProps.products;
    const tokens = Recoil_useRecoilValue_5D3EEEEA(tokens_1);
    const patternInput = useFeliz_React__React_useState_Static_1505(true);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const productsi = patternInput_1[0];
    const environment = Recoil_useRecoilValue_5D3EEEEA(injectiveEnvironment);
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton_1.Delay(() => {
            const data = products.filter((tupledArg) => {
                const p = tupledArg[0];
                if (p.Product.BaseDenom === underlyingMint) {
                    return equals(InjectiveProduct__GetKind(p.Product), kind);
                }
                else {
                    return false;
                }
            });
            const endpoints = getNetworkEndpoints(getNetworkByInjectiveEnvironment(environment));
            patternInput_1[1]([data, oracles]);
            return singleton_1.Zero();
        }));
    }, [tokens, underlyingMint, environment, products]);
    return errorBoundary((productsi == null) ? createElement(ButtonProgressLoader, {
        classes: empty(),
    }) : (prs = productsi, createElement(ShowProducts, {
        products: prs[0],
        isOpen: patternInput[0],
        onClose: () => {
            onClose();
            patternInput[1](false);
        },
        hideProductSelection: hideProductSelection,
        oracles: prs[1],
        refreshDataToggle: refreshDataToggle,
        setRefreshDataToggle: setRefreshDataToggle,
        isCollapsedProductRecap: isCollapsedProductRecap,
        uiFormat: uiFormat,
    })));
}

