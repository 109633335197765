import { singleton } from "../.fable/fable-library.3.1.16/AsyncBuilder.js";
import { Api } from "../Server.fs.js";
import { PKey } from "../../../shared/Api.fs.js";
import { length } from "../.fable/fable-library.3.1.16/List.js";

export function check(environment, connection, publickey, productKey) {
    return singleton.Delay(() => singleton.Bind(Api.GetProvisionalTransactionsList(environment, new PKey(publickey.toBase58()), productKey), (_arg1) => {
        const provisionalPosTransactionsList = _arg1;
        return (provisionalPosTransactionsList.tag === 1) ? singleton.Return(void 0) : singleton.Return(provisionalPosTransactionsList.fields[0]);
    }));
}

export function checkHolding(environment, connection, publickey, productKey) {
    return singleton.Delay(() => singleton.Bind(Api.GetProvisionalHoldingPositionTransactions(environment, new PKey(publickey.toBase58()), productKey), (_arg1) => {
        const provisionalPosTransactionsList = _arg1;
        return (length(provisionalPosTransactionsList) === 0) ? singleton.Return(void 0) : singleton.Return(provisionalPosTransactionsList);
    }));
}

