import { singleton } from "../.fable/fable-library.3.1.16/AsyncBuilder.js";
import { createTransaction, TransactionInstructionInput, PublicKeyDefault, TransactionInstructionKey, transactionInstruction, bn, getTokenAccountBalance, getAssociatedTokenAddress, getBalance, publicKeyBackEndToSolana, findProgramAddress } from "../../../../lib/Solana/Solana.fs.js";
import { encodeU8, encodeU64, encodeU32, encodePublicKey2, encodeString2NoLength } from "../../../../lib/Solana/Layout.fs.js";
import { toNumber, op_Subtraction, compare, op_Addition, op_Multiply, floor, op_Division, fromParts, pow } from "../.fable/fable-library.3.1.16/Decimal.js";
import Decimal from "../.fable/fable-library.3.1.16/Decimal.js";
import { Holding__GetDepositMint } from "../../../../lib/Domain/Types/Holding.fs.js";
import { some, value as value_2 } from "../.fable/fable-library.3.1.16/Option.js";
import { max } from "../.fable/fable-library.3.1.16/Util.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.1.16/Choice.js";
import { printf, toText } from "../.fable/fable-library.3.1.16/String.js";
import { toString, fromNumber } from "../.fable/fable-library.3.1.16/Long.js";
import { ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { PublicKey } from "../../../../lib/Domain/Types/Common.fs.js";
import { signAndSendTransaction } from "../../../../lib/Solana/Transactions.fs.js";

export const unitNativeToken = Math.pow(10, 9);

export function getSingleStringPda(string, program) {
    return singleton.Delay(() => singleton.Bind(findProgramAddress([encodeString2NoLength(string)], program), (_arg1) => singleton.Return(_arg1)));
}

export function getStringKeyPda(string, key, program) {
    return singleton.Delay(() => singleton.Bind(findProgramAddress([encodeString2NoLength(string), encodePublicKey2(key)], program), (_arg1) => singleton.Return(_arg1)));
}

export function getKeyTwoStringPda(key, string1, string2, program) {
    return singleton.Delay(() => singleton.Bind(findProgramAddress([encodePublicKey2(key), encodeString2NoLength(string1), encodeString2NoLength(string2)], program), (_arg1) => singleton.Return(_arg1)));
}

export function getTwoKeyStringPda(key1, key2, s, program) {
    return singleton.Delay(() => singleton.Bind(findProgramAddress([encodePublicKey2(key1), encodePublicKey2(key2), encodeString2NoLength(s)], program), (_arg1) => singleton.Return(_arg1)));
}

export function getDepositTokenAccPda(holding, holdingProgram) {
    let dir;
    const matchValue = holding.PossibleOrderDirection;
    switch (matchValue.tag) {
        case 1: {
            dir = "sell";
            break;
        }
        case 2: {
            throw (new Error("No BuyAndSell products available yet."));
            break;
        }
        default: {
            dir = "buy";
        }
    }
    return getKeyTwoStringPda(publicKeyBackEndToSolana(holding.PublicKey), "deposit", dir, holdingProgram);
}

export function getPositionDataPda(holdingKey, walletPublicKey, program) {
    return getTwoKeyStringPda(holdingKey, walletPublicKey, "position", program);
}

export function getMemberAccPda(walletPublicKey, pool, stakeProgram) {
    return singleton.Delay(() => singleton.Bind(findProgramAddress([encodePublicKey2(walletPublicKey), encodePublicKey2(pool), encodeString2NoLength("member")], stakeProgram), (_arg1) => singleton.Return(_arg1)));
}

export function floorDecimal(collateralMintTokenInfo, lessDigit, value) {
    const minDigit = (collateralMintTokenInfo.decimals - lessDigit) | 0;
    const multiplier = pow(fromParts(10, 0, 0, false, 0), minDigit);
    return op_Division(floor(op_Multiply(value, multiplier)), multiplier);
}

export function checkBalanceFromUser(connection, walletPublicKey, qty, holding, depositMintTokenInfo) {
    return singleton.Delay(() => {
        const depositMint = publicKeyBackEndToSolana(Holding__GetDepositMint(holding));
        return singleton.Bind(getBalance(connection, walletPublicKey), (_arg1) => {
            const lamportsBalance_1 = op_Division(new Decimal(_arg1), new Decimal(unitNativeToken));
            return singleton.Bind(getAssociatedTokenAddress(depositMint, walletPublicKey), (_arg2) => {
                const isNative = depositMint.toBase58() === "So11111111111111111111111111111111111111112";
                const floorQty = floorDecimal(depositMintTokenInfo, 2, qty);
                return singleton.TryWith(singleton.Delay(() => singleton.Bind(getTokenAccountBalance(connection, _arg2), (_arg3) => {
                    let arg20, arg20_1;
                    const tokenAmount = new Decimal(value_2(_arg3.value.uiAmount));
                    const tokenAmountPlusLamports = op_Addition(tokenAmount, max((x, y) => compare(x, y), fromParts(0, 0, 0, false, 1), op_Subtraction(lamportsBalance_1, fromParts(1, 0, 0, false, 2))));
                    return ((isNative ? (compare(tokenAmountPlusLamports, floorQty) >= 0) : false) ? true : ((!isNative) ? (compare(tokenAmount, qty) >= 0) : false)) ? singleton.Return(new FSharpResult$2(0, void 0)) : ((isNative ? (compare(tokenAmountPlusLamports, floorQty) < 0) : false) ? singleton.Return(new FSharpResult$2(1, (arg20 = depositMintTokenInfo.symbol, toText(printf("Insufficient Balance! Please try depositing maximum %M %s. Remember to keep some SOL for on-chain transaction fees"))(tokenAmountPlusLamports)(arg20)))) : singleton.Return(new FSharpResult$2(1, (arg20_1 = depositMintTokenInfo.symbol, toText(printf("Insufficient Balance! Please try depositing maximum %M %s"))(tokenAmount)(arg20_1)))));
                })), (_arg4) => {
                    let arg20_2, arg10_3;
                    return isNative ? ((compare(max((x_1, y_1) => compare(x_1, y_1), fromParts(0, 0, 0, false, 1), op_Subtraction(lamportsBalance_1, fromParts(1, 0, 0, false, 2))), floorQty) > 0) ? singleton.Return(new FSharpResult$2(0, void 0)) : singleton.Return(new FSharpResult$2(1, (arg20_2 = depositMintTokenInfo.symbol, toText(printf("Insufficient Balance! Please try depositing maximum %M %s. Remember to keep some SOL for on-chain transaction fees"))(lamportsBalance_1)(arg20_2))))) : singleton.Return(new FSharpResult$2(1, (arg10_3 = depositMintTokenInfo.symbol, toText(printf("User does not have %s account"))(arg10_3))));
                });
            });
        });
    });
}

export function placeOrder(connection, holdingProgramPubkey, walletPublicKey, wallet, qty, holding, depositMintTokenInfo) {
    return singleton.Delay(() => {
        const holdingProgram = publicKeyBackEndToSolana(holdingProgramPubkey);
        const holdingAccount = publicKeyBackEndToSolana(holding.PublicKey);
        const depositMint = publicKeyBackEndToSolana(Holding__GetDepositMint(holding));
        return singleton.Bind(getAssociatedTokenAddress(depositMint, walletPublicKey), (_arg1) => {
            const userDepositTokenAcc = _arg1;
            return singleton.Bind(getDepositTokenAccPda(holding, holdingProgram), (_arg2) => singleton.Bind(getPositionDataPda(holdingAccount, walletPublicKey, holdingProgram), (_arg3) => {
                const n2 = 1784709741 >>> 0;
                const instxBuf1 = encodeU32(bn(2946220595));
                const instxBuf2 = encodeU32(bn(n2));
                const isNative = depositMint.toBase58() === "So11111111111111111111111111111111111111112";
                const amount = fromNumber(toNumber(op_Multiply(floorDecimal(depositMintTokenInfo, 2, qty), new Decimal(Math.pow(10, depositMintTokenInfo.decimals)))), false);
                const userCollateralDeposit = encodeU64(bn(toString(amount)));
                let orderDirection;
                const matchValue = holding.PossibleOrderDirection;
                switch (matchValue.tag) {
                    case 1: {
                        orderDirection = encodeU8(bn(1));
                        break;
                    }
                    case 2: {
                        throw (new Error("No BuyAndSell products available yet."));
                        break;
                    }
                    default: {
                        orderDirection = encodeU8(bn(0));
                    }
                }
                const bufPayload = Buffer.concat([userCollateralDeposit]);
                const instructionDeposit = transactionInstruction(new TransactionInstructionInput(holdingProgram, Buffer.concat([instxBuf1, instxBuf2, orderDirection, bufPayload]), [new TransactionInstructionKey(walletPublicKey, true, true), new TransactionInstructionKey(holdingAccount, false, true), new TransactionInstructionKey(_arg2[0], false, true), new TransactionInstructionKey(userDepositTokenAcc, false, true), new TransactionInstructionKey(_arg3[0], false, true), new TransactionInstructionKey(TOKEN_PROGRAM_ID, false, false), new TransactionInstructionKey(PublicKeyDefault, false, false)]));
                const transaction = createTransaction();
                return singleton.Combine(isNative ? singleton.TryWith(singleton.Delay(() => singleton.Bind(getTokenAccountBalance(connection, userDepositTokenAcc), (_arg4) => {
                    return singleton.Zero();
                })), (_arg5) => {
                    let rent;
                    transaction.add((rent = publicKeyBackEndToSolana(new PublicKey(0, "SysvarRent111111111111111111111111111111111")), transactionInstruction(new TransactionInstructionInput(ASSOCIATED_TOKEN_PROGRAM_ID, Buffer.alloc(0), [new TransactionInstructionKey(walletPublicKey, true, true), new TransactionInstructionKey(userDepositTokenAcc, false, true), new TransactionInstructionKey(walletPublicKey, false, false), new TransactionInstructionKey(depositMint, false, false), new TransactionInstructionKey(PublicKeyDefault, false, false), new TransactionInstructionKey(TOKEN_PROGRAM_ID, false, false), new TransactionInstructionKey(rent, false, false)]))));
                    return singleton.Zero();
                }) : singleton.Zero(), singleton.Delay(() => {
                    transaction.add(instructionDeposit);
                    const matchValue_1 = wallet.wallet;
                    if (matchValue_1 == null) {
                        return singleton.Return((() => {
                            throw (new Error("Wallet Not Found."));
                        })());
                    }
                    else {
                        console.log(some("sending to wallet"));
                        console.log(some(walletPublicKey.toBase58()));
                        console.log(some("connection"));
                        console.log(some(connection.toString()));
                        return singleton.Bind(signAndSendTransaction(wallet, walletPublicKey, connection, [], transaction), (_arg6) => {
                            const result = _arg6;
                            console.log(some(result));
                            return singleton.Return([result, amount]);
                        });
                    }
                }));
            }));
        });
    });
}

