import { Record, Union } from "../../../app/client/src/.fable/fable-library.3.1.16/Types.js";
import { option_type, bool_type, uint8_type, string_type, list_type, record_type, union_type, class_type } from "../../../app/client/src/.fable/fable-library.3.1.16/Reflection.js";
import { convertToAPRFromRate, PublicKey$reflection } from "./Common.fs.js";
import { fromParts, op_Addition, op_Division } from "../../../app/client/src/.fable/fable-library.3.1.16/Decimal.js";
import Decimal from "../../../app/client/src/.fable/fable-library.3.1.16/Decimal.js";
import { defaultArg, map } from "../../../app/client/src/.fable/fable-library.3.1.16/Option.js";
import { toNumber, fromBits, op_Division as op_Division_1 } from "../../../app/client/src/.fable/fable-library.3.1.16/Long.js";

export class StartEpoch extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["StartEpoch"];
    }
}

export function StartEpoch$reflection() {
    return union_type("ExoticMarkets.Domain.Products.StartEpoch", [], StartEpoch, () => [[["Item", class_type("System.DateTimeOffset")]]]);
}

export function StartEpochModule_value(_arg1) {
    return _arg1.fields[0];
}

export class StartSettlement extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["StartSettlement"];
    }
}

export function StartSettlement$reflection() {
    return union_type("ExoticMarkets.Domain.Products.StartSettlement", [], StartSettlement, () => [[["Item", class_type("System.DateTimeOffset")]]]);
}

export function StartSettlementModule_value(_arg1) {
    return _arg1.fields[0];
}

export class EndDeposits extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["EndDeposits"];
    }
}

export function EndDeposits$reflection() {
    return union_type("ExoticMarkets.Domain.Products.EndDeposits", [], EndDeposits, () => [[["Item", class_type("System.DateTimeOffset")]]]);
}

export function EndDepositsModule_value(_arg1) {
    return _arg1.fields[0];
}

export class Kind extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Put", "Call", "Undefined"];
    }
}

export function Kind$reflection() {
    return union_type("ExoticMarkets.Domain.Products.Kind", [], Kind, () => [[], [], []]);
}

export class DualInvestment extends Record {
    constructor(Kind) {
        super();
        this.Kind = Kind;
    }
}

export function DualInvestment$reflection() {
    return record_type("ExoticMarkets.Domain.Products.DualInvestment", [], DualInvestment, () => [["Kind", Kind$reflection()]]);
}

export class Spread extends Record {
    constructor(Strike1, Strike2) {
        super();
        this.Strike1 = Strike1;
        this.Strike2 = Strike2;
    }
}

export function Spread$reflection() {
    return record_type("ExoticMarkets.Domain.Products.Spread", [], Spread, () => [["Strike1", class_type("System.Decimal")], ["Strike2", class_type("System.Decimal")]]);
}

export class PutWorstOff extends Record {
    constructor(UnderlyingMints) {
        super();
        this.UnderlyingMints = UnderlyingMints;
    }
}

export function PutWorstOff$reflection() {
    return record_type("ExoticMarkets.Domain.Products.PutWorstOff", [], PutWorstOff, () => [["UnderlyingMints", list_type(PublicKey$reflection())]]);
}

export class SpecificData extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["DualInvestment", "Spread", "PutWorstOff"];
    }
}

export function SpecificData$reflection() {
    return union_type("ExoticMarkets.Domain.Products.SpecificData", [], SpecificData, () => [[["Item", DualInvestment$reflection()]], [["Item", Spread$reflection()]], [["Item", PutWorstOff$reflection()]]]);
}

export class Product extends Record {
    constructor(PublicKey, UnderlyingMint, CollateralMint, StartEpoch, EndDeposits, StartSettlement, VaultName, VaultBump, RedeemableMintBump, VaultCollateralTokenAccountBump, VaultCollateralTokenAccount, StartEpochVaultBalance, OptionMint, OptionsRemaining, CollateralCollected, UnderlyingCount, WinningBidder, BidderPremium, MinOrderDeposit, MaxOrderDeposit, MinYield, MaxYield, RiskLevel, TotalBuyAmount, SpecificData) {
        super();
        this.PublicKey = PublicKey;
        this.UnderlyingMint = UnderlyingMint;
        this.CollateralMint = CollateralMint;
        this.StartEpoch = StartEpoch;
        this.EndDeposits = EndDeposits;
        this.StartSettlement = StartSettlement;
        this.VaultName = VaultName;
        this.VaultBump = VaultBump;
        this.RedeemableMintBump = RedeemableMintBump;
        this.VaultCollateralTokenAccountBump = VaultCollateralTokenAccountBump;
        this.VaultCollateralTokenAccount = VaultCollateralTokenAccount;
        this.StartEpochVaultBalance = StartEpochVaultBalance;
        this.OptionMint = OptionMint;
        this.OptionsRemaining = OptionsRemaining;
        this.CollateralCollected = CollateralCollected;
        this.UnderlyingCount = UnderlyingCount;
        this.WinningBidder = WinningBidder;
        this.BidderPremium = BidderPremium;
        this.MinOrderDeposit = MinOrderDeposit;
        this.MaxOrderDeposit = MaxOrderDeposit;
        this.MinYield = MinYield;
        this.MaxYield = MaxYield;
        this.RiskLevel = RiskLevel;
        this.TotalBuyAmount = TotalBuyAmount;
        this.SpecificData = SpecificData;
    }
}

export function Product$reflection() {
    return record_type("ExoticMarkets.Domain.Products.Product", [], Product, () => [["PublicKey", PublicKey$reflection()], ["UnderlyingMint", PublicKey$reflection()], ["CollateralMint", PublicKey$reflection()], ["StartEpoch", StartEpoch$reflection()], ["EndDeposits", EndDeposits$reflection()], ["StartSettlement", StartSettlement$reflection()], ["VaultName", string_type], ["VaultBump", uint8_type], ["RedeemableMintBump", uint8_type], ["VaultCollateralTokenAccountBump", uint8_type], ["VaultCollateralTokenAccount", uint8_type], ["StartEpochVaultBalance", class_type("System.UInt64")], ["OptionMint", string_type], ["OptionsRemaining", bool_type], ["CollateralCollected", bool_type], ["UnderlyingCount", option_type(class_type("System.UInt64"))], ["WinningBidder", string_type], ["BidderPremium", option_type(class_type("System.UInt64"))], ["MinOrderDeposit", option_type(class_type("System.UInt64"))], ["MaxOrderDeposit", option_type(class_type("System.UInt64"))], ["MinYield", class_type("System.UInt64")], ["MaxYield", class_type("System.UInt64")], ["RiskLevel", uint8_type], ["TotalBuyAmount", class_type("System.UInt64")], ["SpecificData", SpecificData$reflection()]]);
}

export function estimatedYield(maturity, yield$0027) {
    return convertToAPRFromRate(maturity, yield$0027);
}

export function estimatedAverageYield(maturity, lowYield, highYield) {
    return op_Division(op_Addition(convertToAPRFromRate(maturity, lowYield), convertToAPRFromRate(maturity, highYield)), fromParts(2, 0, 0, false, 0));
}

export function strikePercentage(strike) {
    return map((strike_1) => op_Division_1(strike_1, fromBits(1000000, 0, true)), strike);
}

export function size(deposit, decimals) {
    return defaultArg(map((x) => op_Division(new Decimal(toNumber(x)), new Decimal(Math.pow(10, decimals))), deposit), fromParts(0, 0, 0, false, 1));
}

export function remaining(maxDeposit, totalBuyAmount) {
    if (maxDeposit == null) {
        return 0;
    }
    else {
        const maxProductAmount = maxDeposit;
        return (toNumber(maxProductAmount) - (toNumber(totalBuyAmount) / toNumber(maxProductAmount))) * 100;
    }
}

