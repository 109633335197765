import { Record } from "../../../app/client/src/.fable/fable-library.3.1.16/Types.js";
import { array_type, record_type, option_type, string_type } from "../../../app/client/src/.fable/fable-library.3.1.16/Reflection.js";
import { contains } from "../../../app/client/src/.fable/fable-library.3.1.16/Array.js";
import { stringHash } from "../../../app/client/src/.fable/fable-library.3.1.16/Util.js";

export class InjectiveHideProduct extends Record {
    constructor(HoldingId, ProductId) {
        super();
        this.HoldingId = HoldingId;
        this.ProductId = ProductId;
    }
}

export function InjectiveHideProduct$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveProductFilter.InjectiveHideProduct", [], InjectiveHideProduct, () => [["HoldingId", option_type(string_type)], ["ProductId", option_type(string_type)]]);
}

export function InjectiveHideProduct__IsHideProduct(this$, holdingId, productId) {
    let matchValue;
    if (matchValue = this$.HoldingId, (matchValue == null) ? false : (holdingId === matchValue)) {
        return true;
    }
    else {
        const matchValue_1 = this$.ProductId;
        if (matchValue_1 == null) {
            return false;
        }
        else {
            return productId === matchValue_1;
        }
    }
}

export class InjectiveWhitelistProduct extends Record {
    constructor(HoldingId, ProductId, WhitelistAddresses) {
        super();
        this.HoldingId = HoldingId;
        this.ProductId = ProductId;
        this.WhitelistAddresses = WhitelistAddresses;
    }
}

export function InjectiveWhitelistProduct$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveProductFilter.InjectiveWhitelistProduct", [], InjectiveWhitelistProduct, () => [["HoldingId", option_type(string_type)], ["ProductId", option_type(string_type)], ["WhitelistAddresses", array_type(string_type)]]);
}

export function InjectiveWhitelistProduct__IsWhitelisted(this$, holdingId, productId, walletAddress) {
    let matchValue, matchValue_1;
    if ((matchValue = this$.HoldingId, (matchValue == null) ? false : (holdingId === matchValue)) ? true : (matchValue_1 = this$.ProductId, (matchValue_1 == null) ? false : (productId === matchValue_1))) {
        return contains(walletAddress, this$.WhitelistAddresses, {
            Equals: (x, y) => (x === y),
            GetHashCode: (x) => stringHash(x),
        });
    }
    else {
        return true;
    }
}

