import { createElement } from "react";
import { printf, toText, join } from "./.fable/fable-library.3.1.16/String.js";
import { Interop_reactApi } from "./.fable/Feliz.1.62.0/Interop.fs.js";
import { MuiHelpers_createElement } from "./.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Slider from "@material-ui/core/Slider";
import { round as round_1, fromParts, op_Multiply, op_Division, toNumber } from "./.fable/fable-library.3.1.16/Decimal.js";
import Decimal from "./.fable/fable-library.3.1.16/Decimal.js";
import { tryFind } from "./.fable/fable-library.3.1.16/Array.js";
import { round } from "./.fable/fable-library.3.1.16/Util.js";

export function SimulateSpotLevel(simulateSpotLevelInputProps) {
    const setExpirySpot = simulateSpotLevelInputProps.setExpirySpot;
    const expirtSpot = simulateSpotLevelInputProps.expirtSpot;
    const initial = simulateSpotLevelInputProps.initial;
    const setSpotLevel = simulateSpotLevelInputProps.setSpotLevel;
    const spot = simulateSpotLevelInputProps.spot;
    const classes = simulateSpotLevelInputProps.classes;
    return createElement("div", {
        className: join(" ", classes),
        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Slider, [["defaultValue", ~(~toNumber(initial))], ["step", 1], ["min", 25], ["max", 175], ["valueLabelDisplay", "on"], ["valueLabelFormat", toText(printf("%0.0f%%"))(expirtSpot)], ["onChange", (_arg25, v_1) => {
            const v = v_1 | 0;
            setExpirySpot(new Decimal(v));
            setSpotLevel(op_Division(op_Multiply(new Decimal(v), spot), fromParts(100, 0, 0, false, 0)));
        }]])]),
    });
}

export function SimulateSpotLevelRealValue(simulateSpotLevelRealValueInputProps) {
    const uiFormat = simulateSpotLevelRealValueInputProps.uiFormat;
    const spotToken = simulateSpotLevelRealValueInputProps.spotToken;
    const setExpirySpot = simulateSpotLevelRealValueInputProps.setExpirySpot;
    const expirtSpot = simulateSpotLevelRealValueInputProps.expirtSpot;
    const initialSpot = simulateSpotLevelRealValueInputProps.initialSpot;
    const setSpotLevel = simulateSpotLevelRealValueInputProps.setSpotLevel;
    const spot = simulateSpotLevelRealValueInputProps.spot;
    const classes = simulateSpotLevelRealValueInputProps.classes;
    const formatOption = (uiFormat.length === 0) ? (void 0) : tryFind((x) => (x.TokenSymbol.toLocaleLowerCase() === spotToken.symbol.toLocaleLowerCase()), uiFormat);
    const stepDefault = (spotToken.symbol.toLocaleLowerCase() === "inj") ? round(toNumber(spot) * 0.01, 1) : 1;
    let step;
    if (formatOption == null) {
        step = stepDefault;
    }
    else {
        const matchValue_1 = formatOption.PriceStep;
        step = ((matchValue_1 == null) ? stepDefault : matchValue_1);
    }
    let priceFormatDefault;
    if (spotToken.symbol.toLocaleLowerCase() === "inj") {
        const arg10 = op_Division(op_Multiply(expirtSpot, spot), fromParts(100, 0, 0, false, 0));
        priceFormatDefault = toText(printf("%0.01f"))(arg10);
    }
    else {
        const arg10_1 = (~(~toNumber(round_1(op_Division(op_Multiply(expirtSpot, spot), fromParts(100, 0, 0, false, 0)))))) | 0;
        priceFormatDefault = toText(printf("%d"))(arg10_1);
    }
    let priceFormat;
    if (formatOption == null) {
        priceFormat = priceFormatDefault;
    }
    else {
        const matchValue_3 = formatOption.PriceDigit;
        if (matchValue_3 == null) {
            priceFormat = priceFormatDefault;
        }
        else {
            const d = matchValue_3;
            priceFormat = toText(printf("%0.0" + toText(printf("%df"))(d)))(op_Division(op_Multiply(expirtSpot, spot), fromParts(100, 0, 0, false, 0)));
        }
    }
    const min = (~(~toNumber(round_1(op_Multiply(spot, fromParts(25, 0, 0, false, 2)))))) | 0;
    const max = (~(~toNumber(round_1(op_Multiply(spot, fromParts(175, 0, 0, false, 2)))))) | 0;
    return createElement("div", {
        className: join(" ", classes),
        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Slider, [["defaultValue", toNumber(op_Division(op_Multiply(initialSpot, spot), fromParts(100, 0, 0, false, 0)))], ["step", step], ["min", min], ["max", max], ["valueLabelDisplay", "on"], ["valueLabelFormat", priceFormat], ["onChange", (_arg26, v_1) => {
            setExpirySpot(op_Division(op_Multiply(new Decimal(v_1), fromParts(100, 0, 0, false, 0)), spot));
        }]])]),
    });
}

