import { FSharpRef, Union, Record } from "./.fable/fable-library.3.1.16/Types.js";
import { union_type, record_type, float64_type, string_type } from "./.fable/fable-library.3.1.16/Reflection.js";
import { createElement } from "react";
import * as react from "react";
import { printf, interpolate, toText, join } from "./.fable/fable-library.3.1.16/String.js";
import utils$002Emodule from "./styles/utils.module.scss";
import { op_Addition, op_Subtraction, toNumber as toNumber_1, compare, op_Multiply, op_Division, fromParts, tryParse } from "./.fable/fable-library.3.1.16/Decimal.js";
import Decimal from "./.fable/fable-library.3.1.16/Decimal.js";
import { Col, Row } from "./UtilComponents.fs.js";
import { toArray, empty, singleton, ofArray } from "./.fable/fable-library.3.1.16/List.js";
import { singleton as singleton_1, append, delay, toList } from "./.fable/fable-library.3.1.16/Seq.js";
import { Interop_reactApi } from "./.fable/Feliz.1.62.0/Interop.fs.js";
import { useReact_useMemo_CF4EA67, useFeliz_React__React_useState_Static_1505 } from "./.fable/Feliz.1.62.0/React.fs.js";
import { fromInteger, fromBits, toNumber } from "./.fable/fable-library.3.1.16/Long.js";
import { map, defaultArg } from "./.fable/fable-library.3.1.16/Option.js";
import { Point as Point_1 } from "./FixedStrikedChart.fs.js";
import { CurrencyInput, Price } from "./Currency.fs.js";
import * as accounting from "accounting";
import { SimulateSpotLevel } from "./SpotLevel.fs.js";
import { RemainingTime, ProductStart, ProductLifeCycle } from "./ProductLifecyle.fs.js";
import { equals, createObj, int32ToString, max } from "./.fable/fable-library.3.1.16/Util.js";
import { ResponsiveContainer, Area, CartesianGrid, Tooltip, YAxis, XAxis, AreaChart } from "recharts";
import { map as map_1 } from "./.fable/fable-library.3.1.16/Array.js";
import single$002Dproducts$002Emodule from "./styles/single-products.module.scss";

export class Point extends Record {
    constructor(name, uv) {
        super();
        this.name = name;
        this.uv = uv;
    }
}

export function Point$reflection() {
    return record_type("SettlementRules.Point", [], Point, () => [["name", string_type], ["uv", float64_type]]);
}

export class Scenario extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["AboveSpot", "BelowSpot"];
    }
}

export function Scenario$reflection() {
    return union_type("SettlementRules.Scenario", [], Scenario, () => [[], []]);
}

export function text(t) {
    return createElement("span", {
        className: join(" ", [utils$002Emodule["text-dark"]]),
        children: t,
    });
}

export function textBold(t) {
    return createElement("span", {
        className: join(" ", [utils$002Emodule["text-dark"], utils$002Emodule["boldness-500"]]),
        children: t,
    });
}

export function parseDecimal(str) {
    let matchValue;
    let outArg = new Decimal(0);
    matchValue = [tryParse(str, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (matchValue[0]) {
        return matchValue[1];
    }
    else {
        return fromParts(100, 0, 0, false, 0);
    }
}

export function ScenarioACall(scenarioACallInputProps) {
    let children;
    const maxYield = scenarioACallInputProps.maxYield;
    const minYield = scenarioACallInputProps.minYield;
    const yield$0027 = scenarioACallInputProps["yield\u0027"];
    const simulatedSpotAsExpiry = scenarioACallInputProps.simulatedSpotAsExpiry;
    const depositAmount = scenarioACallInputProps.depositAmount;
    const fixedStrike = scenarioACallInputProps.fixedStrike;
    const collateralMintToken = scenarioACallInputProps.collateralMintToken;
    const symbol = scenarioACallInputProps.symbol;
    const recieve = scenarioACallInputProps.recieve;
    const product = scenarioACallInputProps.product;
    return react.createElement(react.Fragment, {}, createElement(Row, {
        classes: ofArray([utils$002Emodule["boldness-600"], utils$002Emodule["margin-m-bottom"], utils$002Emodule["text-scenario-a"]]),
        children: singleton("Scenario A: Above the Strike"),
    }), (children = toList(delay(() => append(singleton_1(text(toText(interpolate("The settlement price of ", [])))), delay(() => {
        let arg10;
        return append(singleton_1(textBold((arg10 = collateralMintToken.symbol, toText(printf("%s"))(arg10)))), delay(() => append(singleton_1(text(toText(interpolate(" is ", [])))), delay(() => append(singleton_1(textBold(toText(printf("\u003e %0.2f (Strike)"))(fixedStrike))), delay(() => append(singleton_1(text(toText(interpolate(" and if you have deposited ", [])))), delay(() => {
            let arg20;
            return append(singleton_1(textBold((arg20 = collateralMintToken.symbol, toText(printf("%0.02f %s"))(depositAmount)(arg20)))), delay(() => append(singleton_1(text(toText(interpolate(", you would then receive at expiry ", [])))), delay(() => append(singleton_1(textBold(toText(printf("%0.02f %s"))(recieve)(symbol))), delay(() => append(singleton_1(text(" converted into ")), delay(() => {
                let arg10_4;
                return append(singleton_1(textBold((arg10_4 = collateralMintToken.symbol, toText(printf("%s"))(arg10_4)))), delay(() => append(singleton_1(text(" at the ")), delay(() => {
                    let arg10_5;
                    return append(singleton_1(textBold((arg10_5 = collateralMintToken.symbol, toText(printf(" %s/%s "))(arg10_5)(symbol)))), delay(() => append(singleton_1(text("settlement price ")), delay(() => ((product.BidderPremium == null) ? append(singleton_1(text(" and an expected annual yield of ")), delay(() => append(singleton_1(textBold(toText(printf("%0.02M%%"))(maxYield))), delay(() => {
                        let arg20_3;
                        return singleton_1(text((arg20_3 = collateralMintToken.symbol, toText(printf(" (min %0.02f%%) on your deposit in %s"))(minYield)(arg20_3))));
                    })))) : append(singleton_1(text(" and an annual yield of ")), delay(() => append(singleton_1(textBold(toText(printf("%0.02M%%"))(maxYield))), delay(() => {
                        let arg10_9;
                        return singleton_1(text((arg10_9 = collateralMintToken.symbol, toText(printf(" on your deposit in %s"))(arg10_9))));
                    })))))))));
                }))));
            }))))))));
        }))))))));
    })))), createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    })));
}

export function ScenarioBCall(scenarioBCallInputProps) {
    let children;
    const maxYield = scenarioBCallInputProps.maxYield;
    const minYield = scenarioBCallInputProps.minYield;
    const yield$0027 = scenarioBCallInputProps["yield\u0027"];
    const collateralMintToken = scenarioBCallInputProps.collateralMintToken;
    const fixedStrike = scenarioBCallInputProps.fixedStrike;
    const depositAmount = scenarioBCallInputProps.depositAmount;
    const product = scenarioBCallInputProps.product;
    return react.createElement(react.Fragment, {}, createElement(Row, {
        classes: ofArray([utils$002Emodule["boldness-600"], utils$002Emodule["margin-m-bottom"], utils$002Emodule["text-scenario-b"]]),
        children: singleton("Scenario B: Below the Strike"),
    }), (children = toList(delay(() => append(singleton_1(text(toText(interpolate("The settlement price of ", [])))), delay(() => {
        let arg10;
        return append(singleton_1(textBold((arg10 = collateralMintToken.symbol, toText(printf("%s"))(arg10)))), delay(() => append(singleton_1(text(toText(interpolate(" is ", [])))), delay(() => append(singleton_1(textBold(toText(printf("≤ %0.2f (Strike)"))(fixedStrike))), delay(() => append(singleton_1(text(" and if you have deposited ")), delay(() => {
            let arg20;
            return append(singleton_1(textBold((arg20 = collateralMintToken.symbol, toText(printf("%0.02f %s"))(depositAmount)(arg20)))), delay(() => append(singleton_1(text(toText(interpolate(". You would then receive at expiry your full deposit back (in ", [])))), delay(() => append(singleton_1(textBold(toText(interpolate("%P())", [collateralMintToken.symbol])))), delay(() => ((product.BidderPremium == null) ? append(singleton_1(text(" and an expected annual yield of ")), delay(() => append(singleton_1(textBold(toText(printf("%0.02M%%"))(maxYield))), delay(() => {
                let arg20_1;
                return singleton_1(text((arg20_1 = collateralMintToken.symbol, toText(printf(" (min %0.02f%%) on your deposit in %s"))(minYield)(arg20_1))));
            })))) : append(singleton_1(text(" and an annual yield of ")), delay(() => append(singleton_1(textBold(toText(printf("%0.02M%%"))(maxYield))), delay(() => {
                let arg10_6;
                return singleton_1(text((arg10_6 = collateralMintToken.symbol, toText(printf(" on your deposit in %s"))(arg10_6))));
            })))))))))));
        }))))))));
    })))), createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    })));
}

export function ScenarioAPut(scenarioAPutInputProps) {
    let children;
    const maxYield = scenarioAPutInputProps.maxYield;
    const minYield = scenarioAPutInputProps.minYield;
    const yield$0027 = scenarioAPutInputProps["yield\u0027"];
    const collateralMintToken = scenarioAPutInputProps.collateralMintToken;
    const spotTokenSymbol = scenarioAPutInputProps.spotTokenSymbol;
    const fixedStrike = scenarioAPutInputProps.fixedStrike;
    const depositAmount = scenarioAPutInputProps.depositAmount;
    const product = scenarioAPutInputProps.product;
    return react.createElement(react.Fragment, {}, createElement(Row, {
        classes: ofArray([utils$002Emodule["boldness-600"], utils$002Emodule["margin-m-bottom"], utils$002Emodule["text-scenario-a"]]),
        children: singleton("Scenario A: Above the Strike"),
    }), (children = toList(delay(() => append(singleton_1(text(toText(interpolate("The settlement price of ", [])))), delay(() => append(singleton_1(textBold(toText(printf("%s"))(spotTokenSymbol))), delay(() => append(singleton_1(text(toText(interpolate(" is ", [])))), delay(() => append(singleton_1(textBold(toText(printf("\u003e= %0.2f (Strike)"))(fixedStrike))), delay(() => append(singleton_1(text(toText(interpolate(" and if you have deposited ", [])))), delay(() => {
        let arg20;
        return append(singleton_1(textBold((arg20 = collateralMintToken.symbol, toText(printf("%0.02f %s"))(depositAmount)(arg20)))), delay(() => append(singleton_1(text(toText(interpolate(". You would then receive at expiry your full deposit back in ", [])))), delay(() => append(singleton_1(textBold(toText(interpolate("%P()", [collateralMintToken.symbol])))), delay(() => ((product.BidderPremium == null) ? append(singleton_1(text(" and an expected annual yield (always paid) of ")), delay(() => append(singleton_1(textBold(toText(printf("%0.02M%%"))(maxYield))), delay(() => {
            let arg20_1;
            return singleton_1(text((arg20_1 = collateralMintToken.symbol, toText(printf(" (min %0.02f%%) on your deposit in %s"))(minYield)(arg20_1))));
        })))) : append(singleton_1(text(" and an annual yield (always paid) of ")), delay(() => append(singleton_1(textBold(toText(printf("%0.02M%%"))(maxYield))), delay(() => {
            let arg10_6;
            return singleton_1(text((arg10_6 = collateralMintToken.symbol, toText(printf(" on your deposit in %s"))(arg10_6))));
        })))))))))));
    })))))))))))), createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    })));
}

export function ScenarioBPut(scenarioBPutInputProps) {
    let children;
    const maxYield = scenarioBPutInputProps.maxYield;
    const minYield = scenarioBPutInputProps.minYield;
    const yield$0027 = scenarioBPutInputProps["yield\u0027"];
    const simulatedSpotAsExpiry = scenarioBPutInputProps.simulatedSpotAsExpiry;
    const depositAmount = scenarioBPutInputProps.depositAmount;
    const fixedStrike = scenarioBPutInputProps.fixedStrike;
    const collateralMintToken = scenarioBPutInputProps.collateralMintToken;
    const spotTokenSymbol = scenarioBPutInputProps.spotTokenSymbol;
    const recieve = scenarioBPutInputProps.recieve;
    const product = scenarioBPutInputProps.product;
    return react.createElement(react.Fragment, {}, createElement(Row, {
        classes: ofArray([utils$002Emodule["boldness-600"], utils$002Emodule["margin-m-bottom"], utils$002Emodule["text-scenario-b"]]),
        children: singleton("Scenario B: Below the Strike"),
    }), (children = toList(delay(() => append(singleton_1(text(toText(interpolate("If the settlement price of ", [])))), delay(() => append(singleton_1(textBold(toText(printf("%s"))(spotTokenSymbol))), delay(() => append(singleton_1(text(toText(interpolate(" is ", [])))), delay(() => append(singleton_1(textBold(toText(printf("\u003c %0.2f"))(fixedStrike))), delay(() => append(singleton_1(text(" and if you have deposited ")), delay(() => {
        let arg20;
        return append(singleton_1(textBold((arg20 = collateralMintToken.symbol, toText(printf("%0.02f %s"))(depositAmount)(arg20)))), delay(() => append(singleton_1(text(toText(interpolate(", you would then receive at expiry ", [])))), delay(() => append(singleton_1(textBold(toText(printf("%0.02f %s"))(recieve)(spotTokenSymbol))), delay(() => append(singleton_1(text(" converted into ")), delay(() => {
            let arg10_4;
            return append(singleton_1(textBold((arg10_4 = collateralMintToken.symbol, toText(printf("%s"))(arg10_4)))), delay(() => append(singleton_1(text(" at the ")), delay(() => {
                let arg20_2;
                return append(singleton_1(textBold((arg20_2 = collateralMintToken.symbol, toText(printf(" %s/%s "))(spotTokenSymbol)(arg20_2)))), delay(() => append(singleton_1(text("settlement price ")), delay(() => ((product.BidderPremium == null) ? append(singleton_1(text(" and an expected annual yield of ")), delay(() => append(singleton_1(textBold(toText(printf("%0.02M%%"))(maxYield))), delay(() => {
                    let arg20_3;
                    return singleton_1(text((arg20_3 = collateralMintToken.symbol, toText(printf(" (min %0.02f%%) on your deposit in %s"))(minYield)(arg20_3))));
                })))) : append(singleton_1(text(" and an annual yield of ")), delay(() => append(singleton_1(textBold(toText(printf("%0.02M%%"))(maxYield))), delay(() => {
                    let arg10_9;
                    return singleton_1(text((arg10_9 = collateralMintToken.symbol, toText(printf(" on your deposit in %s"))(arg10_9))));
                })))))))));
            }))));
        }))))))));
    })))))))))))), createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    })));
}

export function SettlementRules(settlementRulesInputProps) {
    let arg10, arg10_1;
    const duration = settlementRulesInputProps.duration;
    const depositAmount = settlementRulesInputProps.depositAmount;
    const spot = settlementRulesInputProps.spot;
    const spotToken = settlementRulesInputProps.spotToken;
    const underlyingToken = settlementRulesInputProps.underlyingToken;
    const product = settlementRulesInputProps.product;
    const patternInput = useFeliz_React__React_useState_Static_1505(spot);
    const currentPrice = spot;
    let fixedStrike;
    const matchValue = product.FixedStrike;
    fixedStrike = ((matchValue != null) ? op_Division(new Decimal(toNumber(matchValue)), fromParts(1000000, 0, 0, false, 0)) : op_Multiply(op_Division(new Decimal(toNumber(defaultArg(product.RelativeStrike, fromBits(0, 0, true)))), fromParts(100000000, 0, 0, false, 0)), currentPrice));
    const initialStrikeSlider = fromParts(100, 0, 0, false, 0);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(initialStrikeSlider);
    const currentStrikeSlider = patternInput_1[0];
    const settlementPrice = useReact_useMemo_CF4EA67(() => op_Multiply(currentPrice, op_Division(currentStrikeSlider, fromParts(1000, 0, 0, false, 1))), [currentStrikeSlider]);
    const deposit = useReact_useMemo_CF4EA67(() => ((compare(depositAmount, fromParts(0, 0, 0, false, 1)) <= 0) ? fromParts(100, 0, 0, false, 0) : depositAmount), [depositAmount]);
    const chooseScenario = useReact_useMemo_CF4EA67(() => ((compare(settlementPrice, fixedStrike) >= 0) ? (new Scenario(0)) : (new Scenario(1))), [currentStrikeSlider]);
    let data;
    const spotLevel_2 = patternInput[0];
    data = ofArray([new Point_1("Start Product", spotLevel_2, spotLevel_2), new Point_1("", op_Multiply(spotLevel_2, fromParts(111, 0, 0, false, 2)), op_Multiply(spotLevel_2, fromParts(115, 0, 0, false, 2))), new Point_1("", op_Multiply(fixedStrike, fromParts(97, 0, 0, false, 2)), op_Multiply(spotLevel_2, fromParts(93, 0, 0, false, 2))), new Point_1("Product End", op_Multiply(fixedStrike, fromParts(107, 0, 0, false, 2)), op_Multiply(fixedStrike, fromParts(96, 0, 0, false, 2)))]);
    return createElement("div", {
        className: join(" ", ["card-no-shadow"]),
        style: {
            width: 650 + "px",
            height: "auto",
            marginLeft: "auto",
        },
        children: Interop_reactApi.Children.toArray([createElement(Row, {
            classes: singleton(utils$002Emodule["space-between"]),
            children: ofArray([createElement(Col, {
                classes: ofArray([utils$002Emodule["align-start"], utils$002Emodule["gap-s"], "text-grey"]),
                children: ofArray([(arg10 = spotToken.symbol, toText(printf("%s-USD"))(arg10)), createElement("div", {
                    className: join(" ", ["text-color-black"]),
                    children: Interop_reactApi.Children.toArray([createElement(Price, {
                        token: spotToken,
                        amount: currentPrice,
                    })]),
                })]),
            }), createElement("div", {
                className: join(" ", [utils$002Emodule["boldness-500"], utils$002Emodule["text-l"], utils$002Emodule["align-center"]]),
                children: "Settlement Rules",
            }), createElement("div", {
                className: join(" ", [utils$002Emodule["align-end"]]),
            })]),
        }), createElement(Row, {
            classes: empty(),
            children: singleton(createElement(Col, {
                classes: singleton(utils$002Emodule["margin-m-top"]),
                children: singleton(createElement("div", {
                    className: join(" ", ["text-color-black", utils$002Emodule["text-xs"]]),
                    children: "Estimated Spot at Product Start",
                })),
            })),
        }), createElement(Row, {
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["boldness-500"], utils$002Emodule["margin-m-top"]]),
            children: empty(),
        }), createElement(Row, {
            classes: ofArray([utils$002Emodule["text-m"], utils$002Emodule["boldness-600"], utils$002Emodule["margin-s-bottom"], utils$002Emodule["margin-m-top"]]),
            children: singleton((arg10_1 = accounting.formatMoney(toNumber_1(settlementPrice), "$"), toText(printf("Simulate Spot Level at Expiry (Settlement Price): %s"))(arg10_1))),
        }), createElement(Row, {
            classes: empty(),
            children: singleton(createElement(Col, {
                classes: ofArray([utils$002Emodule["margin-xs-top"], utils$002Emodule["margin-l-bottom"]]),
                children: singleton(createElement("div", {
                    className: join(" ", ["text-color-black", utils$002Emodule["text-xs"]]),
                    children: "Slide below to change the Settlement Price",
                })),
            })),
        }), createElement(SimulateSpotLevel, {
            classes: [utils$002Emodule["margin-l-bottom"], utils$002Emodule["margin-s-top"]],
            spot: spot,
            setSpotLevel: patternInput[1],
            initial: initialStrikeSlider,
            expirtSpot: currentStrikeSlider,
            setExpirySpot: patternInput_1[1],
        }), createElement(Row, {
            classes: ofArray([utils$002Emodule["text-m"], utils$002Emodule["boldness-600"], utils$002Emodule["margin-l-bottom"], utils$002Emodule["margin-l-top"]]),
            children: toList(delay(() => {
                let arg20;
                const txt = (product.FixedStrike == null) ? "Strike Price given Spot now (Barrier Price):" : "Fixed Strike Price";
                return singleton_1((arg20 = accounting.formatMoney(toNumber_1(fixedStrike), "$"), toText(printf("%s %s"))(txt)(arg20)));
            })),
        }), createElement(Row, {
            classes: ofArray([utils$002Emodule["margin-m-top"], utils$002Emodule["text-light"], utils$002Emodule["margin-l-bottom"]]),
            children: toList(delay(() => {
                const matchValue_2 = [product.Kind, chooseScenario];
                if (matchValue_2[0].tag === 1) {
                    return (matchValue_2[1].tag === 1) ? singleton_1(createElement(ScenarioBCall, {
                        product: product,
                        depositAmount: deposit,
                        fixedStrike: fixedStrike,
                        collateralMintToken: spotToken,
                        ["yield\u0027"]: product.EstimatedYieldAverage,
                        minYield: product.EstimatedYieldLow,
                        maxYield: product.EstimatedYieldHigh,
                    })) : singleton_1(createElement(ScenarioACall, {
                        product: product,
                        recieve: op_Multiply(deposit, fixedStrike),
                        symbol: "USDC",
                        collateralMintToken: spotToken,
                        fixedStrike: fixedStrike,
                        depositAmount: deposit,
                        simulatedSpotAsExpiry: settlementPrice,
                        ["yield\u0027"]: product.EstimatedYieldAverage,
                        minYield: product.EstimatedYieldLow,
                        maxYield: product.EstimatedYieldHigh,
                    }));
                }
                else if (matchValue_2[0].tag === 2) {
                    if (matchValue_2[1].tag === 0) {
                        const deposit_2 = op_Multiply(deposit, fromParts(100, 0, 0, false, 0));
                        return singleton_1(createElement(ScenarioAPut, {
                            product: product,
                            depositAmount: deposit_2,
                            fixedStrike: fixedStrike,
                            spotTokenSymbol: spotToken.symbol,
                            collateralMintToken: underlyingToken,
                            ["yield\u0027"]: product.EstimatedYieldAverage,
                            minYield: product.EstimatedYieldLow,
                            maxYield: product.EstimatedYieldHigh,
                        }));
                    }
                    else {
                        const deposit_1 = op_Multiply(deposit, fromParts(100, 0, 0, false, 0));
                        return singleton_1(createElement(ScenarioBPut, {
                            product: product,
                            recieve: op_Division(deposit_1, fixedStrike),
                            spotTokenSymbol: spotToken.symbol,
                            collateralMintToken: underlyingToken,
                            fixedStrike: fixedStrike,
                            depositAmount: deposit_1,
                            simulatedSpotAsExpiry: settlementPrice,
                            ["yield\u0027"]: product.EstimatedYieldAverage,
                            minYield: product.EstimatedYieldLow,
                            maxYield: product.EstimatedYieldHigh,
                        }));
                    }
                }
                else {
                    return singleton_1(null);
                }
            })),
        }), createElement(Row, {
            classes: ofArray([utils$002Emodule["text-m"], utils$002Emodule["boldness-600"], utils$002Emodule["margin-s-bottom"]]),
            children: singleton("Product Lifecycle (Local Time)"),
        }), createElement(Row, {
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["boldness-500"], utils$002Emodule["margin-m-top"]]),
            children: singleton(createElement(ProductLifeCycle, {
                subscriptionStart: product.StartEpoch,
                subscriptionEnd: product.EndDeposits,
                productStart: new ProductStart(0, product.EndDeposits),
                productEnd: product.StartSettlement,
            })),
        }), createElement(Row, {
            classes: singleton(utils$002Emodule["margin-m-top"]),
            children: singleton(createElement(RemainingTime, {
                duration: duration,
            })),
        })]),
    });
}

export function SettlementRulesGroup(settlementRulesGroupInputProps) {
    let x_2, deposit, children, color, value_57, arg10, arg10_1;
    const duration = settlementRulesGroupInputProps.duration;
    const depositAmount = settlementRulesGroupInputProps.depositAmount;
    const spot = settlementRulesGroupInputProps.spot;
    const spotToken = settlementRulesGroupInputProps.spotToken;
    const underlyingToken = settlementRulesGroupInputProps.underlyingToken;
    const product = settlementRulesGroupInputProps.product;
    const patternInput = useFeliz_React__React_useState_Static_1505(spot);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("100");
    const amount = patternInput_1[0];
    const amountSimulation = useReact_useMemo_CF4EA67(() => parseDecimal(amount), [amount]);
    const currentPrice = spot;
    let fixedStrike;
    const matchValue = product.FixedStrike;
    fixedStrike = ((matchValue != null) ? op_Division(new Decimal(toNumber(matchValue)), fromParts(1000000, 0, 0, false, 0)) : op_Multiply(op_Division(new Decimal(toNumber(defaultArg(product.RelativeStrike, fromBits(0, 0, true)))), fromParts(100000000, 0, 0, false, 0)), currentPrice));
    const initialStrikeSlider = fromParts(100, 0, 0, false, 0);
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(initialStrikeSlider);
    const currentStrikeSlider = patternInput_2[0];
    const settlementPrice = useReact_useMemo_CF4EA67(() => op_Multiply(currentPrice, op_Division(currentStrikeSlider, fromParts(1000, 0, 0, false, 1))), [currentStrikeSlider, product]);
    const amountSimulationChecked = useReact_useMemo_CF4EA67(() => ((compare(amountSimulation, fromParts(0, 0, 0, false, 1)) <= 0) ? fromParts(100, 0, 0, false, 0) : amountSimulation), [amountSimulation, product]);
    const chooseScenario = useReact_useMemo_CF4EA67(() => ((compare(settlementPrice, fixedStrike) >= 0) ? (new Scenario(0)) : (new Scenario(1))), [currentStrikeSlider]);
    let data;
    const spotLevel_2 = patternInput[0];
    data = ofArray([new Point_1("Start Product", spotLevel_2, spotLevel_2), new Point_1("", op_Multiply(spotLevel_2, fromParts(111, 0, 0, false, 2)), op_Multiply(spotLevel_2, fromParts(115, 0, 0, false, 2))), new Point_1("", op_Multiply(fixedStrike, fromParts(97, 0, 0, false, 2)), op_Multiply(spotLevel_2, fromParts(93, 0, 0, false, 2))), new Point_1("Product End", op_Multiply(fixedStrike, fromParts(107, 0, 0, false, 2)), op_Multiply(fixedStrike, fromParts(96, 0, 0, false, 2)))]);
    const amountToBeReceived = () => {
        const spot_1 = fromParts(100, 0, 0, false, 0);
        const premium = op_Division(op_Multiply(amountSimulationChecked, new Decimal(toNumber(product.MaxYield))), fromParts(1000000, 0, 0, false, 0));
        const strike = new Decimal(defaultArg(map((x_3) => (x_3 / 100000000), map((value_2) => toNumber(value_2), product.RelativeStrike)), 1));
        const fakeAbsStrike = op_Multiply(spot_1, strike);
        const currentExpirySlider = op_Division(currentStrikeSlider, fromParts(100, 0, 0, false, 0));
        const fakeAbsSpot = op_Multiply(spot_1, currentExpirySlider);
        if (product.Kind.tag === 1) {
            return [premium, (compare(op_Subtraction(currentExpirySlider, strike), fromParts(0, 0, 0, false, 0)) > 0) ? op_Addition(premium, op_Division(op_Multiply(amountSimulationChecked, fakeAbsStrike), fakeAbsSpot)) : op_Addition(premium, amountSimulationChecked)];
        }
        else {
            return [premium, op_Addition(premium, op_Multiply(amountSimulationChecked, op_Subtraction(fromParts(10, 0, 0, false, 1), max((x_4, y) => compare(x_4, y), fromParts(0, 0, 0, false, 1), op_Subtraction(fromParts(1, 0, 0, false, 0), op_Division(currentExpirySlider, strike))))))];
        }
    };
    const amountRetrieved = useReact_useMemo_CF4EA67(() => amountToBeReceived()[1], [currentStrikeSlider, amount, product]);
    const premiumRetrieved = useReact_useMemo_CF4EA67(() => amountToBeReceived()[0], [currentStrikeSlider, amount, product]);
    const chart = Interop_reactApi.createElement(AreaChart, {
        width: 512,
        height: 120,
        data: toArray((x_2 = premiumRetrieved, (deposit = amountSimulationChecked, (product.Kind.tag === 1) ? ofArray([new Point("Below Strike", toNumber_1(deposit) + toNumber_1(x_2)), new Point("Below Strike", toNumber_1(deposit) + toNumber_1(x_2)), new Point("Below Strike", toNumber_1(deposit) + toNumber_1(x_2)), new Point("Below Strike", toNumber_1(deposit) + toNumber_1(x_2)), new Point("At Strike", toNumber_1(deposit) + toNumber_1(x_2)), new Point("Above Strike", (toNumber_1(deposit) * 0.8) + toNumber_1(x_2)), new Point("Above Strike", (toNumber_1(deposit) * 0.6) + toNumber_1(x_2)), new Point("Above Strike", (toNumber_1(deposit) * 0.4) + toNumber_1(x_2))]) : ofArray([new Point("Below Strike", (toNumber_1(deposit) * 0.4) + toNumber_1(x_2)), new Point("Below Strike", (toNumber_1(deposit) * 0.6) + toNumber_1(x_2)), new Point("Below Strike", (toNumber_1(deposit) * 0.8) + toNumber_1(x_2)), new Point("At Strike", toNumber_1(deposit) + toNumber_1(x_2)), new Point("Above Strike", toNumber_1(deposit) + toNumber_1(x_2)), new Point("Above Strike", toNumber_1(deposit) + toNumber_1(x_2)), new Point("Above Strike", toNumber_1(deposit) + toNumber_1(x_2)), new Point("Above Strike", toNumber_1(deposit) + toNumber_1(x_2))])))),
        margin: {
            top: defaultArg(10, 0),
            right: defaultArg(30, 0),
            left: defaultArg(void 0, 0),
            bottom: defaultArg(void 0, 0),
        },
        children: Interop_reactApi.Children.toArray([(children = singleton((color = "#8884d8", createElement("linearGradient", {
            id: "colorUv",
            x1: 0,
            x2: 1,
            y1: 0,
            y2: 1,
            children: Interop_reactApi.Children.toArray([createElement("stop", {
                offset: 0 + "%",
                stopColor: color,
                stopOpacity: 0.8,
            }), createElement("stop", {
                offset: 100 + "%",
                stopColor: color,
                stopOpacity: 0,
            })]),
        }))), createElement("defs", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        })), Interop_reactApi.createElement(XAxis, {
            dataKey: (point) => point.name,
            hide: true,
        }), Interop_reactApi.createElement(YAxis, {}), Interop_reactApi.createElement(Tooltip, {}), Interop_reactApi.createElement(CartesianGrid, {
            strokeDasharray: join(" ", map_1((value_40) => int32ToString(value_40), new Int32Array([3, 3]))),
        }), Interop_reactApi.createElement(Area, {
            type: "monotone",
            dataKey: (point_1) => point_1.uv,
            stroke: "#8884d8",
            fillOpacity: 1,
            fill: "url(#colorUv)",
        })]),
    });
    return createElement("div", {
        className: join(" ", [utils$002Emodule["margin-m-top"]]),
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: join(" ", [utils$002Emodule["margin-s-top"], single$002Dproducts$002Emodule["modal-chart"]]),
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: join(" ", [utils$002Emodule["margin-xs-left"]]),
                children: Interop_reactApi.Children.toArray([createElement(Row, {
                    classes: ofArray([utils$002Emodule["gap-s"], utils$002Emodule["text-xs"]]),
                    children: singleton("Payoff at expiry"),
                }), Interop_reactApi.createElement(ResponsiveContainer, createObj(ofArray([["height", 100 + "%"], (value_57 = (100 + "%"), (equals(value_57, 100 + "%") ? true : equals(value_57, 100 + "%")) ? ["width", 99 + "%"] : ["width", value_57]), ["minWidth", 512], ["minHeight", 110], ["children", chart]]))), createElement(Row, {
                    classes: ofArray([utils$002Emodule["gap-s"], utils$002Emodule["text-xs"], utils$002Emodule["margin-xl-left"]]),
                    children: singleton((arg10 = spotToken.symbol, toText(printf("%s Price at Expiry"))(arg10))),
                })]),
            })]),
        }), createElement(Row, {
            classes: ofArray([utils$002Emodule["text-m"], utils$002Emodule["boldness-600"], utils$002Emodule["margin-s-bottom"], utils$002Emodule["margin-m-top"], single$002Dproducts$002Emodule["modal-simulate-spotlevel"]]),
            children: singleton((arg10_1 = spotToken.symbol, toText(printf("Simulate %s Price at Expiry"))(arg10_1))),
        }), createElement(SimulateSpotLevel, {
            classes: [utils$002Emodule["margin-l-bottom"], utils$002Emodule["margin-s-top"]],
            spot: spot,
            setSpotLevel: patternInput[1],
            initial: initialStrikeSlider,
            expirtSpot: currentStrikeSlider,
            setExpirySpot: patternInput_2[1],
        }), createElement("div", {
            className: join(" ", [single$002Dproducts$002Emodule["modal-simulate"]]),
            children: Interop_reactApi.Children.toArray([createElement(Col, {
                classes: singleton(utils$002Emodule["align-center"]),
                children: singleton("Amount deposited:"),
            }), createElement("div", {
                children: Interop_reactApi.Children.toArray([createElement(CurrencyInput, {
                    mint: underlyingToken,
                    value: amount,
                    dispatch: patternInput_1[1],
                    error: void 0,
                })]),
            }), createElement("div", {}), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(createElement("div", {
                    children: Interop_reactApi.Children.toArray(["At expiry, you will get:"]),
                })), delay(() => {
                    let arg10_2, arg20, arg10_3;
                    const strike_1 = defaultArg(product.RelativeStrike, fromInteger(1, true, 2));
                    return singleton_1(createElement(Row, {
                        classes: empty(),
                        children: ofArray([createElement(Col, {
                            classes: ofArray([utils$002Emodule["boldness-500"], utils$002Emodule["margin-xs-top"]]),
                            children: singleton((arg10_2 = accounting.formatMoney(toNumber_1(amountRetrieved), ""), toText(printf("%s"))(arg10_2))),
                        }), createElement(Col, {
                            classes: ofArray([utils$002Emodule["margin-xs-left"], utils$002Emodule["margin-xs-top"]]),
                            children: singleton(underlyingToken.symbol),
                        }), createElement(Col, {
                            classes: ofArray([utils$002Emodule["margin-xs-left"], utils$002Emodule["margin-xs-top"]]),
                            children: singleton((arg20 = underlyingToken.symbol, (arg10_3 = accounting.formatMoney(toNumber_1(premiumRetrieved), ""), toText(printf(" (%s %s as yield)"))(arg10_3)(arg20)))),
                        })]),
                    }));
                })))))),
            })]),
        }), createElement(Row, {
            classes: ofArray([utils$002Emodule["text-m"], utils$002Emodule["boldness-600"], utils$002Emodule["margin-s-bottom"], utils$002Emodule["margin-l-top"], single$002Dproducts$002Emodule["modal-simulate-spotlevel"]]),
            children: singleton("Product Lifecycle (Local Time)"),
        }), createElement(Row, {
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["boldness-500"], utils$002Emodule["margin-s-top"]]),
            children: singleton(createElement(ProductLifeCycle, {
                subscriptionStart: product.StartEpoch,
                subscriptionEnd: product.EndDeposits,
                productStart: new ProductStart(0, product.EndDeposits),
                productEnd: product.StartSettlement,
            })),
        })]),
    });
}

