import { Record } from "./.fable/fable-library.3.1.16/Types.js";
import { record_type, class_type, string_type } from "./.fable/fable-library.3.1.16/Reflection.js";
import { Interop_reactApi } from "./.fable/Feliz.1.62.0/Interop.fs.js";
import { ResponsiveContainer, ReferenceLine, Line, CartesianGrid, Tooltip, YAxis, XAxis, ComposedChart } from "recharts";
import { ofArray, toArray } from "./.fable/fable-library.3.1.16/List.js";
import { defaultArg } from "./.fable/fable-library.3.1.16/Option.js";
import { join } from "./.fable/fable-library.3.1.16/String.js";
import { map } from "./.fable/fable-library.3.1.16/Array.js";
import { equals, createObj, round, int32ToString } from "./.fable/fable-library.3.1.16/Util.js";
import { toNumber } from "./.fable/fable-library.3.1.16/Decimal.js";
import { createElement } from "react";
import utils$002Emodule from "./styles/utils.module.scss";

export class Point extends Record {
    constructor(day, a, b) {
        super();
        this.day = day;
        this.a = a;
        this.b = b;
    }
}

export function Point$reflection() {
    return record_type("FixedStrikedChart.Point", [], Point, () => [["day", string_type], ["a", class_type("System.Decimal")], ["b", class_type("System.Decimal")]]);
}

export function FixedStrikedChart(fixedStrikedChartInputProps) {
    let value_37;
    const data = fixedStrikedChartInputProps.data;
    const fixedStrike = fixedStrikedChartInputProps.fixedStrike;
    const spotLevel = fixedStrikedChartInputProps.spotLevel;
    const chart = Interop_reactApi.createElement(ComposedChart, {
        width: 600,
        height: 200,
        data: toArray(data),
        margin: {
            top: defaultArg(5, 0),
            right: defaultArg(30, 0),
            left: defaultArg(20, 0),
            bottom: defaultArg(void 0, 0),
        },
        children: Interop_reactApi.Children.toArray([Interop_reactApi.createElement(XAxis, {
            dataKey: (point) => point.day,
        }), Interop_reactApi.createElement(YAxis, {
            domain: ["auto", "auto"],
        }), Interop_reactApi.createElement(Tooltip, {}), Interop_reactApi.createElement(CartesianGrid, {
            strokeDasharray: join(" ", map((value_8) => int32ToString(value_8), new Int32Array([7, 1]))),
        }), Interop_reactApi.createElement(Line, {
            dataKey: (point_1) => round(toNumber(point_1.a), 2),
            type: "monotone",
            strokeWidth: 3,
            stroke: "#8884d8",
            activeDot: true,
        }), Interop_reactApi.createElement(ReferenceLine, {
            label: "Spot Level",
            strokeDasharray: join(" ", map((value_21) => int32ToString(value_21), new Int32Array([3, 3]))),
            y: toNumber(spotLevel),
            stroke: "green",
        }), Interop_reactApi.createElement(ReferenceLine, {
            label: "Strike",
            y: toNumber(fixedStrike),
            stroke: "red",
        })]),
    });
    return createElement("div", {
        className: join(" ", [utils$002Emodule["margin-xl-top"]]),
        children: Interop_reactApi.Children.toArray([Interop_reactApi.createElement(ResponsiveContainer, createObj(ofArray([["height", 100 + "%"], (value_37 = (100 + "%"), (equals(value_37, 100 + "%") ? true : equals(value_37, 100 + "%")) ? ["width", 99 + "%"] : ["width", value_37]), ["minWidth", 600], ["minHeight", 170], ["children", chart]])))]),
    });
}

