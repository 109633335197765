import single$002Dproducts$002Emodule from "./styles/single-products.module.scss";
import { createElement } from "react";
import { printf, interpolate, toText, join } from "./.fable/fable-library.3.1.16/String.js";
import utils$002Emodule from "./styles/utils.module.scss";
import { Interop_reactApi } from "./.fable/Feliz.1.62.0/Interop.fs.js";
import { empty as empty_1, singleton, append, delay, toList } from "./.fable/fable-library.3.1.16/Seq.js";
import { CheckOnInjectiveExplorer, Column, TextColumn, ButtonProgressLoader, CheckOnSolanaExplorer, Header } from "./UtilComponents.fs.js";
import { singleton as singleton_2, empty, ofArray } from "./.fable/fable-library.3.1.16/List.js";
import { value as value_28, some, defaultArg } from "./.fable/fable-library.3.1.16/Option.js";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { useSnackbar } from "notistack";
import { useReact_useCallback_93353E, useFeliz_React__React_useState_Static_1505 } from "./.fable/Feliz.1.62.0/React.fs.js";
import { Recoil_useRecoilValue_5D3EEEEA } from "./.fable/Feliz.Recoil.0.19.2/Bindings.fs.js";
import { injectiveEnvironment, solanaEnvironment, config as config_2 } from "./Context.fs.js";
import { startImmediate } from "./.fable/fable-library.3.1.16/Async.js";
import { singleton as singleton_1 } from "./.fable/fable-library.3.1.16/AsyncBuilder.js";
import { ProviderContext__enqueueSnackbar_33B60434, EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../../../lib/Notistack/Notistack.fs.js";
import { withdraw as withdraw_1 } from "./BlockChainInteraction/WithdrawFlexProductV2.fs.js";
import { daysFromSeconds, FPDECIMAL_ONE, PublicKey } from "../../../lib/Domain/Types/Common.fs.js";
import { Api } from "./Server.fs.js";
import { LocalPosition } from "../../../lib/Domain/Types/LocalPosition.fs.js";
import { InjectiveOptionClientStatRaw, InjectiveOptionClientAction, ClientAction } from "../../../lib/Domain/Types/ClientStats.fs.js";
import { DashboardWithdrawButton } from "./Components/Button.fs.js";
import { floorDecimal } from "./BlockChainInteraction/InjectiveHoldingDeposit.fs.js";
import { pow, op_Multiply, op_Division, toNumber, toString as toString_1, fromParts, equals } from "./.fable/fable-library.3.1.16/Decimal.js";
import Decimal from "./.fable/fable-library.3.1.16/Decimal.js";
import { mintColor } from "./Currency.fs.js";
import { toString } from "./.fable/fable-library.3.1.16/Types.js";
import { TimeCountdown } from "./DisplayTime.fs.js";
import { totalSeconds } from "./.fable/fable-library.3.1.16/TimeSpan.js";
import { checkIsOkxAndGetWalletName, chainGrpcBankApi, getNetworkByInjectiveEnvironment, useWalletStore } from "../../../lib/Injective/Injective.fs.js";
import { getNetworkEndpoints } from "@injectivelabs/networks";
import { withdraw as withdraw_2 } from "./BlockChainInteraction/InjectiveHoldingWithdraw.fs.js";
import { InjectiveHoldingProductOptionRfq__GetYield_32C73145 } from "../../shared/SignalRRemoting.fs.js";
import { InjectiveProduct__GetKind, InjectiveProduct__GetFormattedStrike } from "../../../lib/Domain/Types/InjectiveProduct.fs.js";
import { toDecimal } from "./.fable/fable-library.3.1.16/BigInt.js";
import { toNumber as toNumber_1 } from "./.fable/fable-library.3.1.16/Long.js";
import { exerciseOption } from "./BlockChainInteraction/InjectiveExerciseOption.fs.js";
import { createObj, min, comparePrimitives, max } from "./.fable/fable-library.3.1.16/Util.js";
import { BullishLogoSmall, BearishLogoSmall } from "./Components/Icon/OptionLogo.fs.js";

export function Headers(headersInputProps) {
    const currentPosition = headersInputProps.currentPosition;
    const showPnl = headersInputProps.showPnl;
    const buttonExpiryHeader = headersInputProps.buttonExpiryHeader;
    const buttonHeader = headersInputProps.buttonHeader;
    const rowClass = currentPosition ? single$002Dproducts$002Emodule["position-row-current"] : single$002Dproducts$002Emodule["position-row-expired"];
    return createElement("div", {
        className: join(" ", [rowClass, utils$002Emodule["margin-l-top"], utils$002Emodule["padding-m-left"]]),
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(createElement(Header, {
            text: "Product",
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-1"]]),
        })), delay(() => append(singleton(createElement(Header, {
            text: "Position Size",
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-2"]]),
        })), delay(() => append(singleton(createElement(Header, {
            text: "Yield",
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-3"]]),
        })), delay(() => append(singleton(createElement(Header, {
            text: "Time before Expiration",
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-4"], utils$002Emodule["no-wrap"]]),
        })), delay(() => append(currentPosition ? append(singleton(createElement(Header, {
            text: "Strike",
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-5"]]),
        })), delay(() => singleton(createElement(Header, {
            text: "Maturity",
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-6"]]),
        })))) : singleton(createElement(Header, {
            text: "PNL",
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-5"]]),
        })), delay(() => singleton(createElement(Header, {
            text: defaultArg(buttonHeader, ""),
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-7"]]),
        })))))))))))))))),
    });
}

export function OptionHeaders(optionHeadersInputProps) {
    const currentPosition = optionHeadersInputProps.currentPosition;
    const expiredPositionHeader = optionHeadersInputProps.expiredPositionHeader;
    const buttonHeader = optionHeadersInputProps.buttonHeader;
    const rowClass = currentPosition ? single$002Dproducts$002Emodule["position-row-current"] : single$002Dproducts$002Emodule["position-row-expired"];
    return createElement("div", {
        className: join(" ", [rowClass, utils$002Emodule["margin-l-top"], utils$002Emodule["padding-m-left"]]),
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(createElement(Header, {
            text: "Option Type",
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-1"]]),
        })), delay(() => append(singleton(createElement(Header, {
            text: "Paid",
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-2"]]),
        })), delay(() => append(singleton(createElement(Header, {
            text: "Option Size",
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-3"]]),
        })), delay(() => append(singleton(createElement(Header, {
            text: "Time before Expiration",
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-4"], utils$002Emodule["no-wrap"]]),
        })), delay(() => append(currentPosition ? append(singleton(createElement(Header, {
            text: "Spot",
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-5"]]),
        })), delay(() => singleton(createElement(Header, {
            text: "Strike",
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-6"]]),
        })))) : singleton(createElement(Header, {
            text: "Payoff",
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-5"]]),
        })), delay(() => append(singleton(createElement(Header, {
            text: defaultArg(buttonHeader, ""),
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-7"]]),
        })), delay(() => {
            if (expiredPositionHeader == null) {
                return singleton(null);
            }
            else {
                const h = expiredPositionHeader;
                return singleton(createElement(Header, {
                    text: h,
                    classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-8"]]),
                }));
            }
        })))))))))))))))),
    });
}

export function Position(positionInputProps) {
    const currentPosition = positionInputProps.currentPosition;
    const refreshCallback = positionInputProps.refreshCallback;
    const showPnl = positionInputProps.showPnl;
    const buttonExpire = positionInputProps.buttonExpire;
    const button = positionInputProps.button;
    const token = positionInputProps.token;
    const duration = positionInputProps.duration;
    const pnl = positionInputProps.pnl;
    const size = positionInputProps.size;
    const productName = positionInputProps.productName;
    const product = positionInputProps.product;
    const walletCtx = useWallet();
    const connection = useConnection().connection;
    const snackbar = useSnackbar();
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setProcessing = patternInput[1];
    const config = Recoil_useRecoilValue_5D3EEEEA(config_2);
    const environment = Recoil_useRecoilValue_5D3EEEEA(solanaEnvironment);
    const withdrawCallback = useReact_useCallback_93353E(() => {
        startImmediate(singleton_1.Delay(() => {
            let matchValue, config_1, publicKey;
            return singleton_1.Combine((matchValue = [walletCtx.publicKey, config], (matchValue[0] == null) ? (ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "No Wallet Public Key Found.", SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(0, false)), singleton_1.Zero()) : ((matchValue[1] == null) ? (console.error(some("No Config Found")), singleton_1.Zero()) : (config_1 = matchValue[1], (publicKey = matchValue[0], (setProcessing(true), singleton_1.Bind(withdraw_1(connection, new PublicKey(0, config_1.VaultProgramId), publicKey, walletCtx, product), (_arg1) => {
                const result = _arg1;
                if (result.tag === 1) {
                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, result.fields[0], SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(0, false));
                    return singleton_1.Zero();
                }
                else {
                    const signature = result.fields[0];
                    return singleton_1.Bind(Api.RemoveLocalPosition(environment, new LocalPosition(new PublicKey(0, publicKey.toBase58()), product.PublicKey)), () => singleton_1.Bind(Api.AddClientStats(new ClientAction(1), new PublicKey(0, publicKey.toBase58()), signature), () => {
                        console.log(some(signature));
                        return singleton_1.Combine((refreshCallback == null) ? (void 0, singleton_1.Zero()) : (refreshCallback(), singleton_1.Zero()), singleton_1.Delay(() => {
                            let children;
                            ProviderContext__enqueueSnackbar_33B60434(snackbar, (children = ofArray([createElement("p", {
                                children: ["Withdraw has been processed. "],
                            }), createElement(CheckOnSolanaExplorer, {
                                signature: signature,
                            })]), createElement("div", {
                                children: Interop_reactApi.Children.toArray(Array.from(children)),
                            })), SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(0, false));
                            return singleton_1.Zero();
                        }));
                    }));
                }
            })))))), singleton_1.Delay(() => {
                setProcessing(false);
                return singleton_1.Zero();
            }));
        }));
    }, [environment, walletCtx.publicKey, product]);
    const withdrawButton = createElement(DashboardWithdrawButton, {
        text: "Withdraw",
        onClick: (_arg1_1) => {
            withdrawCallback();
        },
        processing: patternInput[0],
        processingElement: createElement(ButtonProgressLoader, {
            classes: empty(),
        }),
    });
    let size_1;
    let sizeText;
    const rounded = floorDecimal(2, size);
    sizeText = (equals(rounded, fromParts(0, 0, 0, false, 0)) ? "\u003c 0.01" : toText(interpolate("%P()", [rounded])));
    size_1 = createElement("div", {
        className: join(" ", ["text-color-normal"]),
        children: Interop_reactApi.Children.toArray([toText(interpolate("%P() ", [sizeText])), createElement("span", {
            className: join(" ", ["text-color-normal", mintColor(token.symbol), utils$002Emodule["boldness-500"], utils$002Emodule["margin-xs-left"]]),
            children: token.symbol,
        })]),
    });
    const pnlEl = createElement("div", {
        className: join(" ", [utils$002Emodule.center, "text-color-normal"]),
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((pnl == null) ? singleton("N/A") : singleton(toText(interpolate("%P()%%", [pnl])))))))),
    });
    let estimatedYield;
    const matchValue_1 = product.Yield;
    estimatedYield = ((matchValue_1.tag === 1) ? toText(printf("%.1f%%"))(matchValue_1.fields[0]) : toText(printf("%.1f%% - %.1f%%"))(matchValue_1.fields[0])(matchValue_1.fields[1]));
    let strike;
    const matchValue_2 = product.Strike;
    switch (matchValue_2.tag) {
        case 1: {
            strike = toString(product.Strike);
            break;
        }
        case 2: {
            strike = "";
            break;
        }
        default: {
            strike = toText(printf("%.2f %s"))(matchValue_2.fields[0])("USDC");
        }
    }
    let strikeDescription;
    const matchValue_3 = product.Strike;
    switch (matchValue_3.tag) {
        case 1: {
            strikeDescription = "(Relative)";
            break;
        }
        case 2: {
            strikeDescription = "";
            break;
        }
        default: {
            strikeDescription = "(Fixed)";
        }
    }
    const rowClass = currentPosition ? single$002Dproducts$002Emodule["position-row-current"] : single$002Dproducts$002Emodule["position-row-expired"];
    return createElement("div", {
        className: join(" ", [rowClass, utils$002Emodule["margin-s-top"], "card"]),
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(createElement(TextColumn, {
            text: productName,
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-1"], "text-color-normal"]),
        })), delay(() => append(singleton(createElement(Column, {
            children: singleton_2(size_1),
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-2"], utils$002Emodule["boldness-500"]]),
        })), delay(() => append(singleton(createElement(TextColumn, {
            text: estimatedYield,
            classes: ofArray([utils$002Emodule.center, single$002Dproducts$002Emodule["estimated-yield-column"], utils$002Emodule["grid-column-3"], utils$002Emodule["no-wrap"]]),
        })), delay(() => append(singleton(createElement(Column, {
            children: singleton_2(createElement(TimeCountdown, {
                seconds: ~(~totalSeconds(duration)),
            })),
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["no-wrap"], utils$002Emodule["grid-column-4"], "text-color-normal"]),
        })), delay(() => append(currentPosition ? append(singleton(createElement(TextColumn, {
            text: (strike + " ") + strikeDescription,
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["no-wrap"], utils$002Emodule["grid-column-5"], "text-color-normal"]),
        })), delay(() => singleton(createElement(TextColumn, {
            text: toText(interpolate("%P() days", [product.Maturity])),
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["no-wrap"], utils$002Emodule["grid-column-6"], "text-color-normal"]),
        })))) : singleton(createElement(Column, {
            children: singleton_2(pnlEl),
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["no-wrap"], utils$002Emodule["grid-column-5"], "text-color-normal"]),
        })), delay(() => {
            let button_1;
            return append((button == null) ? singleton(null) : (button_1 = button, singleton(createElement(Column, {
                children: singleton_2(button_1),
                classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-7"], "text-color-normal"]),
            }))), delay(() => (buttonExpire ? singleton(createElement(Column, {
                children: singleton_2(withdrawButton),
                classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-8"], "text-color-normal"]),
            })) : empty_1())));
        })))))))))))))),
    });
}

export function InjectivePosition(injectivePositionInputProps) {
    const spot = injectivePositionInputProps.spot;
    const currentPosition = injectivePositionInputProps.currentPosition;
    const refreshCallback = injectivePositionInputProps.refreshCallback;
    const showPnl = injectivePositionInputProps.showPnl;
    const buttonExpire = injectivePositionInputProps.buttonExpire;
    const button = injectivePositionInputProps.button;
    const token = injectivePositionInputProps.token;
    const duration = injectivePositionInputProps.duration;
    const pnl = injectivePositionInputProps.pnl;
    const size = injectivePositionInputProps.size;
    const productName = injectivePositionInputProps.productName;
    const product = injectivePositionInputProps.product;
    const wallet = useWalletStore();
    const snackbar = useSnackbar();
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setProcessing = patternInput[1];
    const config = Recoil_useRecoilValue_5D3EEEEA(config_2);
    const environment = Recoil_useRecoilValue_5D3EEEEA(injectiveEnvironment);
    const network = getNetworkByInjectiveEnvironment(environment);
    const grpcBankApi = chainGrpcBankApi(getNetworkEndpoints(network).grpc, environment);
    const withdrawCallback = useReact_useCallback_93353E(() => {
        startImmediate(singleton_1.Delay(() => {
            let matchValue, config_1, walletStrategy;
            return singleton_1.Combine((matchValue = [wallet.walletStrategy, config], (matchValue[0] == null) ? (ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "No Wallet Found.", SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(0, false)), singleton_1.Zero()) : ((matchValue[1] == null) ? (console.error(some("No Config Found")), singleton_1.Zero()) : (config_1 = matchValue[1], (walletStrategy = matchValue[0], (setProcessing(true), singleton_1.Bind(withdraw_2(network, config_1.InjectiveHoldingContract, walletStrategy, grpcBankApi, wallet.injectiveAddress, product.Holding), (_arg1) => {
                const result = _arg1;
                if (result.tag === 1) {
                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, result.fields[0], SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(0, false));
                    return singleton_1.Zero();
                }
                else {
                    const res = result.fields[0];
                    return singleton_1.Bind(checkIsOkxAndGetWalletName(wallet.injectiveAddress, walletStrategy.getWallet()), (_arg2) => singleton_1.Bind(Api.AddInjectiveClientStats(new ClientAction(1), product.Holding.Id, wallet.injectiveAddress, token.symbol, toString_1(size), _arg2, environment, res.txHash), () => {
                        console.log(some(res.txHash));
                        return singleton_1.Combine((refreshCallback == null) ? (void 0, singleton_1.Zero()) : (refreshCallback(), singleton_1.Zero()), singleton_1.Delay(() => {
                            let children;
                            ProviderContext__enqueueSnackbar_33B60434(snackbar, (children = ofArray([createElement("p", {
                                children: ["Withdraw has been processed. "],
                            }), createElement(CheckOnInjectiveExplorer, {
                                txHash: res.txHash,
                            })]), createElement("div", {
                                children: Interop_reactApi.Children.toArray(Array.from(children)),
                            })), SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(0, false));
                            return singleton_1.Zero();
                        }));
                    }));
                }
            })))))), singleton_1.Delay(() => {
                setProcessing(false);
                return singleton_1.Zero();
            }));
        }));
    }, [environment, wallet, product]);
    const withdrawButton = createElement(DashboardWithdrawButton, {
        text: "Withdraw",
        onClick: (_arg1_1) => {
            withdrawCallback();
        },
        processing: patternInput[0],
        processingElement: createElement(ButtonProgressLoader, {
            classes: empty(),
        }),
    });
    let size_1;
    let sizeText;
    const rounded = floorDecimal(2, size);
    sizeText = (equals(rounded, fromParts(0, 0, 0, false, 0)) ? "\u003c 0.01" : toText(interpolate("%P()", [rounded])));
    size_1 = createElement("div", {
        className: join(" ", ["text-color-normal"]),
        children: Interop_reactApi.Children.toArray([toText(interpolate("%P()", [sizeText])), createElement("span", {
            className: join(" ", ["text-color-normal", mintColor(token.symbol), utils$002Emodule["boldness-500"], utils$002Emodule["margin-xs-left"]]),
            children: token.symbol,
        })]),
    });
    const pnlEl = createElement("div", {
        className: join(" ", [utils$002Emodule.center, "text-color-normal"]),
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((pnl == null) ? singleton("N/A") : singleton(toText(interpolate("%P()%%", [pnl])))))))),
    });
    let estimatedYield;
    const matchValue_1 = InjectiveHoldingProductOptionRfq__GetYield_32C73145(product, spot);
    estimatedYield = ((matchValue_1.tag === 1) ? toText(printf("%.1f%%"))(matchValue_1.fields[0]) : toText(printf("%.1f%% - %.1f%%"))(matchValue_1.fields[0])(matchValue_1.fields[1]));
    let strike;
    const matchValue_2 = product.Option;
    if (matchValue_2 == null) {
        strike = InjectiveProduct__GetFormattedStrike(product.Product);
    }
    else {
        let copyOfStruct = toNumber(op_Division(matchValue_2.Strike, toDecimal(FPDECIMAL_ONE)));
        strike = copyOfStruct.toString();
    }
    const strikeDescription = (product.Option == null) ? "(Relative)" : "(Fixed)";
    const rowClass = currentPosition ? single$002Dproducts$002Emodule["position-row-current"] : single$002Dproducts$002Emodule["position-row-expired"];
    const maturity = new Decimal(daysFromSeconds(toNumber_1(product.Product.Duration)));
    return createElement("div", {
        className: join(" ", [rowClass, utils$002Emodule["margin-s-top"], "card"]),
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(createElement(TextColumn, {
            text: productName,
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-1"], "text-color-normal"]),
        })), delay(() => append(singleton(createElement(Column, {
            children: singleton_2(size_1),
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-2"], utils$002Emodule["boldness-500"]]),
        })), delay(() => append(singleton(createElement(TextColumn, {
            text: estimatedYield,
            classes: ofArray([utils$002Emodule.center, single$002Dproducts$002Emodule["estimated-yield-column"], utils$002Emodule["grid-column-3"], utils$002Emodule["no-wrap"]]),
        })), delay(() => append(singleton(createElement(Column, {
            children: singleton_2(createElement(TimeCountdown, {
                seconds: ~(~totalSeconds(duration)),
            })),
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["no-wrap"], utils$002Emodule["grid-column-4"], "text-color-normal"]),
        })), delay(() => append(currentPosition ? append(singleton(createElement(TextColumn, {
            text: (strike + " ") + strikeDescription,
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["no-wrap"], utils$002Emodule["grid-column-5"], "text-color-normal"]),
        })), delay(() => singleton(createElement(TextColumn, {
            text: toText(interpolate("%P() days", [maturity])),
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["no-wrap"], utils$002Emodule["grid-column-6"], "text-color-normal"]),
        })))) : singleton(createElement(Column, {
            children: singleton_2(pnlEl),
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["no-wrap"], utils$002Emodule["grid-column-5"], "text-color-normal"]),
        })), delay(() => {
            let button_1;
            return append((button == null) ? singleton(null) : (button_1 = button, singleton(createElement(Column, {
                children: singleton_2(button_1),
                classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-7"], "text-color-normal"]),
            }))), delay(() => (buttonExpire ? singleton(createElement(Column, {
                children: singleton_2(withdrawButton),
                classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-8"], "text-color-normal"]),
            })) : empty_1())));
        })))))))))))))),
    });
}

export function InjectiveOptionPosition(injectiveOptionPositionInputProps) {
    let matchValue_3, copyOfStruct, value_22, matchValue;
    const spot = injectiveOptionPositionInputProps.spot;
    const currentPosition = injectiveOptionPositionInputProps.currentPosition;
    const refreshCallback = injectiveOptionPositionInputProps.refreshCallback;
    const buttonExpire = injectiveOptionPositionInputProps.buttonExpire;
    const button = injectiveOptionPositionInputProps.button;
    const token = injectiveOptionPositionInputProps.token;
    const duration = injectiveOptionPositionInputProps.duration;
    const payoff = injectiveOptionPositionInputProps.payoff;
    const size = injectiveOptionPositionInputProps.size;
    const paid = injectiveOptionPositionInputProps.paid;
    const productName = injectiveOptionPositionInputProps.productName;
    const escrow = injectiveOptionPositionInputProps.escrow;
    const product = injectiveOptionPositionInputProps.product;
    const wallet = useWalletStore();
    const snackbar = useSnackbar();
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setProcessing = patternInput[1];
    const config = Recoil_useRecoilValue_5D3EEEEA(config_2);
    const environment = Recoil_useRecoilValue_5D3EEEEA(injectiveEnvironment);
    const network = getNetworkByInjectiveEnvironment(environment);
    const grpcBankApi = chainGrpcBankApi(getNetworkEndpoints(network).grpc, environment);
    const withdrawCallback = useReact_useCallback_93353E(() => {
        startImmediate(singleton_1.Delay(() => {
            let matchValue_1, config_1, walletStrategy, matchValue_2;
            return singleton_1.Combine((matchValue_1 = [wallet.walletStrategy, config], (matchValue_1[0] == null) ? (ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "No Wallet Found.", SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(0, false)), singleton_1.Zero()) : ((matchValue_1[1] == null) ? (console.error(some("No Config Found")), singleton_1.Zero()) : (config_1 = matchValue_1[1], (walletStrategy = matchValue_1[0], (setProcessing(true), singleton_1.Bind((matchValue_2 = product.Option, (matchValue_2 == null) ? (() => {
                throw (new Error("Failed to find option contract."));
            })() : exerciseOption(network, matchValue_2.Address, walletStrategy, grpcBankApi, wallet.injectiveAddress)), (_arg1) => {
                const result = _arg1;
                if (result.tag === 1) {
                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, result.fields[0], SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(0, false));
                    return singleton_1.Zero();
                }
                else {
                    const res = result.fields[0];
                    return singleton_1.Bind(checkIsOkxAndGetWalletName(wallet.injectiveAddress, walletStrategy.getWallet()), (_arg2) => singleton_1.Bind(Api.AddInjectiveOptionClientStats(new InjectiveOptionClientStatRaw(wallet.injectiveAddress, new InjectiveOptionClientAction(1), (escrow == null) ? "" : escrow.ContractAddress, product.Holding.Id, token.symbol, toString_1(size), toString_1(size), _arg2, toString(environment), res.txHash, "")), () => {
                        console.log(some(res.txHash));
                        return singleton_1.Combine((refreshCallback == null) ? (void 0, singleton_1.Zero()) : (refreshCallback(), singleton_1.Zero()), singleton_1.Delay(() => {
                            let children;
                            ProviderContext__enqueueSnackbar_33B60434(snackbar, (children = ofArray([createElement("p", {
                                children: ["Transaction has been processed. "],
                            }), createElement(CheckOnInjectiveExplorer, {
                                txHash: res.txHash,
                            })]), createElement("div", {
                                children: Interop_reactApi.Children.toArray(Array.from(children)),
                            })), SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(0, false));
                            return singleton_1.Zero();
                        }));
                    }));
                }
            })))))), singleton_1.Delay(() => {
                setProcessing(false);
                return singleton_1.Zero();
            }));
        }));
    }, [environment, wallet, product, escrow]);
    const exerciseOptionButton = createElement(DashboardWithdrawButton, {
        text: "Confirm",
        onClick: (_arg1_1) => {
            withdrawCallback();
        },
        processing: patternInput[0],
        processingElement: createElement(ButtonProgressLoader, {
            classes: empty(),
        }),
    });
    let size_1;
    let sizeText;
    const rounded = floorDecimal(6, (matchValue_3 = InjectiveProduct__GetKind(product.Product), (matchValue_3.tag === 1) ? op_Division(op_Multiply(op_Division(size, value_28(product.Option).NbOption), toDecimal(product.Holding.TotalDepositedAmount)), pow(fromParts(10, 0, 0, false, 0), token.decimals)) : op_Division(size, pow(fromParts(10, 0, 0, false, 0), token.decimals))));
    sizeText = (equals(rounded, fromParts(0, 0, 0, false, 0)) ? "\u003c 0.000001" : toText(interpolate("%P()", [rounded])));
    size_1 = createElement("div", {
        className: join(" ", ["text-color-normal"]),
        children: Interop_reactApi.Children.toArray([toText(interpolate("%P()", [sizeText])), createElement("span", {
            className: join(" ", ["text-color-normal", mintColor(token.symbol), utils$002Emodule["boldness-500"], utils$002Emodule["margin-xs-left"]]),
            children: token.symbol,
        })]),
    });
    let totalPaid;
    const sizeElem = (!equals(paid, fromParts(0, 0, 0, false, 0))) ? ofArray([toText(interpolate("%P()", [(copyOfStruct = toNumber(floorDecimal(max((x, y) => comparePrimitives(x, y), 6, token.decimals - 6), paid)), copyOfStruct.toString())])), createElement("span", {
        className: join(" ", ["text-color-normal", mintColor(token.symbol), utils$002Emodule["boldness-500"], utils$002Emodule["margin-xs-left"]]),
        children: token.symbol,
    })]) : singleton_2("-");
    totalPaid = createElement("div", {
        className: join(" ", ["text-color-normal"]),
        children: Interop_reactApi.Children.toArray(Array.from(sizeElem)),
    });
    const payoffEl = createElement("div", {
        className: join(" ", [utils$002Emodule.center, "text-color-normal"]),
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let round, copyOfStruct_1, copyOfStruct_2;
            if (payoff == null) {
                return singleton("N/A");
            }
            else {
                const p = payoff;
                return (!equals(p, fromParts(0, 0, 0, false, 0))) ? append(singleton((round = floorDecimal(min((x_1, y_1) => comparePrimitives(x_1, y_1), 6, token.decimals), p), (!equals(round, fromParts(0, 0, 0, false, 0))) ? toText(interpolate("%P()", [(copyOfStruct_1 = toNumber(round), copyOfStruct_1.toString())])) : ("\u003c " + (copyOfStruct_2 = (1 / Math.pow(10, min((x_2, y_2) => comparePrimitives(x_2, y_2), 6, token.decimals))), copyOfStruct_2.toString())))), delay(() => singleton(createElement("span", {
                    className: join(" ", ["text-color-normal", mintColor(token.symbol), utils$002Emodule["boldness-500"], utils$002Emodule["margin-xs-left"]]),
                    children: token.symbol,
                })))) : singleton("0");
            }
        })))),
    });
    let strike;
    const matchValue_4 = product.Option;
    if (matchValue_4 == null) {
        strike = InjectiveProduct__GetFormattedStrike(product.Product);
    }
    else {
        let copyOfStruct_3 = toNumber(op_Division(matchValue_4.Strike, toDecimal(FPDECIMAL_ONE)));
        strike = copyOfStruct_3.toString();
    }
    const rowClass = currentPosition ? single$002Dproducts$002Emodule["position-row-current"] : single$002Dproducts$002Emodule["position-row-expired"];
    const productNameRow = createElement(Column, {
        children: ofArray([createElement("span", createObj(ofArray([(value_22 = utils$002Emodule["margin-s-right"], ["className", value_22]), ["children", productName]]))), (matchValue = InjectiveProduct__GetKind(product.Product), (matchValue.tag === 1) ? BearishLogoSmall() : BullishLogoSmall())]),
        classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-1"], "text-color-normal"]),
    });
    return createElement("div", {
        className: join(" ", [rowClass, utils$002Emodule["margin-s-top"], "card"]),
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(productNameRow), delay(() => append(singleton(createElement(Column, {
            children: singleton_2(totalPaid),
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-2"], utils$002Emodule["boldness-500"]]),
        })), delay(() => append(singleton(createElement(Column, {
            children: singleton_2(size_1),
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-3"], utils$002Emodule["boldness-500"]]),
        })), delay(() => append(singleton(createElement(Column, {
            children: singleton_2(createElement(TimeCountdown, {
                seconds: ~(~totalSeconds(duration)),
            })),
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["no-wrap"], utils$002Emodule["grid-column-4"], "text-color-normal"]),
        })), delay(() => {
            let copyOfStruct_4;
            return append(currentPosition ? append(singleton(createElement(TextColumn, {
                text: (copyOfStruct_4 = toNumber(floorDecimal(2, spot)), copyOfStruct_4.toString()),
                classes: ofArray([utils$002Emodule.center, utils$002Emodule["no-wrap"], utils$002Emodule["grid-column-5"], "text-color-normal"]),
            })), delay(() => singleton(createElement(TextColumn, {
                text: strike,
                classes: ofArray([utils$002Emodule.center, utils$002Emodule["no-wrap"], utils$002Emodule["grid-column-6"], "text-color-normal"]),
            })))) : singleton(createElement(Column, {
                children: singleton_2(payoffEl),
                classes: ofArray([utils$002Emodule.center, utils$002Emodule["no-wrap"], utils$002Emodule["grid-column-5"], "text-color-normal"]),
            })), delay(() => {
                let button_1;
                return append((button == null) ? singleton(null) : (button_1 = button, singleton(createElement(Column, {
                    children: singleton_2(button_1),
                    classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-7"], "text-color-normal"]),
                }))), delay(() => (buttonExpire ? singleton(createElement(Column, {
                    children: singleton_2(exerciseOptionButton),
                    classes: ofArray([utils$002Emodule.center, utils$002Emodule["grid-column-8"], "text-color-normal"]),
                })) : empty_1())));
            }));
        })))))))))))),
    });
}

