import { useFeliz_React__React_useState_Static_1505 } from "./.fable/Feliz.1.62.0/React.fs.js";
import { createElement } from "react";
import { join } from "./.fable/fable-library.3.1.16/String.js";
import setup$002Emodule from "./styles/setup.module.scss";
import { Interop_reactApi } from "./.fable/Feliz.1.62.0/Interop.fs.js";
import { createObj } from "./.fable/fable-library.3.1.16/Util.js";
import { exclamationCircle, disclaimerElipse } from "./Img.fs.js";
import { singleton, ofArray } from "./.fable/fable-library.3.1.16/List.js";
import utils$002Emodule from "./styles/utils.module.scss";
import { Col } from "./UtilComponents.fs.js";
import { MuiHelpers_createElement } from "./.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Browser_Types_Event__Event_get_Checked } from "./.fable/Fable.React.7.4.0/Fable.React.Extensions.fs.js";

export function Disclaimer(disclaimerInputProps) {
    let value_1, value_5;
    const onConfirm = disclaimerInputProps.onConfirm;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const acknowledgement = patternInput[0];
    return createElement("div", {
        className: join(" ", [setup$002Emodule.modal, "card"]),
        children: Interop_reactApi.Children.toArray([createElement("img", createObj(ofArray([(value_1 = setup$002Emodule["disclaimer-icon-background"], ["className", value_1]), ["src", disclaimerElipse]]))), createElement("img", createObj(ofArray([(value_5 = setup$002Emodule["disclaimer-icon"], ["className", value_5]), ["src", exclamationCircle]]))), createElement("div", {
            className: join(" ", [utils$002Emodule.center, utils$002Emodule["text-xl"]]),
            children: Interop_reactApi.Children.toArray([createElement("p", {
                children: ["Disclaimer"],
            })]),
        }), createElement("p", {
            className: join(" ", [utils$002Emodule["margin-l-top"]]),
            children: "The Exotic Markets Platform is a fully decentralised structured product platform for digital assets. No representation or warranty is made concerning any aspect of the Exotic Markets Platform, including its suitability, quality, availability, accessibility, accuracy or safety. As more fully explained in the Rules (available here) and the Risk Statement (available here), your access to and use of the Exotic Markets Platform is entirely at your own risk and could lead to substantial losses. You take full responsibility for your use of the Exotic Markets Platform, and acknowledge that you use it on the basis of your own enquiry, without solicitation or inducement by Contributors (as defined in the Rules).",
        }), createElement("p", {
            className: join(" ", [utils$002Emodule["margin-m-top"]]),
            children: "The Exotic Markets Platform is not available to residents of Belarus, the Central African Republic, the Democratic Republic of Congo, the Democratic People’s Republic of Korea, the Crimea region of Ukraine, Cuba, Iran, Libya, Somalia, Sudan, South Sudan, Syria, the USA, Yemen, and Zimbabwe or any other jurisdiction in which accessing or using the Exotic Markets Platform is prohibited (“Prohibited Jurisdictions”). In using the Exotic Markets Platform, you confirm that you are not located in, incorporated or otherwise established in, or a citizen or resident of, a Prohibited Jurisdiction.",
        }), createElement(Col, {
            classes: singleton(utils$002Emodule["gap-m"]),
            children: ofArray([MuiHelpers_createElement(FormGroup, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(FormControlLabel, [["control", MuiHelpers_createElement(Checkbox, [["checked", acknowledgement], ["onChange", (e) => {
                patternInput[1](Browser_Types_Event__Event_get_Checked(e));
            }]])], ["label", "I confirm that I have read, understand and accept the Rules and the Risk Statement."]])])]]), createElement("button", {
                className: "button-primary",
                disabled: !acknowledgement,
                children: Interop_reactApi.Children.toArray(["Confirm"]),
                onClick: (_arg1) => {
                    onConfirm();
                },
            })]),
        })]),
    });
}

