import { join, interpolate, toText, padLeft } from "./.fable/fable-library.3.1.16/String.js";
import { int32ToString } from "./.fable/fable-library.3.1.16/Util.js";
import { totalSeconds, fromSeconds, seconds as seconds_2, minutes, hours, days } from "./.fable/fable-library.3.1.16/TimeSpan.js";
import { createElement } from "react";
import card$002Emodule from "./styles/card.module.scss";
import utils$002Emodule from "./styles/utils.module.scss";
import { Interop_reactApi } from "./.fable/Feliz.1.62.0/Interop.fs.js";
import landing$002Emodule from "./styles/landing.module.scss";
import { Col } from "./UtilComponents.fs.js";
import { singleton, empty } from "./.fable/fable-library.3.1.16/List.js";
import { useCountdown } from "../../../lib/CustomHooks/CustomHooks.fs.js";

export function formatTime(duration) {
    const pad = (x) => padLeft(int32ToString(x), 2, "0");
    if (duration <= 0) {
        return "Expired";
    }
    else {
        const matchValue = [days(duration), hours(duration), minutes(duration), seconds_2(duration)];
        if (duration <= 0) {
            return toText(interpolate("Expired", []));
        }
        else if (matchValue[0] === 0) {
            if (matchValue[1] === 0) {
                if (matchValue[2] === 0) {
                    return toText(interpolate("%P()s", [pad(matchValue[3])]));
                }
                else {
                    return toText(interpolate("%P()m %P()s", [pad(matchValue[2]), pad(matchValue[3])]));
                }
            }
            else {
                return toText(interpolate("%P()h %P()m %P()s", [pad(matchValue[1]), pad(matchValue[2]), pad(matchValue[3])]));
            }
        }
        else {
            return toText(interpolate("%P()d %P()h %P()m %P()s", [matchValue[0], pad(matchValue[1]), pad(matchValue[2]), pad(matchValue[3])]));
        }
    }
}

export function DisplayTime(displayTimeInputProps) {
    const time = formatTime(fromSeconds(displayTimeInputProps.seconds));
    return createElement("div", {
        className: join(" ", [card$002Emodule["strategy-header"], utils$002Emodule["text-high-risk"], card$002Emodule["subscription-time"]]),
        children: toText(interpolate("%P()", [time])),
    });
}

export function CountDown(countDownInputProps) {
    const classes = countDownInputProps.classes;
    const duration = fromSeconds(countDownInputProps.seconds);
    const pad = (x) => {
        if (x <= 0) {
            return "00";
        }
        else {
            return padLeft(int32ToString(x), 2, "0");
        }
    };
    if (totalSeconds(duration) > 0) {
        return createElement("div", {
            className: join(" ", [utils$002Emodule["margin-l-top"], utils$002Emodule["margin-m-bottom"]]),
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: join(" ", [landing$002Emodule["count-down-title"]]),
                children: "Official Launch in",
            }), createElement("div", {
                className: join(" ", [landing$002Emodule["count-down-row"], utils$002Emodule["margin-s-top"]]),
                children: Interop_reactApi.Children.toArray([createElement(Col, {
                    classes: empty(),
                    children: singleton(createElement("div", {
                        className: join(" ", [landing$002Emodule["count-down-block"]]),
                        children: Interop_reactApi.Children.toArray([createElement("div", {
                            className: join(" ", [landing$002Emodule["count-down-text"]]),
                            children: Interop_reactApi.Children.toArray([pad(days(duration))]),
                        }), createElement("div", {
                            className: join(" ", [landing$002Emodule["count-down-text-small"]]),
                            children: Interop_reactApi.Children.toArray(["Days"]),
                        })]),
                    })),
                }), createElement(Col, {
                    classes: empty(),
                    children: singleton(createElement("div", {
                        className: join(" ", [landing$002Emodule["count-down-block"]]),
                        children: Interop_reactApi.Children.toArray([createElement("div", {
                            className: join(" ", [landing$002Emodule["count-down-text"]]),
                            children: Interop_reactApi.Children.toArray([pad(hours(duration))]),
                        }), createElement("div", {
                            className: join(" ", [landing$002Emodule["count-down-text-small"]]),
                            children: Interop_reactApi.Children.toArray(["Hours"]),
                        })]),
                    })),
                }), createElement(Col, {
                    classes: empty(),
                    children: singleton(createElement("div", {
                        className: join(" ", [landing$002Emodule["count-down-block"]]),
                        children: Interop_reactApi.Children.toArray([createElement("div", {
                            className: join(" ", [landing$002Emodule["count-down-text"]]),
                            children: Interop_reactApi.Children.toArray([pad(minutes(duration))]),
                        }), createElement("div", {
                            className: join(" ", [landing$002Emodule["count-down-text-small"]]),
                            children: Interop_reactApi.Children.toArray(["Minutes"]),
                        })]),
                    })),
                }), createElement(Col, {
                    classes: empty(),
                    children: singleton(createElement("div", {
                        className: join(" ", [landing$002Emodule["count-down-block"]]),
                        children: Interop_reactApi.Children.toArray([createElement("div", {
                            className: join(" ", [landing$002Emodule["count-down-text"]]),
                            children: Interop_reactApi.Children.toArray([pad(seconds_2(duration))]),
                        }), createElement("div", {
                            className: join(" ", [landing$002Emodule["count-down-text-small"]]),
                            children: Interop_reactApi.Children.toArray(["Seconds"]),
                        })]),
                    })),
                })]),
            })]),
        });
    }
    else {
        return createElement("div", {
            className: join(" ", [utils$002Emodule["margin-l-top"], utils$002Emodule["margin-m-bottom"]]),
            children: Interop_reactApi.Children.toArray([createElement("a", {
                className: join(" ", ["button", landing$002Emodule["landing-button"], "open-app", utils$002Emodule["margin-m-bottom"], utils$002Emodule["align-center"]]),
                href: "https://mainnet.exotic.markets",
                target: "_blank",
                children: "Open App",
            })]),
        });
    }
}

export function DateCountdown(dateCountdownInputProps) {
    const seconds_1 = useCountdown(dateCountdownInputProps.seconds) | 0;
    return createElement(CountDown, {
        seconds: seconds_1,
        classes: [],
    });
}

export function Time(timeInputProps) {
    const classes = timeInputProps.classes;
    const time = formatTime(fromSeconds(timeInputProps.seconds));
    return createElement("div", {
        className: join(" ", classes),
        children: toText(interpolate("%P()", [time])),
    });
}

export function TimeCountdown(timeCountdownInputProps) {
    const seconds_1 = useCountdown(timeCountdownInputProps.seconds) | 0;
    return createElement(Time, {
        seconds: seconds_1,
        classes: [],
    });
}

export function TimeCountdownWithStyles(timeCountdownWithStylesInputProps) {
    const className = timeCountdownWithStylesInputProps.className;
    const seconds_1 = useCountdown(timeCountdownWithStylesInputProps.seconds) | 0;
    return createElement(Time, {
        seconds: seconds_1,
        classes: [className],
    });
}

