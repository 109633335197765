import { printf, toFail } from "../../app/client/src/.fable/fable-library.3.1.16/String.js";
import { record_type, string_type, union_type, fullName, makeRecord, class_type, getGenerics, name, isFunction, getFunctionElements } from "../../app/client/src/.fable/fable-library.3.1.16/Reflection.js";
import { length, last as last_1, empty, ofArray, singleton, append } from "../../app/client/src/.fable/fable-library.3.1.16/List.js";
import { createTypeInfo } from "../../app/client/src/.fable/Fable.SimpleJson.3.19.0/TypeInfo.Converter.fs.js";
import { awaitPromise } from "../../app/client/src/.fable/fable-library.3.1.16/Async.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../app/client/src/.fable/Fable.Promise.2.2.2/Promise.fs.js";
import { promise } from "../../app/client/src/.fable/Fable.Promise.2.2.2/PromiseImpl.fs.js";
import { SimpleJson_parseNative } from "../../app/client/src/.fable/Fable.SimpleJson.3.19.0/SimpleJson.fs.js";
import { Convert_serialize, Convert_fromJsonAs } from "../../app/client/src/.fable/Fable.SimpleJson.3.19.0/Json.Converter.fs.js";
import { append as append_1, last } from "../../app/client/src/.fable/fable-library.3.1.16/Array.js";
import { toArray as toArray_1, value as value_4, some } from "../../app/client/src/.fable/fable-library.3.1.16/Option.js";
import { comparePrimitives, clamp, partialApply } from "../../app/client/src/.fable/fable-library.3.1.16/Util.js";
import * as signalr from "@microsoft/signalr";
import { HubConnection$reflection, IRetryPolicy } from "./Bindings.fs.js";
import { iterate, map, delay, toArray } from "../../app/client/src/.fable/fable-library.3.1.16/Seq.js";
import { Record, Union } from "../../app/client/src/.fable/fable-library.3.1.16/Types.js";
import Event$ from "../../app/client/src/.fable/fable-library.3.1.16/Event.js";
import { useReact_useEffect_Z101E1A95, useReact_useState_FCFD9EF, useReact_useEffect_Z5234A374, useReact_useContext_37FA55CF, React_contextProvider_138D2F56, useReact_useEffectOnce_Z5ECA432F, useFeliz_React__React_useState_Static_1505, React_createContext_1AE444D8 } from "../../app/client/src/.fable/Feliz.1.62.0/React.fs.js";
import { choose, subscribe } from "../../app/client/src/.fable/fable-library.3.1.16/Observable.js";

function funcArgs(func) {
    const matchValue = func.FieldType;
    switch (matchValue.tag) {
        case 20: {
            return [func.FieldType];
        }
        case 32: {
            return matchValue.fields[0]();
        }
        default: {
            return toFail(printf("Field %s does not have a valid definiton"))(func.FieldName);
        }
    }
}

function getFuncArgs(func) {
    const flattenFuncArgs = (typ_mut, args_mut) => {
        flattenFuncArgs:
        while (true) {
            const typ = typ_mut, args = args_mut;
            const patternInput = getFunctionElements(typ);
            const res = patternInput[1];
            const input = patternInput[0];
            if (isFunction(res)) {
                typ_mut = res;
                args_mut = append(args, singleton(input));
                continue flattenFuncArgs;
            }
            else {
                return append(args, ofArray([input, res]));
            }
            break;
        }
    };
    const typ_1 = func.PropertyInfo[1];
    if (isFunction(typ_1)) {
        return flattenFuncArgs(typ_1, empty());
    }
    else {
        return toFail(printf("Field %s does not have a valid definiton (it should be a function)"))(func.FieldName);
    }
}

function createProxyAsync(recordField, args, numberOfArgs, connection) {
    const proxy = (returnType, invoke) => {
        let inner;
        switch (returnType.tag) {
            case 20: {
                inner = returnType.fields[0]();
                break;
            }
            case 19: {
                const t = returnType.fields[0]();
                inner = ((name(t) === "IStream`1") ? createTypeInfo(getGenerics(t)[0]) : toFail(printf("Field %s does not have a valid definiton (it should return Async or IAsyncEnumerable)"))(recordField.FieldName));
                break;
            }
            default: {
                inner = toFail(printf("Field %s does not have a valid definiton (it should return Async or IAsyncEnumerable)"))(recordField.FieldName);
            }
        }
        return awaitPromise(PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (invoke.then(((_arg1) => {
            const parsed = SimpleJson_parseNative(JSON.stringify(_arg1));
            return Promise.resolve(Convert_fromJsonAs(parsed, inner));
        }))))));
    };
    const serialize = (value, typ) => JSON.parse(Convert_serialize(value, typ));
    const returnType_1 = last(args);
    switch (numberOfArgs) {
        case 1: {
            return (a1) => proxy(returnType_1, connection.invoke(recordField.FieldName, serialize(a1, args[0])));
        }
        case 2: {
            return (delegateArg0, delegateArg1) => proxy(returnType_1, connection.invoke(recordField.FieldName, serialize(delegateArg0, args[0]), serialize(delegateArg1, args[1])));
        }
        case 3: {
            return (delegateArg0_1, delegateArg1_1, delegateArg2) => proxy(returnType_1, connection.invoke(recordField.FieldName, serialize(delegateArg0_1, args[0]), serialize(delegateArg1_1, args[1]), serialize(delegateArg2, args[2])));
        }
        case 4: {
            return (delegateArg0_2, delegateArg1_2, delegateArg2_1, delegateArg3) => proxy(returnType_1, connection.invoke(recordField.FieldName, serialize(delegateArg0_2, args[0]), serialize(delegateArg1_2, args[1]), serialize(delegateArg2_1, args[2]), serialize(delegateArg3, args[3])));
        }
        case 5: {
            return (delegateArg0_3, delegateArg1_3, delegateArg2_2, delegateArg3_1, delegateArg4) => proxy(returnType_1, connection.invoke(recordField.FieldName, serialize(delegateArg0_3, args[0]), serialize(delegateArg1_3, args[1]), serialize(delegateArg2_2, args[2]), serialize(delegateArg3_1, args[3]), serialize(delegateArg4, args[4])));
        }
        case 6: {
            return (delegateArg0_4, delegateArg1_4, delegateArg2_3, delegateArg3_2, delegateArg4_1, delegateArg5) => proxy(returnType_1, connection.invoke(recordField.FieldName, serialize(delegateArg0_4, args[0]), serialize(delegateArg1_4, args[1]), serialize(delegateArg2_3, args[2]), serialize(delegateArg3_2, args[3]), serialize(delegateArg4_1, args[4]), serialize(delegateArg5, args[5])));
        }
        case 7: {
            return (delegateArg0_5, delegateArg1_5, delegateArg2_4, delegateArg3_3, delegateArg4_2, delegateArg5_1, delegateArg6) => proxy(returnType_1, connection.invoke(recordField.FieldName, serialize(delegateArg0_5, args[0]), serialize(delegateArg1_5, args[1]), serialize(delegateArg2_4, args[2]), serialize(delegateArg3_3, args[3]), serialize(delegateArg4_2, args[4]), serialize(delegateArg5_1, args[5]), serialize(delegateArg6, args[6])));
        }
        case 8: {
            return (delegateArg0_6, delegateArg1_6, delegateArg2_5, delegateArg3_4, delegateArg4_3, delegateArg5_2, delegateArg6_1, delegateArg7) => proxy(returnType_1, connection.invoke(recordField.FieldName, serialize(delegateArg0_6, args[0]), serialize(delegateArg1_6, args[1]), serialize(delegateArg2_5, args[2]), serialize(delegateArg3_4, args[3]), serialize(delegateArg4_3, args[4]), serialize(delegateArg5_2, args[5]), serialize(delegateArg6_1, args[6]), serialize(delegateArg7, args[7])));
        }
        default: {
            return toFail(printf("More than 8 parameters are not supported"));
        }
    }
}

function createProxyAsyncEnumerable(recordField, args, numberOfArgs, connection) {
    const serialize = (value, typ) => JSON.parse(Convert_serialize(value, typ));
    const returnType = last(args);
    const proxy = (returnType_1, stream, callbacks) => {
        const subscription = {
            next: (res) => {
                callbacks.onNext(Convert_fromJsonAs(SimpleJson_parseNative(JSON.stringify(res)), returnType_1));
            },
            complete: () => {
                callbacks.onComplete();
            },
            error: (err) => {
                callbacks.onError(err);
            },
        };
        console.log(some("Start sbuscriptiong"));
        return stream.subscribe(subscription);
    };
    let inner;
    switch (returnType.tag) {
        case 20: {
            inner = returnType.fields[0]();
            break;
        }
        case 19: {
            const t = returnType.fields[0]();
            if (name(t) === "IStream`1") {
                const typeInfo = createTypeInfo(getGenerics(t)[0]);
                if (typeInfo.tag === 35) {
                    console.log(some(typeInfo.fields[0]()));
                }
                inner = typeInfo;
            }
            else {
                inner = toFail(printf("Field %s does not have a valid definiton (it should return Async or IAsyncEnumerable)"))(recordField.FieldName);
            }
            break;
        }
        default: {
            inner = toFail(printf("Field %s does not have a valid definiton (it should return Async or IAsyncEnumerable)"))(recordField.FieldName);
        }
    }
    console.log(some("Creating proxy...."));
    switch (numberOfArgs) {
        case 1: {
            if ((args[0].tag === 0) ? true : false) {
                return (a1) => (() => partialApply(1, proxy, [inner, connection.stream(recordField.FieldName)]));
            }
            else {
                return (a1_1) => (() => partialApply(1, proxy, [inner, connection.stream(recordField.FieldName, serialize(a1_1, args[0]))]));
            }
        }
        case 2: {
            return (delegateArg0, delegateArg1) => (() => partialApply(1, proxy, [inner, connection.stream(recordField.FieldName, serialize(delegateArg0, args[0]), serialize(delegateArg1, args[1]))]));
        }
        case 3: {
            return (delegateArg0_1, delegateArg1_1, delegateArg2) => (() => partialApply(1, proxy, [inner, connection.stream(recordField.FieldName, serialize(delegateArg0_1, args[0]), serialize(delegateArg1_1, args[1]), serialize(delegateArg2, args[2]))]));
        }
        case 4: {
            return (delegateArg0_2, delegateArg1_2, delegateArg2_1, delegateArg3) => (() => partialApply(1, proxy, [inner, connection.stream(recordField.FieldName, serialize(delegateArg0_2, args[0]), serialize(delegateArg1_2, args[1]), serialize(delegateArg2_1, args[2]), serialize(delegateArg3, args[3]))]));
        }
        case 5: {
            return (delegateArg0_3, delegateArg1_3, delegateArg2_2, delegateArg3_1, delegateArg4) => (() => partialApply(1, proxy, [inner, connection.stream(recordField.FieldName, serialize(delegateArg0_3, args[0]), serialize(delegateArg1_3, args[1]), serialize(delegateArg2_2, args[2]), serialize(delegateArg3_1, args[3]), serialize(delegateArg4, args[4]))]));
        }
        case 6: {
            return (delegateArg0_4, delegateArg1_4, delegateArg2_3, delegateArg3_2, delegateArg4_1, delegateArg5) => (() => partialApply(1, proxy, [inner, connection.stream(recordField.FieldName, serialize(delegateArg0_4, args[0]), serialize(delegateArg1_4, args[1]), serialize(delegateArg2_3, args[2]), serialize(delegateArg3_2, args[3]), serialize(delegateArg4_1, args[4]), serialize(delegateArg5, args[5]))]));
        }
        case 7: {
            return (delegateArg0_5, delegateArg1_5, delegateArg2_4, delegateArg3_3, delegateArg4_2, delegateArg5_1, delegateArg6) => (() => partialApply(1, proxy, [inner, connection.stream(recordField.FieldName, serialize(delegateArg0_5, args[0]), serialize(delegateArg1_5, args[1]), serialize(delegateArg2_4, args[2]), serialize(delegateArg3_3, args[3]), serialize(delegateArg4_2, args[4]), serialize(delegateArg5_1, args[5]), serialize(delegateArg6, args[6]))]));
        }
        case 8: {
            return (delegateArg0_6, delegateArg1_6, delegateArg2_5, delegateArg3_4, delegateArg4_3, delegateArg5_2, delegateArg6_1, delegateArg7) => (() => partialApply(1, proxy, [inner, connection.stream(recordField.FieldName, serialize(delegateArg0_6, args[0]), serialize(delegateArg1_6, args[1]), serialize(delegateArg2_5, args[2]), serialize(delegateArg3_4, args[3]), serialize(delegateArg4_3, args[4]), serialize(delegateArg5_2, args[5]), serialize(delegateArg6_1, args[6]), serialize(delegateArg7, args[7]))]));
        }
        default: {
            console.warn(some("Number of args not supported"));
            throw (new Error("Number of args not supported"));
        }
    }
}

function createEndpointProxy(recordField, connection) {
    let argsTypeInfo;
    const matchValue = recordField.FieldType;
    argsTypeInfo = ((matchValue.tag === 32) ? matchValue.fields[0]() : toFail(printf("Field %s does not have a valid definiton (it should be a function)"))(recordField.FieldName));
    const args = getFuncArgs(recordField);
    const lastArg = last_1(args);
    const numberOfArgs = (length(args) - 1) | 0;
    const matchValue_1 = name(lastArg);
    switch (matchValue_1) {
        case "FSharpAsync`1": {
            return createProxyAsync(recordField, argsTypeInfo, numberOfArgs, connection);
        }
        case "IStream`1": {
            return createProxyAsyncEnumerable(recordField, argsTypeInfo, numberOfArgs, connection);
        }
        default: {
            return toFail(printf("Field %s does not have a valid definiton (it should return Async or IAsyncEnumerable)"))(recordField.FieldName);
        }
    }
}

export class Client {
    constructor() {
    }
}

export function Client$reflection() {
    return class_type("Fable.Remoting.Client.SignalR.Client", void 0, Client);
}

export function Client_buildProxy_Z7D14C7A6(endpoint, resolver) {
    const resolvedType = value_4(resolver).ResolveType();
    const schemaType = createTypeInfo(resolvedType);
    if (schemaType.tag === 34) {
        const patternInput = schemaType.fields[0]();
        const url = window.location.origin + endpoint;
        console.log(some(url));
        const connection = (new signalr.HubConnectionBuilder()).withUrl(url).withAutomaticReconnect(new IRetryPolicy((retryContext) => clamp((x, y) => comparePrimitives(x, y), (retryContext.previousRetryCount * 1) * 1000, 0, 30 * 1000))).configureLogging(signalr.LogLevel.Debug).build();
        return [connection, makeRecord(patternInput[1], toArray(delay(() => map((field) => createEndpointProxy(field, connection), patternInput[0]))))];
    }
    else {
        const arg10 = fullName(resolvedType);
        return toFail(printf("Cannot build proxy. Exepected type %s to be a valid protocol definition which is a record of functions"))(arg10);
    }
}

export class Stream_Update$2 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Next", "Error"];
    }
}

export function Stream_Update$2$reflection(gen0, gen1) {
    return union_type("Fable.Remoting.Client.SignalR.Stream.Update`2", [gen0, gen1], Stream_Update$2, () => [[["Item", gen0]], [["Item", gen1]]]);
}

export function Stream_iter(callbacks, stream) {
    const isubscription = stream(void 0)(callbacks);
    return {
        Dispose() {
            isubscription.dispose();
        },
    };
}

export function Stream_toObservable(stream) {
    const event = new Event$();
    return [event.Publish, Stream_iter({
        onComplete: () => {
            console.log(some("Stream completed"));
        },
        onError: (err) => {
            event.Trigger(new Stream_Update$2(1, err));
        },
        onNext: (x) => {
            event.Trigger(new Stream_Update$2(0, x));
        },
    }, stream)];
}

export class Hooks_HubConnectionState extends Record {
    constructor(Connection, State) {
        super();
        this.Connection = Connection;
        this.State = State;
    }
}

export function Hooks_HubConnectionState$reflection() {
    return record_type("Fable.Remoting.Client.SignalR.Hooks.HubConnectionState", [], Hooks_HubConnectionState, () => [["Connection", HubConnection$reflection()], ["State", string_type]]);
}

export function Hooks_HubConnectionState__UnderlyingConnectionState(this$) {
    return this$.State;
}

export function Hooks_HubConnectionState__IsConnected(this$) {
    if (this$.State === "Connected") {
        return true;
    }
    else {
        return false;
    }
}

export const Hooks_hubConnectionContext = React_createContext_1AE444D8();

export function Hooks_HubContextProvider(connection, children) {
    const patternInput = useFeliz_React__React_useState_Static_1505(new Hooks_HubConnectionState(connection, connection.state));
    const connectionState = patternInput[0];
    useReact_useEffectOnce_Z5ECA432F(() => {
        const updateConnectionState = () => {
            patternInput[1](new Hooks_HubConnectionState(connectionState.Connection, connection.state));
        };
        connection.onclose(updateConnectionState);
        connection.onreconnected(updateConnectionState);
        connection.onreconnecting(updateConnectionState);
        const start = () => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => ((PromiseBuilder__Delay_62FBFDE1(promise, () => ((((connection.state) === "Disconnected") ? ((connection.start()).then((() => (Promise.resolve(undefined))))) : (Promise.resolve())).then(() => PromiseBuilder__Delay_62FBFDE1(promise, () => {
            updateConnectionState();
            return Promise.resolve();
        })))).catch(((_arg2) => {
            void window.setTimeout(start, 5000);
            console.error(some("Unable to start signalR connection."), _arg2);
            return Promise.resolve();
        }))).then(() => PromiseBuilder__Delay_62FBFDE1(promise, () => (Promise.resolve(undefined)))))));
        const pr = start();
        pr.then();
        updateConnectionState();
        return {
            Dispose() {
                const pr_1 = connection.stop();
                pr_1.then();
            },
        };
    });
    return React_contextProvider_138D2F56(Hooks_hubConnectionContext, connectionState, children);
}

export function Hooks_useHub() {
    return useReact_useContext_37FA55CF(Hooks_hubConnectionContext);
}

export function Hooks_useObservable(observable, dependencies) {
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    useReact_useEffect_Z5234A374(() => subscribe((x) => {
        patternInput[1](some(x));
    }, observable), append_1([observable], dependencies));
    return patternInput[0];
}

export function Hooks_useObservableWithInitialState(observable, initialState, dependencies) {
    const patternInput = useFeliz_React__React_useState_Static_1505(initialState);
    useReact_useEffect_Z5234A374(() => subscribe((x) => {
        patternInput[1](x);
    }, observable), append_1([observable], dependencies));
    return patternInput[0];
}

export function Hooks_useObservableWithUpdates(observable, onError, dependencies) {
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    useReact_useEffect_Z5234A374(() => subscribe((x) => {
        if (x.tag === 1) {
            const e = x.fields[0];
            console.warn(some("Got error"), e);
            onError(e);
        }
        else {
            console.warn(some("Got next"), x);
            patternInput[1](some(x.fields[0]));
        }
    }, observable), append_1([observable], dependencies));
    return patternInput[0];
}

export function Hooks_useObservableWithUpdatesWithInitialState(observable, initialState, onError, dependencies) {
    const patternInput = useReact_useState_FCFD9EF(initialState);
    useReact_useEffect_Z5234A374(() => subscribe((x) => {
        if (x.tag === 1) {
            onError(x.fields[0]);
        }
        else {
            patternInput[1](x.fields[0]);
        }
    }, observable), append_1([observable], dependencies));
    return patternInput[0];
}

export function Hooks_useSplitObservableWithUpdates(observable) {
    const patternInput = useFeliz_React__React_useState_Static_1505((new Event$()).Publish);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505((new Event$()).Publish);
    useReact_useEffect_Z101E1A95(() => {
        patternInput[1](choose((x) => {
            if (x.tag === 1) {
                return void 0;
            }
            else {
                return some(x.fields[0]);
            }
        }, observable));
        patternInput_1[1](choose((x_1) => {
            if (x_1.tag === 1) {
                return some(x_1.fields[0]);
            }
            else {
                return void 0;
            }
        }, observable));
    }, [observable]);
    return [patternInput[0], patternInput_1[0]];
}

export function Hooks_useSubscribeObservable(observable, callback, dependencies) {
    useReact_useEffect_Z5234A374(() => subscribe(callback, observable), append_1([observable], dependencies));
}

export function Hooks_useStreamToObservable(connectionState, stream, dependencies) {
    const patternInput = useFeliz_React__React_useState_Static_1505((new Event$()).Publish);
    useReact_useEffect_Z5234A374(() => {
        let disposeOption;
        if (Hooks_HubConnectionState__UnderlyingConnectionState(connectionState) === "Connected") {
            const patternInput_1 = Stream_toObservable(stream);
            patternInput[1](patternInput_1[0]);
            disposeOption = patternInput_1[1];
        }
        else {
            disposeOption = (void 0);
        }
        return {
            Dispose() {
                iterate((d) => {
                    let copyOfStruct = d;
                    copyOfStruct.Dispose();
                }, toArray_1(disposeOption));
            },
        };
    }, append_1([Hooks_HubConnectionState__UnderlyingConnectionState(connectionState)], dependencies));
    return patternInput[0];
}

