import { createElement } from "react";
import { interpolate, toText, join } from "./.fable/fable-library.3.1.16/String.js";
import { ofArray, empty as empty_1, cons, singleton, append } from "./.fable/fable-library.3.1.16/List.js";
import { Interop_reactApi } from "./.fable/Feliz.1.62.0/Interop.fs.js";
import utils$002Emodule from "./styles/utils.module.scss";
import pools$002Emodule from "./styles/pools.module.scss";
import { empty, singleton as singleton_1, append as append_1, delay, toList } from "./.fable/fable-library.3.1.16/Seq.js";
import { fromParts, compare } from "./.fable/fable-library.3.1.16/Decimal.js";
import card$002Emodule from "./styles/card.module.scss";
import { createObj, equals } from "./.fable/fable-library.3.1.16/Util.js";
import { RiskLevel } from "../../../lib/Domain/Types/FlexProduct.fs.js";
import { MuiHelpers_createElement } from "./.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useFormField } from "../../../lib/CustomHooks/CustomHooks.fs.js";
import { value as value_17 } from "./.fable/fable-library.3.1.16/Option.js";
import { ifEnter } from "./UserInput.fs.js";
import search$002Emodule from "./styles/search.module.scss";
import landing$002Emodule from "./styles/landing.module.scss";
import { manageLiquidity, sort as sort_1, times2 } from "./Img.fs.js";
import { Recoil_useRecoilValue_5D3EEEEA, Recoil_useRecoilState_Z74A95619 } from "./.fable/Feliz.Recoil.0.19.2/Bindings.fs.js";
import { injectiveEnvironment, solanaEnvironment, productSourcePage as productSourcePage_1 } from "./Context.fs.js";
import { RouterModule_nav } from "./.fable/Feliz.Router.3.8.0/Router.fs.js";
import { PublicKey_Value_Z1B21ACD5 } from "../../../lib/Domain/Types/Common.fs.js";

export function Content(contentInputProps) {
    const classes = contentInputProps.classes;
    const x = contentInputProps.x;
    return createElement("div", {
        className: join(" ", append(singleton("section"), classes)),
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: "content",
            children: Interop_reactApi.Children.toArray([x]),
        })]),
    });
}

export function TitleText(titleTextInputProps) {
    const x = titleTextInputProps.x;
    return createElement("h4", {
        children: x,
    });
}

export function SubText(x) {
    return createElement("div", {
        className: "sub-text",
        children: x,
    });
}

export function displayMaturity(days, classes, showSmallBars) {
    return createElement("div", {
        className: join(" ", classes),
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: join(" ", [utils$002Emodule.center]),
            children: toText(interpolate("%P() Days", [days])),
        }), createElement("div", {
            className: join(" ", [pools$002Emodule["duration-row"]]),
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: join(" ", toList(delay(() => append_1(singleton_1(showSmallBars ? pools$002Emodule["duration-marker-small"] : pools$002Emodule["duration-marker"]), delay(() => ((compare(days, fromParts(70, 0, 0, false, 1)) >= 0) ? singleton_1(pools$002Emodule["duration-marker-filled"]) : empty())))))),
            }), createElement("div", {
                className: join(" ", toList(delay(() => append_1(singleton_1(showSmallBars ? pools$002Emodule["duration-marker-small"] : pools$002Emodule["duration-marker"]), delay(() => ((compare(days, fromParts(140, 0, 0, false, 1)) >= 0) ? singleton_1(pools$002Emodule["duration-marker-filled"]) : empty())))))),
            }), createElement("div", {
                className: join(" ", toList(delay(() => append_1(singleton_1(showSmallBars ? pools$002Emodule["duration-marker-small"] : pools$002Emodule["duration-marker"]), delay(() => ((compare(days, fromParts(200, 0, 0, false, 1)) >= 0) ? singleton_1(pools$002Emodule["duration-marker-filled"]) : empty())))))),
            })]),
        })]),
    });
}

export function displayMaturityProductInfo(days, classes) {
    return createElement("div", {
        className: join(" ", classes),
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: join(" ", [utils$002Emodule.center, card$002Emodule["strategy-header"]]),
            children: toText(interpolate("%P() Days", [days])),
        }), createElement("div", {
            className: join(" ", [pools$002Emodule["duration-row"]]),
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: join(" ", toList(delay(() => append_1(singleton_1(pools$002Emodule["duration-marker"]), delay(() => ((days >= 1) ? singleton_1(pools$002Emodule["duration-marker-filled"]) : empty())))))),
            }), createElement("div", {
                className: join(" ", toList(delay(() => append_1(singleton_1(pools$002Emodule["duration-marker"]), delay(() => ((days >= 3) ? singleton_1(pools$002Emodule["duration-marker-filled"]) : empty())))))),
            }), createElement("div", {
                className: join(" ", toList(delay(() => append_1(singleton_1(pools$002Emodule["duration-marker"]), delay(() => ((days >= 7) ? singleton_1(pools$002Emodule["duration-marker-filled"]) : empty())))))),
            })]),
        })]),
    });
}

export function displayRiskLevel(risk, classes) {
    const patternInput = (risk.tag === 1) ? ["Medium", pools$002Emodule["risk-marker-filled-medium"]] : ((risk.tag === 2) ? ["High", pools$002Emodule["risk-marker-filled-high"]] : ["Low", pools$002Emodule["risk-marker-filled-low"]]);
    const bkg = patternInput[1];
    return createElement("div", {
        className: join(" ", classes),
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: join(" ", [utils$002Emodule.center]),
            children: toText(interpolate("%P()", [patternInput[0]])),
        }), createElement("div", {
            className: join(" ", [pools$002Emodule["duration-row"]]),
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: join(" ", [pools$002Emodule["risk-marker"], bkg]),
            }), createElement("div", {
                className: join(" ", toList(delay(() => append_1(singleton_1(pools$002Emodule["risk-marker"]), delay(() => ((equals(risk, new RiskLevel(2)) ? true : equals(risk, new RiskLevel(1))) ? singleton_1(bkg) : empty())))))),
            }), createElement("div", {
                className: join(" ", toList(delay(() => append_1(singleton_1(pools$002Emodule["risk-marker"]), delay(() => (equals(risk, new RiskLevel(2)) ? singleton_1(bkg) : empty())))))),
            })]),
        })]),
    });
}

export function Row(rowInputProps) {
    const children = rowInputProps.children;
    const classes = rowInputProps.classes;
    return createElement("div", {
        className: join(" ", cons(utils$002Emodule.row, classes)),
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function Col(colInputProps) {
    const children = colInputProps.children;
    const classes = colInputProps.classes;
    return createElement("div", {
        className: join(" ", cons(utils$002Emodule.col, classes)),
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function Grid(classes, children) {
    return createElement("div", {
        className: join(" ", cons(utils$002Emodule.grid, classes)),
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function ErrorText(errorTextInputProps) {
    const classes = errorTextInputProps.classes;
    const text = errorTextInputProps.text;
    return createElement("div", {
        className: join(" ", cons("text-error", classes)),
        children: text,
    });
}

export function ButtonProgressLoader(buttonProgressLoaderInputProps) {
    return MuiHelpers_createElement(CircularProgress, [["className", join(" ", cons("progress-button", buttonProgressLoaderInputProps.classes))]]);
}

export function ButtonProgressLoaderSmall(buttonProgressLoaderSmallInputProps) {
    return MuiHelpers_createElement(CircularProgress, [["className", join(" ", cons("progress-button-small", buttonProgressLoaderSmallInputProps.classes))]]);
}

export function SectionTitle(sectionTitleInputProps) {
    const children = sectionTitleInputProps.children;
    const classes = sectionTitleInputProps.classes;
    return createElement(Row, {
        classes: cons("section-title", classes),
        children: children,
    });
}

export function Card(cardInputProps) {
    const children = cardInputProps.children;
    const classes = cardInputProps.classes;
    return createElement("div", {
        className: join(" ", cons("card", classes)),
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function CardFlat(cardFlatInputProps) {
    const children = cardFlatInputProps.children;
    const classes = cardFlatInputProps.classes;
    return createElement("div", {
        className: join(" ", cons("card-flat", classes)),
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function EducationalLink(educationalLinkInputProps) {
    const children = educationalLinkInputProps.children;
    const classes = educationalLinkInputProps.classes;
    return createElement("div", {
        className: join(" ", cons("edu-link", classes)),
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function HDivider(hDividerInputProps) {
    const classes = hDividerInputProps.classes;
    return createElement("div", {
        className: join(" ", cons("h-divider", classes)),
    });
}

export function VDivider(vDividerInputProps) {
    const classes = vDividerInputProps.classes;
    return createElement("div", {
        className: join(" ", cons("v-divider", classes)),
    });
}

export function SingleInputForm(singleInputFormInputProps) {
    const classes = singleInputFormInputProps.classes;
    const isLoading = singleInputFormInputProps.isLoading;
    const disabled = singleInputFormInputProps.disabled;
    const onSubmit = singleInputFormInputProps.onSubmit;
    const errorText = singleInputFormInputProps.errorText;
    const buttonText = singleInputFormInputProps.buttonText;
    const placeholder = singleInputFormInputProps.placeholder;
    const field = useFormField("", singleInputFormInputProps.validator);
    const submitForm = (_arg1) => {
        const matchValue = field.Validate();
        if (matchValue == null) {
        }
        else {
            onSubmit(value_17(matchValue));
            field.Reset();
        }
    };
    return createElement(Col, {
        classes: empty_1(),
        children: toList(delay(() => append_1(singleton_1(createElement("div", {
            className: join(" ", cons("single-input-form", classes)),
            children: Interop_reactApi.Children.toArray([createElement("input", createObj(toList(delay(() => {
                let value_1;
                return append_1(field.Error ? singleton_1((value_1 = utils$002Emodule["input-error"], ["className", value_1])) : empty(), delay(() => append_1(singleton_1(["placeholder", placeholder]), delay(() => append_1(singleton_1(["value", field.FieldValue]), delay(() => append_1(singleton_1(["onChange", (ev) => {
                    field.SetFieldValue(ev.target.value);
                }]), delay(() => append_1(singleton_1(["onKeyDown", (ev_1) => {
                    ifEnter(submitForm, ev_1);
                }]), delay(() => singleton_1(["size", field.FieldValue.length])))))))))));
            })))), createElement("button", {
                className: join(" ", []),
                disabled: disabled ? true : isLoading,
                onClick: submitForm,
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton_1(createElement("span", {
                    children: Interop_reactApi.Children.toArray([buttonText]),
                })), delay(() => (isLoading ? singleton_1(createElement(ButtonProgressLoader, {
                    classes: singleton("progress-white"),
                })) : empty()))))))),
            })]),
        })), delay(() => (field.Error ? singleton_1(createElement(ErrorText, {
            text: errorText,
            classes: singleton(utils$002Emodule["margin-s-top"]),
        })) : empty()))))),
    });
}

export function SearchTest(searchTestInputProps) {
    const classes = searchTestInputProps.classes;
    const isLoading = searchTestInputProps.isLoading;
    const disabled = searchTestInputProps.disabled;
    const onSubmit = searchTestInputProps.onSubmit;
    const errorText = searchTestInputProps.errorText;
    const buttonText = searchTestInputProps.buttonText;
    const placeholder = searchTestInputProps.placeholder;
    const field = useFormField("", searchTestInputProps.validator);
    const submitForm = (_arg1) => {
        const matchValue = field.Validate();
        if (matchValue == null) {
        }
        else {
            onSubmit(value_17(matchValue));
        }
    };
    return createElement(Col, {
        classes: empty_1(),
        children: toList(delay(() => append_1(singleton_1(createElement("div", {
            className: join(" ", cons(search$002Emodule["search-input"], classes)),
            children: Interop_reactApi.Children.toArray([createElement("input", createObj(toList(delay(() => {
                let value_1;
                return append_1(field.Error ? singleton_1((value_1 = utils$002Emodule["input-error"], ["className", value_1])) : empty(), delay(() => append_1(singleton_1(["placeholder", placeholder]), delay(() => append_1(singleton_1(["value", field.FieldValue]), delay(() => append_1(singleton_1(["onChange", (ev) => {
                    field.SetFieldValue(ev.target.value);
                }]), delay(() => singleton_1(["onKeyDown", (ev_1) => {
                    ifEnter(submitForm, ev_1);
                }])))))))));
            })))), createElement("button", {
                className: join(" ", []),
                disabled: disabled ? true : isLoading,
                onClick: submitForm,
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton_1(createElement("span", {
                    children: Interop_reactApi.Children.toArray([buttonText]),
                })), delay(() => (isLoading ? singleton_1(createElement(ButtonProgressLoader, {
                    classes: singleton("progress-white"),
                })) : empty()))))))),
            })]),
        })), delay(() => (field.Error ? singleton_1(createElement(ErrorText, {
            text: errorText,
            classes: singleton(utils$002Emodule["margin-s-top"]),
        })) : empty()))))),
    });
}

export function ModalClose(modalCloseInputProps) {
    const onClick = modalCloseInputProps.onClick;
    return createElement("button", {
        className: join(" ", [landing$002Emodule["modal-close"]]),
        children: Interop_reactApi.Children.toArray([createElement("img", {
            src: times2,
            alt: "Close",
        })]),
        onClick: (_arg1) => {
            onClick();
        },
    });
}

export function ModalCloseButton(modalCloseButtonInputProps) {
    const onClick = modalCloseButtonInputProps.onClick;
    const classes = modalCloseButtonInputProps.classes;
    const img = modalCloseButtonInputProps.img;
    return createElement("button", {
        className: join(" ", classes),
        children: Interop_reactApi.Children.toArray([createElement("img", {
            src: img,
            alt: "Close",
        })]),
        onClick: (_arg1) => {
            onClick();
        },
    });
}

export function Header(headerInputProps) {
    const classes = headerInputProps.classes;
    const text = headerInputProps.text;
    return createElement("div", {
        className: join(" ", append(singleton("text-color-normal"), classes)),
        children: text,
    });
}

export function SortableHeader(sortableHeaderInputProps) {
    const classes = sortableHeaderInputProps.classes;
    const sort = sortableHeaderInputProps.sort;
    const text = sortableHeaderInputProps.text;
    return createElement("div", {
        className: join(" ", append(ofArray([utils$002Emodule["cursor-pointer"], utils$002Emodule["text-light"], utils$002Emodule["text-center"]]), classes)),
        children: Interop_reactApi.Children.toArray([createElement("span", {
            children: [text],
        }), createElement("img", {
            className: join(" ", [utils$002Emodule["margin-s-left"]]),
            src: sort_1,
        })]),
        onClick: (_arg1) => {
            sort();
        },
    });
}

export function TextColumn(textColumnInputProps) {
    const classes = textColumnInputProps.classes;
    const text = textColumnInputProps.text;
    return createElement("div", {
        className: join(" ", append(empty_1(), classes)),
        children: text,
    });
}

export function Column(columnInputProps) {
    const classes = columnInputProps.classes;
    const children = columnInputProps.children;
    return createElement("div", {
        className: join(" ", append(empty_1(), classes)),
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function GoToProductButton(goToProductButtonInputProps) {
    const publicKey = goToProductButtonInputProps.publicKey;
    const productType = goToProductButtonInputProps.productType;
    const patternInput = Recoil_useRecoilState_Z74A95619(productSourcePage_1);
    return createElement("button", {
        className: join(" ", ["go-button"]),
        onClick: (_arg1) => {
            patternInput[1]("dashboard");
            RouterModule_nav(ofArray(["products", productType, PublicKey_Value_Z1B21ACD5(publicKey)]), 1, 1);
        },
        children: Interop_reactApi.Children.toArray([createElement("img", {
            src: manageLiquidity,
        })]),
    });
}

export function CheckOnSolanaExplorer(checkOnSolanaExplorerInputProps) {
    const signature = checkOnSolanaExplorerInputProps.signature;
    const environment = Recoil_useRecoilValue_5D3EEEEA(solanaEnvironment);
    const solanaExplorerLink = toText(interpolate("https://explorer.solana.com/tx/%P()?cluster=%P()", [signature, (environment.tag === 1) ? "" : "devnet"]));
    return createElement("a", {
        children: "Check on Solana Explorer",
        href: solanaExplorerLink,
        target: "_blank",
    });
}

export function CheckOnInjectiveExplorer(checkOnInjectiveExplorerInputProps) {
    const txHash = checkOnInjectiveExplorerInputProps.txHash;
    const environment = Recoil_useRecoilValue_5D3EEEEA(injectiveEnvironment);
    const injectiveExplorerLink = toText(interpolate("https://%P()explorer.injective.network/transaction/%P()", [(environment.tag === 1) ? "" : "testnet.", txHash]));
    return createElement("a", {
        children: "Check on Injective Explorer",
        href: injectiveExplorerLink,
        target: "_blank",
    });
}

