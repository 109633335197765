import { singleton } from "../.fable/fable-library.3.1.16/AsyncBuilder.js";
import { MsgType, coin, msgExecuteContractCompatParams, msgExecuteContractCompat as msgExecuteContractCompat_1, createBroadcast, getDenomBalance, createMsgBroadcastClient } from "../../../../lib/Injective/Injective.fs.js";
import { interpolate, toText } from "../.fable/fable-library.3.1.16/String.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.1.16/Choice.js";
import { toString } from "../.fable/fable-library.3.1.16/Types.js";

export function exerciseOption(network, optionContract, walletStrategy, api, address) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        const client = createMsgBroadcastClient(network, walletStrategy);
        const positionTokenDenom = toText(interpolate("factory/%P()/position", [optionContract]));
        return singleton.Bind(getDenomBalance(api, address, positionTokenDenom), (_arg1) => {
            const userPositionTokenBalance = _arg1;
            return (userPositionTokenBalance === "0") ? singleton.Return(new FSharpResult$2(1, "User has no option position")) : singleton.Bind(createBroadcast(client, new MsgType(1, msgExecuteContractCompat_1(msgExecuteContractCompatParams([coin(positionTokenDenom, userPositionTokenBalance)], address, optionContract, "exercise_option"))), address), (_arg2) => singleton.Return(new FSharpResult$2(0, _arg2)));
        });
    }), (_arg3) => singleton.Return(new FSharpResult$2(1, toString(_arg3)))));
}

