import { useReact_useMemo_CF4EA67, useFeliz_React__React_useState_Static_1505 } from "./.fable/Feliz.1.62.0/React.fs.js";
import { map, defaultArg } from "./.fable/fable-library.3.1.16/Option.js";
import { shortenedKey } from "../../../lib/Solana/Solana.fs.js";
import { createElement } from "react";
import { Interop_reactApi } from "./.fable/Feliz.1.62.0/Interop.fs.js";
import { append, singleton, empty, delay, toList } from "./.fable/fable-library.3.1.16/Seq.js";
import { interpolate, toText, join } from "./.fable/fable-library.3.1.16/String.js";
import utils$002Emodule from "./styles/utils.module.scss";
import { WalletMultiButton, WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import navbar$002Emodule from "./styles/navbar.module.scss";
import { accountBalanceWallet } from "./Img.fs.js";
import { ConnectWalletButtonWhite } from "./Components/Button.fs.js";
import { InjectiveWalletSelector } from "./Components/Navbar.fs.js";

export function ConnectWalletMessage(connectWalletMessageInputProps) {
    const walletCtx = connectWalletMessageInputProps.walletCtx;
    const connection = connectWalletMessageInputProps.connection;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const content = useReact_useMemo_CF4EA67(() => {
        if (walletCtx.wallet != null) {
            return defaultArg(map((publicKey) => shortenedKey(publicKey), walletCtx.publicKey), "Connect");
        }
        else if (walletCtx.connected) {
            return "Connected";
        }
        else if (walletCtx.connecting) {
            return "Connecting...";
        }
        else {
            return "Connect Wallet";
        }
    }, [walletCtx.connecting, walletCtx.connected, walletCtx.wallet]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            if (walletCtx.publicKey != null) {
                return empty();
            }
            else {
                return singleton(createElement("div", {
                    className: join(" ", ["connect-wallet-message-container"]),
                    children: Interop_reactApi.Children.toArray([createElement("div", {
                        className: join(" ", ["connect-wallet-message-header"]),
                        children: Interop_reactApi.Children.toArray(["Connect your Wallet to View Product"]),
                    }), createElement("div", {
                        className: join(" ", ["text-dim", utils$002Emodule["margin-l-bottom"]]),
                        children: Interop_reactApi.Children.toArray(["Please connect your wallet to have access to our products."]),
                    }), Interop_reactApi.createElement(WalletDialogProvider, {
                        featuredWallets: 7,
                        title: "Select a Wallet",
                        id: "wallet-modal",
                        children: Interop_reactApi.Children.toArray([Interop_reactApi.createElement(WalletMultiButton, {
                            id: "connect-button-2",
                            className: join(" ", ["button-dropdown", navbar$002Emodule["connect-wallet-button"]]),
                            children: Interop_reactApi.Children.toArray([createElement("span", {
                                children: content,
                            }), createElement("img", {
                                src: accountBalanceWallet,
                                alt: "Wallet",
                            })]),
                        })]),
                    })]),
                }));
            }
        })))),
    });
}

export function ConnectInjectiveWalletMessage(wallet) {
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const visible = patternInput[0];
    const setVisible = patternInput[1];
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let addr;
            const content = createElement("div", {
                className: join(" ", ["connect-wallet-message-container", "connect-wallet-modal"]),
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["connect-wallet-message-header"]),
                    children: Interop_reactApi.Children.toArray(["Connect your Wallet to View Product"]),
                }), createElement("div", {
                    className: join(" ", ["text-dim"]),
                    children: Interop_reactApi.Children.toArray(["Please connect your wallet to have access to our products."]),
                }), createElement(ConnectWalletButtonWhite, {
                    text: (addr = wallet.injectiveAddress, (addr === "") ? "Connect Wallet" : toText(interpolate("%P()...%P()", [addr.slice(0, 5 + 1), addr.slice(-3, addr.length)]))),
                    onClick: (_arg1) => {
                        setVisible(true);
                    },
                })]),
            });
            if (wallet.walletStrategy != null) {
                if (wallet.injectiveAddress === "") {
                    return append(singleton(content), delay(() => (visible ? singleton(createElement(InjectiveWalletSelector, {
                        onClose: () => {
                            setVisible(false);
                        },
                        modalExtraAttribute: ["transform", ("translateY(" + -160) + "px)"],
                    })) : empty())));
                }
                else {
                    return empty();
                }
            }
            else {
                return append(singleton(content), delay(() => (visible ? singleton(createElement(InjectiveWalletSelector, {
                    onClose: () => {
                        setVisible(false);
                    },
                    modalExtraAttribute: ["transform", ("translateY(" + -160) + "px)"],
                })) : empty())));
            }
        })))),
    });
}

export function ConnectWalletMessageSmall(connectWalletMessageSmallInputProps) {
    const walletCtx = connectWalletMessageSmallInputProps.walletCtx;
    const connection = connectWalletMessageSmallInputProps.connection;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const content = useReact_useMemo_CF4EA67(() => {
        if (walletCtx.wallet != null) {
            return defaultArg(map((publicKey) => shortenedKey(publicKey), walletCtx.publicKey), "Connect");
        }
        else if (walletCtx.connected) {
            return "Connected";
        }
        else if (walletCtx.connecting) {
            return "Connecting...";
        }
        else {
            return "Connect Wallet";
        }
    }, [walletCtx.connecting, walletCtx.connected, walletCtx.wallet]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            if (walletCtx.publicKey != null) {
                return empty();
            }
            else {
                return singleton(createElement("div", {
                    className: join(" ", ["connect-wallet-message-container-small"]),
                    children: Interop_reactApi.Children.toArray([createElement("div", {
                        className: join(" ", ["connect-wallet-message-header-small"]),
                        children: Interop_reactApi.Children.toArray(["Connect your Wallet to View Product"]),
                    }), createElement("div", {
                        className: join(" ", ["text-dim", utils$002Emodule["margin-s-bottom"]]),
                        children: Interop_reactApi.Children.toArray(["Please connect your wallet to have access to our products."]),
                    }), Interop_reactApi.createElement(WalletDialogProvider, {
                        featuredWallets: 7,
                        title: "Select a Wallet",
                        id: "wallet-modal",
                        children: Interop_reactApi.Children.toArray([Interop_reactApi.createElement(WalletMultiButton, {
                            id: "connect-button-2",
                            className: join(" ", ["button-dropdown", navbar$002Emodule["connect-wallet-button"]]),
                            children: Interop_reactApi.Children.toArray([createElement("span", {
                                children: content,
                            }), createElement("img", {
                                src: accountBalanceWallet,
                                alt: "Wallet",
                            })]),
                        })]),
                    })]),
                }));
            }
        })))),
    });
}

export function ConnectInjectiveWalletMessageSmall(wallet) {
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setVisible = patternInput[1];
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            const content = createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(createElement("div", {
                    className: join(" ", ["connect-wallet-message-header-small"]),
                    children: Interop_reactApi.Children.toArray(["Connect your Wallet to View Product"]),
                })), delay(() => append(singleton(createElement("div", {
                    className: join(" ", ["text-dim", utils$002Emodule["margin-s-bottom"]]),
                    children: Interop_reactApi.Children.toArray(["Please connect your wallet to have access to our products."]),
                })), delay(() => {
                    let addr;
                    return append(singleton(createElement(ConnectWalletButtonWhite, {
                        text: (addr = wallet.injectiveAddress, (addr === "") ? "Connect Wallet" : toText(interpolate("%P()...%P()", [addr.slice(0, 5 + 1), addr.slice(-3, addr.length)]))),
                        onClick: (_arg1) => {
                            setVisible(true);
                        },
                    })), delay(() => (patternInput[0] ? singleton(createElement(InjectiveWalletSelector, {
                        onClose: () => {
                            setVisible(false);
                        },
                        modalExtraAttribute: void 0,
                    })) : empty())));
                })))))))),
            });
            if (wallet.walletStrategy != null) {
                if (wallet.injectiveAddress === "") {
                    return singleton(content);
                }
                else {
                    return empty();
                }
            }
            else {
                return singleton(content);
            }
        })))),
    });
}

