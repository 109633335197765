import { toString, Union } from "./.fable/fable-library.3.1.16/Types.js";
import { union_type, array_type, class_type } from "./.fable/fable-library.3.1.16/Reflection.js";
import { fromValue, fromNumber, compare, fromBits, toNumber, op_Subtraction, fromInteger, equals } from "./.fable/fable-library.3.1.16/Long.js";
import { map } from "./.fable/fable-library.3.1.16/Array.js";
import { PublicKey, convertUInt64ToPercent } from "../../../lib/Domain/Types/Common.fs.js";
import { SpecificProduct__GetStrikes } from "../../../lib/Domain/Types/Product.fs.js";
import { interpolate, join, printf, toText } from "./.fable/fable-library.3.1.16/String.js";
import { singleton } from "./.fable/fable-library.3.1.16/AsyncBuilder.js";
import { toNumber as toNumber_1, compare as compare_1, op_Multiply, floor, op_Division, fromParts, pow } from "./.fable/fable-library.3.1.16/Decimal.js";
import Decimal from "./.fable/fable-library.3.1.16/Decimal.js";
import { getTokenAccountBalance, publicKeyBackEndToSolana, getAssociatedTokenAddress, getBalance } from "../../../lib/Solana/Solana.fs.js";
import { Holding__GetDepositMint } from "../../../lib/Domain/Types/Holding.fs.js";
import { createObj, equals as equals_1, comparePrimitives, max as max_1, min } from "./.fable/fable-library.3.1.16/Util.js";
import { some, value as value_18 } from "./.fable/fable-library.3.1.16/Option.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useCallback_93353E, useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "./.fable/Feliz.1.62.0/React.fs.js";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { sleep, startImmediate } from "./.fable/fable-library.3.1.16/Async.js";
import { createElement } from "react";
import { ButtonProgressLoader, CheckOnSolanaExplorer, Row } from "./UtilComponents.fs.js";
import utils$002Emodule from "./styles/utils.module.scss";
import { singleton as singleton_2, ofArray } from "./.fable/fable-library.3.1.16/List.js";
import { empty, singleton as singleton_1, append, delay, toList } from "./.fable/fable-library.3.1.16/Seq.js";
import yield$002Emodule from "./styles/yield.module.scss";
import { size as size_1 } from "../../../lib/Domain/Types/Products.fs.js";
import { useSnackbar } from "notistack";
import { Recoil_useRecoilValue_5D3EEEEA, Recoil_useRecoilState_Z74A95619 } from "./.fable/Feliz.Recoil.0.19.2/Bindings.fs.js";
import { SetupState, completedSetup, SetupStep, solanaEnvironment, config as config_2, setup as setup_1 } from "./Context.fs.js";
import { ProviderContext__enqueueSnackbar_33B60434, EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../../../lib/Notistack/Notistack.fs.js";
import { placeOrder, checkBalanceFromUser } from "./BlockChainInteraction/ExoticProductPlaceOrder.fs.js";
import { Api } from "./Server.fs.js";
import { LocalHoldingPosition } from "../../../lib/Domain/Types/LocalPosition.fs.js";
import { ProvisionalHoldingPosition } from "../../../lib/Domain/Types/ProvisionalPosition.fs.js";
import { ClientAction } from "../../../lib/Domain/Types/ClientStats.fs.js";
import { Interop_reactApi } from "./.fable/Feliz.1.62.0/Interop.fs.js";
import card$002Emodule from "./styles/card.module.scss";
import { HoldingPosition__Size_Z17E62494, HoldingPosition } from "../../../lib/Domain/Types/HoldingPosition.fs.js";
import { utcNow, op_Subtraction as op_Subtraction_1 } from "./.fable/fable-library.3.1.16/DateOffset.js";

export class ExoticProductStatus extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Collecting", "InAuction", "BidChosen"];
    }
}

export function ExoticProductStatus$reflection() {
    return union_type("ExoticProductUtils.ExoticProductStatus", [], ExoticProductStatus, () => [[], [["Item", array_type(class_type("System.UInt64"))]], [["Item1", array_type(class_type("System.UInt64"))], ["Item2", class_type("System.UInt64")]]]);
}

export function ExoticProductStatus_GetStatus_57719730(holding) {
    const matchValue = holding.PossibleOrderDirection;
    switch (matchValue.tag) {
        case 1: {
            const matchValue_2 = [holding.Strike, holding.BidderDepositAmount];
            if (matchValue_2[0] != null) {
                if (matchValue_2[1] == null) {
                    const k_3 = matchValue_2[0];
                    const bidAmount_3 = holding.TotalBuyAmount;
                    if (!equals(bidAmount_3, fromInteger(0, true, 2))) {
                        return new ExoticProductStatus(2, k_3, bidAmount_3);
                    }
                    else {
                        return new ExoticProductStatus(1, k_3);
                    }
                }
                else {
                    const d_1 = matchValue_2[1];
                    const k_2 = matchValue_2[0];
                    const bidAmount_2 = holding.TotalBuyAmount;
                    if (!equals(bidAmount_2, fromInteger(0, true, 2))) {
                        return new ExoticProductStatus(2, k_2, op_Subtraction(bidAmount_2, d_1));
                    }
                    else {
                        return new ExoticProductStatus(1, k_2);
                    }
                }
            }
            else {
                return new ExoticProductStatus(0);
            }
        }
        case 2: {
            throw (new Error("No BuyAndSell products available"));
        }
        default: {
            const matchValue_1 = [holding.Strike, holding.BidderDepositAmount];
            if (matchValue_1[0] != null) {
                if (matchValue_1[1] == null) {
                    const k_1 = matchValue_1[0];
                    const bidAmount_1 = holding.TotalSellAmount;
                    if (!equals(bidAmount_1, fromInteger(0, true, 2))) {
                        return new ExoticProductStatus(2, k_1, bidAmount_1);
                    }
                    else {
                        return new ExoticProductStatus(1, k_1);
                    }
                }
                else {
                    const d = matchValue_1[1];
                    const k = matchValue_1[0];
                    const bidAmount = holding.TotalSellAmount;
                    if (!equals(bidAmount, fromInteger(0, true, 2))) {
                        return new ExoticProductStatus(2, k, op_Subtraction(bidAmount, d));
                    }
                    else {
                        return new ExoticProductStatus(1, k);
                    }
                }
            }
            else {
                return new ExoticProductStatus(0);
            }
        }
    }
}

export function ExoticProductStatus_ExtimatedOrFinalYield_57719730(holding) {
    const matchValue = ExoticProductStatus_GetStatus_57719730(holding);
    switch (matchValue.tag) {
        case 1: {
            return "Estimated Yield";
        }
        case 2: {
            return "Final Yield";
        }
        default: {
            return "Estimated Yield";
        }
    }
}

export function ExoticProductStatus_RelativeAbsoluteStrike_Z25B397FB(holding, product) {
    const matchValue = ExoticProductStatus_GetStatus_57719730(holding);
    switch (matchValue.tag) {
        case 1: {
            return ["Fixed Strike", map((nb) => convertUInt64ToPercent(nb), matchValue.fields[0], Float64Array)];
        }
        case 2: {
            return ["Fixed Strike", map((nb_1) => convertUInt64ToPercent(nb_1), matchValue.fields[0], Float64Array)];
        }
        default: {
            return ["Relative Strike", map((value) => toNumber(value), SpecificProduct__GetStrikes(product.SpecificProduct), Float64Array)];
        }
    }
}

export function formatString(depositToken, value) {
    if (depositToken.address === "So11111111111111111111111111111111111111112") {
        return toText(printf("%M"))(value);
    }
    else {
        return toText(printf("%M"))(value);
    }
}

export function setExoticProductMax(connection, walletCtx, setMaxLimit, holding, depositToken, buyAmount, setMaxAmount) {
    return singleton.Delay(() => {
        const floorDecimal = (lessDigit, value) => {
            const multiplier = pow(fromParts(10, 0, 0, false, 0), depositToken.decimals - lessDigit);
            return op_Division(floor(op_Multiply(value, multiplier)), multiplier);
        };
        const multiplier_1 = new Decimal(Math.pow(10, depositToken.decimals));
        const matchValue = walletCtx.publicKey;
        if (matchValue == null) {
            setMaxAmount(fromParts(0, 0, 0, false, 0));
            setMaxLimit("0");
            return singleton.Zero();
        }
        else {
            const walletPublicKey = matchValue;
            const maxBalance = holding.MaxDepositPerUser;
            let maxProductDeposit;
            const matchValue_1 = holding.MaxTotalDeposit;
            maxProductDeposit = ((matchValue_1 == null) ? fromBits(4294967295, 4294967295, true) : matchValue_1);
            let remainingAmount;
            const matchValue_2 = holding.PossibleOrderDirection;
            switch (matchValue_2.tag) {
                case 1: {
                    remainingAmount = op_Subtraction(maxProductDeposit, holding.TotalSellAmount);
                    break;
                }
                case 2: {
                    throw (new Error("No BuyAndSell products available"));
                    break;
                }
                default: {
                    remainingAmount = op_Subtraction(maxProductDeposit, holding.TotalBuyAmount);
                }
            }
            return singleton.Bind(getBalance(connection, walletPublicKey), (_arg1) => {
                const userLamportsBalance = _arg1;
                return singleton.Bind(getAssociatedTokenAddress(publicKeyBackEndToSolana(Holding__GetDepositMint(holding)), walletPublicKey), (_arg2) => {
                    const isNative = depositToken.address === "So11111111111111111111111111111111111111112";
                    if (maxBalance == null) {
                        setMaxAmount(fromParts(0, 0, 0, false, 0));
                        setMaxLimit("0");
                        return singleton.Zero();
                    }
                    else {
                        const minOrderProductRemaining = min((x, y) => compare(x, y), remainingAmount, op_Subtraction(maxBalance, buyAmount));
                        return singleton.TryWith(singleton.Delay(() => singleton.Bind(getTokenAccountBalance(connection, _arg2), (_arg3) => {
                            const userTokenAmount = _arg3;
                            const amountPossible = min((x_1, y_1) => compare(x_1, y_1), minOrderProductRemaining, isNative ? fromNumber(((value_18(userTokenAmount.value.uiAmount) * Math.pow(10, depositToken.decimals)) + userLamportsBalance) - 10000000, true) : fromNumber(value_18(userTokenAmount.value.uiAmount) * Math.pow(10, depositToken.decimals), true));
                            const amtbal = floorDecimal(2, op_Division(new Decimal(toNumber(amountPossible)), multiplier_1));
                            setMaxAmount(amtbal);
                            setMaxLimit(formatString(depositToken, amtbal));
                            return singleton.Zero();
                        })), (_arg4) => {
                            if (isNative) {
                                const amountPossible_1 = min((x_3, y_3) => compare(x_3, y_3), minOrderProductRemaining, fromNumber(max_1((x_2, y_2) => comparePrimitives(x_2, y_2), 0, userLamportsBalance - 10000000), true));
                                const amtbal_1 = floorDecimal(2, op_Division(new Decimal(toNumber(amountPossible_1)), multiplier_1));
                                setMaxAmount(amtbal_1);
                                setMaxLimit(formatString(depositToken, amtbal_1));
                                return singleton.Zero();
                            }
                            else {
                                setMaxAmount(fromParts(0, 0, 0, false, 0));
                                setMaxLimit("0");
                                return singleton.Zero();
                            }
                        });
                    }
                });
            });
        }
    });
}

export function ExoticProductAmountAndMaxButton(exoticProductAmountAndMaxButtonInputProps) {
    const largeFont = exoticProductAmountAndMaxButtonInputProps.largeFont;
    const isProcessing = exoticProductAmountAndMaxButtonInputProps.isProcessing;
    const buyAmount = exoticProductAmountAndMaxButtonInputProps.buyAmount;
    const depositToken = exoticProductAmountAndMaxButtonInputProps.depositToken;
    const holding = exoticProductAmountAndMaxButtonInputProps.holding;
    const setMaxLimit = exoticProductAmountAndMaxButtonInputProps.setMaxLimit;
    const setAmount = exoticProductAmountAndMaxButtonInputProps.setAmount;
    const amount = exoticProductAmountAndMaxButtonInputProps.amount;
    const connection = exoticProductAmountAndMaxButtonInputProps.connection;
    const patternInput = useFeliz_React__React_useState_Static_1505(new Decimal(0));
    const maxAmount = patternInput[0];
    const walletCtx = useWallet();
    const multiplier_1 = new Decimal(Math.pow(10, depositToken.decimals));
    const minAmount = op_Division(new Decimal(toNumber(holding.MinDepositPerUser)), multiplier_1);
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(setExoticProductMax(connection, walletCtx, setMaxLimit, holding, depositToken, buyAmount, patternInput[1]));
    }, [walletCtx, isProcessing]);
    const minAmountCallback = useReact_useCallback_93353E((arg_1) => {
        startImmediate(singleton.Delay(() => {
            let copyOfStruct;
            const matchValue = walletCtx.publicKey;
            if (matchValue == null) {
                console.error(some("No Wallet Public Key Found."));
                setAmount("0");
                return singleton.Zero();
            }
            else {
                const publicKey = matchValue;
                const minBalance = op_Division(new Decimal(toNumber(holding.MinDepositPerUser)), multiplier_1);
                setAmount((copyOfStruct = arg_1, toString(copyOfStruct)));
                return singleton.Zero();
            }
        }));
    }, [[walletCtx, amount]]);
    return createElement(Row, {
        classes: ofArray([utils$002Emodule["space-between"], utils$002Emodule["margin-s-bottom"]]),
        children: toList(delay(() => append(largeFont ? singleton_1(createElement(Row, {
            classes: ofArray([utils$002Emodule["boldness-500"], utils$002Emodule["text-xs"]]),
            children: singleton_2("Amount to Deposit"),
        })) : singleton_1(createElement(Row, {
            classes: ofArray([utils$002Emodule["boldness-500"], utils$002Emodule["text-xs"]]),
            children: singleton_2("Amount to Deposit"),
        })), delay(() => append(singleton_1(createElement("a", {
            className: join(" ", [utils$002Emodule["text-xs"]]),
            children: toText(interpolate("Min %P()", [minAmount])),
        })), delay(() => singleton_1(createElement("a", {
            className: join(" ", [yield$002Emodule.max]),
            id: "max-button",
            children: toText(interpolate("Max %P()", [formatString(depositToken, maxAmount)])),
            onClick: (_arg2) => {
                const formatMaxAmount = formatString(depositToken, maxAmount);
                setAmount(formatMaxAmount);
                setMaxLimit(formatMaxAmount);
            },
        })))))))),
    });
}

export function ExoticProductAmountAndMaxButton2(exoticProductAmountAndMaxButton2InputProps) {
    const isProcessing = exoticProductAmountAndMaxButton2InputProps.isProcessing;
    const buyAmount = exoticProductAmountAndMaxButton2InputProps.buyAmount;
    const depositToken = exoticProductAmountAndMaxButton2InputProps.depositToken;
    const holding = exoticProductAmountAndMaxButton2InputProps.holding;
    const setMaxLimit = exoticProductAmountAndMaxButton2InputProps.setMaxLimit;
    const setAmount = exoticProductAmountAndMaxButton2InputProps.setAmount;
    const amount = exoticProductAmountAndMaxButton2InputProps.amount;
    const connection = exoticProductAmountAndMaxButton2InputProps.connection;
    const patternInput = useFeliz_React__React_useState_Static_1505(new Decimal(0));
    const walletCtx = useWallet();
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(setExoticProductMax(connection, walletCtx, setMaxLimit, holding, depositToken, buyAmount, patternInput[1]));
    }, [walletCtx, isProcessing]);
    return createElement(Row, {
        classes: ofArray([utils$002Emodule["space-between"], utils$002Emodule["margin-s-bottom"]]),
        children: ofArray([createElement(Row, {
            classes: ofArray([utils$002Emodule["boldness-600"], utils$002Emodule["text-s"]]),
            children: singleton_2("Amount to Deposit."),
        }), createElement("a", {
            className: join(" ", [yield$002Emodule.max]),
            id: "max-button",
            children: toText(interpolate("Max.", [])),
            onClick: (_arg2) => {
                const formatMaxAmount = formatString(depositToken, patternInput[0]);
                setAmount(formatMaxAmount);
                setMaxLimit(formatMaxAmount);
            },
        })]),
    });
}

export function ExoticProductDepositButton(exoticProductDepositButtonInputProps) {
    let value_2;
    const callback = exoticProductDepositButtonInputProps.callback;
    const maxLimit = exoticProductDepositButtonInputProps.maxLimit;
    const setProcessing = exoticProductDepositButtonInputProps.setProcessing;
    const processing = exoticProductDepositButtonInputProps.processing;
    const depositToken = exoticProductDepositButtonInputProps.depositToken;
    const amount = exoticProductDepositButtonInputProps.amount;
    const holding = exoticProductDepositButtonInputProps.holding;
    const patternInput = useFeliz_React__React_useState_Static_1505(new Decimal(0));
    const isAboveMax = () => (compare_1((amount == null) ? fromParts(0, 0, 0, false, 0) : amount, new Decimal(maxLimit)) > 0);
    const isAboveOrderMax = () => (compare_1((amount == null) ? fromParts(0, 0, 0, false, 0) : amount, size_1(holding.MaxDepositPerUser, depositToken.decimals)) > 0);
    const isBelowMin = () => (compare_1((amount == null) ? fromParts(0, 0, 0, false, 0) : amount, size_1(holding.MinDepositPerUser, depositToken.decimals)) < 0);
    const snackbar = useSnackbar();
    const walletCtx = useWallet();
    const connection = useConnection().connection;
    const patternInput_1 = Recoil_useRecoilState_Z74A95619(setup_1);
    const setup = patternInput_1[0];
    const config = Recoil_useRecoilValue_5D3EEEEA(config_2);
    const environment = Recoil_useRecoilValue_5D3EEEEA(solanaEnvironment);
    const setupMode = equals_1(setup.Step, new SetupStep(2)) ? true : equals_1(setup.Step, new SetupStep(3));
    useReact_useEffectOnce_3A5B6456(() => {
        if (completedSetup === false) {
            patternInput_1[1](new SetupState(new SetupStep(1), setup.Amount, setup.ConfirmedDeposit, setup.Positions));
        }
    });
    const sendTransactionCallback = useReact_useCallback_93353E(() => {
        startImmediate(singleton.Delay(() => {
            let matchValue, amt_3, publicKey;
            return singleton.Combine((matchValue = [walletCtx.publicKey, amount], (matchValue[0] == null) ? (ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "No Wallet Public Key Found.", SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(0, false)), singleton.Zero()) : ((matchValue[1] == null) ? (ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Not a valid amount", SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(0, false)), singleton.Zero()) : (amt_3 = matchValue[1], (publicKey = matchValue[0], (compare_1(amt_3, fromParts(0, 0, 0, false, 1)) > 0) ? (console.log(some("deposit amount: "), amt_3), singleton.Bind(checkBalanceFromUser(connection, publicKey, amt_3, holding, depositToken), (_arg1) => {
                const matchValue_1 = [_arg1, config];
                const copyOfStruct = matchValue_1[0];
                if (copyOfStruct.tag === 1) {
                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, copyOfStruct.fields[0], SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(0, false));
                    return singleton.Zero();
                }
                else if (matchValue_1[1] == null) {
                    console.log(some("No config found."));
                    return singleton.Zero();
                }
                else {
                    const config_1 = matchValue_1[1];
                    return singleton.Bind(Api.AddLocalHoldingPosition(environment, new LocalHoldingPosition(new PublicKey(0, publicKey.toBase58()), holding.PublicKey)), () => {
                        setProcessing(true);
                        return singleton.Bind(placeOrder(connection, new PublicKey(0, config_1.HoldingProgramId), publicKey, walletCtx, amt_3, holding, depositToken), (_arg3) => {
                            const result = _arg3;
                            const copyOfStruct_1 = result[0];
                            if (copyOfStruct_1.tag === 1) {
                                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, copyOfStruct_1.fields[0], SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(0, false));
                                return singleton.Zero();
                            }
                            else {
                                return singleton.Bind(Api.AddProvisionalHoldingPosition(environment, new ProvisionalHoldingPosition(holding.PublicKey, new PublicKey(0, publicKey.toBase58()), holding.PublicKey, fromValue(result[1], true), fromInteger(0, true, 2), holding.Bump, copyOfStruct_1.fields[0])), () => singleton.Bind(Api.AddClientStats(new ClientAction(0), new PublicKey(0, publicKey.toBase58()), copyOfStruct_1.fields[0]), () => {
                                    let msg;
                                    const children = ofArray([createElement("p", {
                                        children: ["Transaction has been sent. The order will show up with your orders shortly."],
                                    }), createElement(CheckOnSolanaExplorer, {
                                        signature: copyOfStruct_1.fields[0],
                                    })]);
                                    msg = createElement("div", {
                                        children: Interop_reactApi.Children.toArray(Array.from(children)),
                                    });
                                    callback([copyOfStruct_1.fields[0], result[1]]);
                                    ProviderContext__enqueueSnackbar_33B60434(snackbar, msg, SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(0, false));
                                    return singleton.Zero();
                                }));
                            }
                        });
                    });
                }
            })) : (ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Amount is ZERO", SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(0, false)), singleton.Zero()))))), singleton.Delay(() => singleton.Bind(sleep(1500), () => {
                setProcessing(false);
                return singleton.Zero();
            })));
        }));
    }, [walletCtx, amount, environment]);
    return createElement("div", createObj(ofArray([(value_2 = card$002Emodule["start-earning-yield"], ["className", value_2]), ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        if (walletCtx.publicKey != null) {
            return singleton_1(createElement("button", {
                className: join(" ", ["button-green", utils$002Emodule["margin-s-top"]]),
                children: Interop_reactApi.Children.toArray([createElement(Row, {
                    classes: singleton_2(utils$002Emodule.center),
                    children: toList(delay(() => append(setupMode ? singleton_1("Confirm Deposit") : (((!setupMode) ? (compare_1(holding.Remaining, fromParts(0, 0, 0, false, 1)) <= 0) : false) ? singleton_1((ExoticProductStatus_GetStatus_57719730(holding).tag === 0) ? "Product Is Already Full" : "Deposit Ended") : ((((!setupMode) ? isAboveMax() : false) ? (!isAboveOrderMax()) : false) ? singleton_1((ExoticProductStatus_GetStatus_57719730(holding).tag === 0) ? "Insufficient Balance" : "Deposit Ended") : ((((!setupMode) ? isAboveMax() : false) ? isAboveOrderMax() : false) ? singleton_1((ExoticProductStatus_GetStatus_57719730(holding).tag === 0) ? "Amount Exceeds Deposit Per User" : "Deposit Ended") : (((!setupMode) ? isBelowMin() : false) ? singleton_1((ExoticProductStatus_GetStatus_57719730(holding).tag === 0) ? toText(interpolate("Below Minimum Deposit", [])) : "Deposit Ended") : singleton_1((ExoticProductStatus_GetStatus_57719730(holding).tag === 0) ? "Confirm Deposit" : "Deposit Ended"))))), delay(() => (processing ? singleton_1(createElement(ButtonProgressLoader, {
                        classes: singleton_2("progress-white"),
                    })) : empty()))))),
                })]),
                disabled: (((((amount == null) ? true : processing) ? true : (compare_1(holding.Remaining, fromParts(0, 0, 0, false, 1)) <= 0)) ? true : isAboveMax()) ? true : isBelowMin()) ? true : ((ExoticProductStatus_GetStatus_57719730(holding).tag === 0) ? (compare_1(holding.Remaining, fromParts(0, 0, 0, false, 1)) <= 0) : (true)),
                onClick: (_arg2_1) => {
                    if (setupMode) {
                        const position = new HoldingPosition(new PublicKey(0, ""), new PublicKey(0, ""), new PublicKey(0, ""), fromNumber(toNumber_1(op_Multiply(new Decimal(setup.Amount), new Decimal(Math.pow(10, depositToken.decimals)))), true), fromBits(0, 0, true), 0 & 0xFF);
                        const duration = op_Subtraction_1(holding.EndCollection, utcNow());
                        const size = HoldingPosition__Size_Z17E62494(position, holding, depositToken.decimals);
                    }
                    else {
                        sendTransactionCallback();
                    }
                },
            }));
        }
        else {
            return empty();
        }
    }))))]])));
}

