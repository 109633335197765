import { Record } from "./.fable/fable-library.3.1.16/Types.js";
import { array_type, record_type, class_type } from "./.fable/fable-library.3.1.16/Reflection.js";
import { singleton } from "./.fable/fable-library.3.1.16/AsyncBuilder.js";
import { exists, filter, empty, choose } from "./.fable/fable-library.3.1.16/List.js";
import { singleton as singleton_1, collect, delay, toList } from "./.fable/fable-library.3.1.16/Seq.js";
import { fetchInjectiveToken, fetchToken } from "../../../lib/Solana/Utils.fs.js";
import { PublicKey_Value_Z1B21ACD5, PublicKey } from "../../../lib/Domain/Types/Common.fs.js";
import { FSharpResult$2 } from "./.fable/fable-library.3.1.16/Choice.js";
import { some } from "./.fable/fable-library.3.1.16/Option.js";
import { Log_developmentError } from "./Extensions.fs.js";
import { sortBy, tryFind, map, choose as choose_1 } from "./.fable/fable-library.3.1.16/Array.js";
import { addDays, utcNow } from "./.fable/fable-library.3.1.16/DateOffset.js";
import { InjectiveEscrowData, InjectiveHoldingProductOptionRfq } from "../../shared/SignalRRemoting.fs.js";
import { compare } from "./.fable/fable-library.3.1.16/Date.js";
import { InjectiveHideProduct__IsHideProduct } from "../../../lib/Domain/Types/InjectiveProductFilter.fs.js";
import { InjectiveProduct__GetKind } from "../../../lib/Domain/Types/InjectiveProduct.fs.js";
import { compare as compare_1, fromNumber } from "./.fable/fable-library.3.1.16/Long.js";
import { Escrow__GetExpirationInt64 } from "../../../lib/Domain/Types/InjectiveEscrow.fs.js";
import { comparePrimitives } from "./.fable/fable-library.3.1.16/Util.js";
import { get_Zero, equals } from "./.fable/fable-library.3.1.16/BigInt.js";
import { toNumber } from "./.fable/fable-library.3.1.16/Decimal.js";
import { printf, toConsole } from "./.fable/fable-library.3.1.16/String.js";

export class ProductTokens extends Record {
    constructor(BaseToken, QuoteToken, DepositMintBuy, DepositMintSell) {
        super();
        this.BaseToken = BaseToken;
        this.QuoteToken = QuoteToken;
        this.DepositMintBuy = DepositMintBuy;
        this.DepositMintSell = DepositMintSell;
    }
}

export function ProductTokens$reflection() {
    return record_type("HoldingData.ProductTokens", [], ProductTokens, () => [["BaseToken", class_type("Token.TokenInfo")], ["QuoteToken", class_type("Token.TokenInfo")], ["DepositMintBuy", class_type("Token.TokenInfo")], ["DepositMintSell", class_type("Token.TokenInfo")]]);
}

export class ProductDataTokens extends Record {
    constructor(Deposit, Spot, ProductPairLeft, ProductPairRight) {
        super();
        this.Deposit = Deposit;
        this.Spot = Spot;
        this.ProductPairLeft = ProductPairLeft;
        this.ProductPairRight = ProductPairRight;
    }
}

export function ProductDataTokens$reflection() {
    return record_type("HoldingData.ProductDataTokens", [], ProductDataTokens, () => [["Deposit", class_type("Token.TokenInfo")], ["Spot", class_type("Token.TokenInfo")], ["ProductPairLeft", class_type("Token.TokenInfo")], ["ProductPairRight", class_type("Token.TokenInfo")]]);
}

export class ExoticProductTokens extends Record {
    constructor(Deposit, Underlying) {
        super();
        this.Deposit = Deposit;
        this.Underlying = Underlying;
    }
}

export function ExoticProductTokens$reflection() {
    return record_type("HoldingData.ExoticProductTokens", [], ExoticProductTokens, () => [["Deposit", class_type("Token.TokenInfo")], ["Underlying", array_type(class_type("Token.TokenInfo"))]]);
}

export function processFlexProductData(data, tokens) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Return(new FSharpResult$2(0, choose((x) => {
        if (x.tag === 1) {
            return void 0;
        }
        else {
            return x.fields[0];
        }
    }, toList(delay(() => collect((product) => {
        const matchValue = [fetchToken(product.CollateralMint, tokens), fetchToken(product.UnderlyingMint, tokens), fetchToken(product.UnderlyingMint, tokens), fetchToken(new PublicKey(0, "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v"), tokens)];
        let pattern_matching_result, deposit, productPairLeft_1, productPairRight_1, spot;
        if (matchValue[0] != null) {
            if (matchValue[1] != null) {
                if (matchValue[2] != null) {
                    if (matchValue[3] != null) {
                        pattern_matching_result = 0;
                        deposit = matchValue[0];
                        productPairLeft_1 = matchValue[2];
                        productPairRight_1 = matchValue[3];
                        spot = matchValue[1];
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                return singleton_1(new FSharpResult$2(0, [product, new ProductDataTokens(deposit, spot, productPairLeft_1, productPairRight_1)]));
            }
            case 1: {
                console.log(some("One or more tokens can\u0027t be found from the registry"));
                return singleton_1(new FSharpResult$2(1, "One or more tokens can\u0027t be found from the registry"));
            }
        }
    }, data))))))), (_arg1) => {
        Log_developmentError(_arg1);
        return singleton.Return(new FSharpResult$2(1, "Error occured while retrieving token information from Solana registry"));
    }));
}

export function processExoticProductData(data, tokens) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Return(new FSharpResult$2(0, choose((x_1) => {
        if (x_1.tag === 1) {
            return void 0;
        }
        else {
            return x_1.fields[0];
        }
    }, toList(delay(() => collect((product) => {
        const deposit = fetchToken(product.Product.CommonData.DepositBuyMint, tokens);
        const underlying = choose_1((x) => x, map((u) => fetchToken(u.BaseMint, tokens), product.Underlying));
        if (deposit == null) {
            console.error(some("One or more tokens can\u0027t be found from the registry: "), PublicKey_Value_Z1B21ACD5(product.Product.CommonData.DepositBuyMint));
            return singleton_1(new FSharpResult$2(1, "One or more tokens can\u0027t be found from the registry"));
        }
        else {
            return singleton_1(new FSharpResult$2(0, [product, new ExoticProductTokens(deposit, underlying)]));
        }
    }, data))))))), (_arg1) => {
        Log_developmentError(_arg1);
        return singleton.Return(new FSharpResult$2(1, "Error occured while retrieving token information from Solana registry"));
    }));
}

export function processInjectiveHoldingProductData(holdings, products, tokens, checkBeforeEndDeposit, excludedProducts) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        const now = utcNow();
        const data = map((tupledArg_2) => {
            const holding = tupledArg_2[0];
            return new InjectiveHoldingProductOptionRfq(holding, products.find((p) => (p.Id === holding.ProductId)), tupledArg_2[1], tupledArg_2[2]);
        }, holdings.filter((tupledArg) => {
            const h = tupledArg[0];
            if (checkBeforeEndDeposit) {
                if (compare(h.EndCollection, now) > 0) {
                    return compare(h.StartCollection, now) <= 0;
                }
                else {
                    return false;
                }
            }
            else {
                return !checkBeforeEndDeposit;
            }
        }).filter((tupledArg_1) => {
            const h_1 = tupledArg_1[0];
            if (checkBeforeEndDeposit) {
                return tryFind((f) => InjectiveHideProduct__IsHideProduct(f, h_1.Id, h_1.ProductId), excludedProducts) == null;
            }
            else {
                return !checkBeforeEndDeposit;
            }
        }));
        return singleton.Return(new FSharpResult$2(0, choose((x) => {
            if (x.tag === 1) {
                return void 0;
            }
            else {
                return x.fields[0];
            }
        }, toList(delay(() => collect((product_1) => {
            let matchValue;
            const matchValue_1 = [fetchInjectiveToken((matchValue = InjectiveProduct__GetKind(product_1.Product), (matchValue.tag === 1) ? product_1.Product.QuoteDenom : product_1.Product.BaseDenom), tokens), fetchInjectiveToken(product_1.Product.BaseDenom, tokens), fetchInjectiveToken(product_1.Product.BaseDenom, tokens), fetchInjectiveToken(product_1.Product.QuoteDenom, tokens)];
            let pattern_matching_result, deposit, productPairLeft_1, productPairRight_1, spot;
            if (matchValue_1[0] != null) {
                if (matchValue_1[1] != null) {
                    if (matchValue_1[2] != null) {
                        if (matchValue_1[3] != null) {
                            pattern_matching_result = 0;
                            deposit = matchValue_1[0];
                            productPairLeft_1 = matchValue_1[2];
                            productPairRight_1 = matchValue_1[3];
                            spot = matchValue_1[1];
                        }
                        else {
                            pattern_matching_result = 1;
                        }
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return singleton_1(new FSharpResult$2(0, [product_1, new ProductDataTokens(deposit, spot, productPairLeft_1, productPairRight_1)]));
                }
                case 1: {
                    return singleton_1(new FSharpResult$2(1, "One or more tokens can\u0027t be found from the registry"));
                }
            }
        }, data))))));
    }), (_arg1_1) => {
        Log_developmentError(_arg1_1);
        return singleton.Return(new FSharpResult$2(0, empty()));
    }));
}

export function processInjectiveEscrowData(data, tokens, checkBeforeEndDeposit) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        const now = utcNow();
        return singleton.Return(new FSharpResult$2(0, choose((x) => {
            if (x.tag === 1) {
                return void 0;
            }
            else {
                return x.fields[0];
            }
        }, toList(delay(() => collect((d) => {
            let matchValue;
            const matchValue_1 = [fetchInjectiveToken((matchValue = InjectiveProduct__GetKind(d.Product.Product), (matchValue.tag === 1) ? d.Product.Product.QuoteDenom : d.Product.Product.BaseDenom), tokens), fetchInjectiveToken(d.Product.Product.BaseDenom, tokens), fetchInjectiveToken(d.Product.Product.BaseDenom, tokens), fetchInjectiveToken(d.Product.Product.QuoteDenom, tokens)];
            let pattern_matching_result, deposit, productPairLeft_1, productPairRight_1, spot;
            if (matchValue_1[0] != null) {
                if (matchValue_1[1] != null) {
                    if (matchValue_1[2] != null) {
                        if (matchValue_1[3] != null) {
                            pattern_matching_result = 0;
                            deposit = matchValue_1[0];
                            productPairLeft_1 = matchValue_1[2];
                            productPairRight_1 = matchValue_1[3];
                            spot = matchValue_1[1];
                        }
                        else {
                            pattern_matching_result = 1;
                        }
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return singleton_1(new FSharpResult$2(0, [d, new ProductDataTokens(deposit, spot, productPairLeft_1, productPairRight_1)]));
                }
                case 1: {
                    return singleton_1(new FSharpResult$2(1, "One or more tokens can\u0027t be found from the registry"));
                }
            }
        }, data))))));
    }), (_arg1) => {
        Log_developmentError(_arg1);
        return singleton.Return(new FSharpResult$2(0, empty()));
    }));
}

export function processInjectiveHoldingProductDataDashboard(holdings, products) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Return(map((tupledArg) => {
        const holding = tupledArg[0];
        return new InjectiveHoldingProductOptionRfq(holding, products.find((p) => (p.Id === holding.ProductId)), tupledArg[1], tupledArg[2]);
    }, holdings))), (_arg1) => {
        Log_developmentError(_arg1);
        return singleton.Return([]);
    }));
}

export function processInjectiveEscrowDataOptionality(products, escrows) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        let now;
        let copyOfStruct = utcNow();
        now = fromNumber(copyOfStruct.getTime() / 1000);
        return singleton.Return(choose_1((x_2) => x_2, map((escrow_2) => {
            const product = tryFind((p) => (p.Holding.Id === escrow_2.HoldingId), products);
            if (product == null) {
                return void 0;
            }
            else {
                return new InjectiveEscrowData(escrow_2, product);
            }
        }, sortBy((escrow_1) => {
            const matchValue = escrow_1.BuyDenom;
            switch (matchValue) {
                case "inj": {
                    return 0;
                }
                case "peggy0xdAC17F958D2ee523a2206206994597C13D831ec7": {
                    return 1;
                }
                case "ibc/c4cff46fd6de35ca4cf4ce031e643c8fdc9ba4b99ae598e9b0ed98fe3a2319f9": {
                    return 2;
                }
                case "peggy0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2": {
                    return 3;
                }
                default: {
                    return 3;
                }
            }
        }, sortBy((escrow) => Escrow__GetExpirationInt64(escrow), escrows, {
            Compare: (x, y) => compare_1(x, y),
        }), {
            Compare: (x_1, y_1) => comparePrimitives(x_1, y_1),
        }))).filter((data) => {
            let copyOfStruct_1;
            if ((compare_1((copyOfStruct_1 = addDays(data.Product.Holding.EndCollection, 10), fromNumber(copyOfStruct_1.getTime() / 1000)), now) > 0) ? equals(data.Escrow.Balance, get_Zero) : false) {
                return true;
            }
            else {
                return compare_1(Escrow__GetExpirationInt64(data.Escrow), now) > 0;
            }
        }));
    }), (_arg1) => {
        Log_developmentError(_arg1);
        return singleton.Return([]);
    }));
}

export function processInjectiveEscrowDataDashboard(products, escrows) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Return(map((escrow_2) => (new InjectiveEscrowData(escrow_2, products.find((p) => (p.Holding.Id === escrow_2.HoldingId)))), sortBy((escrow_1) => {
        const matchValue = escrow_1.BuyDenom;
        switch (matchValue) {
            case "inj": {
                return 0;
            }
            case "peggy0xdAC17F958D2ee523a2206206994597C13D831ec7": {
                return 1;
            }
            case "ibc/c4cff46fd6de35ca4cf4ce031e643c8fdc9ba4b99ae598e9b0ed98fe3a2319f9": {
                return 2;
            }
            case "peggy0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2": {
                return 3;
            }
            default: {
                return 3;
            }
        }
    }, sortBy((escrow) => Escrow__GetExpirationInt64(escrow), escrows, {
        Compare: (x, y) => compare_1(x, y),
    }), {
        Compare: (x_1, y_1) => comparePrimitives(x_1, y_1),
    })))), (_arg1) => {
        Log_developmentError(_arg1);
        return singleton.Return([]);
    }));
}

export function processFlexProductDataWithAmount(data, tokens) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Return(new FSharpResult$2(0, choose((x) => {
        if (x.tag === 1) {
            return void 0;
        }
        else {
            return x.fields[0];
        }
    }, toList(delay(() => collect((matchValue) => {
        const product = matchValue[0];
        const underlyingToken = fetchToken(product.UnderlyingMint, tokens);
        if (underlyingToken != null) {
            return singleton_1(new FSharpResult$2(0, [product, matchValue[1], underlyingToken]));
        }
        else {
            console.log(some("One or more tokens can\u0027t be found from the registry"));
            return singleton_1(new FSharpResult$2(1, "One or more tokens can\u0027t be found from the registry"));
        }
    }, data))))))), (_arg1) => {
        Log_developmentError(_arg1);
        return singleton.Return(new FSharpResult$2(1, "Error occured while retrieving token information from Solana registry"));
    }));
}

export function filterHoldingProductsTimeframe(_arg1_0, _arg1_1, timeFrame_0, timeFrame_1) {
    const timeFrame = [timeFrame_0, timeFrame_1];
    const p = _arg1_0;
    const getDays = (index) => {
        switch (index) {
            case 1: {
                return 1;
            }
            case 2: {
                return 3;
            }
            case 3: {
                return 7;
            }
            case 4: {
                return 14;
            }
            case 5: {
                return 30;
            }
            case 6: {
                return 60;
            }
            case 7: {
                return 90;
            }
            case 8: {
                return 180;
            }
            default: {
                return 1;
            }
        }
    };
    const minDays = getDays(timeFrame[0]) | 0;
    const maxDays = getDays(timeFrame[1]) | 0;
    const res = ((~(~toNumber(p.Maturity))) >= ((3600 * 24) * minDays)) ? ((~(~toNumber(p.Maturity))) <= ((3600 * 24) * maxDays)) : false;
    const tupledArg = [(3600 * 24) * minDays, (3600 * 24) * maxDays];
    toConsole(printf("days %A, maturity: %f, res: %A"))([tupledArg[0], tupledArg[1]])(p.Maturity)(res);
    return res;
}

export function filterProductsByDirection(_arg1_0, _arg1_1, yieldP) {
    return true;
}

export function filterProductsBySentiment(_arg1_0, _arg1_1, sentiment_0, sentiment_1) {
    return true;
}

export function filterByRisk(_arg1_0, _arg1_1, risk_0, risk_1) {
    const risk = [risk_0, risk_1];
    const p = _arg1_0;
    if (p.RiskLevel > (risk[0] & 0xFF)) {
        return p.RiskLevel <= (risk[1] & 0xFF);
    }
    else {
        return false;
    }
}

export function filterHoldingProductsUsingAssistant(assistant, data) {
    if (assistant != null) {
        const assistant_1 = assistant;
        toConsole(printf("filtering %A"))(assistant_1);
        return filter((prod_3) => {
            const matchValue_2 = assistant_1.RiskAversion;
            if (matchValue_2 == null) {
                return false;
            }
            else {
                const tupledArg_2 = matchValue_2;
                return filterByRisk(prod_3[0], prod_3[1], tupledArg_2[0], tupledArg_2[1]);
            }
        }, filter((prod_2) => {
            const matchValue_1 = assistant_1.MarketSentiment;
            if (matchValue_1 == null) {
                return true;
            }
            else {
                const tupledArg_1 = matchValue_1;
                return filterProductsBySentiment(prod_2[0], prod_2[1], tupledArg_1[0], tupledArg_1[1]);
            }
        }, filter((prod_1) => exists((yieldP) => filterProductsByDirection(prod_1[0], prod_1[1], yieldP), assistant_1.YieldPreference), filter((prod) => {
            const matchValue = assistant_1.InvestmentTimeframe;
            if (matchValue == null) {
                return false;
            }
            else {
                const tupledArg = matchValue;
                return filterHoldingProductsTimeframe(prod[0], prod[1], tupledArg[0], tupledArg[1]);
            }
        }, data))));
    }
    else {
        return data;
    }
}

