import { Record, Union } from "../../fable-library.3.1.16/Types.js";
import { unit_type, record_type, bool_type, option_type, class_type, lambda_type, obj_type, union_type, string_type } from "../../fable-library.3.1.16/Reflection.js";

export class SelectorState_Key extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Value"];
    }
}

export function SelectorState_Key$reflection() {
    return union_type("Feliz.Recoil.SelectorCE.SelectorState.Key", [], SelectorState_Key, () => [[["Item", string_type]]]);
}

export class SelectorState_ReadOnly$2 extends Record {
    constructor(Key, Get, Cache, DangerouslyAllowMutability) {
        super();
        this.Key = Key;
        this.Get = Get;
        this.Cache = Cache;
        this.DangerouslyAllowMutability = DangerouslyAllowMutability;
    }
    SetCache(cache) {
        const this$ = this;
        return new SelectorState_ReadOnly$2(this$.Key, this$.Get, cache, this$.DangerouslyAllowMutability);
    }
    SetMutability() {
        const this$ = this;
        return new SelectorState_ReadOnly$2(this$.Key, this$.Get, this$.Cache, true);
    }
}

export function SelectorState_ReadOnly$2$reflection(gen0, gen1) {
    return record_type("Feliz.Recoil.SelectorCE.SelectorState.ReadOnly`2", [gen0, gen1], SelectorState_ReadOnly$2, () => [["Key", string_type], ["Get", lambda_type(obj_type, gen1)], ["Cache", option_type(class_type("Feliz.Recoil.CacheImplementation`2", [gen0, obj_type]))], ["DangerouslyAllowMutability", option_type(bool_type)]]);
}

export class SelectorState_ReadWrite$2 extends Record {
    constructor(Key, Get, Set$, Cache, DangerouslyAllowMutability) {
        super();
        this.Key = Key;
        this.Get = Get;
        this.Set = Set$;
        this.Cache = Cache;
        this.DangerouslyAllowMutability = DangerouslyAllowMutability;
    }
    SetCache(cache) {
        const this$ = this;
        return new SelectorState_ReadWrite$2(this$.Key, this$.Get, this$.Set, cache, this$.DangerouslyAllowMutability);
    }
    SetMutability() {
        const this$ = this;
        return new SelectorState_ReadWrite$2(this$.Key, this$.Get, this$.Set, this$.Cache, true);
    }
}

export function SelectorState_ReadWrite$2$reflection(gen0, gen1) {
    return record_type("Feliz.Recoil.SelectorCE.SelectorState.ReadWrite`2", [gen0, gen1], SelectorState_ReadWrite$2, () => [["Key", string_type], ["Get", lambda_type(obj_type, gen1)], ["Set", lambda_type(obj_type, lambda_type(gen0, unit_type))], ["Cache", option_type(class_type("Feliz.Recoil.CacheImplementation`2", [gen0, obj_type]))], ["DangerouslyAllowMutability", option_type(bool_type)]]);
}

export class SelectorState_WriteOnly$1 extends Record {
    constructor(Key, Set$, DangerouslyAllowMutability) {
        super();
        this.Key = Key;
        this.Set = Set$;
        this.DangerouslyAllowMutability = DangerouslyAllowMutability;
    }
    SetMutability() {
        const this$ = this;
        return new SelectorState_WriteOnly$1(this$.Key, this$.Set, true);
    }
}

export function SelectorState_WriteOnly$1$reflection(gen0) {
    return record_type("Feliz.Recoil.SelectorCE.SelectorState.WriteOnly`1", [gen0], SelectorState_WriteOnly$1, () => [["Key", string_type], ["Set", lambda_type(obj_type, lambda_type(gen0, unit_type))], ["DangerouslyAllowMutability", option_type(bool_type)]]);
}

export class SelectorBuilder {
    constructor() {
    }
}

export function SelectorBuilder$reflection() {
    return class_type("Feliz.Recoil.SelectorCE.SelectorBuilder", void 0, SelectorBuilder);
}

export function SelectorBuilder_$ctor() {
    return new SelectorBuilder();
}

export function SelectorBuilder__Yield_1505(_, _arg1) {
    return void 0;
}

export function SelectorBuilder__Key_44181178(_, state, value) {
    return new SelectorState_Key(0, value);
}

export const selector = SelectorBuilder_$ctor();

