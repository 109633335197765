import { isNullOrWhiteSpace, interpolate, toText, isNullOrEmpty } from "./.fable/fable-library.3.1.16/String.js";
import { some } from "./.fable/fable-library.3.1.16/Option.js";
import { Cmd_ofSub } from "./.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { catchAsync, startImmediate } from "./.fable/fable-library.3.1.16/Async.js";
import { singleton } from "./.fable/fable-library.3.1.16/AsyncBuilder.js";
import { PublicKey } from "../../../lib/Domain/Types/Common.fs.js";

export const isDevelopment = false;

export const websocketAddress = isDevelopment ? "http://localhost:5000/signalr" : (isNullOrEmpty(window.location.port) ? toText(interpolate("%P()//%P()/signalr", [window.location.protocol, window.location.hostname])) : toText(interpolate("%P()//%P():%P()/signalr", [window.location.protocol, window.location.hostname, window.location.port])));

export function Log_developmentError(error) {
    if (isDevelopment) {
        console.error(some(error));
    }
}

export function Cmd_fromAsync(operation) {
    return Cmd_ofSub((dispatch) => {
        startImmediate(singleton.Delay(() => singleton.Bind(catchAsync(operation), (_arg1) => {
            if (_arg1.tag === 1) {
                Log_developmentError(_arg1.fields[0]);
                return singleton.Zero();
            }
            else {
                dispatch(_arg1.fields[0]);
                return singleton.Zero();
            }
        })));
    });
}

export function Config_variableOrDefault(key, defaultValue) {
    const foundValue = process.env[key] ? process.env[key] : '';
    if (isNullOrWhiteSpace(foundValue)) {
        return defaultValue;
    }
    else {
        return foundValue;
    }
}

export function Route_$007CPublicKey$007C_$007C(input) {
    return new PublicKey(0, input);
}

