import { Record } from "../.fable/fable-library.3.1.16/Types.js";
import { record_type, string_type } from "../.fable/fable-library.3.1.16/Reflection.js";
import { createElement } from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.62.0/Interop.fs.js";
import { map, concat } from "../.fable/fable-library.3.1.16/Array.js";
import { ExoticLogo } from "./Icon/ExoticLogo.fs.js";
import { singleton, append, delay, toList } from "../.fable/fable-library.3.1.16/Seq.js";

export class LinkContent extends Record {
    constructor(Content, Link) {
        super();
        this.Content = Content;
        this.Link = Link;
    }
}

export function LinkContent$reflection() {
    return record_type("Components.Footer.LinkContent", [], LinkContent, () => [["Content", string_type], ["Link", string_type]]);
}

export function Link(linkInputProps) {
    const link = linkInputProps.link;
    const content = linkInputProps.content;
    return createElement("li", {
        className: "transition-colors",
        children: Interop_reactApi.Children.toArray([createElement("a", {
            target: "_blank",
            href: link,
            children: content,
        })]),
    });
}

export function LinkSection(linkSectionInputProps) {
    const content = linkSectionInputProps.content;
    const title = linkSectionInputProps.title;
    const combined = concat([[createElement("h4", {
        className: "font-bold",
        children: title,
    })], map((c) => createElement(Link, {
        content: c.Content,
        link: c.Link,
    }), content)]);
    return createElement("div", {
        className: "flex flex-col lg:gap-4 gap-2",
        children: Interop_reactApi.Children.toArray(Array.from(combined)),
    });
}

export function Footer() {
    return createElement("footer", {
        className: "text-exo-background\n                bg-gradient-to-br\n                from-exo-primary\n                to-exo-primary-high\n                flex\n                flex-col\n                lg:px-32\n                p-5",
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: "flex lg:flex-row flex-col py-5 gap-14 justify-between",
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: "col-span-2 self-center",
                children: Interop_reactApi.Children.toArray([createElement(ExoticLogo, null)]),
            }), createElement("div", {
                className: "flex lg:flex-row flex-col gap-14 text-center lg:text-left list-none",
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    const content1 = [new LinkContent("About Exotic", "https://docs.exotic.markets/exotic-markets-v2/what-is-exotic"), new LinkContent("Product Suite", "https://docs.exotic.markets/exotic-markets-v2/product-suite"), new LinkContent("Getting Started", "https://docs.exotic.markets/exotic-markets-v2/getting-started")];
                    const content3 = [new LinkContent("Telegram", "https://t.me/exoticmarkets"), new LinkContent("Discord", "https://discord.com/invite/yfCMb9j35Y"), new LinkContent("Twitter", "https://twitter.com/exotic_markets_")];
                    return append(singleton(createElement(LinkSection, {
                        title: "What is Exotic Markets",
                        content: content1,
                    })), delay(() => singleton(createElement(LinkSection, {
                        title: "Join the Community",
                        content: content3,
                    }))));
                })))),
            })]),
        }), createElement("hr", {
            className: "w-full bg-exo-background h-0.5 self-center",
        }), createElement("p", {
            className: "py-4 self-center",
            children: "®2024 Exotic Markets All Rights Reserved",
        })]),
    });
}

