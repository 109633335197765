import { createElement } from "react";
import * as react from "react";
import { interpolate, printf, toText, join } from "./.fable/fable-library.3.1.16/String.js";
import { empty as empty_1, ofArray, singleton, append } from "./.fable/fable-library.3.1.16/List.js";
import utils$002Emodule from "./styles/utils.module.scss";
import { Interop_reactApi } from "./.fable/Feliz.1.62.0/Interop.fs.js";
import leaderboard$002Emodule from "./styles/leaderboard.module.scss";
import { MuiHelpers_createElement } from "./.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Pagination from "@material-ui/lab/Pagination";
import { toNumber, compare, parse, fromParts, equals } from "./.fable/fable-library.3.1.16/Decimal.js";
import { getOracleList, indexerGrpcOracleApi, chainGrpcWasmApi, getNetworkByInjectiveEnvironment, useWalletStore } from "../../../lib/Injective/Injective.fs.js";
import { Recoil_useRecoilValue_5D3EEEEA } from "./.fable/Feliz.Recoil.0.19.2/Bindings.fs.js";
import { injectiveEnvironment, config as config_1 } from "./Context.fs.js";
import { getNetworkEndpoints } from "@injectivelabs/networks";
import { useReact_useCallback_93353E, useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "./.fable/Feliz.1.62.0/React.fs.js";
import { startImmediate } from "./.fable/fable-library.3.1.16/Async.js";
import { singleton as singleton_1 } from "./.fable/fable-library.3.1.16/AsyncBuilder.js";
import { Api } from "./Server.fs.js";
import { indexOf, take, skip, tryFind, map } from "./.fable/fable-library.3.1.16/Array.js";
import { map as map_1 } from "./.fable/fable-library.3.1.16/Option.js";
import { empty, collect, singleton as singleton_2, append as append_1, delay, toList } from "./.fable/fable-library.3.1.16/Seq.js";
import * as accounting from "accounting";
import { getEthereumAddress } from "@injectivelabs/sdk-ts";
import { Content, ButtonProgressLoader, Row } from "./UtilComponents.fs.js";
import { errorBoundary } from "./Error.fs.js";

export function Headers() {
    const header = (text, classes, subText) => {
        if (subText == null) {
            return createElement("div", {
                className: join(" ", append(singleton(utils$002Emodule["text-light"]), classes)),
                children: text,
            });
        }
        else {
            const t = subText;
            return createElement("div", {
                className: join(" ", append(singleton(utils$002Emodule["text-light"]), classes)),
                children: Interop_reactApi.Children.toArray([text, createElement("sup", {
                    children: [t],
                })]),
            });
        }
    };
    return createElement("div", {
        className: join(" ", [leaderboard$002Emodule["leaderboard-headers"], utils$002Emodule["margin-l-top"], utils$002Emodule["padding-m"]]),
        children: Interop_reactApi.Children.toArray([header("", singleton(leaderboard$002Emodule["leaderboard-column1"]), void 0), header("Address", singleton(leaderboard$002Emodule["leaderboard-column2"]), void 0), header("Total Deposit in USD", singleton(leaderboard$002Emodule["leaderboard-column3"]), void 0), header("P\u0026L(%)", ofArray([leaderboard$002Emodule["leaderboard-column4"], utils$002Emodule["align-end"]]), "[1]"), header("APY", ofArray([leaderboard$002Emodule["leaderboard-column5"], utils$002Emodule["align-end"]]), "[2]")]),
    });
}

export function LeaderboardRow(leaderboardRowInputProps) {
    const classes = leaderboardRowInputProps.classes;
    const apy = leaderboardRowInputProps.apy;
    const pnl = leaderboardRowInputProps.pnl;
    const totalDeposit = leaderboardRowInputProps.totalDeposit;
    const address = leaderboardRowInputProps.address;
    const number = leaderboardRowInputProps.number;
    const performanceClass = leaderboardRowInputProps.isNegative ? leaderboard$002Emodule["performance-negative-column"] : leaderboard$002Emodule["performance-column"];
    return createElement("div", {
        className: join(" ", append(ofArray([leaderboard$002Emodule["leaderboard-row"], utils$002Emodule["padding-m"], "card", utils$002Emodule["margin-s-bottom"]]), classes)),
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: join(" ", [leaderboard$002Emodule["leaderboard-column1"]]),
            children: Interop_reactApi.Children.toArray([createElement("span", {
                children: [number],
            })]),
        }), createElement("div", {
            className: join(" ", [leaderboard$002Emodule["leaderboard-column2"]]),
            children: Interop_reactApi.Children.toArray([createElement("span", {
                children: [address],
            })]),
        }), createElement("div", {
            className: join(" ", [leaderboard$002Emodule["leaderboard-column3"]]),
            children: Interop_reactApi.Children.toArray([createElement("span", {
                children: [totalDeposit],
            })]),
        }), createElement("div", {
            className: join(" ", [leaderboard$002Emodule["leaderboard-column4"], performanceClass, utils$002Emodule["boldness-500"], utils$002Emodule["align-end"]]),
            children: Interop_reactApi.Children.toArray([createElement("span", {
                children: [pnl],
            })]),
        }), createElement("div", {
            className: join(" ", [leaderboard$002Emodule["leaderboard-column5"], performanceClass, utils$002Emodule["boldness-500"], utils$002Emodule["align-end"]]),
            children: Interop_reactApi.Children.toArray([createElement("span", {
                children: [apy],
            })]),
        })]),
    });
}

export function PagingRow(pagingRowInputProps) {
    const changePageCallback = pagingRowInputProps.changePageCallback;
    const pageNumber = pagingRowInputProps.pageNumber;
    const totalPages = pagingRowInputProps.totalPages;
    return createElement("div", {
        className: join(" ", [utils$002Emodule["padding-m"], utils$002Emodule["margin-s-bottom"]]),
        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Pagination, [["count", (totalPages != null) ? totalPages : 1], ["page", pageNumber], ["showFirstButton", true], ["showLastButton", true], ["onChange", (delegateArg0, delegateArg1) => {
            changePageCallback(delegateArg1);
        }]])]),
    });
}

export function display(v) {
    if (equals(v, fromParts(0, 0, 0, false, 0))) {
        return "0";
    }
    else {
        return toText(printf("%0.02M%%"))(v);
    }
}

export function Leaderboard() {
    const wallet = useWalletStore();
    const config = Recoil_useRecoilValue_5D3EEEEA(config_1);
    const environment = Recoil_useRecoilValue_5D3EEEEA(injectiveEnvironment);
    const endpoints = getNetworkEndpoints(getNetworkByInjectiveEnvironment(environment));
    const wasmGrpcApi = chainGrpcWasmApi(endpoints.grpc, environment);
    const pageSize = useFeliz_React__React_useState_Static_1505(9)[0] | 0;
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(1);
    const page = patternInput_1[0] | 0;
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(void 0);
    const leaderBoard = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(void 0);
    const pagedLeaderBoard = patternInput_3[0];
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(void 0);
    const totalPage = patternInput_4[0];
    const api = indexerGrpcOracleApi(endpoints.indexer);
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton_1.Delay(() => singleton_1.Combine(singleton_1.TryWith(singleton_1.Delay(() => singleton_1.Bind(getOracleList(api), (_arg1) => singleton_1.Bind(Api.GetInjectiveDepositTx(map((tupledArg) => {
            const d = tupledArg[0];
            const spot = map_1((s) => parse(s.price), tryFind((o) => {
                if (o.symbol === tupledArg[1]) {
                    return o.oracleType === "pyth";
                }
                else {
                    return false;
                }
            }, _arg1));
            if (spot == null) {
                return [d, (d === "inj") ? fromParts(2909, 0, 0, false, 2) : ((d === "weth") ? fromParts(2282, 0, 0, false, 0) : ((d === "atom") ? fromParts(115, 0, 0, false, 1) : fromParts(0, 0, 0, false, 0)))];
            }
            else {
                return [d, spot];
            }
        }, [["inj", "0x2d9315a88f3019f8efa88dfe9c0f0843712da0bac814461e27733f6b83eb51b3"], ["weth", "0xca80ba6dc32e08d06f1aa886011eed1d77c77be9eb761cc10d72b7d0a2fd57a6"], ["atom", "0x61226d39beea19d334f17c2febce27e12646d84675924ebb02b9cdaea68727e3"]])), (_arg2) => {
            const rows = _arg2;
            patternInput_2[1](rows);
            patternInput_4[1]((~(~(rows.length / pageSize))) + 1);
            return singleton_1.Zero();
        }))), (_arg3) => {
            return singleton_1.Zero();
        }), singleton_1.Delay(() => singleton_1.Return()))));
    }, [config, environment]);
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton_1.Delay(() => {
            patternInput_3[1](map_1((b) => {
                const temp = skip((page - 1) * pageSize, b);
                if (temp.length < pageSize) {
                    return temp;
                }
                else {
                    return take(pageSize, temp);
                }
            }, leaderBoard));
            return singleton_1.Zero();
        }));
    }, [totalPage, pageSize, page, wallet]);
    const changePageCallback = useReact_useCallback_93353E((newPage) => {
        patternInput_1[1](newPage);
    });
    const leaderBoardContent = react.createElement(react.Fragment, {}, ...toList(delay(() => append_1(singleton_2(createElement(Headers, null)), delay(() => {
        if (pagedLeaderBoard != null) {
            const profiles = pagedLeaderBoard;
            let address;
            const matchValue = wallet.walletStrategy;
            if (matchValue == null) {
                address = (void 0);
            }
            else {
                const w = matchValue;
                address = ((wallet.injectiveAddress === "") ? (void 0) : wallet.injectiveAddress);
            }
            const rawBoard = (leaderBoard == null) ? profiles : leaderBoard;
            return append_1(collect((profile) => {
                const isMyProile = (address == null) ? false : (profile.User === address);
                const i = indexOf(rawBoard, profile) | 0;
                const css = toList(delay(() => append_1((i === 0) ? singleton_2(leaderboard$002Emodule["first-place"]) : empty(), delay(() => append_1((i === 1) ? singleton_2(leaderboard$002Emodule["second-place"]) : empty(), delay(() => append_1((i === 2) ? singleton_2(leaderboard$002Emodule["third-place"]) : empty(), delay(() => (isMyProile ? singleton_2(leaderboard$002Emodule.you) : empty())))))))));
                const apy = (compare(profile.Apy, fromParts(0, 0, 0, false, 0)) >= 0) ? display(profile.Apy) : "-";
                return singleton_2(createElement(LeaderboardRow, {
                    number: toText(interpolate("%P().", [i + 1])),
                    address: profile.User,
                    totalDeposit: accounting.formatMoney(toNumber(profile.TotalDeposited), "$"),
                    pnl: display(profile.Pnl),
                    apy: apy,
                    classes: css,
                    isNegative: compare(profile.Pnl, fromParts(0, 0, 0, false, 0)) < 0,
                }));
            }, profiles), delay(() => append_1(singleton_2(createElement(PagingRow, {
                totalPages: totalPage,
                pageNumber: page,
                changePageCallback: changePageCallback,
            })), delay(() => {
                let selfPosition;
                if (address == null) {
                    selfPosition = (void 0);
                }
                else {
                    const addr_1 = address;
                    selfPosition = tryFind((x_1) => (x_1.User === addr_1), rawBoard);
                }
                if (selfPosition != null) {
                    const selfPosition_1 = selfPosition;
                    const selfPositonIndex = indexOf(rawBoard, selfPosition_1) | 0;
                    const apy_1 = (compare(selfPosition_1.Apy, fromParts(0, 0, 0, false, 0)) >= 0) ? display(selfPosition_1.Apy) : "-";
                    const ethAddr = getEthereumAddress(selfPosition_1.User);
                    return append_1(singleton_2(createElement("div", {
                        className: join(" ", ["header-8", utils$002Emodule["margin-l-top"], utils$002Emodule["margin-m-left"], utils$002Emodule["margin-m-bottom"]]),
                        children: Interop_reactApi.Children.toArray(["My Position", createElement("div", {
                            className: join(" ", ["header-4"]),
                            children: ((("(INJ: " + selfPosition_1.User) + " | ETH: ") + ethAddr) + ")",
                        })]),
                    })), delay(() => singleton_2(createElement(LeaderboardRow, {
                        number: toText(interpolate("%P().", [selfPositonIndex + 1])),
                        address: selfPosition_1.User,
                        totalDeposit: accounting.formatMoney(toNumber(selfPosition_1.TotalDeposited), "$"),
                        pnl: display(selfPosition_1.Pnl),
                        apy: apy_1,
                        classes: singleton(leaderboard$002Emodule.you),
                        isNegative: compare(selfPosition_1.Pnl, fromParts(0, 0, 0, false, 0)) < 0,
                    }))));
                }
                else {
                    return append_1(singleton_2(createElement("div", {
                        className: join(" ", ["header-5", utils$002Emodule["margin-l-top"], utils$002Emodule["margin-m-left"], utils$002Emodule["margin-m-bottom"]]),
                        children: "My Position",
                    })), delay(() => singleton_2(createElement(LeaderboardRow, {
                        number: "-",
                        address: "Please connect your wallet!",
                        totalDeposit: "-",
                        pnl: "-",
                        apy: "-",
                        classes: singleton(leaderboard$002Emodule.you),
                        isNegative: false,
                    }))));
                }
            }))));
        }
        else {
            return singleton_2(createElement(Row, {
                classes: ofArray([utils$002Emodule.center, utils$002Emodule["padding-xl-top"]]),
                children: singleton(createElement(ButtonProgressLoader, {
                    classes: empty_1(),
                })),
            }));
        }
    })))));
    const content = createElement("div", {
        className: join(" ", []),
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: join(" ", ["header-5", utils$002Emodule["margin-l-top"], utils$002Emodule["margin-m-left"]]),
            children: "Leaderboard",
        }), createElement("div", {
            className: join(" ", ["header-4", utils$002Emodule["margin-s-top"], utils$002Emodule["margin-m-left"]]),
            children: Interop_reactApi.Children.toArray(["Leaderboard refreshes ", createElement("b", {
                children: ["every 24 hours"],
            })]),
        }), createElement("div", {
            className: join(" ", ["header-7", utils$002Emodule["margin-s-top"], utils$002Emodule["margin-m-left"]]),
            children: Interop_reactApi.Children.toArray([createElement("sup", {
                children: ["[1]"],
            }), createElement("b", {
                children: ["P\u0026L(%): "],
            }), "PnL generated using our yield products since the start of the testnet"]),
        }), createElement("div", {
            className: join(" ", ["header-7", utils$002Emodule["margin-s-top"], utils$002Emodule["margin-m-left"]]),
            children: Interop_reactApi.Children.toArray([createElement("sup", {
                children: ["[2]"],
            }), createElement("b", {
                children: ["APY: "],
            }), "PnL generated using our yield products since user\u0027s first deposit, calculated on a yearly basis (for example, 1% in 30 days is ~12% APY)"]),
        }), leaderBoardContent]),
    });
    return errorBoundary(createElement(Content, {
        x: content,
        classes: empty_1(),
    }));
}

