import { createElement } from "react";
import { createObj } from "./.fable/fable-library.3.1.16/Util.js";
import card$002Emodule from "./styles/card.module.scss";
import { Interop_reactApi } from "./.fable/Feliz.1.62.0/Interop.fs.js";
import { interpolate, toText, join } from "./.fable/fable-library.3.1.16/String.js";
import currency$002Emodule from "./styles/currency.module.scss";
import { defaultArg } from "./.fable/fable-library.3.1.16/Option.js";
import { singleton as singleton_1, empty, map, ofArray } from "./.fable/fable-library.3.1.16/List.js";
import { empty as empty_1, singleton, append, delay, toList } from "./.fable/fable-library.3.1.16/Seq.js";
import utils$002Emodule from "./styles/utils.module.scss";
import { Row, Col } from "./UtilComponents.fs.js";
import single$002Dproducts$002Emodule from "./styles/single-products.module.scss";
import * as accounting from "accounting";
import { toNumber } from "./.fable/fable-library.3.1.16/Decimal.js";
import { MuiHelpers_createElement } from "./.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import FormControl from "@material-ui/core/FormControl";
import yield$002Emodule from "./styles/yield.module.scss";
import react$002Dnumber$002Dformat from "react-number-format";
import { OutlinedInput } from "@material-ui/core";

export function mintColor(text) {
    const matchValue = text.toLowerCase();
    switch (matchValue) {
        case "usdc": {
            return "text-color-usdc";
        }
        case "eth": {
            return "text-color-eth";
        }
        case "soeth": {
            return "text-color-eth";
        }
        case "btc": {
            return "text-color-btc";
        }
        case "ray": {
            return "text-color-ray";
        }
        case "srm": {
            return "text-color-srm";
        }
        case "sol": {
            return "text-color-sol";
        }
        default: {
            return "text-color-black";
        }
    }
}

export function Deposits(depositsInputProps) {
    let value, value_5;
    const depositMint = depositsInputProps.depositMint;
    const patternInput = [depositMint.symbol, depositMint.logoURI];
    return createElement("div", createObj(ofArray([(value = card$002Emodule["deposits-container"], ["className", value]), ["children", Interop_reactApi.Children.toArray([createElement("div", {
        className: join(" ", [card$002Emodule.deposits]),
        children: Interop_reactApi.Children.toArray([createElement("span", {
            children: toText(interpolate("%P() Deposits", [patternInput[0]])),
        }), createElement("img", createObj(ofArray([(value_5 = currency$002Emodule["currency-icon"], ["className", value_5]), ["src", defaultArg(patternInput[1], "")]])))]),
    })])]])));
}

export function CurrencyIcon(currencyIconInputProps) {
    let value;
    const depositMint = currencyIconInputProps.depositMint;
    return createElement("img", createObj(ofArray([(value = currency$002Emodule["currency-icon"], ["className", value]), ["src", defaultArg(depositMint.logoURI, "")]])));
}

export function CurrencyIconProductList(currencyIconProductListInputProps) {
    const productPaidRight = currencyIconProductListInputProps.productPaidRight;
    const productPaidLeft = currencyIconProductListInputProps.productPaidLeft;
    return createElement("div", {
        className: join(" ", [currency$002Emodule["currency-container"]]),
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let value_1;
            return append(singleton(createElement("div", createObj(ofArray([(value_1 = currency$002Emodule["currency-item-1"], ["className", value_1]), ["children", Interop_reactApi.Children.toArray([createElement("img", {
                src: defaultArg(productPaidLeft.logoURI, ""),
            })])]])))), delay(() => {
                let value_7;
                if (productPaidRight == null) {
                    return singleton(null);
                }
                else {
                    const productPaidRight_1 = productPaidRight;
                    return singleton(createElement("div", createObj(ofArray([(value_7 = currency$002Emodule["currency-item-2"], ["className", value_7]), ["children", Interop_reactApi.Children.toArray([createElement("img", {
                        src: defaultArg(productPaidRight_1.logoURI, ""),
                    })])]]))));
                }
            }));
        })))),
    });
}

export function Currency(currencyInputProps) {
    const depositMint = currencyInputProps.depositMint;
    const text = depositMint.symbol;
    return createElement("div", {
        className: join(" ", [currency$002Emodule["currency-row"]]),
        children: Interop_reactApi.Children.toArray([createElement(CurrencyIcon, {
            depositMint: depositMint,
        }), createElement("span", {
            className: join(" ", [mintColor(text), utils$002Emodule["boldness-500"]]),
            children: toText(interpolate("%P()", [text])),
        })]),
    });
}

export function CurrencySimple(currencySimpleInputProps) {
    const depositMint = currencySimpleInputProps.depositMint;
    const text = depositMint.symbol;
    return createElement("div", {
        children: Interop_reactApi.Children.toArray([createElement(CurrencyIcon, {
            depositMint: depositMint,
        }), createElement("span", {
            className: join(" ", [mintColor(text), utils$002Emodule["boldness-500"]]),
            children: toText(interpolate("%P()", [text])),
        })]),
    });
}

export function CurrencyList(currencyListInputProps) {
    const t = map((depositMint_1) => createElement(Currency, {
        depositMint: depositMint_1,
    }), currencyListInputProps.tokens);
    return createElement(Col, {
        classes: empty(),
        children: t,
    });
}

export function CurrencyLinkedTo(currencyLinkedToInputProps) {
    const depositMint = currencyLinkedToInputProps.depositMint;
    const text = depositMint.symbol;
    return createElement("div", {
        className: join(" ", [currency$002Emodule["currency-row"]]),
        children: Interop_reactApi.Children.toArray([createElement(CurrencyIcon, {
            depositMint: depositMint,
        }), createElement("span", {
            className: join(" ", [mintColor(text), utils$002Emodule["boldness-500"]]),
            children: toText(interpolate("%P()/USD", [text])),
        })]),
    });
}

export function CurrencyProductInfo(currencyProductInfoInputProps) {
    let value_1;
    const depositMint = currencyProductInfoInputProps.depositMint;
    const patternInput = [depositMint.symbol, depositMint.logoURI];
    return createElement("div", {
        className: join(" ", [currency$002Emodule["currency-row"]]),
        children: Interop_reactApi.Children.toArray([createElement("div", createObj(ofArray([(value_1 = single$002Dproducts$002Emodule["product-info-icon"], ["className", value_1]), ["children", Interop_reactApi.Children.toArray([createElement(CurrencyIcon, {
            depositMint: depositMint,
        }), createElement("span", {
            className: join(" ", [card$002Emodule["strategy-header"]]),
            children: toText(interpolate("%P()", [patternInput[0]])),
        })])]])))]),
    });
}

export function Price(priceInputProps) {
    let value;
    const token = priceInputProps.token;
    const amount_1 = accounting.formatMoney(toNumber(priceInputProps.amount), "$");
    return createElement(Row, {
        classes: singleton_1(utils$002Emodule["gap-s"]),
        children: singleton_1(createElement("span", createObj(ofArray([(value = utils$002Emodule["boldness-500"], ["className", value]), ["children", toText(interpolate("%P()", [amount_1]))]])))),
    });
}

export function CurrencyInput(currencyInputInputProps) {
    let value_2;
    const error = currencyInputInputProps.error;
    const dispatch = currencyInputInputProps.dispatch;
    const value = currencyInputInputProps.value;
    const mint = currencyInputInputProps.mint;
    return MuiHelpers_createElement(FormControl, [["variant", "outlined"], (value_2 = yield$002Emodule["yield-input"], ["className", value_2]), ["children", Interop_reactApi.Children.toArray([createElement(react$002Dnumber$002Dformat, createObj(toList(delay(() => append(singleton(["error", defaultArg(error, false)]), delay(() => append(singleton(["value", value]), delay(() => append(singleton(["onValueChange", (v) => {
        dispatch(v.value);
    }]), delay(() => append((!(navigator.userAgent.toLocaleLowerCase().indexOf("firefox") >= 0)) ? singleton(["type", "text"]) : empty_1(), delay(() => append(singleton(["inputProps", {
        step: 1,
        min: 0,
        style: {
            paddingTop: 12,
            paddingBottom: 12,
        },
        className: join(" ", ["text-color-normal"]),
    }]), delay(() => {
        let mint_1, value_23;
        return append((mint == null) ? (void 0, empty_1()) : (mint_1 = mint, singleton(["startAdornment", createElement("div", createObj(ofArray([(value_23 = utils$002Emodule["margin-s-right"], ["className", value_23]), ["children", Interop_reactApi.Children.toArray([createElement(CurrencyIcon, {
            depositMint: mint_1,
        })])]])))])), delay(() => {
            let elem;
            return append(singleton((elem = OutlinedInput, ["customInput", elem])), delay(() => append(singleton(["decimalSeparator", "."]), delay(() => singleton(["thousandSeparator", ","])))));
        }));
    }))))))))))))))])]]);
}

export function CurrencyInputDisabled(currencyInputDisabledInputProps) {
    let value_2;
    const error = currencyInputDisabledInputProps.error;
    const value = currencyInputDisabledInputProps.value;
    const mint = currencyInputDisabledInputProps.mint;
    return MuiHelpers_createElement(FormControl, [["variant", "outlined"], (value_2 = yield$002Emodule["yield-input"], ["className", value_2]), ["children", Interop_reactApi.Children.toArray([createElement(react$002Dnumber$002Dformat, createObj(toList(delay(() => append(singleton(["error", defaultArg(error, false)]), delay(() => append(singleton(["value", value]), delay(() => append(singleton(["disabled", true]), delay(() => append((!(navigator.userAgent.toLocaleLowerCase().indexOf("firefox") >= 0)) ? singleton(["type", "text"]) : empty_1(), delay(() => append(singleton(["inputProps", {
        step: 1,
        min: 0,
        style: {
            paddingTop: 12,
            paddingBottom: 12,
        },
        className: join(" ", ["text-color-normal"]),
    }]), delay(() => {
        let mint_1, value_24;
        return append((mint == null) ? (void 0, empty_1()) : (mint_1 = mint, singleton(["startAdornment", createElement("div", createObj(ofArray([(value_24 = utils$002Emodule["margin-s-right"], ["className", value_24]), ["children", Interop_reactApi.Children.toArray([createElement(CurrencyIcon, {
            depositMint: mint_1,
        })])]])))])), delay(() => {
            let elem;
            return append(singleton((elem = OutlinedInput, ["customInput", elem])), delay(() => append(singleton(["decimalSeparator", "."]), delay(() => singleton(["thousandSeparator", ","])))));
        }));
    }))))))))))))))])]]);
}

