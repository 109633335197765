import { Record, Union } from "../../../app/client/src/.fable/fable-library.3.1.16/Types.js";
import { option_type, list_type, record_type, string_type, class_type, union_type } from "../../../app/client/src/.fable/fable-library.3.1.16/Reflection.js";
import { Paging$1$reflection, PublicKey$reflection } from "./Common.fs.js";

export class ClientAction extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Deposit", "Withdraw"];
    }
}

export function ClientAction$reflection() {
    return union_type("ExoticMarkets.Domain.ClientStatsTypes.ClientAction", [], ClientAction, () => [[], []]);
}

export class ClientStat extends Record {
    constructor(Time, ClientAction, IpAddress, UserPublicKey, TransactionId, ExtraData, IpCountryName, IpCountryCode, IpRegionName, IpCityName) {
        super();
        this.Time = Time;
        this.ClientAction = ClientAction;
        this.IpAddress = IpAddress;
        this.UserPublicKey = UserPublicKey;
        this.TransactionId = TransactionId;
        this.ExtraData = ExtraData;
        this.IpCountryName = IpCountryName;
        this.IpCountryCode = IpCountryCode;
        this.IpRegionName = IpRegionName;
        this.IpCityName = IpCityName;
    }
}

export function ClientStat$reflection() {
    return record_type("ExoticMarkets.Domain.ClientStatsTypes.ClientStat", [], ClientStat, () => [["Time", class_type("System.DateTime")], ["ClientAction", ClientAction$reflection()], ["IpAddress", string_type], ["UserPublicKey", PublicKey$reflection()], ["TransactionId", string_type], ["ExtraData", string_type], ["IpCountryName", string_type], ["IpCountryCode", string_type], ["IpRegionName", string_type], ["IpCityName", string_type]]);
}

export class InjectiveClientStat extends Record {
    constructor(Time, ClientAction, HoldingId, UserAddress, Currency, Amount, WalletName, Environment, IpAddress, IpCountryCode, IpCountryName, IpRegionName, IpCityName, TransactionId, ExtraData) {
        super();
        this.Time = Time;
        this.ClientAction = ClientAction;
        this.HoldingId = HoldingId;
        this.UserAddress = UserAddress;
        this.Currency = Currency;
        this.Amount = Amount;
        this.WalletName = WalletName;
        this.Environment = Environment;
        this.IpAddress = IpAddress;
        this.IpCountryCode = IpCountryCode;
        this.IpCountryName = IpCountryName;
        this.IpRegionName = IpRegionName;
        this.IpCityName = IpCityName;
        this.TransactionId = TransactionId;
        this.ExtraData = ExtraData;
    }
}

export function InjectiveClientStat$reflection() {
    return record_type("ExoticMarkets.Domain.ClientStatsTypes.InjectiveClientStat", [], InjectiveClientStat, () => [["Time", class_type("System.DateTime")], ["ClientAction", ClientAction$reflection()], ["HoldingId", string_type], ["UserAddress", string_type], ["Currency", string_type], ["Amount", string_type], ["WalletName", string_type], ["Environment", string_type], ["IpAddress", string_type], ["IpCountryCode", string_type], ["IpCountryName", string_type], ["IpRegionName", string_type], ["IpCityName", string_type], ["TransactionId", string_type], ["ExtraData", string_type]]);
}

export class InjectiveOptionClientAction extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Buy", "Exercise"];
    }
}

export function InjectiveOptionClientAction$reflection() {
    return union_type("ExoticMarkets.Domain.ClientStatsTypes.InjectiveOptionClientAction", [], InjectiveOptionClientAction, () => [[], []]);
}

export class InjectiveOptionClientStat extends Record {
    constructor(Time, UserAddress, ClientAction, EscrowAddress, HoldingId, Currency, AmountSent, Quantity, WalletName, Environment, IpAddress, IpCountryCode, IpCountryName, IpRegionName, IpCityName, TransactionId, ExtraData) {
        super();
        this.Time = Time;
        this.UserAddress = UserAddress;
        this.ClientAction = ClientAction;
        this.EscrowAddress = EscrowAddress;
        this.HoldingId = HoldingId;
        this.Currency = Currency;
        this.AmountSent = AmountSent;
        this.Quantity = Quantity;
        this.WalletName = WalletName;
        this.Environment = Environment;
        this.IpAddress = IpAddress;
        this.IpCountryCode = IpCountryCode;
        this.IpCountryName = IpCountryName;
        this.IpRegionName = IpRegionName;
        this.IpCityName = IpCityName;
        this.TransactionId = TransactionId;
        this.ExtraData = ExtraData;
    }
}

export function InjectiveOptionClientStat$reflection() {
    return record_type("ExoticMarkets.Domain.ClientStatsTypes.InjectiveOptionClientStat", [], InjectiveOptionClientStat, () => [["Time", class_type("System.DateTime")], ["UserAddress", string_type], ["ClientAction", InjectiveOptionClientAction$reflection()], ["EscrowAddress", string_type], ["HoldingId", string_type], ["Currency", string_type], ["AmountSent", string_type], ["Quantity", string_type], ["WalletName", string_type], ["Environment", string_type], ["IpAddress", string_type], ["IpCountryCode", string_type], ["IpCountryName", string_type], ["IpRegionName", string_type], ["IpCityName", string_type], ["TransactionId", string_type], ["ExtraData", string_type]]);
}

export class InjectiveOptionClientStatRaw extends Record {
    constructor(UserAddress, ClientAction, EscrowAddress, HoldingId, Currency, AmountSent, Quantity, WalletName, Environment, TransactionId, ExtraData) {
        super();
        this.UserAddress = UserAddress;
        this.ClientAction = ClientAction;
        this.EscrowAddress = EscrowAddress;
        this.HoldingId = HoldingId;
        this.Currency = Currency;
        this.AmountSent = AmountSent;
        this.Quantity = Quantity;
        this.WalletName = WalletName;
        this.Environment = Environment;
        this.TransactionId = TransactionId;
        this.ExtraData = ExtraData;
    }
}

export function InjectiveOptionClientStatRaw$reflection() {
    return record_type("ExoticMarkets.Domain.ClientStatsTypes.InjectiveOptionClientStatRaw", [], InjectiveOptionClientStatRaw, () => [["UserAddress", string_type], ["ClientAction", InjectiveOptionClientAction$reflection()], ["EscrowAddress", string_type], ["HoldingId", string_type], ["Currency", string_type], ["AmountSent", string_type], ["Quantity", string_type], ["WalletName", string_type], ["Environment", string_type], ["TransactionId", string_type], ["ExtraData", string_type]]);
}

export class ClientStatsSortField extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Time", "ClientAction", "Ip", "CountryName", "WalletKey", "TransactionId"];
    }
}

export function ClientStatsSortField$reflection() {
    return union_type("ExoticMarkets.Domain.ClientStatsTypes.ClientStatsSortField", [], ClientStatsSortField, () => [[], [], [], [], [], []]);
}

export class ClientStatPage extends Record {
    constructor(ClientStats, Paging) {
        super();
        this.ClientStats = ClientStats;
        this.Paging = Paging;
    }
}

export function ClientStatPage$reflection() {
    return record_type("ExoticMarkets.Domain.ClientStatsTypes.ClientStatPage", [], ClientStatPage, () => [["ClientStats", list_type(ClientStat$reflection())], ["Paging", Paging$1$reflection(option_type(ClientStatsSortField$reflection()))]]);
}

