import { Union, Record } from "../../../app/client/src/.fable/fable-library.3.1.16/Types.js";
import { PublicKey$reflection } from "./Common.fs.js";
import { union_type, record_type } from "../../../app/client/src/.fable/fable-library.3.1.16/Reflection.js";

export class LocalPosition extends Record {
    constructor(User, Product) {
        super();
        this.User = User;
        this.Product = Product;
    }
}

export function LocalPosition$reflection() {
    return record_type("ExoticMarkets.Domain.LocalPosition.LocalPosition", [], LocalPosition, () => [["User", PublicKey$reflection()], ["Product", PublicKey$reflection()]]);
}

export class LocalHoldingPosition extends Record {
    constructor(User, Holding) {
        super();
        this.User = User;
        this.Holding = Holding;
    }
}

export function LocalHoldingPosition$reflection() {
    return record_type("ExoticMarkets.Domain.LocalPosition.LocalHoldingPosition", [], LocalHoldingPosition, () => [["User", PublicKey$reflection()], ["Holding", PublicKey$reflection()]]);
}

export class LocalPositionAction extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LocalPostionAdded", "LocalPositionRemoved"];
    }
}

export function LocalPositionAction$reflection() {
    return union_type("ExoticMarkets.Domain.LocalPosition.LocalPositionAction", [], LocalPositionAction, () => [[], []]);
}

