import { FSharpRef, Record, Union } from "../../../app/client/src/.fable/fable-library.3.1.16/Types.js";
import { int8_type, array_type, class_type, record_type, option_type, int32_type, union_type, string_type } from "../../../app/client/src/.fable/fable-library.3.1.16/Reflection.js";
import { tryParse, round, op_Division, op_Multiply, toNumber as toNumber_1, fromParts } from "../../../app/client/src/.fable/fable-library.3.1.16/Decimal.js";
import Decimal from "../../../app/client/src/.fable/fable-library.3.1.16/Decimal.js";
import { toDecimal, parse } from "../../../app/client/src/.fable/fable-library.3.1.16/BigInt.js";
import { filter, map, toList } from "../../../app/client/src/.fable/fable-library.3.1.16/Seq.js";
import { uncurry, compare, equals } from "../../../app/client/src/.fable/fable-library.3.1.16/Util.js";
import { tryFind } from "../../../app/client/src/.fable/fable-library.3.1.16/List.js";
import { ofSeq, FSharpMap__get_Item } from "../../../app/client/src/.fable/fable-library.3.1.16/Map.js";
import { toNumber } from "../../../app/client/src/.fable/fable-library.3.1.16/Long.js";
import { fromSeconds, days as days_1 } from "../../../app/client/src/.fable/fable-library.3.1.16/TimeSpan.js";
import { array, bigint, string, object } from "../../../app/client/src/.fable/Thoth.Json.10.1.0/Decode.fs.js";

export class PublicKey extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["PublicKey"];
    }
}

export function PublicKey$reflection() {
    return union_type("ExoticMarkets.Domain.Common.PublicKey", [], PublicKey, () => [[["Item", string_type]]]);
}

export function PublicKey_Value_Z1B21ACD5(_arg1) {
    return _arg1.fields[0];
}

export class SolanaEnvironment extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Devnet", "Mainnet"];
    }
    toString() {
        const this$ = this;
        return (this$.tag === 1) ? "Mainnet" : "Devnet";
    }
}

export function SolanaEnvironment$reflection() {
    return union_type("ExoticMarkets.Domain.Common.SolanaEnvironment", [], SolanaEnvironment, () => [[], []]);
}

export function SolanaEnvironment_Parse_Z721C83C5(s) {
    switch (s) {
        case "Devnet": {
            return new SolanaEnvironment(0);
        }
        case "Mainnet": {
            return new SolanaEnvironment(1);
        }
        default: {
            throw (new Error("Invalid environment"));
        }
    }
}

export class Token extends Record {
    constructor(Name, Address, Symbol$, Decimals, ChailId, LogoUri) {
        super();
        this.Name = Name;
        this.Address = Address;
        this.Symbol = Symbol$;
        this.Decimals = (Decimals | 0);
        this.ChailId = (ChailId | 0);
        this.LogoUri = LogoUri;
    }
}

export function Token$reflection() {
    return record_type("ExoticMarkets.Domain.Common.Token", [], Token, () => [["Name", string_type], ["Address", string_type], ["Symbol", string_type], ["Decimals", int32_type], ["ChailId", int32_type], ["LogoUri", option_type(string_type)]]);
}

export class PythCurrency extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["BTC", "SOL", "ETH", "SRM", "RAY", "FTT", "MNGO", "USDC", "MSOL", "Unknown"];
    }
}

export function PythCurrency$reflection() {
    return union_type("ExoticMarkets.Domain.Common.PythCurrency", [], PythCurrency, () => [[], [], [], [], [], [], [], [], [], []]);
}

export function PythCurrency_Parse_Z721C83C5(token) {
    const token_1 = token.toLowerCase();
    switch (token_1) {
        case "btc": {
            return new PythCurrency(0);
        }
        case "sol": {
            return new PythCurrency(1);
        }
        case "eth": {
            return new PythCurrency(2);
        }
        case "srm": {
            return new PythCurrency(3);
        }
        case "ray": {
            return new PythCurrency(4);
        }
        case "ftt": {
            return new PythCurrency(5);
        }
        case "mngo": {
            return new PythCurrency(6);
        }
        case "usdc": {
            return new PythCurrency(7);
        }
        case "msol": {
            return new PythCurrency(8);
        }
        default: {
            return new PythCurrency(9);
        }
    }
}

export const MULTIPLIER_RATE = fromParts(1000000, 0, 0, false, 0);

export const FPDECIMAL_ONE = parse("1000000000000000000");

export const FPDECIMAL_PERCENT_BASE = parse("10000000000000000");

export function currencyMintMapping(currencies) {
    return toList(map((kv_1) => [PythCurrency_Parse_Z721C83C5(kv_1[0]), kv_1[1]], filter((kv) => (!equals(PythCurrency_Parse_Z721C83C5(kv[0]), new PythCurrency(9))), currencies)));
}

export function mintCurrencyMapping(currencies) {
    return toList(map((kv_1) => [kv_1[1], PythCurrency_Parse_Z721C83C5(kv_1[0])], filter((kv) => (!equals(PythCurrency_Parse_Z721C83C5(kv[0]), new PythCurrency(9))), currencies)));
}

export class PythPrice extends Record {
    constructor(Currency, PythProductAccountKey, PreviousPrice) {
        super();
        this.Currency = Currency;
        this.PythProductAccountKey = PythProductAccountKey;
        this.PreviousPrice = PreviousPrice;
    }
}

export function PythPrice$reflection() {
    return record_type("ExoticMarkets.Domain.Common.PythPrice", [], PythPrice, () => [["Currency", PythCurrency$reflection()], ["PythProductAccountKey", PublicKey$reflection()], ["PreviousPrice", class_type("System.Decimal")]]);
}

export function getCurrency(mint, mapping) {
    const currency = tryFind((x) => (x[0] === mint), mapping);
    if (currency == null) {
        return new PythCurrency(9);
    }
    else {
        return currency[1];
    }
}

export function getMint(currency, mapping) {
    const currencyMint = tryFind((x) => equals(x[0], currency), mapping);
    if (currencyMint == null) {
        return new PublicKey(0, "");
    }
    else {
        return new PublicKey(0, currencyMint[1]);
    }
}

export class BalanceType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CurrentBalance", "DeployedBalance", "Pnl"];
    }
}

export function BalanceType$reflection() {
    return union_type("ExoticMarkets.Domain.Common.BalanceType", [], BalanceType, () => [[], [], []]);
}

export class SortOrder extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Ascending", "Descending"];
    }
}

export function SortOrder$reflection() {
    return union_type("ExoticMarkets.Domain.Common.SortOrder", [], SortOrder, () => [[], []]);
}

export function SortOrder__Toggle(this$) {
    if (this$.tag === 1) {
        return new SortOrder(0);
    }
    else {
        return new SortOrder(1);
    }
}

export class SortOrderType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Quantity", "Strike", "Expiry"];
    }
}

export function SortOrderType$reflection() {
    return union_type("ExoticMarkets.Domain.Common.SortOrderType", [], SortOrderType, () => [[], [], []]);
}

export class Paging$1 extends Record {
    constructor(TotalPages, PageNumber, PageSize, SortField, SortOrder) {
        super();
        this.TotalPages = TotalPages;
        this.PageNumber = (PageNumber | 0);
        this.PageSize = (PageSize | 0);
        this.SortField = SortField;
        this.SortOrder = SortOrder;
    }
}

export function Paging$1$reflection(gen0) {
    return record_type("ExoticMarkets.Domain.Common.Paging`1", [gen0], Paging$1, () => [["TotalPages", option_type(int32_type)], ["PageNumber", int32_type], ["PageSize", int32_type], ["SortField", gen0], ["SortOrder", SortOrder$reflection()]]);
}

export class EnvironmentClients$1 {
    constructor(devnet, mainnet) {
        this.clients = ofSeq([[new SolanaEnvironment(0), devnet], [new SolanaEnvironment(1), mainnet]], {
            Compare: (x, y) => compare(x, y),
        });
    }
    GetClient(environment) {
        const _ = this;
        return FSharpMap__get_Item(_.clients, environment);
    }
}

export function EnvironmentClients$1$reflection(gen0) {
    return class_type("ExoticMarkets.Domain.Common.EnvironmentClients`1", [gen0], EnvironmentClients$1);
}

export function EnvironmentClients$1_$ctor_5BDDA0(devnet, mainnet) {
    return new EnvironmentClients$1(devnet, mainnet);
}

export function convertUInt64ToPercent(nb) {
    return (toNumber(nb) / toNumber_1(MULTIPLIER_RATE)) * 100;
}

export function convertDecimalToPercent(nb) {
    return op_Multiply(op_Division(nb, MULTIPLIER_RATE), fromParts(100, 0, 0, false, 0));
}

export function daysFromSeconds(seconds) {
    return Math.ceil(days_1(fromSeconds(seconds)));
}

export function convertToAPRFromRate(maturitySeconds, nb) {
    return op_Division(round(op_Multiply(op_Multiply(op_Division(op_Multiply(op_Division(toDecimal(nb), MULTIPLIER_RATE), new Decimal(31557600)), new Decimal(maturitySeconds)), fromParts(100, 0, 0, false, 0)), fromParts(10, 0, 0, false, 0))), fromParts(10, 0, 0, false, 0));
}

export function convertToAPRFromRateU64(maturitySeconds, nb) {
    return op_Division(round(op_Multiply(op_Multiply(op_Division(op_Multiply(op_Division(new Decimal(toNumber(nb)), MULTIPLIER_RATE), new Decimal(31557600)), new Decimal(toNumber(maturitySeconds))), fromParts(100, 0, 0, false, 0)), fromParts(10, 0, 0, false, 0))), fromParts(10, 0, 0, false, 0));
}

export function convertToAPRFromRateDecimal(maturitySeconds, nb) {
    return op_Division(round(op_Multiply(op_Multiply(op_Division(op_Multiply(op_Division(nb, toDecimal(FPDECIMAL_ONE)), new Decimal(31557600)), new Decimal(toNumber(maturitySeconds))), fromParts(100, 0, 0, false, 0)), fromParts(10, 0, 0, false, 0))), fromParts(10, 0, 0, false, 0));
}

export function convertToRateDecimalFromAPR(maturitySeconds, nb) {
    return op_Division(op_Division(op_Multiply(nb, new Decimal(toNumber(maturitySeconds))), new Decimal(31557600)), fromParts(100, 0, 0, false, 0));
}

export function convertToYieldFromRate(nb) {
    return op_Division(round(op_Multiply(op_Multiply(op_Division(toDecimal(nb), MULTIPLIER_RATE), fromParts(100, 0, 0, false, 0)), fromParts(10, 0, 0, false, 0))), fromParts(10, 0, 0, false, 0));
}

export function convertToYieldUInt64FromRate(nb) {
    return op_Division(round(op_Multiply(op_Multiply(op_Division(new Decimal(toNumber(nb)), MULTIPLIER_RATE), fromParts(100, 0, 0, false, 0)), fromParts(10, 0, 0, false, 0))), fromParts(10, 0, 0, false, 0));
}

export function convertToDecimal(str) {
    let matchValue;
    let outArg = new Decimal(0);
    matchValue = [tryParse(str, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (matchValue[0]) {
        return matchValue[1];
    }
    else {
        return void 0;
    }
}

export class Blockchain extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Solana", "Injective"];
    }
    toString() {
        const this$ = this;
        return (this$.tag === 1) ? "Injective" : "Solana";
    }
}

export function Blockchain$reflection() {
    return union_type("ExoticMarkets.Domain.Common.Blockchain", [], Blockchain, () => [[], []]);
}

export function Blockchain_Parse_Z721C83C5(s) {
    switch (s) {
        case "Solana": {
            return new Blockchain(0);
        }
        case "Injective": {
            return new Blockchain(1);
        }
        default: {
            throw (new Error("Invalid chain"));
        }
    }
}

export class InjectiveEnvironment extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Testnet", "InjectiveMainnet"];
    }
    toString() {
        const this$ = this;
        return (this$.tag === 1) ? "Mainnet" : "Testnet";
    }
}

export function InjectiveEnvironment$reflection() {
    return union_type("ExoticMarkets.Domain.Common.InjectiveEnvironment", [], InjectiveEnvironment, () => [[], []]);
}

export function InjectiveEnvironment_Parse_Z721C83C5(s) {
    switch (s) {
        case "Testnet": {
            return new InjectiveEnvironment(0);
        }
        case "Mainnet": {
            return new InjectiveEnvironment(1);
        }
        default: {
            throw (new Error("Invalid environment"));
        }
    }
}

export class EnvironmentInjectiveClients$1 {
    constructor(devnet, mainnet) {
        this.clients = ofSeq([[new InjectiveEnvironment(0), devnet], [new InjectiveEnvironment(1), mainnet]], {
            Compare: (x, y) => compare(x, y),
        });
    }
    GetClient(environment) {
        const _ = this;
        return FSharpMap__get_Item(_.clients, environment);
    }
}

export function EnvironmentInjectiveClients$1$reflection(gen0) {
    return class_type("ExoticMarkets.Domain.Common.EnvironmentInjectiveClients`1", [gen0], EnvironmentInjectiveClients$1);
}

export function EnvironmentInjectiveClients$1_$ctor_5BDDA0(devnet, mainnet) {
    return new EnvironmentInjectiveClients$1(devnet, mainnet);
}

export class Coin extends Record {
    constructor(Denom, Amount) {
        super();
        this.Denom = Denom;
        this.Amount = Amount;
    }
}

export function Coin$reflection() {
    return record_type("ExoticMarkets.Domain.Common.Coin", [], Coin, () => [["Denom", string_type], ["Amount", class_type("System.Numerics.BigInteger")]]);
}

export class Cw20CoinVerified extends Record {
    constructor(Address, Amount) {
        super();
        this.Address = Address;
        this.Amount = Amount;
    }
}

export function Cw20CoinVerified$reflection() {
    return record_type("ExoticMarkets.Domain.Common.Cw20CoinVerified", [], Cw20CoinVerified, () => [["Address", string_type], ["Amount", string_type]]);
}

export const coinDecoder = (path_2) => ((v) => object((get$) => (new Coin(get$.Required.Field("denom", (path, value) => string(path, value)), get$.Required.Field("amount", (path_1, value_1) => bigint(path_1, value_1)))), path_2, v));

export class Denom extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Native", "Cw20"];
    }
}

export function Denom$reflection() {
    return union_type("ExoticMarkets.Domain.Common.Denom", [], Denom, () => [[["Item", string_type]], [["Item", string_type]]]);
}

export const nativeDenomDecoder = (path_1) => ((v) => object((get$) => (new Denom(0, get$.Required.Field("native", (path, value) => string(path, value)))), path_1, v));

export class OptionGenericBalance extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Native", "Cw20"];
    }
}

export function OptionGenericBalance$reflection() {
    return union_type("ExoticMarkets.Domain.Common.OptionGenericBalance", [], OptionGenericBalance, () => [[["Item", Coin$reflection()]], [["Item", Cw20CoinVerified$reflection()]]]);
}

export const nativeOptionGenericBalanceDecoder = (() => {
    try {
        return (path) => ((v) => object((get$) => (new OptionGenericBalance(0, get$.Required.Field("native", uncurry(2, coinDecoder)))), path, v));
    }
    catch (ex) {
        throw (new Error("Error in deserializing Option GenericBalance"));
    }
})();

export class RfqGenericBalance extends Record {
    constructor(Native, Cw20) {
        super();
        this.Native = Native;
        this.Cw20 = Cw20;
    }
}

export function RfqGenericBalance$reflection() {
    return record_type("ExoticMarkets.Domain.Common.RfqGenericBalance", [], RfqGenericBalance, () => [["Native", array_type(Coin$reflection())], ["Cw20", array_type(Cw20CoinVerified$reflection())]]);
}

export const rfqGenericBalanceDecoder = (() => {
    try {
        return (path_1) => ((v) => object((get$) => (new RfqGenericBalance(get$.Required.Field("native", (path, value) => array(uncurry(2, coinDecoder), path, value)), new Array(0))), path_1, v));
    }
    catch (ex) {
        throw (new Error("Error in deserializing RFQ GenericBalance"));
    }
})();

export class FPDecimal extends Record {
    constructor(Num, Sign) {
        super();
        this.Num = Num;
        this.Sign = (Sign | 0);
    }
}

export function FPDecimal$reflection() {
    return record_type("ExoticMarkets.Domain.Common.FPDecimal", [], FPDecimal, () => [["Num", class_type("System.Decimal")], ["Sign", int8_type]]);
}

