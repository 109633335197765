import { Record } from "../../../app/client/src/.fable/fable-library.3.1.16/Types.js";
import { array_type, uint8_type, record_type, uint32_type, class_type } from "../../../app/client/src/.fable/fable-library.3.1.16/Reflection.js";
import { PublicKey$reflection } from "./Common.fs.js";

export class ExoticFee extends Record {
    constructor(StakeRequirement, ManagementFee, PerformanceFee, WithdrawalFee) {
        super();
        this.StakeRequirement = StakeRequirement;
        this.ManagementFee = ManagementFee;
        this.PerformanceFee = PerformanceFee;
        this.WithdrawalFee = WithdrawalFee;
    }
}

export function ExoticFee$reflection() {
    return record_type("ExoticMarkets.Domain.ExoticFeeState.ExoticFee", [], ExoticFee, () => [["StakeRequirement", class_type("System.UInt64")], ["ManagementFee", uint32_type], ["PerformanceFee", uint32_type], ["WithdrawalFee", uint32_type]]);
}

export class ExoticFeeState extends Record {
    constructor(Pubkey, Bump, ManagementFeeRecipient, PerformanceFeeRecipient, WithdrawalFeeRecipient, Fees) {
        super();
        this.Pubkey = Pubkey;
        this.Bump = Bump;
        this.ManagementFeeRecipient = ManagementFeeRecipient;
        this.PerformanceFeeRecipient = PerformanceFeeRecipient;
        this.WithdrawalFeeRecipient = WithdrawalFeeRecipient;
        this.Fees = Fees;
    }
}

export function ExoticFeeState$reflection() {
    return record_type("ExoticMarkets.Domain.ExoticFeeState.ExoticFeeState", [], ExoticFeeState, () => [["Pubkey", PublicKey$reflection()], ["Bump", uint8_type], ["ManagementFeeRecipient", PublicKey$reflection()], ["PerformanceFeeRecipient", PublicKey$reflection()], ["WithdrawalFeeRecipient", PublicKey$reflection()], ["Fees", array_type(ExoticFee$reflection())]]);
}

