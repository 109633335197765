import { createElement } from "react";
import * as react from "react";
import { ApplicationTab_FromIndex_Z524259A4 } from "../Types.fs.js";
import { RouterModule_nav } from "../.fable/Feliz.Router.3.8.0/Router.fs.js";
import { ofArray, singleton } from "../.fable/fable-library.3.1.16/List.js";
import { Interop_reactApi } from "../.fable/Feliz.1.62.0/Interop.fs.js";
import { getWalletTypeByWalletName, copyTextWeb, getWalletAddresses, useWalletStore } from "../../../../lib/Injective/Injective.fs.js";
import { useReact_useRef_1505, useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.62.0/React.fs.js";
import { startImmediate } from "../.fable/fable-library.3.1.16/Async.js";
import { singleton as singleton_1 } from "../.fable/fable-library.3.1.16/AsyncBuilder.js";
import { useSnackbar } from "notistack";
import { EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../../../../lib/Notistack/Notistack.fs.js";
import { useWallet } from "@solana/wallet-adapter-react";
import { Recoil_useRecoilValue_5D3EEEEA } from "../.fable/Feliz.Recoil.0.19.2/Bindings.fs.js";
import { blockchain, injectiveEnvironment } from "../Context.fs.js";
import { WalletListButton } from "./Button.fs.js";
import { toString } from "../.fable/fable-library.3.1.16/Types.js";
import { disconnectWallet as disconnectWallet_1 } from "../../../../lib/Solana/Solana.fs.js";
import { OpenBurgerMenu, Modal } from "./Modal.fs.js";
import { empty, toArray, singleton as singleton_2, append, delay, toList } from "../.fable/fable-library.3.1.16/Seq.js";
import { interpolate, toText } from "../.fable/fable-library.3.1.16/String.js";
import { accountBalanceWallet } from "../Img.fs.js";
import { structuralHash, equals } from "../.fable/fable-library.3.1.16/Util.js";
import { map, contains } from "../.fable/fable-library.3.1.16/Array.js";
import { rangeDouble } from "../.fable/fable-library.3.1.16/Range.js";
import { ExoticLogo } from "./Icon/ExoticLogo.fs.js";
import { BurgerMenu } from "./Icon/Menu.fs.js";

export function SwitchTab(switchTabInputProps) {
    const goToCommunityToggle = switchTabInputProps.goToCommunityToggle;
    const setGoToCommunityToggle = switchTabInputProps.setGoToCommunityToggle;
    const setGoToCommunity = switchTabInputProps.setGoToCommunity;
    const isJoinCommunity = switchTabInputProps.isJoinCommunity;
    const setTabIndex = switchTabInputProps.setTabIndex;
    const tabIndex = switchTabInputProps.tabIndex;
    const text = switchTabInputProps.text;
    return createElement("a", {
        children: text,
        onClick: (_arg1) => {
            setTabIndex(tabIndex);
            const matchValue = ApplicationTab_FromIndex_Z524259A4(tabIndex);
            switch (matchValue.tag) {
                case 2: {
                    RouterModule_nav(singleton("optionality"), 1, 1);
                    break;
                }
                case 3: {
                    RouterModule_nav(singleton("dashboard"), 1, 1);
                    break;
                }
                case 0: {
                    RouterModule_nav(singleton("/"), 1, 1);
                    break;
                }
                default: {
                    RouterModule_nav(singleton("products"), 1, 1);
                }
            }
            if (isJoinCommunity) {
                setGoToCommunity(true);
                setGoToCommunityToggle(!goToCommunityToggle);
            }
            else {
                setGoToCommunity(false);
            }
        },
    });
}

export function Link(linkInputProps) {
    const goToCommunityToggle = linkInputProps.goToCommunityToggle;
    const setGoToCommunityToggle = linkInputProps.setGoToCommunityToggle;
    const setGoToCommunity = linkInputProps.setGoToCommunity;
    const isJoinCommunity = linkInputProps.isJoinCommunity;
    const setTabIndex = linkInputProps.setTabIndex;
    const tabIndex = linkInputProps.tabIndex;
    const text = linkInputProps.text;
    return createElement("li", {
        className: "hover:text-exo-primary-high\n                transition-colors\n                cursor-pointer",
        children: Interop_reactApi.Children.toArray([createElement(SwitchTab, {
            text: text,
            tabIndex: tabIndex,
            setTabIndex: setTabIndex,
            isJoinCommunity: isJoinCommunity,
            setGoToCommunity: setGoToCommunity,
            setGoToCommunityToggle: setGoToCommunityToggle,
            goToCommunityToggle: goToCommunityToggle,
        })]),
    });
}

export function InjectiveWalletDropdown(injectiveWalletDropdownInputProps) {
    const openSelector = injectiveWalletDropdownInputProps.openSelector;
    const onClose = injectiveWalletDropdownInputProps.onClose;
    const parent = injectiveWalletDropdownInputProps.parent;
    const wallet = useWalletStore();
    const patternInput = useFeliz_React__React_useState_Static_1505(wallet.injectiveAddress);
    const setAddresses = patternInput[1];
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton_1.Delay(() => {
            const matchValue = wallet.walletStrategy;
            if (matchValue == null) {
                setAddresses(wallet.injectiveAddress);
                return singleton_1.Zero();
            }
            else {
                return singleton_1.Bind(getWalletAddresses(matchValue), (_arg1) => {
                    const addrRes = _arg1;
                    if (addrRes.tag === 0) {
                        setAddresses(addrRes.fields[0][0]);
                        return singleton_1.Zero();
                    }
                    else {
                        setAddresses(wallet.injectiveAddress);
                        return singleton_1.Zero();
                    }
                });
            }
        }));
    }, [wallet]);
    const snackbar = useSnackbar();
    const createWalletDropdownButton = (text, onClick) => createElement("button", {
        className: "text-exo-primary hover:text-exo-primary-high bg-transparent cursor-pointer",
        children: text,
        onClick: onClick,
    });
    return createElement("div", {
        id: "m2",
        className: "absolute\n                w-48\n                p-5\n                flex\n                flex-col\n                justify-center\n                items-center\n                gap-2\n                bg-exo-background\n                rounded-md\n                drop-shadow\n                z-50",
        ref: parent,
        children: Interop_reactApi.Children.toArray([createWalletDropdownButton("Copy Address", (_arg1_1) => {
            startImmediate(copyTextWeb(patternInput[0]));
            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Address Copied", SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(0, false));
            onClose();
        }), createWalletDropdownButton("Switch Wallet", (_arg2) => {
            onClose();
            openSelector();
        }), createWalletDropdownButton("Disconnect", (_arg3) => {
            wallet.disconnectWallet();
            onClose();
        })]),
    });
}

export function InjectiveWalletSelector(injectiveWalletSelectorInputProps) {
    const modalExtraAttribute = injectiveWalletSelectorInputProps.modalExtraAttribute;
    const onClose = injectiveWalletSelectorInputProps.onClose;
    const wallet = useWalletStore();
    const solanaWallet = useWallet();
    const environment = Recoil_useRecoilValue_5D3EEEEA(injectiveEnvironment);
    const snackbar = useSnackbar();
    const createWalletListButton = (walletName_1) => createElement(WalletListButton, {
        text: walletName_1,
        onClick: (_arg1) => {
            try {
                const patternInput = getWalletTypeByWalletName(walletName_1);
                if (patternInput[1] ? (solanaWallet.wallet != null) : false) {
                    startImmediate(disconnectWallet_1(solanaWallet));
                }
                startImmediate(wallet.connectWallet(patternInput[0], snackbar, environment));
            }
            catch (ex) {
                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, toString(ex), SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(0, false));
            }
            onClose();
        },
    });
    const children = createElement("div", {
        className: "relative\n                    bg-exo-background\n                    p-8\n                    rounded-md\n                    flex\n                    flex-col\n                    items-center\n                    gap-5",
        children: Interop_reactApi.Children.toArray([createElement("h3", {
            className: "text-xl\n                            font-title\n                            font-extrabold\n                            exo-background\n                            inline-block\n                            text-transparent\n                            bg-clip-text",
            children: "Select a wallet",
        }), createWalletListButton("Ninji"), createWalletListButton("OKX / MetaMask"), createWalletListButton("Keplr"), createWalletListButton("Leap"), createElement("button", {
            className: "cursor-pointer text-sm text-exo-primary hover:text-exo-primary-high bg-transparent",
            children: "- Close -",
            onClick: (_arg2) => {
                onClose();
            },
        })]),
    });
    return createElement(Modal, {
        children: children,
        onClose: onClose,
        extraStyleAttribute: modalExtraAttribute,
    });
}

export function WalletBtn(walletBtnInputProps) {
    const environment = walletBtnInputProps.environment;
    const store = useWalletStore();
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setOpenSelector = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setOpenDropdown = patternInput_1[1];
    const openDropdown = patternInput_1[0];
    const anchorEl = useReact_useRef_1505(void 0);
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(store.injectiveAddress);
    const setAddresses = patternInput_2[1];
    const address = patternInput_2[0];
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton_1.Delay(() => {
            const matchValue = store.walletStrategy;
            if (matchValue == null) {
                setAddresses(store.injectiveAddress);
                return singleton_1.Zero();
            }
            else {
                return singleton_1.Bind(getWalletAddresses(matchValue), (_arg1) => {
                    const addrRes = _arg1;
                    if (addrRes.tag === 0) {
                        setAddresses(addrRes.fields[0][0]);
                        return singleton_1.Zero();
                    }
                    else {
                        setAddresses(store.injectiveAddress);
                        return singleton_1.Zero();
                    }
                });
            }
        }));
    }, [store]);
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton_1.Delay(() => singleton_1.Return(store.disconnectWallet())));
    }, [environment]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let addr_1;
            return append(singleton_2(createElement("button", {
                id: "m3",
                ref: anchorEl,
                className: "w-48\n                        shadow-sm\n                        flex\n                        items-center\n                        justify-center\n                        gap-2\n                        h-10\n                        px-5\n                        bg-[length:200%_auto]\n                        rounded-md \n                        bg-gradient-to-r\n                        from-exo-primary\n                        via-exo-primary-high\n                        via-51%\n                        to-exo-primary\n                        hover:bg-right\n                        transition-all\n                        duration-300\n                        ease-in-out\n                        relative",
                children: Interop_reactApi.Children.toArray([createElement("span", {
                    className: "text-white drop-shadow-sm",
                    children: (addr_1 = address, (addr_1 === "") ? "Connect Wallet" : toText(interpolate("%P()...%P()", [addr_1.slice(0, 5 + 1), addr_1.slice(-3, addr_1.length)]))),
                }), createElement("img", {
                    src: accountBalanceWallet,
                    alt: "Wallet",
                })]),
                onClick: (_arg1_1) => {
                    const matchValue_2 = store.walletStrategy;
                    if (matchValue_2 != null) {
                        const _wallet = matchValue_2;
                        if (openDropdown) {
                            if (address === "") {
                                setOpenSelector(true);
                            }
                            setOpenDropdown(false);
                        }
                        else {
                            if (address === "") {
                                setOpenSelector(true);
                            }
                            setOpenDropdown(true);
                        }
                    }
                    else {
                        setOpenSelector(true);
                    }
                },
            })), delay(() => append((openDropdown ? (!(address === "")) : false) ? append(singleton_2(createElement(InjectiveWalletDropdown, {
                parent: anchorEl,
                onClose: () => {
                    setOpenDropdown(false);
                },
                openSelector: () => {
                    setOpenSelector(true);
                },
            })), delay(() => {
                document.addEventListener("mousedown", (e) => {
                    try {
                        const target = e.target;
                        const m2 = document.getElementById("m2");
                        const m3 = document.getElementById("m3");
                        const anyTargetInM3Children = (!equals(m3.children, null)) ? contains(true, map((e_1) => equals(target, e_1), map((i) => (m3.children[i]), toArray(rangeDouble(0, 1, m3.children.length)))), {
                            Equals: (x, y) => (x === y),
                            GetHashCode: (x) => structuralHash(x),
                        }) : false;
                        if (equals(target, m3) ? true : anyTargetInM3Children) {
                        }
                        else if (!equals(m2, null)) {
                            const anyTargetInM2Children = (!equals(m2.children, null)) ? contains(true, map((e_2) => equals(target, e_2), map((i_1) => (m2.children[i_1]), toArray(rangeDouble(0, 1, m2.children.length)))), {
                                Equals: (x_1, y_1) => (x_1 === y_1),
                                GetHashCode: (x_1) => structuralHash(x_1),
                            }) : false;
                            if (!(equals(target, m2) ? true : anyTargetInM2Children)) {
                                setOpenDropdown(false);
                            }
                        }
                    }
                    catch (matchValue_1) {
                    }
                });
                return empty();
            })) : empty(), delay(() => {
                if (patternInput[0]) {
                    return append(singleton_2(createElement(InjectiveWalletSelector, {
                        onClose: () => {
                            setOpenSelector(false);
                            setOpenDropdown(false);
                        },
                        modalExtraAttribute: void 0,
                    })), delay(() => {
                        document.body.classList.add("hide");
                        return empty();
                    }));
                }
                else {
                    document.body.classList.remove("hide");
                    return empty();
                }
            }))));
        })))),
    });
}

export function Navbar(navbarInputProps) {
    const goToCommunityToggle = navbarInputProps.goToCommunityToggle;
    const setGoToCommunityToggle = navbarInputProps.setGoToCommunityToggle;
    const setGoToCommunity = navbarInputProps.setGoToCommunity;
    const setTabIndex = navbarInputProps.setTabIndex;
    const chain = Recoil_useRecoilValue_5D3EEEEA(blockchain);
    const injectiveEnv = Recoil_useRecoilValue_5D3EEEEA(injectiveEnvironment);
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setIsOpenMenu = patternInput[1];
    const isOpenMenu = patternInput[0];
    const navBarBtn = ofArray([createElement(Link, {
        text: "Earn Yield",
        tabIndex: 1,
        setTabIndex: setTabIndex,
        isJoinCommunity: false,
        setGoToCommunity: setGoToCommunity,
        setGoToCommunityToggle: setGoToCommunityToggle,
        goToCommunityToggle: goToCommunityToggle,
    }), createElement(Link, {
        text: "Buy Options",
        tabIndex: 2,
        setTabIndex: setTabIndex,
        isJoinCommunity: false,
        setGoToCommunity: setGoToCommunity,
        setGoToCommunityToggle: setGoToCommunityToggle,
        goToCommunityToggle: goToCommunityToggle,
    }), createElement(Link, {
        text: "Dashboard",
        tabIndex: 3,
        setTabIndex: setTabIndex,
        isJoinCommunity: false,
        setGoToCommunity: setGoToCommunity,
        setGoToCommunityToggle: setGoToCommunityToggle,
        goToCommunityToggle: goToCommunityToggle,
    }), createElement(Link, {
        text: "Join the Community",
        tabIndex: 0,
        setTabIndex: setTabIndex,
        isJoinCommunity: true,
        setGoToCommunity: setGoToCommunity,
        setGoToCommunityToggle: setGoToCommunityToggle,
        goToCommunityToggle: goToCommunityToggle,
    })]);
    return react.createElement(react.Fragment, {}, ...toList(delay(() => append(singleton_2(createElement("nav", {
        className: "flex\n                    lg:px-32\n                    px-5\n                    my-6\n                    justify-between\n                    items-center\n                    text-exo-primary\n                    px-8",
        children: Interop_reactApi.Children.toArray([createElement("a", {
            onClick: (_arg1) => {
                setTabIndex(0);
                setGoToCommunity(false);
                RouterModule_nav(singleton("/"), 1, 1);
            },
            className: "cursor-pointer",
            children: Interop_reactApi.Children.toArray([createElement(ExoticLogo, null)]),
        }), createElement("div", {
            className: "lg:hidden cursor-pointer",
            children: Interop_reactApi.Children.toArray([createElement(BurgerMenu, {
                isOpen: isOpenMenu,
            })]),
            onClick: (_arg2) => {
                setIsOpenMenu(true);
            },
        }), createElement("ul", {
            className: "hidden\n                            lg:flex\n                            lg:flex-auto\n                            lg:justify-evenly",
            children: Interop_reactApi.Children.toArray(Array.from(navBarBtn)),
        }), createElement("div", {
            className: "hidden lg:block",
            children: Interop_reactApi.Children.toArray([createElement(WalletBtn, {
                environment: injectiveEnv,
            })]),
        })]),
    })), delay(() => (isOpenMenu ? singleton_2(createElement(OpenBurgerMenu, {
        children: navBarBtn,
        onClose: () => {
            setIsOpenMenu(false);
        },
    })) : empty()))))));
}

