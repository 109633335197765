import { Record } from "./.fable/fable-library.3.1.16/Types.js";
import { record_type, lambda_type, unit_type, bool_type } from "./.fable/fable-library.3.1.16/Reflection.js";
import { useReact_useMemo_CF4EA67, useReact_useEffect_Z101E1A95, useReact_useEffectOnce_3A5B6456, React_contextProvider_34D9BBBD, useReact_useContext_37FA55CF, React_createContext_1AE444D8 } from "./.fable/Feliz.1.62.0/React.fs.js";
import { map, defaultArg, some } from "./.fable/fable-library.3.1.16/Option.js";
import { WalletProvider, ConnectionProvider, useLocalStorage } from "@solana/wallet-adapter-react";
import { createElement } from "react";
import * as react from "react";
import { Recoil_useRecoilState_Z74A95619, Recoil_useRecoilValue_5D3EEEEA } from "./.fable/Feliz.Recoil.0.19.2/Bindings.fs.js";
import { config as config_3, themeConfig as themeConfig_1, injectiveEnvironment, solanaEnvironment, blockchain as blockchain_1 } from "./Context.fs.js";
import { SnackbarProvider, useSnackbar } from "notistack";
import { startImmediate } from "./.fable/fable-library.3.1.16/Async.js";
import { singleton } from "./.fable/fable-library.3.1.16/AsyncBuilder.js";
import { signalRconnection, Api } from "./Server.fs.js";
import { Blockchain, SolanaEnvironment } from "../../../lib/Domain/Types/Common.fs.js";
import { getCoin98, getSolongWallet, getSolflareWallet, getPhantomWallet } from "../../../lib/Solana/Solana.fs.js";
import { MuiHelpers_createElement } from "./.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import { StylesProvider } from "@material-ui/core/styles";
import { Interop_reactApi } from "./.fable/Feliz.1.62.0/Interop.fs.js";
import { interpolate, toText, join } from "./.fable/fable-library.3.1.16/String.js";
import { singleton as singleton_1, delay, toList } from "./.fable/fable-library.3.1.16/Seq.js";
import { EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../../../lib/Notistack/Notistack.fs.js";
import { WalletContextProvider } from "../../../lib/Injective/Injective.fs.js";
import { Hooks_HubContextProvider } from "../../../lib/Fable.Remoting.SignalR/SignalR.fs.js";
import { NavBar } from "./NavBar.fs.js";
import { Footer } from "./Components/Footer.fs.js";

export class AutoConnectContextState extends Record {
    constructor(autoConnect, setAutoConnect) {
        super();
        this.autoConnect = autoConnect;
        this.setAutoConnect = setAutoConnect;
    }
}

export function AutoConnectContextState$reflection() {
    return record_type("App.AutoConnectContextState", [], AutoConnectContextState, () => [["autoConnect", bool_type], ["setAutoConnect", lambda_type(bool_type, unit_type)]]);
}

export const autoConnectContext = React_createContext_1AE444D8(void 0, some(void 0));

export function useAutoConnect() {
    return useReact_useContext_37FA55CF(autoConnectContext);
}

export function AutoConnectProvider(autoConnectProviderInputProps) {
    const children = autoConnectProviderInputProps.children;
    const patternInput = useLocalStorage("autoConnect", false);
    return React_contextProvider_34D9BBBD(autoConnectContext, new AutoConnectContextState(patternInput[0], patternInput[1]), react.createElement(react.Fragment, {}, ...children));
}

export function ContextProvider(contextProviderInputProps) {
    const children = contextProviderInputProps.children;
    const blockchain = Recoil_useRecoilValue_5D3EEEEA(blockchain_1);
    const environment = Recoil_useRecoilValue_5D3EEEEA(solanaEnvironment);
    const injectiveEnv = Recoil_useRecoilValue_5D3EEEEA(injectiveEnvironment);
    const themeConfig = Recoil_useRecoilValue_5D3EEEEA(themeConfig_1);
    const autoConnectCtx = useAutoConnect();
    const snackbar = useSnackbar();
    const patternInput = Recoil_useRecoilState_Z74A95619(config_3);
    const config = patternInput[0];
    const autoConnect = defaultArg(map((ctx) => ctx.autoConnect, autoConnectCtx), false);
    useReact_useEffectOnce_3A5B6456(() => {
        if (autoConnectCtx == null) {
        }
        else {
            autoConnectCtx.setAutoConnect(true);
        }
    });
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton.Delay(() => singleton.Bind(Api.GetSettings((blockchain.tag === 1) ? ((injectiveEnv.tag === 0) ? (new SolanaEnvironment(0)) : (new SolanaEnvironment(1))) : environment), (_arg1) => {
            patternInput[1](_arg1);
            return singleton.Zero();
        })));
    }, [environment, blockchain, injectiveEnv]);
    const wallets = useReact_useMemo_CF4EA67(() => [[getPhantomWallet(), getSolflareWallet(), getSolongWallet(), getCoin98()], [environment]]);
    return MuiHelpers_createElement(StylesProvider, [["injectFirst", true], ["children", Interop_reactApi.Children.toArray([createElement("div", {
        className: join(" ", ["main-content", themeConfig]),
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            if (config == null) {
                return singleton_1(null);
            }
            else {
                const config_2 = config;
                return singleton_1(Interop_reactApi.createElement(ConnectionProvider, {
                    endpoint: config_2.SolanaUrl,
                    children: Interop_reactApi.Children.toArray([Interop_reactApi.createElement(WalletProvider, {
                        wallets: wallets[0],
                        autoConnect: autoConnect,
                        onError: (error) => {
                            let errorMessage;
                            const matchValue = error.message;
                            errorMessage = ((matchValue == null) ? error.name : toText(interpolate("%P()\n%P()", [error.name, matchValue])));
                            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, errorMessage, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(0, false));
                            console.error(some(errorMessage));
                        },
                        children: Interop_reactApi.Children.toArray(Array.from(children)),
                    })]),
                }));
            }
        })))),
    })])]]);
}

export function App(appInputProps) {
    const isSolana = appInputProps.isSolana;
    const setChain = Recoil_useRecoilState_Z74A95619(blockchain_1)[1];
    useReact_useEffectOnce_3A5B6456(() => {
        if (isSolana) {
            setChain(new Blockchain(0));
        }
        else {
            setChain(new Blockchain(1));
        }
    });
    return createElement(AutoConnectProvider, {
        children: [createElement(ContextProvider, {
            children: [createElement(WalletContextProvider, {
                children: [react.createElement(SnackbarProvider, {}, Hooks_HubContextProvider(signalRconnection, [createElement(NavBar, null), createElement(Footer, null)]))],
            })],
        })],
    });
}

