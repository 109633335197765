import { createElement } from "react";
import { createObj } from "../.fable/fable-library.3.1.16/Util.js";
import { empty, singleton, append, delay, toList } from "../.fable/fable-library.3.1.16/Seq.js";
import { value as value_11 } from "../.fable/fable-library.3.1.16/Option.js";
import { Recoil_useRecoilState_Z74A95619 } from "../.fable/Feliz.Recoil.0.19.2/Bindings.fs.js";
import { appTab } from "../Context.fs.js";
import { ApplicationTab } from "../Types.fs.js";
import { RouterModule_nav } from "../.fable/Feliz.Router.3.8.0/Router.fs.js";
import { singleton as singleton_1 } from "../.fable/fable-library.3.1.16/List.js";
import { Interop_reactApi } from "../.fable/Feliz.1.62.0/Interop.fs.js";
import { WalletLogo } from "./Icon/Menu.fs.js";

export function Button(buttonInputProps) {
    const onClick = buttonInputProps.onClick;
    const externalUrl = buttonInputProps.externalUrl;
    const children = buttonInputProps.children;
    return createElement("a", createObj(toList(delay(() => append((externalUrl != null) ? append(singleton(["target", "_blank"]), delay(() => singleton(["href", value_11(externalUrl)]))) : empty(), delay(() => append(singleton(["className", "text-exo-primary\n                hover:text-exo-background\n                flex\n                justify-center\n                items-center\n                gap-3\n                w-52\n                outline\n                outline-exo-primary\n                outline-1\n                rounded-md\n                p-3\n                hover:outline-none\n                hover:exo-background\n                transition-colors\n                cursor-pointer"]), delay(() => append(singleton(["children", children]), delay(() => ((onClick != null) ? singleton(["onClick", value_11(onClick)]) : empty())))))))))));
}

export function LinkToPageButton(linkToPageButtonInputProps) {
    const goToCommunityToggle = linkToPageButtonInputProps.goToCommunityToggle;
    const setGoToCommunityToggle = linkToPageButtonInputProps.setGoToCommunityToggle;
    const setGoToCommunity = linkToPageButtonInputProps.setGoToCommunity;
    const text = linkToPageButtonInputProps.text;
    const setAppTab = Recoil_useRecoilState_Z74A95619(appTab)[1];
    return createElement(Button, {
        children: text,
        externalUrl: void 0,
        onClick: (_arg1) => {
            switch (text) {
                case "Earn Yield": {
                    setAppTab(new ApplicationTab(1));
                    RouterModule_nav(singleton_1("products"), 1, 1);
                    setGoToCommunity(false);
                    break;
                }
                case "Buy Options": {
                    setAppTab(new ApplicationTab(2));
                    RouterModule_nav(singleton_1("optionality"), 1, 1);
                    setGoToCommunity(false);
                    break;
                }
                case "Join The Community": {
                    setGoToCommunity(true);
                    setGoToCommunityToggle(!goToCommunityToggle);
                    break;
                }
                default: {
                }
            }
        },
    });
}

export function LinkToExternalButton(linkToExternalButtonInputProps) {
    const url = linkToExternalButtonInputProps.url;
    const children = linkToExternalButtonInputProps.children;
    return createElement(Button, {
        children: children,
        externalUrl: url,
        onClick: void 0,
    });
}

export function WalletListButton(walletListButtonInputProps) {
    const onClick = walletListButtonInputProps.onClick;
    const text = walletListButtonInputProps.text;
    return createElement(Button, {
        children: text,
        externalUrl: void 0,
        onClick: onClick,
    });
}

export function ConnectWalletButtonWhite(connectWalletButtonWhiteInputProps) {
    const onClick = connectWalletButtonWhiteInputProps.onClick;
    const text = connectWalletButtonWhiteInputProps.text;
    return createElement("div", {
        className: "flex justify-center items-center",
        children: Interop_reactApi.Children.toArray([createElement("div", {
            id: "inj-wallet-modal",
            className: "text-exo-primary\n                        flex\n                        justify-center\n                        items-center\n                        gap-3\n                        mt-4\n                        w-52\n                        outline\n                        outline-exo-primary\n                        outline-1\n                        rounded-md\n                        hover:text-exo-background\n                        hover:outline-none\n                        hover:hover:exo-background\n                        p-3\n                        transition-colors\n                        cursor-pointer",
            children: Interop_reactApi.Children.toArray([createElement("span", {
                children: text,
            }), createElement(WalletLogo, null)]),
            onClick: onClick,
        })]),
    });
}

export function DashboardWithdrawButton(dashboardWithdrawButtonInputProps) {
    const processingElement = dashboardWithdrawButtonInputProps.processingElement;
    const processing = dashboardWithdrawButtonInputProps.processing;
    const onClick = dashboardWithdrawButtonInputProps.onClick;
    const text = dashboardWithdrawButtonInputProps.text;
    return createElement("div", {
        className: "flex justify-center items-center",
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: "text-exo-primary\n                        flex\n                        justify-center\n                        items-center\n                        w-40\n                        outline\n                        outline-exo-primary\n                        outline-1\n                        rounded-md\n                        hover:text-exo-background\n                        hover:outline-none\n                        hover:hover:exo-background\n                        p-3\n                        transition-colors\n                        cursor-pointer",
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => (processing ? singleton(processingElement) : singleton(createElement("span", {
                children: text,
            }))))))),
            onClick: onClick,
        })]),
    });
}

