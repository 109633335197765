import utils$002Emodule from "./styles/utils.module.scss";
import { createElement } from "react";
import { Col } from "./UtilComponents.fs.js";
import { ofArray, singleton } from "./.fable/fable-library.3.1.16/List.js";
import { join } from "./.fable/fable-library.3.1.16/String.js";
import { notFound } from "./Img.fs.js";
import { Interop_reactApi } from "./.fable/Feliz.1.62.0/Interop.fs.js";
import { RouterModule_nav } from "./.fable/Feliz.Router.3.8.0/Router.fs.js";

export function NotFound() {
    document.body.classList.add(utils$002Emodule["background-secondary"]);
    return createElement(Col, {
        classes: singleton(utils$002Emodule.center),
        children: ofArray([createElement("img", {
            className: join(" ", [utils$002Emodule["margin-l-top"]]),
            src: notFound,
            alt: "Page Not Found",
        }), createElement("div", {
            className: join(" ", [utils$002Emodule["boldness-400"], utils$002Emodule["margin-m-top"]]),
            children: "Oops! Looks like you got lost.",
        }), createElement("div", {
            className: join(" ", [utils$002Emodule["margin-l-top"]]),
            children: Interop_reactApi.Children.toArray([createElement("button", {
                className: join(" ", ["button-primary"]),
                children: "Back to Home Page",
                onClick: (_arg1) => {
                    RouterModule_nav(singleton("products"), 1, 1);
                },
            })]),
        })]),
    });
}

