import { singleton } from "../.fable/fable-library.3.1.16/AsyncBuilder.js";
import { createTransaction, TransactionInstructionInput, PublicKeyDefault, TransactionInstructionKey, transactionInstruction, bn, getTokenAccountBalance, getAssociatedTokenAddress, getBalance, publicKeyBackEndToSolana, findProgramAddress } from "../../../../lib/Solana/Solana.fs.js";
import { encodeU64, encodeU32, encodePublicKey2, encodeString2NoLength } from "../../../../lib/Solana/Layout.fs.js";
import { toNumber, op_Subtraction, compare, op_Addition, op_Multiply, floor, op_Division, fromParts, pow } from "../.fable/fable-library.3.1.16/Decimal.js";
import Decimal from "../.fable/fable-library.3.1.16/Decimal.js";
import { some, value as value_2 } from "../.fable/fable-library.3.1.16/Option.js";
import { max } from "../.fable/fable-library.3.1.16/Util.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.1.16/Choice.js";
import { printf, toText } from "../.fable/fable-library.3.1.16/String.js";
import { toString, fromNumber } from "../.fable/fable-library.3.1.16/Long.js";
import { ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { PublicKey } from "../../../../lib/Domain/Types/Common.fs.js";
import { signAndSendTransaction } from "../../../../lib/Solana/Transactions.fs.js";

export const unitNativeToken = Math.pow(10, 9);

export function getSingleStringPda(string, program) {
    return singleton.Delay(() => singleton.Bind(findProgramAddress([encodeString2NoLength(string)], program), (_arg1) => singleton.Return(_arg1)));
}

export function getStringKeyPda(string, key, program) {
    return singleton.Delay(() => singleton.Bind(findProgramAddress([encodeString2NoLength(string), encodePublicKey2(key)], program), (_arg1) => singleton.Return(_arg1)));
}

export function getVaultStatePda(vaultProgram) {
    return getSingleStringPda("state", vaultProgram);
}

export function getVaultAuthorityPda(vaultProgram) {
    return getSingleStringPda("vault-authority", vaultProgram);
}

export function getVaultCollateralTokenAccountPda(vault, vaultProgram) {
    return getStringKeyPda("vault-collateral", vault, vaultProgram);
}

export function getFlexProductProgramPda(vaultProgram) {
    return getSingleStringPda("devnet-profile", vaultProgram);
}

export function getUserProductPositionPda(walletPublicKey, vault, productProgram) {
    return singleton.Delay(() => singleton.Bind(findProgramAddress([encodePublicKey2(walletPublicKey), encodePublicKey2(vault)], productProgram), (_arg1) => singleton.Return(_arg1)));
}

export function getMemberAccPda(walletPublicKey, pool, stakeProgram) {
    return singleton.Delay(() => singleton.Bind(findProgramAddress([encodePublicKey2(walletPublicKey), encodePublicKey2(pool), encodeString2NoLength("member")], stakeProgram), (_arg1) => singleton.Return(_arg1)));
}

export function floorDecimal(collateralMintTokenInfo, lessDigit, value) {
    const minDigit = (collateralMintTokenInfo.decimals - lessDigit) | 0;
    const multiplier = pow(fromParts(10, 0, 0, false, 0), minDigit);
    return op_Division(floor(op_Multiply(value, multiplier)), multiplier);
}

export function checkBalanceFromUser(connection, walletPublicKey, qty, product, collateralMintTokenInfo) {
    return singleton.Delay(() => {
        const collateralMint = publicKeyBackEndToSolana(product.CollateralMint);
        return singleton.Bind(getBalance(connection, walletPublicKey), (_arg1) => {
            const lamportsBalance_1 = op_Division(new Decimal(_arg1), new Decimal(unitNativeToken));
            return singleton.Bind(getAssociatedTokenAddress(collateralMint, walletPublicKey), (_arg2) => {
                const isNative = collateralMint.toBase58() === "So11111111111111111111111111111111111111112";
                const floorQty = floorDecimal(collateralMintTokenInfo, 2, qty);
                return singleton.TryWith(singleton.Delay(() => singleton.Bind(getTokenAccountBalance(connection, _arg2), (_arg3) => {
                    let arg20, arg20_1;
                    const tokenAmount = new Decimal(value_2(_arg3.value.uiAmount));
                    const tokenAmountPlusLamports = op_Addition(tokenAmount, max((x, y) => compare(x, y), fromParts(0, 0, 0, false, 1), op_Subtraction(lamportsBalance_1, fromParts(1, 0, 0, false, 2))));
                    return ((isNative ? (compare(tokenAmountPlusLamports, floorQty) >= 0) : false) ? true : ((!isNative) ? (compare(tokenAmount, qty) >= 0) : false)) ? singleton.Return(new FSharpResult$2(0, void 0)) : ((isNative ? (compare(tokenAmountPlusLamports, floorQty) < 0) : false) ? singleton.Return(new FSharpResult$2(1, (arg20 = collateralMintTokenInfo.symbol, toText(printf("Insufficient Balance! Please try depositing maximum %M %s. Remember to keep some SOL for on-chain transaction fees"))(tokenAmountPlusLamports)(arg20)))) : singleton.Return(new FSharpResult$2(1, (arg20_1 = collateralMintTokenInfo.symbol, toText(printf("Insufficient Balance! Please try depositing maximum %M %s"))(tokenAmount)(arg20_1)))));
                })), (_arg4) => {
                    let arg20_2, arg10_3;
                    return isNative ? ((compare(max((x_1, y_1) => compare(x_1, y_1), fromParts(0, 0, 0, false, 1), op_Subtraction(lamportsBalance_1, fromParts(1, 0, 0, false, 2))), floorQty) > 0) ? singleton.Return(new FSharpResult$2(0, void 0)) : singleton.Return(new FSharpResult$2(1, (arg20_2 = collateralMintTokenInfo.symbol, toText(printf("Insufficient Balance! Please try depositing maximum %M %s. Remember to keep some SOL for on-chain transaction fees"))(lamportsBalance_1)(arg20_2))))) : singleton.Return(new FSharpResult$2(1, (arg10_3 = collateralMintTokenInfo.symbol, toText(printf("User does not have %s account"))(arg10_3))));
                });
            });
        });
    });
}

export function deposit(connection, vaultProgramPubkey, walletPublicKey, wallet, qty, product, collateralMintTokenInfo) {
    return singleton.Delay(() => {
        const vaultProgram = publicKeyBackEndToSolana(vaultProgramPubkey);
        const vaultAccount = publicKeyBackEndToSolana(product.PublicKey);
        const collateralMint = publicKeyBackEndToSolana(product.CollateralMint);
        return singleton.Bind(getVaultStatePda(vaultProgram), (_arg1) => singleton.Bind(getAssociatedTokenAddress(collateralMint, walletPublicKey), (_arg2) => {
            const userCollateralTokenAcc = _arg2;
            return singleton.Bind(getVaultAuthorityPda(vaultProgram), (_arg3) => singleton.Bind(getVaultCollateralTokenAccountPda(vaultAccount, vaultProgram), (_arg4) => singleton.Bind(getUserProductPositionPda(walletPublicKey, vaultAccount, vaultProgram), (_arg5) => {
                const n2 = 561329636 >>> 0;
                const instxBuf1 = encodeU32(bn(4279623806));
                const instxBuf2 = encodeU32(bn(n2));
                const isNative = collateralMint.toBase58() === "So11111111111111111111111111111111111111112";
                const amount = fromNumber(toNumber(op_Multiply(floorDecimal(collateralMintTokenInfo, 2, qty), new Decimal(Math.pow(10, collateralMintTokenInfo.decimals)))), false);
                const userCollateralDeposit = encodeU64(bn(toString(amount)));
                const bufPayload = Buffer.concat([userCollateralDeposit]);
                const instructionDeposit = transactionInstruction(new TransactionInstructionInput(vaultProgram, Buffer.concat([instxBuf1, instxBuf2, bufPayload]), [new TransactionInstructionKey(_arg1[0], false, false), new TransactionInstructionKey(walletPublicKey, true, true), new TransactionInstructionKey(userCollateralTokenAcc, false, true), new TransactionInstructionKey(vaultAccount, false, true), new TransactionInstructionKey(_arg3[0], false, false), new TransactionInstructionKey(collateralMint, false, false), new TransactionInstructionKey(_arg4[0], false, true), new TransactionInstructionKey(TOKEN_PROGRAM_ID, false, false), new TransactionInstructionKey(_arg5[0], false, true), new TransactionInstructionKey(PublicKeyDefault, false, false)]));
                const transaction = createTransaction();
                return singleton.Combine(isNative ? singleton.TryWith(singleton.Delay(() => singleton.Bind(getTokenAccountBalance(connection, userCollateralTokenAcc), (_arg6) => {
                    return singleton.Zero();
                })), (_arg7) => {
                    let rent;
                    transaction.add((rent = publicKeyBackEndToSolana(new PublicKey(0, "SysvarRent111111111111111111111111111111111")), transactionInstruction(new TransactionInstructionInput(ASSOCIATED_TOKEN_PROGRAM_ID, Buffer.alloc(0), [new TransactionInstructionKey(walletPublicKey, true, true), new TransactionInstructionKey(userCollateralTokenAcc, false, true), new TransactionInstructionKey(walletPublicKey, false, false), new TransactionInstructionKey(collateralMint, false, false), new TransactionInstructionKey(PublicKeyDefault, false, false), new TransactionInstructionKey(TOKEN_PROGRAM_ID, false, false), new TransactionInstructionKey(rent, false, false)]))));
                    return singleton.Zero();
                }) : singleton.Zero(), singleton.Delay(() => {
                    transaction.add(instructionDeposit);
                    const matchValue = wallet.wallet;
                    if (matchValue == null) {
                        return singleton.Return((() => {
                            throw (new Error("Wallet Not Found."));
                        })());
                    }
                    else {
                        console.log(some("sending to wallet"));
                        console.log(some(walletPublicKey.toBase58()));
                        console.log(some("connection"));
                        console.log(some(connection.toString()));
                        return singleton.Bind(signAndSendTransaction(wallet, walletPublicKey, connection, [], transaction), (_arg8) => {
                            const result = _arg8;
                            console.log(some(result));
                            return singleton.Return([result, amount]);
                        });
                    }
                }));
            })));
        }));
    });
}

