import { Record } from "../../../app/client/src/.fable/fable-library.3.1.16/Types.js";
import { PublicKey$reflection } from "./Common.fs.js";
import { record_type, uint8_type } from "../../../app/client/src/.fable/fable-library.3.1.16/Reflection.js";

export class ExoticUnderlying extends Record {
    constructor(PublicKey, BaseMint, QuoteMint, BaseOracle, QuoteOracle, Owner, Bump) {
        super();
        this.PublicKey = PublicKey;
        this.BaseMint = BaseMint;
        this.QuoteMint = QuoteMint;
        this.BaseOracle = BaseOracle;
        this.QuoteOracle = QuoteOracle;
        this.Owner = Owner;
        this.Bump = Bump;
    }
}

export function ExoticUnderlying$reflection() {
    return record_type("ExoticMarkets.Domain.ExoticUnderlying.ExoticUnderlying", [], ExoticUnderlying, () => [["PublicKey", PublicKey$reflection()], ["BaseMint", PublicKey$reflection()], ["QuoteMint", PublicKey$reflection()], ["BaseOracle", PublicKey$reflection()], ["QuoteOracle", PublicKey$reflection()], ["Owner", PublicKey$reflection()], ["Bump", uint8_type]]);
}

