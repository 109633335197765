import { some } from "./.fable/fable-library.3.1.16/Option.js";
import { Component, createElement } from "react";
import * as react from "react";
import { Record } from "./.fable/fable-library.3.1.16/Types.js";
import { option_type, record_type, lambda_type, unit_type, tuple_type, class_type } from "./.fable/fable-library.3.1.16/Reflection.js";
import { join } from "./.fable/fable-library.3.1.16/String.js";
import utils$002Emodule from "./styles/utils.module.scss";
import { Interop_reactApi } from "./.fable/Feliz.1.62.0/Interop.fs.js";

export function ErrorMessage(errorMessageInputProps) {
    const message = errorMessageInputProps.message;
    console.log(some(message));
    return createElement("b", {
        style: {
            color: "red",
        },
        children: message,
    });
}

export class ErrorBoundaryProps extends Record {
    constructor(Inner, OnError) {
        super();
        this.Inner = Inner;
        this.OnError = OnError;
    }
}

export function ErrorBoundaryProps$reflection() {
    return record_type("Error.ErrorBoundaryProps", [], ErrorBoundaryProps, () => [["Inner", class_type("Fable.React.ReactElement")], ["OnError", lambda_type(tuple_type(class_type("System.Exception"), class_type("Error.InfoComponentObject")), unit_type)]]);
}

export class ErrorBoundaryState extends Record {
    constructor(Error$) {
        super();
        this.Error = Error$;
    }
}

export function ErrorBoundaryState$reflection() {
    return record_type("Error.ErrorBoundaryState", [], ErrorBoundaryState, () => [["Error", option_type(class_type("System.Exception"))]]);
}

export class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = (new ErrorBoundaryState(void 0));
    }
    componentDidCatch(error, info) {
        const x = this;
        console.log(some("CAUGHT ERROR:\n " + error.message));
        (x.props).OnError([error, info]);
        x.setState(((state, _arg1) => (new ErrorBoundaryState(error))));
    }
    render() {
        const x = this;
        const matchValue = (x.state).Error;
        if (matchValue == null) {
            return (x.props).Inner;
        }
        else {
            const error = matchValue;
            return createElement("div", {
                className: join(" ", [utils$002Emodule["h-center"], utils$002Emodule["v-center"]]),
                children: Interop_reactApi.Children.toArray([createElement(ErrorMessage, {
                    message: error.message,
                })]),
            });
        }
    }
}

export function ErrorBoundary$reflection() {
    return class_type("Error.ErrorBoundary", void 0, ErrorBoundary, class_type("Fable.React.Component`2", [ErrorBoundaryProps$reflection(), ErrorBoundaryState$reflection()]));
}

export function ErrorBoundary_$ctor_4A9064FD(props) {
    return new ErrorBoundary(props);
}

export function errorBoundaryCatch(onError, element) {
    return react.createElement(ErrorBoundary, new ErrorBoundaryProps(element, onError));
}

export function errorBoundary(element) {
    return react.createElement(ErrorBoundary, new ErrorBoundaryProps(element, (value) => {
    }));
}

