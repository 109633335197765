import { createElement } from "react";
import { Interop_reactApi } from "../../.fable/Feliz.1.62.0/Interop.fs.js";

export function TelegramIcon() {
    return createElement("svg", {
        width: 22,
        height: 20,
        viewBox: (((((0 + " ") + 0) + " ") + 22) + " ") + 20,
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
        children: Interop_reactApi.Children.toArray([createElement("path", {
            d: "M10.9848 13.4083L14.2268 17.0943C15.4268 18.4593 16.0278 19.1423 16.6568 18.9753C17.2848 18.8093 17.5008 17.9113 17.9318 16.1143L20.3218 6.14628C20.9868 3.37828 21.3188 1.99528 20.5808 1.31228C19.8428 0.629283 18.5638 1.13728 16.0058 2.15228L4.1398 6.86528C2.0938 7.67828 1.0708 8.08428 1.0058 8.78228C0.998147 8.85341 0.998147 8.92516 1.0058 8.99628C1.0688 9.69528 2.0898 10.1043 4.1338 10.9233C5.0588 11.2943 5.5218 11.4803 5.8538 11.8353C5.89114 11.8753 5.92714 11.9166 5.9618 11.9593C6.2678 12.3393 6.3978 12.8393 6.6588 13.8353L7.1478 15.7023C7.4008 16.6723 7.5278 17.1583 7.8608 17.2243C8.1938 17.2903 8.4828 16.8883 9.0618 16.0833L10.9848 13.4083ZM10.9848 13.4083L10.6678 13.0783C10.3058 12.7003 10.1248 12.5123 10.1248 12.2783C10.1248 12.0443 10.3048 11.8553 10.6678 11.4783L14.2408 7.75428",
            stroke: "currentColor",
        })]),
        strokeWidth: 1.5,
        strokeLinecap: "round",
        strokeLinejoin: "round",
    });
}

