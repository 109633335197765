import { toString as toString_2, round as round_1, op_Addition, compare, op_Multiply, floor, op_Division, fromParts, pow } from "../.fable/fable-library.3.1.16/Decimal.js";
import Decimal from "../.fable/fable-library.3.1.16/Decimal.js";
import { singleton } from "../.fable/fable-library.3.1.16/AsyncBuilder.js";
import { MsgType, coin, msgExecuteContractCompatParams, msgExecuteContractCompat as msgExecuteContractCompat_1, createBroadcast, getWasmSmartQuery, createMsgBroadcastClient, chainGrpcBankApi, getAddressTokenBalanceUi } from "../../../../lib/Injective/Injective.fs.js";
import { getNetworkEndpoints } from "@injectivelabs/networks";
import { FSharpResult$2 } from "../.fable/fable-library.3.1.16/Choice.js";
import { printf, toText } from "../.fable/fable-library.3.1.16/String.js";
import { toString } from "../.fable/fable-library.3.1.16/Types.js";
import { toString as toString_1, toDecimal } from "../.fable/fable-library.3.1.16/BigInt.js";
import { QueryPriceForQuantityMsg$reflection, QueryPriceForQuantityMsg, ExchangeMsg$reflection, ExchangeMsg, Escrow__GetExchangeMsg } from "../../../../lib/Domain/Types/InjectiveEscrow.fs.js";
import { toString as toString_3, bigint, Auto_generateBoxedEncoder_Z20B7B430 } from "../.fable/Thoth.Json.10.1.0/Encode.fs.js";
import { ExtraCoders, CaseStrategy } from "../.fable/Thoth.Json.10.1.0/Types.fs.js";
import { newGuid } from "../.fable/fable-library.3.1.16/Guid.js";
import { add } from "../.fable/fable-library.3.1.16/Map.js";
import { string, fromString, bigint as bigint_1 } from "../.fable/Thoth.Json.10.1.0/Decode.fs.js";
import { empty } from "../.fable/Thoth.Json.10.1.0/Extra.fs.js";

export function floorDecimal(targetDigit, value) {
    const multiplier = pow(fromParts(10, 0, 0, false, 0), targetDigit);
    return op_Division(floor(op_Multiply(value, multiplier)), multiplier);
}

export function checkBalanceOfUser(environment, network, escrow, address, qty, depositToken) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(getAddressTokenBalanceUi(chainGrpcBankApi(getNetworkEndpoints(network).grpc, environment), address, depositToken.address, depositToken.decimals), (_arg1) => {
        let arg20, arg20_1;
        const userDepositTokenBalanceUi = _arg1;
        const isInj = depositToken.address === "inj";
        const qty_1 = (escrow.Price.tag === 0) ? op_Division(op_Multiply(qty, new Decimal(100000 + 5000)), new Decimal(100000)) : qty;
        return ((isInj ? (compare(userDepositTokenBalanceUi, op_Addition(qty_1, fromParts(9, 0, 0, false, 3))) >= 0) : false) ? true : ((!isInj) ? (compare(userDepositTokenBalanceUi, qty_1) >= 0) : false)) ? singleton.Return(new FSharpResult$2(0, void 0)) : ((isInj ? (compare(op_Addition(qty_1, fromParts(9, 0, 0, false, 3)), userDepositTokenBalanceUi) > 0) : false) ? singleton.Return(new FSharpResult$2(1, (arg20 = depositToken.symbol, toText(printf("Insufficient Balance! Please try maximum %M %s. Remember to keep some INJ for transaction fees"))(userDepositTokenBalanceUi)(arg20)))) : singleton.Return(new FSharpResult$2(1, (arg20_1 = depositToken.symbol, toText(printf("Insufficient Balance! Please try maximum %M %s"))(userDepositTokenBalanceUi)(arg20_1)))));
    })), (_arg2) => singleton.Return(new FSharpResult$2(1, toString(_arg2)))));
}

export function exchange(network, escrowContract, walletStrategy, address, nbEscrowToken, priceQueriedDecimal, escrow, depositToken, wasmApi) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        let copyOfStruct_1;
        const client = createMsgBroadcastClient(network, walletStrategy);
        let queryQuantity;
        if (compare(nbEscrowToken, toDecimal(escrow.Balance)) >= 0) {
            queryQuantity = toString_1(escrow.Balance);
        }
        else {
            let copyOfStruct = round_1(nbEscrowToken);
            queryQuantity = toString_2(copyOfStruct);
        }
        const priceQueried = toString_2(round_1(op_Multiply(priceQueriedDecimal, pow(fromParts(10, 0, 0, false, 0), depositToken.decimals))));
        const exchangeMsg = new ExchangeMsg(Escrow__GetExchangeMsg(escrow, queryQuantity, priceQueried));
        const msg_1 = Auto_generateBoxedEncoder_Z20B7B430(ExchangeMsg$reflection(), new CaseStrategy(2), new ExtraCoders((copyOfStruct_1 = newGuid(), copyOfStruct_1), add("System.Numerics.BigInteger", [(value) => bigint(value), (path) => ((value_1) => bigint_1(path, value_1))], empty.Coders)), void 0)(exchangeMsg);
        const queryPriceForQuantity = new QueryPriceForQuantityMsg(queryQuantity);
        return singleton.Bind(getWasmSmartQuery(wasmApi, escrow.ContractAddress, toString_3(0, Auto_generateBoxedEncoder_Z20B7B430(QueryPriceForQuantityMsg$reflection(), new CaseStrategy(2), void 0, void 0)(queryPriceForQuantity))), (_arg1) => {
            let priceQueriedLatest;
            const r = fromString((path_1, value_4) => string(path_1, value_4), _arg1);
            if (r.tag === 1) {
                throw (new Error("Price is not up-to-date. Refresh and try again."));
            }
            else {
                priceQueriedLatest = r.fields[0];
            }
            let sendAmount;
            if (escrow.Price.tag === 1) {
                sendAmount = priceQueriedLatest;
            }
            else {
                let copyOfStruct_2 = round_1(op_Division(op_Multiply(new Decimal(priceQueried), new Decimal(100000 + 5000)), new Decimal(100000)));
                sendAmount = toString_2(copyOfStruct_2);
            }
            return singleton.Bind(createBroadcast(client, new MsgType(1, msgExecuteContractCompat_1(msgExecuteContractCompatParams([coin(depositToken.address, sendAmount)], address, escrowContract, msg_1))), address), (_arg2) => singleton.Return(new FSharpResult$2(0, [_arg2, op_Division(new Decimal(sendAmount), pow(fromParts(10, 0, 0, false, 0), depositToken.decimals)), exchangeMsg.Exchange.Quantity])));
        });
    }), (_arg3) => {
        const ex = _arg3;
        return singleton.Return(new FSharpResult$2(1, (toString(ex).indexOf("Oracle time error,") === 0) ? "Price is not up-to-date. Refresh and try again." : toString(ex)));
    }));
}

