import { Record } from "../../../app/client/src/.fable/fable-library.3.1.16/Types.js";
import { PublicKey$reflection } from "./Common.fs.js";
import { list_type, tuple_type, record_type, class_type } from "../../../app/client/src/.fable/fable-library.3.1.16/Reflection.js";
import { op_Division } from "../../../app/client/src/.fable/fable-library.3.1.16/Decimal.js";
import Decimal from "../../../app/client/src/.fable/fable-library.3.1.16/Decimal.js";
import { toNumber } from "../../../app/client/src/.fable/fable-library.3.1.16/Long.js";
import { FlexProduct$reflection } from "./FlexProduct.fs.js";

export class Position_Position extends Record {
    constructor(User, Product, BuyAmount, SellAmount) {
        super();
        this.User = User;
        this.Product = Product;
        this.BuyAmount = BuyAmount;
        this.SellAmount = SellAmount;
    }
}

export function Position_Position$reflection() {
    return record_type("ExoticMarkets.Domain.Position.Position", [], Position_Position, () => [["User", PublicKey$reflection()], ["Product", PublicKey$reflection()], ["BuyAmount", class_type("System.UInt64")], ["SellAmount", class_type("System.UInt64")]]);
}

export function Position_Position__Size_Z524259A4(this$, decimals) {
    return op_Division(new Decimal(toNumber(this$.BuyAmount)), new Decimal(Math.pow(10, decimals)));
}

export class PositionWithProduct_PositionWithProduct extends Record {
    constructor(User, Products) {
        super();
        this.User = User;
        this.Products = Products;
    }
}

export function PositionWithProduct_PositionWithProduct$reflection() {
    return record_type("ExoticMarkets.Domain.PositionWithProduct.PositionWithProduct", [], PositionWithProduct_PositionWithProduct, () => [["User", PublicKey$reflection()], ["Products", list_type(tuple_type(Position_Position$reflection(), FlexProduct$reflection()))]]);
}

