import { Record } from "../../../app/client/src/.fable/fable-library.3.1.16/Types.js";
import { PublicKey$reflection } from "./Common.fs.js";
import { record_type, uint8_type, class_type } from "../../../app/client/src/.fable/fable-library.3.1.16/Reflection.js";
import { fromParts, pow, op_Division } from "../../../app/client/src/.fable/fable-library.3.1.16/Decimal.js";
import Decimal from "../../../app/client/src/.fable/fable-library.3.1.16/Decimal.js";
import { toNumber } from "../../../app/client/src/.fable/fable-library.3.1.16/Long.js";

export class HoldingPosition extends Record {
    constructor(PublicKey, User, Holding, BuyAmount, SellAmount, Bump) {
        super();
        this.PublicKey = PublicKey;
        this.User = User;
        this.Holding = Holding;
        this.BuyAmount = BuyAmount;
        this.SellAmount = SellAmount;
        this.Bump = Bump;
    }
}

export function HoldingPosition$reflection() {
    return record_type("ExoticMarkets.Domain.HoldingPosition.HoldingPosition", [], HoldingPosition, () => [["PublicKey", PublicKey$reflection()], ["User", PublicKey$reflection()], ["Holding", PublicKey$reflection()], ["BuyAmount", class_type("System.UInt64")], ["SellAmount", class_type("System.UInt64")], ["Bump", uint8_type]]);
}

export function HoldingPosition__Size_Z17E62494(this$, holding, decimals) {
    const matchValue = holding.PossibleOrderDirection;
    switch (matchValue.tag) {
        case 1: {
            return op_Division(new Decimal(toNumber(this$.SellAmount)), pow(fromParts(10, 0, 0, false, 0), decimals));
        }
        case 2: {
            throw (new Error("No BuyAndSell products available"));
        }
        default: {
            return op_Division(new Decimal(toNumber(this$.BuyAmount)), pow(fromParts(10, 0, 0, false, 0), decimals));
        }
    }
}

