import { useReact_useEffect_Z5ECA432F, useReact_useEffect_Z101E1A95, useReact_useRef_1505, useFeliz_React__React_useState_Static_1505, useReact_useEffect_Z5234A374, React_useStateWithUpdater_1505 } from "../../app/client/src/.fable/Feliz.1.62.0/React.fs.js";
import { addSeconds } from "../../app/client/src/.fable/fable-library.3.1.16/DateOffset.js";
import { some, value as value_1 } from "../../app/client/src/.fable/fable-library.3.1.16/Option.js";

export function useCountdown(fromSeconds) {
    const patternInput = React_useStateWithUpdater_1505(fromSeconds);
    const setSeconds = patternInput[1];
    const seconds = patternInput[0] | 0;
    useReact_useEffect_Z5234A374(() => {
        setSeconds((_arg1_1) => fromSeconds);
        const subscriptionId = window.setInterval((_arg1) => {
            if (seconds > 0) {
                setSeconds((s) => (s - 1));
            }
        }, 1000);
        return {
            Dispose() {
                window.clearTimeout(subscriptionId);
            },
        };
    }, [fromSeconds]);
    return seconds | 0;
}

export function useDateOffset(date) {
    const patternInput = React_useStateWithUpdater_1505(date);
    const setDate = patternInput[1];
    const date_1 = patternInput[0];
    useReact_useEffect_Z5234A374(() => {
        setDate((_arg1_1) => date_1);
        const subscriptionId = window.setInterval((_arg1) => {
            setDate((d) => addSeconds(d, 1));
        }, 1000);
        return {
            Dispose() {
                window.clearTimeout(subscriptionId);
            },
        };
    }, [date_1]);
    return date_1;
}

export function useToggle(initial) {
    const patternInput = useFeliz_React__React_useState_Static_1505(initial);
    const setIsOn = patternInput[1];
    const isOn = patternInput[0];
    return {
        Off: () => {
            setIsOn(false);
        },
        On: () => {
            setIsOn(true);
        },
        State: isOn,
        Toggle: () => {
            setIsOn(!isOn);
        },
    };
}

export function usePolling(callback, seconds, dependencies) {
    const savedCallback = useReact_useRef_1505(void 0);
    useReact_useEffect_Z101E1A95(() => {
        savedCallback.current = callback;
    }, dependencies);
    useReact_useEffect_Z5ECA432F(() => {
        const id = window.setInterval(() => {
            const matchValue = savedCallback.current;
            if (matchValue == null) {
            }
            else {
                matchValue();
            }
        }, seconds * 1000);
        return {
            Dispose() {
                window.clearInterval(id);
            },
        };
    });
}

export function useFormField(initialValue, validator) {
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(initialValue);
    const setFieldValue = patternInput_1[1];
    const fieldValue = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const setError = patternInput_2[1];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(false);
    const setTouched = patternInput_3[1];
    return {
        Error: patternInput_2[0],
        FieldValue: fieldValue,
        Reset: () => {
            setFieldValue(initialValue);
            setError(false);
            setTouched(false);
        },
        SetFieldValue: (x) => {
            setTouched(true);
            setFieldValue(x);
        },
        Touched: patternInput_3[0],
        Validate: () => {
            const matchValue = validator(fieldValue);
            if (matchValue == null) {
                setError(true);
                return void 0;
            }
            else {
                const x_1 = value_1(matchValue);
                patternInput[1](some(x_1));
                return some(x_1);
            }
        },
        Value: patternInput[0],
    };
}

