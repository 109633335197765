import { createElement } from "react";
import { Router } from "./Router.fs.js";
import { singleton } from "./.fable/fable-library.3.1.16/List.js";
import { Recoil_RecoilRoot_4E60E31B } from "./.fable/Feliz.Recoil.0.19.2/Bindings.fs.js";
import { Interop_reactApi } from "./.fable/Feliz.1.62.0/Interop.fs.js";

export function Root() {
    const children = singleton(createElement(Router, null));
    return Recoil_RecoilRoot_4E60E31B({
        children: Interop_reactApi.Children.toArray(children),
    });
}

