import { createElement } from "react";
import { Interop_reactApi } from "./.fable/Feliz.1.62.0/Interop.fs.js";
import { empty, singleton, append, delay, toList } from "./.fable/fable-library.3.1.16/Seq.js";
import { interpolate, toText, isNullOrWhiteSpace, join } from "./.fable/fable-library.3.1.16/String.js";
import single$002Dproducts$002Emodule from "./styles/single-products.module.scss";
import { errorBoundary } from "./Error.fs.js";
import { equals } from "./.fable/fable-library.3.1.16/Util.js";
import utils$002Emodule from "./styles/utils.module.scss";

export function ProductDetailHeader(productDetailHeaderInputProps) {
    const subheading = productDetailHeaderInputProps.subheading;
    const heading = productDetailHeaderInputProps.heading;
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(createElement("div", {
            className: join(" ", [single$002Dproducts$002Emodule["modal-header"]]),
            children: Interop_reactApi.Children.toArray([heading]),
        })), delay(() => ((!isNullOrWhiteSpace(subheading)) ? singleton(createElement("div", {
            className: join(" ", [single$002Dproducts$002Emodule["modal-subheader"]]),
            children: subheading,
        })) : empty()))))))),
    });
}

export function ProductInfoCell(productInfoCellInputProps) {
    const onSelected = productInfoCellInputProps.onSelected;
    const selectedProduct = productInfoCellInputProps.selectedProduct;
    const apy = productInfoCellInputProps.apy;
    const publicKey = productInfoCellInputProps.publicKey;
    return errorBoundary(createElement("div", {
        className: join(" ", toList(delay(() => append(singleton(single$002Dproducts$002Emodule["modal-product-info"]), delay(() => {
            let p_1;
            return (selectedProduct != null) ? (equals(selectedProduct, publicKey) ? (p_1 = selectedProduct, singleton(single$002Dproducts$002Emodule["modal-product-info-selected"])) : singleton("")) : singleton("");
        }))))),
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: join(" ", [utils$002Emodule["boldness-400"]]),
            children: Interop_reactApi.Children.toArray([toText(interpolate("%P()%%", [apy]))]),
        }), createElement("div", {
            className: join(" ", [utils$002Emodule["text-xxs"], utils$002Emodule["text-light"], utils$002Emodule["margin-xxs-top"], utils$002Emodule["boldness-400"]]),
            children: Interop_reactApi.Children.toArray([toText(interpolate("APY", []))]),
        })]),
        onClick: (_arg1) => {
            onSelected(publicKey);
        },
    }));
}

export function InjectiveProductInfoCell(injectiveProductInfoCellInputProps) {
    const onSelected = injectiveProductInfoCellInputProps.onSelected;
    const selectedProduct = injectiveProductInfoCellInputProps.selectedProduct;
    const apy = injectiveProductInfoCellInputProps.apy;
    const address = injectiveProductInfoCellInputProps.address;
    return errorBoundary(createElement("div", {
        className: join(" ", toList(delay(() => append(singleton(single$002Dproducts$002Emodule["modal-product-info"]), delay(() => {
            let p_1;
            return (selectedProduct != null) ? ((selectedProduct === address) ? (p_1 = selectedProduct, singleton(single$002Dproducts$002Emodule["modal-product-info-selected"])) : singleton("")) : singleton("");
        }))))),
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: join(" ", [utils$002Emodule["boldness-400"]]),
            children: Interop_reactApi.Children.toArray([toText(interpolate("%P()%%", [apy]))]),
        }), createElement("div", {
            className: join(" ", [utils$002Emodule["text-xxs"], utils$002Emodule["text-light"], utils$002Emodule["margin-xxs-top"], utils$002Emodule["boldness-400"]]),
            children: Interop_reactApi.Children.toArray([toText(interpolate("APY", []))]),
        })]),
        onClick: (_arg1) => {
            onSelected(address);
        },
    }));
}

