import { class_type } from "./.fable/fable-library.3.1.16/Reflection.js";
import { createElement } from "react";
import { createObj } from "./.fable/fable-library.3.1.16/Util.js";
import { empty, singleton, append, delay, toList } from "./.fable/fable-library.3.1.16/Seq.js";
import { join } from "./.fable/fable-library.3.1.16/String.js";
import badge$002Emodule from "./styles/badge.module.scss";
import { defaultArg } from "./.fable/fable-library.3.1.16/Option.js";
import { empty as empty_1 } from "./.fable/fable-library.3.1.16/List.js";
import { Interop_reactApi } from "./.fable/Feliz.1.62.0/Interop.fs.js";

export class Components {
    constructor() {
    }
}

export function Components$reflection() {
    return class_type("Badge.Components", void 0, Components);
}

export function Components_$ctor() {
    return new Components();
}

export function Components_Badge_1638E216(components_Badge_1638E216InputProps) {
    const props = components_Badge_1638E216InputProps.props;
    const selected = components_Badge_1638E216InputProps.selected;
    const text = components_Badge_1638E216InputProps.text;
    return createElement("span", createObj(toList(delay(() => append(singleton(["className", join(" ", toList(delay(() => append(singleton(badge$002Emodule.badge), delay(() => (selected ? singleton(badge$002Emodule.selected) : empty()))))))]), delay(() => append(defaultArg(props, empty_1()), delay(() => singleton(["children", Interop_reactApi.Children.toArray([text])])))))))));
}

export function Components_Badge2_Z55EFCE8F(components_Badge2_Z55EFCE8FInputProps) {
    const selected = components_Badge2_Z55EFCE8FInputProps.selected;
    const text = components_Badge2_Z55EFCE8FInputProps.text;
    return createElement("span", {
        className: join(" ", toList(delay(() => append(singleton(badge$002Emodule.badge2), delay(() => (selected ? singleton(badge$002Emodule.selected2) : empty())))))),
        children: Interop_reactApi.Children.toArray([text]),
    });
}

