import { createElement } from "react";
import { Interop_reactApi } from "../../.fable/Feliz.1.62.0/Interop.fs.js";

export function BullishLogo() {
    return createElement("svg", {
        width: 48,
        height: 48,
        viewBox: (((((0 + " ") + 0) + " ") + 48) + " ") + 48,
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
        children: Interop_reactApi.Children.toArray([createElement("path", {
            d: "M27.9067 20.2848C27.9067 21.209 28.6568 21.9592 29.5811 21.9592H31.1216L26.6274 26.4533C26.5756 26.5053 26.514 26.5465 26.4462 26.5747C26.3784 26.6028 26.3057 26.6173 26.2323 26.6173C26.1588 26.6173 26.0862 26.6028 26.0183 26.5747C25.9505 26.5465 25.8889 26.5053 25.8371 26.4533L22.2963 22.9125C21.5636 22.1804 20.5703 21.7691 19.5346 21.7691C18.4989 21.7691 17.5055 22.1804 16.7729 22.9125L11.6537 28.0317C11.4891 28.185 11.3572 28.3699 11.2657 28.5753C11.1742 28.7807 11.125 29.0024 11.121 29.2272C11.117 29.4521 11.1584 29.6754 11.2426 29.8839C11.3268 30.0924 11.4522 30.2818 11.6112 30.4408C11.7702 30.5998 11.9595 30.7251 12.168 30.8093C12.3765 30.8935 12.5999 30.9349 12.8247 30.9309C13.0495 30.927 13.2712 30.8778 13.4766 30.7862C13.682 30.6947 13.8669 30.5628 14.0202 30.3983L19.1394 25.279C19.1913 25.227 19.2529 25.1858 19.3207 25.1576C19.3885 25.1295 19.4612 25.115 19.5346 25.115C19.608 25.115 19.6807 25.1295 19.7485 25.1576C19.8163 25.1858 19.8779 25.227 19.9298 25.279L23.4706 28.8198C24.2032 29.5519 25.1965 29.9632 26.2323 29.9632C27.268 29.9632 28.2613 29.5519 28.9939 28.8198L33.4881 24.3279V25.8662C33.4881 26.3102 33.6645 26.7361 33.9785 27.0502C34.2925 27.3642 34.7184 27.5406 35.1625 27.5406C35.6066 27.5406 36.0325 27.3642 36.3465 27.0502C36.6605 26.7361 36.8369 26.3102 36.8369 25.8662V20.2848C36.8369 19.8407 36.6605 19.4148 36.3465 19.1008C36.0325 18.7868 35.6066 18.6104 35.1625 18.6104H29.5811C29.137 18.6104 28.7111 18.7868 28.3971 19.1008C28.0831 19.4148 27.9067 19.8407 27.9067 20.2848Z",
            fill: "#10E040",
        }), createElement("path", {
            d: "M23.8727 0.75C18.7178 0.75 14.6791 0.75 11.5267 1.17419C8.30288 1.6073 5.75777 2.51372 3.75963 4.50963C1.76149 6.50777 0.857302 9.05288 0.424186 12.2789C1.33071e-07 15.4291 0 19.4678 0 24.6227V24.8773C0 30.0322 1.33071e-07 34.0709 0.424186 37.2233C0.857302 40.4471 1.76372 42.9922 3.75963 44.9904C5.75777 46.9885 8.30288 47.8927 11.5289 48.3258C14.6791 48.75 18.7178 48.75 23.8727 48.75H24.1273C29.2822 48.75 33.3209 48.75 36.4733 48.3258C39.6971 47.8927 42.2422 46.9863 44.2404 44.9904C46.2385 42.9922 47.1427 40.4471 47.5758 37.2211C48 34.0709 48 30.0322 48 24.8773V24.6227C48 19.4678 48 15.4291 47.5758 12.2767C47.1427 9.05288 46.2363 6.50777 44.2404 4.50963C42.2422 2.51149 39.6971 1.6073 36.4711 1.17419C33.3209 0.75 29.2822 0.75 24.1273 0.75H23.8727ZM6.12837 6.87837C7.40093 5.60581 9.12 4.87577 11.9754 4.49177C14.8778 4.1033 18.691 4.09884 24 4.09884C29.309 4.09884 33.1222 4.1033 36.0246 4.49177C38.88 4.87577 40.6013 5.60805 41.8739 6.87837C43.1442 8.15093 43.8742 9.87 44.2582 12.7254C44.6467 15.6278 44.6512 19.441 44.6512 24.75C44.6512 30.059 44.6467 33.8722 44.2582 36.7746C43.8742 39.63 43.142 41.3513 41.8716 42.6239C40.5991 43.8942 38.88 44.6242 36.0246 45.0082C33.1222 45.3967 29.309 45.4012 24 45.4012C18.691 45.4012 14.8778 45.3967 11.9754 45.0082C9.12 44.6242 7.3987 43.892 6.12614 42.6216C4.85581 41.3491 4.12577 39.63 3.74177 36.7746C3.3533 33.8722 3.34884 30.059 3.34884 24.75C3.34884 19.441 3.3533 15.6278 3.74177 12.7254C4.12577 9.87 4.85805 8.15093 6.12837 6.87837Z",
            fill: "#10E040",
            clipRule: "evenodd",
            fillRule: "evenodd",
        })]),
    });
}

export function BearishLogo() {
    return createElement("svg", {
        width: 48,
        height: 48,
        viewBox: (((((0 + " ") + 0) + " ") + 48) + " ") + 48,
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
        children: Interop_reactApi.Children.toArray([createElement("path", {
            d: "M27.9068 29.2152C27.9068 28.291 28.6569 27.5408 29.5812 27.5408H31.1217L26.6275 23.0467C26.5757 22.9947 26.5141 22.9535 26.4463 22.9253C26.3785 22.8972 26.3058 22.8827 26.2324 22.8827C26.159 22.8827 26.0863 22.8972 26.0185 22.9253C25.9507 22.9535 25.8891 22.9947 25.8372 23.0467L22.2964 26.5875C21.5638 27.3196 20.5704 27.7309 19.5347 27.7309C18.499 27.7309 17.5057 27.3196 16.773 26.5875L11.6538 21.4683C11.4893 21.315 11.3573 21.1301 11.2658 20.9247C11.1743 20.7193 11.1251 20.4976 11.1211 20.2728C11.1171 20.0479 11.1585 19.8246 11.2427 19.6161C11.3269 19.4076 11.4523 19.2182 11.6113 19.0592C11.7703 18.9002 11.9597 18.7749 12.1682 18.6907C12.3767 18.6065 12.6 18.5651 12.8248 18.5691C13.0496 18.573 13.2714 18.6222 13.4767 18.7138C13.6821 18.8053 13.867 18.9372 14.0203 19.1017L19.1395 24.221C19.1914 24.273 19.253 24.3142 19.3208 24.3424C19.3886 24.3705 19.4613 24.385 19.5347 24.385C19.6081 24.385 19.6808 24.3705 19.7486 24.3424C19.8164 24.3142 19.878 24.273 19.9299 24.221L23.4707 20.6802C24.2033 19.9481 25.1967 19.5368 26.2324 19.5368C27.2681 19.5368 28.2614 19.9481 28.9941 20.6802L33.4882 25.1721V23.6338C33.4882 23.1898 33.6646 22.7639 33.9786 22.4498C34.2926 22.1358 34.7185 21.9594 35.1626 21.9594C35.6067 21.9594 36.0326 22.1358 36.3466 22.4498C36.6606 22.7639 36.837 23.1898 36.837 23.6338V29.2152C36.837 29.6593 36.6606 30.0852 36.3466 30.3992C36.0326 30.7132 35.6067 30.8896 35.1626 30.8896H29.5812C29.1371 30.8896 28.7112 30.7132 28.3972 30.3992C28.0832 30.0852 27.9068 29.6593 27.9068 29.2152Z",
            fill: "#F24949",
        }), createElement("path", {
            d: "M23.8727 48.75C18.7178 48.75 14.6791 48.75 11.5267 48.3258C8.30288 47.8927 5.75777 46.9863 3.75963 44.9904C1.76149 42.9922 0.857303 40.4471 0.424186 37.2211C1.33071e-07 34.0709 0 30.0322 0 24.8773V24.6227C0 19.4678 1.33071e-07 15.4291 0.424186 12.2767C0.857303 9.05288 1.76372 6.50777 3.75963 4.50963C5.75777 2.51149 8.30288 1.6073 11.5289 1.17418C14.6791 0.749999 18.7178 0.749998 23.8727 0.749998H24.1273C29.2822 0.749998 33.3209 0.749999 36.4733 1.17418C39.6971 1.6073 42.2422 2.51372 44.2404 4.50963C46.2385 6.50777 47.1427 9.05288 47.5758 12.2789C48 15.4291 48 19.4678 48 24.6227V24.8773C48 30.0322 48 34.0709 47.5758 37.2233C47.1427 40.4471 46.2363 42.9922 44.2404 44.9904C42.2422 46.9885 39.6971 47.8927 36.4711 48.3258C33.3209 48.75 29.2822 48.75 24.1273 48.75H23.8727ZM6.12837 42.6216C7.40093 43.8942 9.12 44.6242 11.9754 45.0082C14.8778 45.3967 18.691 45.4012 24 45.4012C29.309 45.4012 33.1222 45.3967 36.0246 45.0082C38.88 44.6242 40.6013 43.892 41.8739 42.6216C43.1442 41.3491 43.8742 39.63 44.2582 36.7746C44.6467 33.8722 44.6512 30.059 44.6512 24.75C44.6512 19.441 44.6467 15.6278 44.2582 12.7254C43.8742 9.87 43.142 8.14869 41.8716 6.87614C40.5991 5.60581 38.88 4.87577 36.0246 4.49177C33.1222 4.1033 29.309 4.09883 24 4.09883C18.691 4.09883 14.8778 4.1033 11.9754 4.49177C9.12 4.87577 7.3987 5.60805 6.12614 6.87837C4.85581 8.15093 4.12577 9.87 3.74177 12.7254C3.3533 15.6278 3.34884 19.441 3.34884 24.75C3.34884 30.059 3.3533 33.8722 3.74177 36.7746C4.12577 39.63 4.85805 41.3491 6.12837 42.6216Z",
            fill: "#F24949",
            clipRule: "evenodd",
            fillRule: "evenodd",
        })]),
    });
}

export function MoneyLogo() {
    return createElement("svg", {
        width: 16,
        height: 16,
        viewBox: (((((0 + " ") + 0) + " ") + 16) + " ") + 16,
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
        children: Interop_reactApi.Children.toArray([createElement("path", {
            d: "M8 0C3.58818 0 0 3.58818 0 8C0 12.4118 3.58901 16 8 16C12.4118 16 16 12.411 16 8C16 3.58818 12.411 0 8 0ZM8 14.7845C4.25934 14.7845 1.21613 11.7413 1.21613 8.00062C1.21613 4.25913 4.25934 1.21593 8 1.21593C11.7407 1.21593 14.7839 4.25913 14.7839 7.99979C14.7839 11.7405 11.7407 14.7837 8 14.7837V14.7845Z",
            fill: "#FF7B4E",
        }), createElement("path", {
            d: "M8.4223 7.41706C7.25434 7.0502 6.87787 6.76927 6.87787 6.25472C6.87787 5.85978 7.23989 5.39661 8.26417 5.39661C8.64064 5.39661 9.00669 5.44798 9.35427 5.54993C9.52284 5.59809 9.70585 5.59247 9.86801 5.52986C10.0302 5.46725 10.1498 5.35647 10.206 5.22001C10.3223 4.94469 10.1393 4.64285 9.80137 4.54654C9.43211 4.44138 9.01711 4.38198 8.56278 4.36432V3.86422C8.56278 3.55197 8.2465 3.2959 7.85959 3.2959C7.47268 3.2959 7.1564 3.55197 7.1564 3.86422V4.49836C6.79758 4.59228 6.4813 4.73436 6.20998 4.9222C5.6858 5.28583 5.39763 5.79476 5.39763 6.35748C5.39763 6.85517 5.61597 7.28702 6.04864 7.63943C6.43235 7.95248 6.98462 8.20775 7.74079 8.42369C8.81404 8.75602 9.19371 9.07148 9.19371 9.62295C9.19371 10.2394 8.59568 10.6231 7.63161 10.6231C7.04722 10.6231 6.54794 10.4955 6.23487 10.3871C6.06228 10.3277 5.86561 10.3302 5.69303 10.396C5.52446 10.4586 5.39763 10.5782 5.34465 10.7227L5.34144 10.7283C5.24271 10.99 5.40486 11.2685 5.71791 11.3737C6.14736 11.519 6.67475 11.6153 7.19172 11.6466V12.1355C7.19172 12.4477 7.508 12.7038 7.89491 12.7038C8.28182 12.7038 8.5981 12.4477 8.5981 12.1355V11.5671C9.11184 11.4676 9.55493 11.2886 9.8961 11.0381C10.4058 10.6657 10.6876 10.1375 10.6876 9.5547C10.6876 8.5513 10.0084 7.91146 8.4223 7.41706Z",
            fill: "#FF7B4E",
        })]),
    });
}

export function BullishLogoSmall() {
    return createElement("svg", {
        width: 16,
        height: 16,
        viewBox: (((((0 + " ") + 0) + " ") + 48) + " ") + 48,
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
        children: Interop_reactApi.Children.toArray([createElement("path", {
            d: "M27.9067 20.2848C27.9067 21.209 28.6568 21.9592 29.5811 21.9592H31.1216L26.6274 26.4533C26.5756 26.5053 26.514 26.5465 26.4462 26.5747C26.3784 26.6028 26.3057 26.6173 26.2323 26.6173C26.1588 26.6173 26.0862 26.6028 26.0183 26.5747C25.9505 26.5465 25.8889 26.5053 25.8371 26.4533L22.2963 22.9125C21.5636 22.1804 20.5703 21.7691 19.5346 21.7691C18.4989 21.7691 17.5055 22.1804 16.7729 22.9125L11.6537 28.0317C11.4891 28.185 11.3572 28.3699 11.2657 28.5753C11.1742 28.7807 11.125 29.0024 11.121 29.2272C11.117 29.4521 11.1584 29.6754 11.2426 29.8839C11.3268 30.0924 11.4522 30.2818 11.6112 30.4408C11.7702 30.5998 11.9595 30.7251 12.168 30.8093C12.3765 30.8935 12.5999 30.9349 12.8247 30.9309C13.0495 30.927 13.2712 30.8778 13.4766 30.7862C13.682 30.6947 13.8669 30.5628 14.0202 30.3983L19.1394 25.279C19.1913 25.227 19.2529 25.1858 19.3207 25.1576C19.3885 25.1295 19.4612 25.115 19.5346 25.115C19.608 25.115 19.6807 25.1295 19.7485 25.1576C19.8163 25.1858 19.8779 25.227 19.9298 25.279L23.4706 28.8198C24.2032 29.5519 25.1965 29.9632 26.2323 29.9632C27.268 29.9632 28.2613 29.5519 28.9939 28.8198L33.4881 24.3279V25.8662C33.4881 26.3102 33.6645 26.7361 33.9785 27.0502C34.2925 27.3642 34.7184 27.5406 35.1625 27.5406C35.6066 27.5406 36.0325 27.3642 36.3465 27.0502C36.6605 26.7361 36.8369 26.3102 36.8369 25.8662V20.2848C36.8369 19.8407 36.6605 19.4148 36.3465 19.1008C36.0325 18.7868 35.6066 18.6104 35.1625 18.6104H29.5811C29.137 18.6104 28.7111 18.7868 28.3971 19.1008C28.0831 19.4148 27.9067 19.8407 27.9067 20.2848Z",
            fill: "#10E040",
        }), createElement("path", {
            d: "M23.8727 0.75C18.7178 0.75 14.6791 0.75 11.5267 1.17419C8.30288 1.6073 5.75777 2.51372 3.75963 4.50963C1.76149 6.50777 0.857302 9.05288 0.424186 12.2789C1.33071e-07 15.4291 0 19.4678 0 24.6227V24.8773C0 30.0322 1.33071e-07 34.0709 0.424186 37.2233C0.857302 40.4471 1.76372 42.9922 3.75963 44.9904C5.75777 46.9885 8.30288 47.8927 11.5289 48.3258C14.6791 48.75 18.7178 48.75 23.8727 48.75H24.1273C29.2822 48.75 33.3209 48.75 36.4733 48.3258C39.6971 47.8927 42.2422 46.9863 44.2404 44.9904C46.2385 42.9922 47.1427 40.4471 47.5758 37.2211C48 34.0709 48 30.0322 48 24.8773V24.6227C48 19.4678 48 15.4291 47.5758 12.2767C47.1427 9.05288 46.2363 6.50777 44.2404 4.50963C42.2422 2.51149 39.6971 1.6073 36.4711 1.17419C33.3209 0.75 29.2822 0.75 24.1273 0.75H23.8727ZM6.12837 6.87837C7.40093 5.60581 9.12 4.87577 11.9754 4.49177C14.8778 4.1033 18.691 4.09884 24 4.09884C29.309 4.09884 33.1222 4.1033 36.0246 4.49177C38.88 4.87577 40.6013 5.60805 41.8739 6.87837C43.1442 8.15093 43.8742 9.87 44.2582 12.7254C44.6467 15.6278 44.6512 19.441 44.6512 24.75C44.6512 30.059 44.6467 33.8722 44.2582 36.7746C43.8742 39.63 43.142 41.3513 41.8716 42.6239C40.5991 43.8942 38.88 44.6242 36.0246 45.0082C33.1222 45.3967 29.309 45.4012 24 45.4012C18.691 45.4012 14.8778 45.3967 11.9754 45.0082C9.12 44.6242 7.3987 43.892 6.12614 42.6216C4.85581 41.3491 4.12577 39.63 3.74177 36.7746C3.3533 33.8722 3.34884 30.059 3.34884 24.75C3.34884 19.441 3.3533 15.6278 3.74177 12.7254C4.12577 9.87 4.85805 8.15093 6.12837 6.87837Z",
            fill: "#10E040",
            clipRule: "evenodd",
            fillRule: "evenodd",
        })]),
    });
}

export function BearishLogoSmall() {
    return createElement("svg", {
        width: 16,
        height: 16,
        viewBox: (((((0 + " ") + 0) + " ") + 48) + " ") + 48,
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
        children: Interop_reactApi.Children.toArray([createElement("path", {
            d: "M27.9068 29.2152C27.9068 28.291 28.6569 27.5408 29.5812 27.5408H31.1217L26.6275 23.0467C26.5757 22.9947 26.5141 22.9535 26.4463 22.9253C26.3785 22.8972 26.3058 22.8827 26.2324 22.8827C26.159 22.8827 26.0863 22.8972 26.0185 22.9253C25.9507 22.9535 25.8891 22.9947 25.8372 23.0467L22.2964 26.5875C21.5638 27.3196 20.5704 27.7309 19.5347 27.7309C18.499 27.7309 17.5057 27.3196 16.773 26.5875L11.6538 21.4683C11.4893 21.315 11.3573 21.1301 11.2658 20.9247C11.1743 20.7193 11.1251 20.4976 11.1211 20.2728C11.1171 20.0479 11.1585 19.8246 11.2427 19.6161C11.3269 19.4076 11.4523 19.2182 11.6113 19.0592C11.7703 18.9002 11.9597 18.7749 12.1682 18.6907C12.3767 18.6065 12.6 18.5651 12.8248 18.5691C13.0496 18.573 13.2714 18.6222 13.4767 18.7138C13.6821 18.8053 13.867 18.9372 14.0203 19.1017L19.1395 24.221C19.1914 24.273 19.253 24.3142 19.3208 24.3424C19.3886 24.3705 19.4613 24.385 19.5347 24.385C19.6081 24.385 19.6808 24.3705 19.7486 24.3424C19.8164 24.3142 19.878 24.273 19.9299 24.221L23.4707 20.6802C24.2033 19.9481 25.1967 19.5368 26.2324 19.5368C27.2681 19.5368 28.2614 19.9481 28.9941 20.6802L33.4882 25.1721V23.6338C33.4882 23.1898 33.6646 22.7639 33.9786 22.4498C34.2926 22.1358 34.7185 21.9594 35.1626 21.9594C35.6067 21.9594 36.0326 22.1358 36.3466 22.4498C36.6606 22.7639 36.837 23.1898 36.837 23.6338V29.2152C36.837 29.6593 36.6606 30.0852 36.3466 30.3992C36.0326 30.7132 35.6067 30.8896 35.1626 30.8896H29.5812C29.1371 30.8896 28.7112 30.7132 28.3972 30.3992C28.0832 30.0852 27.9068 29.6593 27.9068 29.2152Z",
            fill: "#F24949",
        }), createElement("path", {
            d: "M23.8727 48.75C18.7178 48.75 14.6791 48.75 11.5267 48.3258C8.30288 47.8927 5.75777 46.9863 3.75963 44.9904C1.76149 42.9922 0.857303 40.4471 0.424186 37.2211C1.33071e-07 34.0709 0 30.0322 0 24.8773V24.6227C0 19.4678 1.33071e-07 15.4291 0.424186 12.2767C0.857303 9.05288 1.76372 6.50777 3.75963 4.50963C5.75777 2.51149 8.30288 1.6073 11.5289 1.17418C14.6791 0.749999 18.7178 0.749998 23.8727 0.749998H24.1273C29.2822 0.749998 33.3209 0.749999 36.4733 1.17418C39.6971 1.6073 42.2422 2.51372 44.2404 4.50963C46.2385 6.50777 47.1427 9.05288 47.5758 12.2789C48 15.4291 48 19.4678 48 24.6227V24.8773C48 30.0322 48 34.0709 47.5758 37.2233C47.1427 40.4471 46.2363 42.9922 44.2404 44.9904C42.2422 46.9885 39.6971 47.8927 36.4711 48.3258C33.3209 48.75 29.2822 48.75 24.1273 48.75H23.8727ZM6.12837 42.6216C7.40093 43.8942 9.12 44.6242 11.9754 45.0082C14.8778 45.3967 18.691 45.4012 24 45.4012C29.309 45.4012 33.1222 45.3967 36.0246 45.0082C38.88 44.6242 40.6013 43.892 41.8739 42.6216C43.1442 41.3491 43.8742 39.63 44.2582 36.7746C44.6467 33.8722 44.6512 30.059 44.6512 24.75C44.6512 19.441 44.6467 15.6278 44.2582 12.7254C43.8742 9.87 43.142 8.14869 41.8716 6.87614C40.5991 5.60581 38.88 4.87577 36.0246 4.49177C33.1222 4.1033 29.309 4.09883 24 4.09883C18.691 4.09883 14.8778 4.1033 11.9754 4.49177C9.12 4.87577 7.3987 5.60805 6.12614 6.87837C4.85581 8.15093 4.12577 9.87 3.74177 12.7254C3.3533 15.6278 3.34884 19.441 3.34884 24.75C3.34884 30.059 3.3533 33.8722 3.74177 36.7746C4.12577 39.63 4.85805 41.3491 6.12837 42.6216Z",
            fill: "#F24949",
            clipRule: "evenodd",
            fillRule: "evenodd",
        })]),
    });
}

