import { getInjectiveAddress } from "@injectivelabs/sdk-ts";
import { choose } from "./.fable/fable-library.3.1.16/Array.js";
import { WhiteListed } from "./InjWhiteListedAdd.fs.js";
import { WhiteListedWallet } from "./Context.fs.js";

export function createWhiteListedTestNetToInj(eth) {
    if (eth.indexOf("0") === 0) {
        try {
            return getInjectiveAddress(eth);
        }
        catch (matchValue) {
            return void 0;
        }
    }
    else {
        return eth;
    }
}

export const WhiteListedTestNetAllInj = choose((eth) => createWhiteListedTestNetToInj(eth), WhiteListed);

export function checkIfWhiteListedAndChangeRecoil(str) {
    if (str.length > 10) {
        return new WhiteListedWallet(0);
    }
    else {
        return new WhiteListedWallet(2);
    }
}

