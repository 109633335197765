import { Record, FSharpException, Union } from "./.fable/fable-library.3.1.16/Types.js";
import { record_type, option_type, string_type, class_type, union_type } from "./.fable/fable-library.3.1.16/Reflection.js";
import { equals } from "./.fable/fable-library.3.1.16/Util.js";

export class ApplicationTab extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LandingPageTab", "SingleProducts", "Optionality", "Dashboard"];
    }
}

export function ApplicationTab$reflection() {
    return union_type("Types.ApplicationTab", [], ApplicationTab, () => [[], [], [], []]);
}

export function ApplicationTab_FromIndex_Z524259A4(index) {
    switch (index) {
        case 0: {
            return new ApplicationTab(0);
        }
        case 1: {
            return new ApplicationTab(1);
        }
        case 2: {
            return new ApplicationTab(2);
        }
        case 3: {
            return new ApplicationTab(3);
        }
        default: {
            return new ApplicationTab(0);
        }
    }
}

export function ApplicationTab_ToIndex_Z65EDBD3(tab) {
    switch (tab.tag) {
        case 1: {
            return 1;
        }
        case 2: {
            return 2;
        }
        case 3: {
            return 3;
        }
        default: {
            return 0;
        }
    }
}

export class CardTab extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Strategy", "Risk", "AllOrders", "MyOrders"];
    }
}

export function CardTab$reflection() {
    return union_type("Types.CardTab", [], CardTab, () => [[], [], [], []]);
}

export function CardTab_FromIndex_Z524259A4(index) {
    switch (index) {
        case 0: {
            return new CardTab(0);
        }
        case 1: {
            return new CardTab(1);
        }
        case 2: {
            return new CardTab(2);
        }
        case 3: {
            return new CardTab(3);
        }
        default: {
            return void 0;
        }
    }
}

export function CardTab_ToIndex_68079FA3(tab) {
    switch (tab.tag) {
        case 1: {
            return 1;
        }
        case 2: {
            return 2;
        }
        case 3: {
            return 3;
        }
        default: {
            return 0;
        }
    }
}

export class ApplicationWallet extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["NotConnected", "Connected"];
    }
}

export function ApplicationWallet$reflection() {
    return union_type("Types.ApplicationWallet", [], ApplicationWallet, () => [[], [["Item", class_type("Solana.Wallet")]]]);
}

export class DirectionRecommended extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["AssistantNotLaunched", "RecoomendedGetYield", "RecoomendedGetOption"];
    }
}

export function DirectionRecommended$reflection() {
    return union_type("Types.DirectionRecommended", [], DirectionRecommended, () => [[], [], []]);
}

export class NoWalletConnectedException extends FSharpException {
    constructor(Data0) {
        super();
        this.Data0 = Data0;
    }
}

export function NoWalletConnectedException$reflection() {
    return class_type("Types.NoWalletConnectedException", void 0, NoWalletConnectedException, class_type("System.Exception"));
}

function NoWalletConnectedException__Equals_229D3F39(this$, obj) {
    if (!equals(this$, null)) {
        if (!equals(obj, null)) {
            if (obj instanceof NoWalletConnectedException) {
                return this$.Data0 === obj.Data0;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    else if (!equals(obj, null)) {
        return false;
    }
    else {
        return true;
    }
}

export class MintFakeTokensException extends FSharpException {
    constructor(Data0) {
        super();
        this.Data0 = Data0;
    }
}

export function MintFakeTokensException$reflection() {
    return class_type("Types.MintFakeTokensException", void 0, MintFakeTokensException, class_type("System.Exception"));
}

function MintFakeTokensException__Equals_229D3F39(this$, obj) {
    if (!equals(this$, null)) {
        if (!equals(obj, null)) {
            if (obj instanceof MintFakeTokensException) {
                return this$.Data0 === obj.Data0;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    else if (!equals(obj, null)) {
        return false;
    }
    else {
        return true;
    }
}

export class Languages extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["English", "Russian", "Thai", "Indonesian", "Chinese", "Indian", "Spanish", "Turkish", "SouthKorean", "Vietnamese"];
    }
}

export function Languages$reflection() {
    return union_type("Types.Languages", [], Languages, () => [[], [], [], [], [], [], [], [], [], []]);
}

export class LanguageLinks extends Record {
    constructor(Language, Flag, TelegramLink, MediumLink, DiscordLink, TwitterLink) {
        super();
        this.Language = Language;
        this.Flag = Flag;
        this.TelegramLink = TelegramLink;
        this.MediumLink = MediumLink;
        this.DiscordLink = DiscordLink;
        this.TwitterLink = TwitterLink;
    }
}

export function LanguageLinks$reflection() {
    return record_type("Types.LanguageLinks", [], LanguageLinks, () => [["Language", Languages$reflection()], ["Flag", string_type], ["TelegramLink", option_type(string_type)], ["MediumLink", option_type(string_type)], ["DiscordLink", option_type(string_type)], ["TwitterLink", option_type(string_type)]]);
}

export function LanguageLinks__get_LanguageName(this$) {
    const matchValue = this$.Language;
    switch (matchValue.tag) {
        case 1: {
            return "Russian";
        }
        case 2: {
            return "Thai";
        }
        case 3: {
            return "Indonesian";
        }
        case 4: {
            return "Chinese";
        }
        case 5: {
            return "Hindi";
        }
        case 6: {
            return "Spanish";
        }
        case 7: {
            return "Turkish";
        }
        case 8: {
            return "South Korean";
        }
        case 9: {
            return "Vietnamese";
        }
        default: {
            return "English";
        }
    }
}

