import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "./.fable/Feliz.1.62.0/Interop.fs.js";
import { isNullOrEmpty, join } from "./.fable/fable-library.3.1.16/String.js";
import navbar$002Emodule from "./styles/navbar.module.scss";
import { copyTextWeb, getWalletAddresses, useWalletStore } from "../../../lib/Injective/Injective.fs.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "./.fable/Feliz.1.62.0/React.fs.js";
import { startImmediate } from "./.fable/fable-library.3.1.16/Async.js";
import { singleton } from "./.fable/fable-library.3.1.16/AsyncBuilder.js";
import { useSnackbar } from "notistack";
import { MuiHelpers_createElement } from "./.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../../../lib/Notistack/Notistack.fs.js";
import { WalletDialogProvider, WalletMultiButton } from "@solana/wallet-adapter-material-ui";
import { login } from "./Img.fs.js";
import { empty as empty_1, ofArray, singleton as singleton_1 } from "./.fable/fable-library.3.1.16/List.js";
import { Hooks_useHub, Hooks_HubConnectionState__IsConnected } from "../../../lib/Fable.Remoting.SignalR/SignalR.fs.js";
import utils$002Emodule from "./styles/utils.module.scss";
import Chip from "@material-ui/core/Chip";
import Sync from "@material-ui/icons/Sync";
import { int32ToString, equals, createObj } from "./.fable/fable-library.3.1.16/Util.js";
import SyncDisabled from "@material-ui/icons/SyncDisabled";
import { Recoil_useSetRecoilState_Z74A95619, Recoil_useRecoilState_Z74A95619, Recoil_useRecoilValue_5D3EEEEA } from "./.fable/Feliz.Recoil.0.19.2/Bindings.fs.js";
import { setAgreedToDisclaimer, agreedToDisclaimer, injectiveEnvironment, setup as setup_1, tokens as tokens_1, profile, appTab as appTab_1, generalMessageShown, warningMessageShown, solanaEnvironment, blockchain } from "./Context.fs.js";
import { useFeliz_React__React_useDeferredCallback_Static_7088D81D, Deferred$1 } from "./.fable/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { Api } from "./Server.fs.js";
import { ApplicationTab_ToIndex_Z65EDBD3 } from "./Types.fs.js";
import { useWallet } from "@solana/wallet-adapter-react";
import { checkIfWhiteListedAndChangeRecoil } from "./InjectiveWhitelist.fs.js";
import { getMainNetTokens } from "../../../lib/Solana/SolanaTokenRegistry.fs.js";
import { empty, singleton as singleton_2, append, delay, toList } from "./.fable/fable-library.3.1.16/Seq.js";
import { Blockchain } from "../../../lib/Domain/Types/Common.fs.js";
import { Navbar } from "./Components/Navbar.fs.js";
import TabPanel from "@material-ui/lab/TabPanel";
import TabContext from "@material-ui/lab/TabContext";
import { Content } from "./UtilComponents.fs.js";
import Modal from "@material-ui/core/Modal";
import { Setup } from "./Setup.fs.js";
import { Disclaimer } from "./Disclaimer.fs.js";
import { Landing } from "./LandingPage.fs.js";
import { SingleProducts } from "./SingleProducts.fs.js";
import { Optionality } from "./Optionality.fs.js";
import { Dashboard } from "./Dashboard.fs.js";

export function InjectiveTestnetHowToBtn() {
    return createElement("div", {
        children: Interop_reactApi.Children.toArray([createElement("button", {
            id: "howto-testnet-inj",
            className: join(" ", [navbar$002Emodule["testnet-inj"], navbar$002Emodule.howto]),
            children: Interop_reactApi.Children.toArray([createElement("div", {
                children: Interop_reactApi.Children.toArray([createElement("a", {
                    href: "https://docs.exotic.markets/exotic-markets-v2/injective-testnet",
                    target: "_blank",
                    children: "HOW-TO?",
                })]),
            })]),
        })]),
    });
}

export function InjectiveTestnetRedeemBtn() {
    return createElement("div", {
        children: Interop_reactApi.Children.toArray([createElement("button", {
            id: "Redeem-testnet-inj",
            className: join(" ", [navbar$002Emodule["testnet-inj"], navbar$002Emodule.redeem]),
            children: Interop_reactApi.Children.toArray([createElement("div", {
                children: Interop_reactApi.Children.toArray([createElement("a", {
                    children: "REDEEM",
                    href: "https://galxe.com/exotic/campaign/GCCjEUM4iR",
                    target: "_blank",
                })]),
            })]),
        })]),
    });
}

export function InjectiveWalletDropdown(injectiveWalletDropdownInputProps) {
    const openSelector = injectiveWalletDropdownInputProps.openSelector;
    const onClose = injectiveWalletDropdownInputProps.onClose;
    const parent = injectiveWalletDropdownInputProps.parent;
    const wallet = useWalletStore();
    const patternInput = useFeliz_React__React_useState_Static_1505(wallet.injectiveAddress);
    const setAddresses = patternInput[1];
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton.Delay(() => {
            const matchValue = wallet.walletStrategy;
            if (matchValue == null) {
                setAddresses(wallet.injectiveAddress);
                return singleton.Zero();
            }
            else {
                return singleton.Bind(getWalletAddresses(matchValue), (_arg1) => {
                    const addrRes = _arg1;
                    if (addrRes.tag === 0) {
                        setAddresses(addrRes.fields[0][0]);
                        return singleton.Zero();
                    }
                    else {
                        setAddresses(wallet.injectiveAddress);
                        return singleton.Zero();
                    }
                });
            }
        }));
    }, [wallet]);
    const snackbar = useSnackbar();
    return MuiHelpers_createElement(Popover, [["anchorEl", () => parent.current], ["anchorOrigin", {
        vertical: "bottom",
        horizontal: "center",
    }], ["transformOrigin", {
        vertical: "top",
        horizontal: "center",
    }], ["open", true], ["onClose", (_arg19, v) => {
        onClose();
    }], ["children", MuiHelpers_createElement(List, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(ListItem, [["button", true], ["children", MuiHelpers_createElement(ListItemText, [["style", {
        textAlign: "center",
    }], ["children", "Copy address"], ["onClick", (_arg2) => {
        startImmediate(copyTextWeb(patternInput[0]));
        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Address Copied", SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(0, false));
        onClose();
    }]])]]), MuiHelpers_createElement(ListItem, [["button", true], ["children", MuiHelpers_createElement(ListItemText, [["style", {
        textAlign: "center",
    }], ["children", "Switch wallet"], ["onClick", (_arg3) => {
        onClose();
        openSelector();
    }]])]]), MuiHelpers_createElement(ListItem, [["button", true], ["children", MuiHelpers_createElement(ListItemText, [["style", {
        textAlign: "center",
    }], ["children", "Disconnect"], ["onClick", (_arg4) => {
        wallet.disconnectWallet();
        onClose();
    }]])]])])]])]]);
}

export function ConnectWalletButtonMobile() {
    const children = singleton_1(Interop_reactApi.createElement(WalletMultiButton, {
        className: join(" ", [navbar$002Emodule["connect-wallet-button-mobile"]]),
        children: Interop_reactApi.Children.toArray([createElement("img", {
            src: login,
            alt: "Wallet",
        })]),
    }));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function ServerConnection() {
    let className_1, value_8;
    if (Hooks_HubConnectionState__IsConnected(Hooks_useHub())) {
        return createElement("div", {
            className: join(" ", [utils$002Emodule.center]),
            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Chip, [["classes.label", "text-color-normal"], (className_1 = navbar$002Emodule["navbar-chip-border"], ["classes.outlined", className_1]), ["icon", react.createElement(Sync, {
                color: "primary",
            })], ["label", "Connected"], ["variant", "outlined"]])]),
        });
    }
    else {
        return createElement("div", createObj(ofArray([(value_8 = utils$002Emodule.center, ["className", value_8]), ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Chip, [["icon", react.createElement(SyncDisabled, {
            color: "error",
        })], ["label", "Disconnected from server"], ["variant", "outlined"]])])]])));
    }
}

export function WarningMessage() {
    const chain = Recoil_useRecoilValue_5D3EEEEA(blockchain);
    const environment = Recoil_useRecoilValue_5D3EEEEA(solanaEnvironment);
    const setWarningMessageShown = Recoil_useRecoilState_Z74A95619(warningMessageShown)[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const data = patternInput_1[0];
    useReact_useEffect_Z101E1A95(useFeliz_React__React_useDeferredCallback_Static_7088D81D(() => singleton.Delay(() => singleton.Bind(Api.GetWarningMessage(environment), (_arg1_1) => singleton.Return(_arg1_1))), patternInput_1[1]), [environment]);
    switch (data.tag) {
        case 1: {
            return null;
        }
        case 3: {
            return null;
        }
        case 2: {
            const message = data.fields[0];
            if (chain.tag === 1) {
                return null;
            }
            else if (!isNullOrEmpty(message)) {
                setWarningMessageShown(true);
                return createElement("div", {
                    className: join(" ", [utils$002Emodule["text-center"], "header-3", "WarningBanner", utils$002Emodule["padding-m-top"], utils$002Emodule["padding-m-bottom"]]),
                    children: Interop_reactApi.Children.toArray([createElement("div", {
                        children: message,
                    })]),
                });
            }
            else {
                setWarningMessageShown(false);
                return null;
            }
        }
        default: {
            return null;
        }
    }
}

export function GeneralMessage() {
    const chain = Recoil_useRecoilValue_5D3EEEEA(blockchain);
    const environment = Recoil_useRecoilValue_5D3EEEEA(solanaEnvironment);
    const setGeneralMessageShown = Recoil_useRecoilState_Z74A95619(generalMessageShown)[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const data = patternInput_1[0];
    useReact_useEffect_Z101E1A95(useFeliz_React__React_useDeferredCallback_Static_7088D81D(() => singleton.Delay(() => singleton.Bind(Api.GetGeneralMessage(environment), (_arg1_1) => singleton.Return(_arg1_1))), patternInput_1[1]), [environment]);
    switch (data.tag) {
        case 1: {
            return null;
        }
        case 3: {
            return null;
        }
        case 2: {
            const message = data.fields[0];
            if (chain.tag === 1) {
                return null;
            }
            else if (!isNullOrEmpty(message)) {
                setGeneralMessageShown(true);
                return createElement("div", {
                    className: join(" ", [utils$002Emodule["text-center"], "header-4", "GeneralBanner", utils$002Emodule["padding-m-top"], utils$002Emodule["padding-m-bottom"]]),
                    children: Interop_reactApi.Children.toArray([createElement("div", {
                        children: message,
                    })]),
                });
            }
            else {
                setGeneralMessageShown(false);
                return null;
            }
        }
        default: {
            return null;
        }
    }
}

export function InjectiveWhitelisted(injectiveWhitelistedInputProps) {
    const whiteListed = injectiveWhitelistedInputProps.whiteListed;
    const chain = Recoil_useRecoilValue_5D3EEEEA(blockchain);
    const environment = Recoil_useRecoilValue_5D3EEEEA(solanaEnvironment);
    const patternInput = Recoil_useRecoilState_Z74A95619(generalMessageShown);
    if (chain.tag === 1) {
        return null;
    }
    else {
        return null;
    }
}

export function NavBar() {
    const appTab = Recoil_useRecoilValue_5D3EEEEA(appTab_1);
    const patternInput = useFeliz_React__React_useState_Static_1505(ApplicationTab_ToIndex_Z65EDBD3(appTab));
    const tabIndex = patternInput[0] | 0;
    const setTabIndex = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const walletCtx = useWallet();
    let setProfile;
    const value = Recoil_useSetRecoilState_Z74A95619(profile);
    setProfile = ((arg10) => {
        value(arg10);
    });
    let setTokens;
    const value_1 = Recoil_useSetRecoilState_Z74A95619(tokens_1);
    setTokens = ((arg10_1) => {
        value_1(arg10_1);
    });
    const connectionState = Hooks_useHub();
    const patternInput_2 = Recoil_useRecoilState_Z74A95619(setup_1);
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(false);
    const setShowDisclaimer = patternInput_3[1];
    const chain = Recoil_useRecoilValue_5D3EEEEA(blockchain);
    const injectiveEnv = Recoil_useRecoilValue_5D3EEEEA(injectiveEnvironment);
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(false);
    const patternInput_5 = useFeliz_React__React_useState_Static_1505(false);
    const goToCommunityToggle = patternInput_5[0];
    const whiteListed = checkIfWhiteListedAndChangeRecoil(useWalletStore().injectiveAddress);
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton.Delay(() => {
            if (walletCtx.wallet != null) {
                setShowDisclaimer(!agreedToDisclaimer);
                return singleton.Zero();
            }
            else {
                return singleton.Zero();
            }
        }));
    }, [walletCtx.wallet]);
    useReact_useEffectOnce_3A5B6456(() => {
        startImmediate(singleton.Delay(() => singleton.Bind(getMainNetTokens(), (_arg1) => {
            setTokens(_arg1);
            return singleton.Zero();
        })));
    });
    useReact_useEffect_Z101E1A95(() => {
        setTabIndex(ApplicationTab_ToIndex_Z65EDBD3(appTab));
    }, [appTab]);
    const toolbar = createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let value_3;
            return append(singleton_2(createElement("div", createObj(ofArray([(value_3 = navbar$002Emodule["mobile-menu-item"], ["className", value_3]), ["children", Interop_reactApi.Children.toArray([createElement(ConnectWalletButtonMobile, null)])]])))), delay(() => ((!equals(chain, new Blockchain(0))) ? singleton_2(createElement(Navbar, {
                setTabIndex: setTabIndex,
                setGoToCommunity: patternInput_4[1],
                setGoToCommunityToggle: patternInput_5[1],
                goToCommunityToggle: goToCommunityToggle,
            })) : empty())));
        })))),
    });
    const mobileMessage = createElement("div", {
        className: join(" ", [utils$002Emodule["show-only-mobile"], utils$002Emodule.center, "header-3", "MuiAlert-standardWarning", utils$002Emodule["padding-l-top"], utils$002Emodule["padding-l-bottom"], utils$002Emodule["margin-s-bottom"]]),
        children: "Mobile screens are not yet supported!",
    });
    const tabPanel = (children, value_10) => {
        let value_11;
        return MuiHelpers_createElement(TabPanel, [(value_11 = navbar$002Emodule["tab-panel"], ["className", value_11]), ["value", value_10], ["children", Interop_reactApi.Children.toArray(Array.from(children))]]);
    };
    const chain_1 = Recoil_useRecoilValue_5D3EEEEA(blockchain);
    return MuiHelpers_createElement(TabContext, [["value", int32ToString(tabIndex)], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_2(createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append((chain_1.tag === 1) ? append(singleton_2(createElement(GeneralMessage, null)), delay(() => append(singleton_2(createElement(InjectiveWhitelisted, {
            whiteListed: whiteListed,
        })), delay(() => append(singleton_2(createElement(WarningMessage, null)), delay(() => append(singleton_2(mobileMessage), delay(() => singleton_2(createElement(Content, {
            x: toolbar,
            classes: empty_1(),
        })))))))))) : singleton_2(Interop_reactApi.createElement(WalletDialogProvider, {
            featuredWallets: 7,
            title: "Select a Wallet",
            id: "wallet-modal",
            children: Interop_reactApi.Children.toArray([createElement(GeneralMessage, null), createElement(InjectiveWhitelisted, {
                whiteListed: whiteListed,
            }), createElement(WarningMessage, null), mobileMessage, createElement(Content, {
                x: toolbar,
                classes: empty_1(),
            })]),
        })), delay(() => append(singleton_2(MuiHelpers_createElement(Modal, [["open", false], ["children", createElement(Setup, null)]])), delay(() => singleton_2(MuiHelpers_createElement(Modal, [["open", patternInput_3[0]], ["children", createElement(Disclaimer, {
            onConfirm: () => {
                setAgreedToDisclaimer(true);
                setShowDisclaimer(false);
            },
        })]])))))))))),
    })), delay(() => append(singleton_2((tabIndex === 0) ? null : createElement("div", {
        className: join(" ", [navbar$002Emodule["feedback-btn"]]),
        children: Interop_reactApi.Children.toArray([createElement("a", {
            href: "https://forms.gle/MzcueHPsjrcmr47C6",
            target: "_blank",
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: join(" ", [navbar$002Emodule["feedback-txt"]]),
                children: Interop_reactApi.Children.toArray([createElement("p", {
                    children: ["Feedback"],
                })]),
            })]),
        })]),
    })), delay(() => append(singleton_2(tabPanel([createElement(Landing, {
        goToCommunityNavbar: patternInput_4[0],
        goToCommunityToggleNavbar: goToCommunityToggle,
    })], "0")), delay(() => append(singleton_2(tabPanel([createElement(SingleProducts, null)], "1")), delay(() => append(singleton_2(tabPanel([createElement(Optionality, null)], "2")), delay(() => singleton_2(tabPanel([createElement(Dashboard, null)], "3"))))))))))))))))]]);
}

