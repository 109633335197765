import { toString, Union, Record } from "../../../app/client/src/.fable/fable-library.3.1.16/Types.js";
import { option_type, union_type, record_type, tuple_type, class_type, string_type } from "../../../app/client/src/.fable/fable-library.3.1.16/Reflection.js";
import { nativeDenomDecoder, rfqGenericBalanceDecoder, Denom$reflection, RfqGenericBalance$reflection } from "./Common.fs.js";
import { fromParts } from "../../../app/client/src/.fable/fable-library.3.1.16/Decimal.js";
import { toInt64, toDecimal } from "../../../app/client/src/.fable/fable-library.3.1.16/BigInt.js";
import { keyValuePairs, fromString, map, oneOf, option, bigint, string, tuple2, object } from "../../../app/client/src/.fable/Thoth.Json.10.1.0/Decode.fs.js";
import { uncurry } from "../../../app/client/src/.fable/fable-library.3.1.16/Util.js";
import { printf, toFail } from "../../../app/client/src/.fable/fable-library.3.1.16/String.js";
import DateOffset from "../../../app/client/src/.fable/fable-library.3.1.16/DateOffset.js";
import { fromInteger, op_Division, toNumber } from "../../../app/client/src/.fable/fable-library.3.1.16/Long.js";
import { toArray, ofArray } from "../../../app/client/src/.fable/fable-library.3.1.16/List.js";
import { Result_Map } from "../../../app/client/src/.fable/fable-library.3.1.16/Choice.js";
import { map as map_1 } from "../../../app/client/src/.fable/fable-library.3.1.16/Array.js";

export class ClosedStatus extends Record {
    constructor(SelectedBid) {
        super();
        this.SelectedBid = SelectedBid;
    }
}

export function ClosedStatus$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveRfq.ClosedStatus", [], ClosedStatus, () => [["SelectedBid", tuple_type(string_type, class_type("System.Numerics.BigInteger"))]]);
}

export class Status extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Open", "Closed"];
    }
}

export function Status$reflection() {
    return union_type("ExoticMarkets.Domain.InjectiveRfq.Status", [], Status, () => [[], [["Item", ClosedStatus$reflection()]]]);
}

export class Selection$ extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Highest", "Manual"];
    }
}

export function Selection$$reflection() {
    return union_type("ExoticMarkets.Domain.InjectiveRfq.Selection", [], Selection$, () => [[], []]);
}

export class Rfq extends Record {
    constructor(Address, Creator, Asset, BidToken, MinBid, BidEnd, SelectionEnd, SelectionType, Status, HighestBid) {
        super();
        this.Address = Address;
        this.Creator = Creator;
        this.Asset = Asset;
        this.BidToken = BidToken;
        this.MinBid = MinBid;
        this.BidEnd = BidEnd;
        this.SelectionEnd = SelectionEnd;
        this.SelectionType = SelectionType;
        this.Status = Status;
        this.HighestBid = HighestBid;
    }
}

export function Rfq$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveRfq.Rfq", [], Rfq, () => [["Address", string_type], ["Creator", string_type], ["Asset", RfqGenericBalance$reflection()], ["BidToken", Denom$reflection()], ["MinBid", class_type("System.Numerics.BigInteger")], ["BidEnd", class_type("System.DateTimeOffset")], ["SelectionEnd", class_type("System.DateTimeOffset")], ["SelectionType", Selection$$reflection()], ["Status", Status$reflection()], ["HighestBid", option_type(tuple_type(string_type, class_type("System.Numerics.BigInteger")))]]);
}

export function Rfq__GetSelectedBid(this$) {
    const matchValue = this$.Status;
    if (matchValue.tag === 0) {
        return fromParts(0, 0, 0, false, 0);
    }
    else {
        return toDecimal(matchValue.fields[0].SelectedBid[1]);
    }
}

export class RfqRaw extends Record {
    constructor(Creator, Asset, BidToken, MinBid, BidEnd, SelectionEnd, SelectionType, Status, HighestBid) {
        super();
        this.Creator = Creator;
        this.Asset = Asset;
        this.BidToken = BidToken;
        this.MinBid = MinBid;
        this.BidEnd = BidEnd;
        this.SelectionEnd = SelectionEnd;
        this.SelectionType = SelectionType;
        this.Status = Status;
        this.HighestBid = HighestBid;
    }
}

export function RfqRaw$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveRfq.RfqRaw", [], RfqRaw, () => [["Creator", string_type], ["Asset", RfqGenericBalance$reflection()], ["BidToken", Denom$reflection()], ["MinBid", class_type("System.Numerics.BigInteger")], ["BidEnd", class_type("System.DateTimeOffset")], ["SelectionEnd", class_type("System.DateTimeOffset")], ["SelectionType", Selection$$reflection()], ["Status", Status$reflection()], ["HighestBid", option_type(tuple_type(string_type, class_type("System.Numerics.BigInteger")))]]);
}

export function RfqRaw__ToRfq_Z721C83C5(this$, address) {
    return new Rfq(address, this$.Creator, this$.Asset, this$.BidToken, this$.MinBid, this$.BidEnd, this$.SelectionEnd, this$.SelectionType, this$.Status, this$.HighestBid);
}

export const closedStatusDecoder = (path_2) => ((v) => object((get$) => (new ClosedStatus(get$.Required.Field("selected_bid", uncurry(2, tuple2((path, value) => string(path, value), (path_1, value_1) => bigint(path_1, value_1)))))), path_2, v));

export const rfqStatusDecoder = (path) => ((v) => object((get$) => (new Status(1, get$.Required.Field("closed", uncurry(2, closedStatusDecoder)))), path, v));

export const selectionTypeDecoder = (path_4) => ((v) => object((get$) => {
    const h = get$.Required.Field("highest", (path_1, value_1) => option((path, value) => string(path, value), path_1, value_1));
    const m = get$.Required.Field("manual", (path_3, value_3) => option((path_2, value_2) => string(path_2, value_2), path_3, value_3));
    const matchValue = [h, m];
    let pattern_matching_result;
    if (matchValue[0] == null) {
        if (matchValue[1] != null) {
            pattern_matching_result = 1;
        }
        else {
            pattern_matching_result = 2;
        }
    }
    else if (matchValue[1] == null) {
        pattern_matching_result = 0;
    }
    else {
        pattern_matching_result = 2;
    }
    switch (pattern_matching_result) {
        case 0: {
            return new Selection$(0);
        }
        case 1: {
            return new Selection$(1);
        }
        case 2: {
            const arg20 = toString(m);
            const arg10_2 = toString(h);
            return toFail(printf("Error in deserializing rfq selection type. h: %s. m: %s"))(arg10_2)(arg20);
        }
    }
}, path_4, v));

export function openStatusDecoder(s) {
    if (s === "open") {
        return new Status(0);
    }
    else {
        throw (new Error("Unknown rfq status"));
    }
}

export const injectiveRfqRawDecoder = (path_11) => ((v) => object((get$) => {
    let x_2, decoder;
    return new RfqRaw(get$.Required.Field("creator", (path, value) => string(path, value)), get$.Required.Field("asset", uncurry(2, rfqGenericBalanceDecoder)), get$.Required.Field("bid_token", uncurry(2, nativeDenomDecoder)), get$.Required.Field("min_bid", (path_1, value_1) => bigint(path_1, value_1)), DateOffset(toNumber(op_Division(toInt64(get$.Required.Field("bid_end", (path_2, value_2) => bigint(path_2, value_2))), fromInteger(1000000000, false, 2))) * 1000, 0), DateOffset(toNumber(op_Division(toInt64(get$.Required.Field("selection_end", (path_3, value_3) => bigint(path_3, value_3))), fromInteger(1000000000, false, 2))) * 1000, 0), (x_2 = get$.Required.Field("selection_type", (path_4, value_4) => string(path_4, value_4)), (x_2 === "highest") ? (new Selection$(0)) : ((x_2 === "manual") ? (new Selection$(1)) : (() => {
        throw (new Error("Error in deserializing rfq selection type."));
    })())), get$.Required.Field("status", (path_7, value_7) => oneOf(ofArray([(path_6) => ((value_6) => map((x_3) => openStatusDecoder(x_3), (path_5, value_5) => string(path_5, value_5), path_6, value_6)), rfqStatusDecoder]), path_7, value_7)), get$.Required.Field("highest_bid", uncurry(2, (decoder = tuple2((path_8, value_8) => string(path_8, value_8), (path_9, value_9) => bigint(path_9, value_9)), (path_10) => ((value_10) => option(uncurry(2, decoder), path_10, value_10))))));
}, path_11, v));

export function deserializeRfq(address, data) {
    const raw = fromString(uncurry(2, injectiveRfqRawDecoder), data);
    if (raw.tag === 1) {
        throw (new Error("failed in rfq deserialization"));
    }
    else {
        return RfqRaw__ToRfq_Z721C83C5(raw.fields[0], address);
    }
}

export function deserializeRfqArray(jsonString) {
    const res = Result_Map((x) => toArray(x), fromString((path, value) => keyValuePairs(uncurry(2, injectiveRfqRawDecoder), path, value), jsonString));
    if (res.tag === 1) {
        throw (new Error("failed in rfq array deserialization"));
    }
    else {
        return map_1((tupledArg) => RfqRaw__ToRfq_Z721C83C5(tupledArg[1], tupledArg[0]), res.fields[0]);
    }
}

