import { Recoil_selector_4E60E31B, Recoil_atom_4E60E31B } from "./.fable/Feliz.Recoil.0.19.2/Bindings.fs.js";
import { equals, createObj } from "./.fable/fable-library.3.1.16/Util.js";
import { empty, singleton, append, delay, toList } from "./.fable/fable-library.3.1.16/Seq.js";
import { PublicKey$reflection, InjectiveEnvironment, SolanaEnvironment, Blockchain } from "../../../lib/Domain/Types/Common.fs.js";
import { value } from "./.fable/fable-library.3.1.16/Option.js";
import { empty as empty_1, isEmpty } from "./.fable/fable-library.3.1.16/List.js";
import { SelectorState_ReadOnly$2, selector as selector_1, SelectorBuilder__Yield_1505, SelectorBuilder__Key_44181178 } from "./.fable/Feliz.Recoil.0.19.2/ComputationExpressions/Selector.fs.js";
import { publicKey } from "../../../lib/Solana/Solana.fs.js";
import { AtomState_ReadWrite$3, atom as atom_1, AtomBuilder__Yield_1505, AtomBuilder__Key_Z620645C8, AtomBuilder__DangerouslyAllowMutability_Z71F318D4 } from "./.fable/Feliz.Recoil.0.19.2/ComputationExpressions/Atom.fs.js";
import { CardTab, ApplicationTab, ApplicationWallet } from "./Types.fs.js";
import { Record, Union, toString } from "./.fable/fable-library.3.1.16/Types.js";
import { array_type, class_type, bool_type, string_type, record_type, option_type, tuple_type, int32_type, list_type, union_type } from "./.fable/fable-library.3.1.16/Reflection.js";
import { Kind$reflection, FlexProduct$reflection, Kind } from "../../../lib/Domain/Types/FlexProduct.fs.js";
import { Position_Position$reflection } from "../../../lib/Domain/Types/Position.fs.js";
import { ExoticUnderlying$reflection } from "../../../lib/Domain/Types/ExoticUnderlying.fs.js";
import { SpecificProduct$reflection } from "../../../lib/Domain/Types/Product.fs.js";
import { InjectiveProductKind$reflection } from "../../../lib/Domain/Types/InjectiveProduct.fs.js";
import { ofList } from "./.fable/fable-library.3.1.16/Map.js";

export const blockchain = (() => {
    const effects = void 0;
    const persistence = void 0;
    const dangerouslyAllowMutability = void 0;
    return Recoil_atom_4E60E31B(createObj(toList(delay(() => append(singleton(["key", "blockchain"]), delay(() => append(singleton(["default", new Blockchain(1)]), delay(() => append(((effects != null) ? (!isEmpty(value(effects))) : false) ? singleton(["effects_UNSTABLE", Array.from(value(effects))]) : empty(), delay(() => {
        let settings;
        return append((persistence != null) ? singleton(["persistence_UNSTABLE", (settings = value(persistence), createObj(toList(delay(() => append(singleton(["type", settings.Type]), delay(() => append((settings.Backbutton != null) ? singleton(["backbutton", value(settings.Backbutton)]) : empty(), delay(() => singleton(["validator", (tupledArg) => {
            const matchValue = settings.Validator(tupledArg[0]);
            return (matchValue == null) ? tupledArg[1] : matchValue;
        }])))))))))]) : empty(), delay(() => ((dangerouslyAllowMutability != null) ? singleton(["dangerouslyAllowMutability", value(dangerouslyAllowMutability)]) : empty())));
    }))))))))));
})();

export const solanaEnvironment = (() => {
    const effects = void 0;
    const persistence = void 0;
    const dangerouslyAllowMutability = void 0;
    return Recoil_atom_4E60E31B(createObj(toList(delay(() => append(singleton(["key", "environment"]), delay(() => append(singleton(["default", new SolanaEnvironment(1)]), delay(() => append(((effects != null) ? (!isEmpty(value(effects))) : false) ? singleton(["effects_UNSTABLE", Array.from(value(effects))]) : empty(), delay(() => {
        let settings;
        return append((persistence != null) ? singleton(["persistence_UNSTABLE", (settings = value(persistence), createObj(toList(delay(() => append(singleton(["type", settings.Type]), delay(() => append((settings.Backbutton != null) ? singleton(["backbutton", value(settings.Backbutton)]) : empty(), delay(() => singleton(["validator", (tupledArg) => {
            const matchValue = settings.Validator(tupledArg[0]);
            return (matchValue == null) ? tupledArg[1] : matchValue;
        }])))))))))]) : empty(), delay(() => ((dangerouslyAllowMutability != null) ? singleton(["dangerouslyAllowMutability", value(dangerouslyAllowMutability)]) : empty())));
    }))))))))));
})();

export const injectiveEnvironment = (() => {
    const effects = void 0;
    const persistence = void 0;
    const dangerouslyAllowMutability = void 0;
    return Recoil_atom_4E60E31B(createObj(toList(delay(() => append(singleton(["key", "injectiveEnv"]), delay(() => append(singleton(["default", new InjectiveEnvironment(1)]), delay(() => append(((effects != null) ? (!isEmpty(value(effects))) : false) ? singleton(["effects_UNSTABLE", Array.from(value(effects))]) : empty(), delay(() => {
        let settings;
        return append((persistence != null) ? singleton(["persistence_UNSTABLE", (settings = value(persistence), createObj(toList(delay(() => append(singleton(["type", settings.Type]), delay(() => append((settings.Backbutton != null) ? singleton(["backbutton", value(settings.Backbutton)]) : empty(), delay(() => singleton(["validator", (tupledArg) => {
            const matchValue = settings.Validator(tupledArg[0]);
            return (matchValue == null) ? tupledArg[1] : matchValue;
        }])))))))))]) : empty(), delay(() => ((dangerouslyAllowMutability != null) ? singleton(["dangerouslyAllowMutability", value(dangerouslyAllowMutability)]) : empty())));
    }))))))))));
})();

export const tokenProgramId = (() => {
    const selector = new SelectorState_ReadOnly$2(SelectorBuilder__Key_44181178(selector_1, SelectorBuilder__Yield_1505(selector_1), "solana/programId").fields[0], (getter) => {
        const matchValue = getter.get(solanaEnvironment);
        if (matchValue.tag === 1) {
            return publicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA");
        }
        else {
            return publicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA");
        }
    }, void 0, void 0);
    const cacheImplementation = selector.Cache;
    const dangerouslyAllowMutability = selector.DangerouslyAllowMutability;
    return Recoil_selector_4E60E31B(createObj(toList(delay(() => append(singleton(["key", selector.Key]), delay(() => append(singleton(["get", selector.Get]), delay(() => append((cacheImplementation != null) ? singleton(["cacheImplementation_UNSTABLE", value(cacheImplementation)]) : empty(), delay(() => ((dangerouslyAllowMutability != null) ? singleton(["dangerouslyAllowMutability", value(dangerouslyAllowMutability)]) : empty())))))))))));
})();

export const wallet = (() => {
    const atom = AtomBuilder__DangerouslyAllowMutability_Z71F318D4(atom_1, new AtomState_ReadWrite$3(AtomBuilder__Key_Z620645C8(atom_1, AtomBuilder__Yield_1505(atom_1), "wallet").fields[0], new ApplicationWallet(0), empty_1(), void 0, void 0));
    const effects = atom.Effects;
    const persistence = atom.Persist;
    const dangerouslyAllowMutability = atom.DangerouslyAllowMutability;
    return Recoil_atom_4E60E31B(createObj(toList(delay(() => append(singleton(["key", atom.Key]), delay(() => append(singleton(["default", atom.Def]), delay(() => append(((effects != null) ? (!isEmpty(value(effects))) : false) ? singleton(["effects_UNSTABLE", Array.from(value(effects))]) : empty(), delay(() => {
        let settings;
        return append((persistence != null) ? singleton(["persistence_UNSTABLE", (settings = value(persistence), createObj(toList(delay(() => append(singleton(["type", settings.Type]), delay(() => append((settings.Backbutton != null) ? singleton(["backbutton", value(settings.Backbutton)]) : empty(), delay(() => singleton(["validator", (tupledArg) => {
            const matchValue = settings.Validator(tupledArg[0]);
            return (matchValue == null) ? tupledArg[1] : matchValue;
        }])))))))))]) : empty(), delay(() => ((dangerouslyAllowMutability != null) ? singleton(["dangerouslyAllowMutability", value(dangerouslyAllowMutability)]) : empty())));
    }))))))))));
})();

export const agreedToDisclaimer = (() => {
    const matchValue = localStorage.getItem("agreedToDisclaimer");
    return (matchValue === null) ? false : (matchValue === "true");
})();

export function setAgreedToDisclaimer(completed) {
    localStorage.setItem("agreedToDisclaimer", toString(completed));
}

export const completedSetup = (() => {
    const matchValue = localStorage.getItem("completedSetup");
    return (matchValue === null) ? false : (matchValue === "true");
})();

export function setCompletedSetup(completed) {
    localStorage.setItem("completedSetup", toString(completed));
}

export class MarketDirection extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Up", "Down", "Stable"];
    }
}

export function MarketDirection$reflection() {
    return union_type("Context.MarketDirection", [], MarketDirection, () => [[], [], []]);
}

export class YieldPreference extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["GetOption", "GetYield"];
    }
}

export function YieldPreference$reflection() {
    return union_type("Context.YieldPreference", [], YieldPreference, () => [[], []]);
}

export class AssistantState extends Record {
    constructor(YieldPreference, MarketSentiment, InvestmentTimeframe, RiskAversion) {
        super();
        this.YieldPreference = YieldPreference;
        this.MarketSentiment = MarketSentiment;
        this.InvestmentTimeframe = InvestmentTimeframe;
        this.RiskAversion = RiskAversion;
    }
}

export function AssistantState$reflection() {
    return record_type("Context.AssistantState", [], AssistantState, () => [["YieldPreference", list_type(YieldPreference$reflection())], ["MarketSentiment", option_type(tuple_type(int32_type, int32_type))], ["InvestmentTimeframe", option_type(tuple_type(int32_type, int32_type))], ["RiskAversion", option_type(tuple_type(int32_type, int32_type))]]);
}

export class ProductType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["DualCurrencyCall", "DualCurrencyPut", "SellAbove"];
    }
}

export function ProductType$reflection() {
    return union_type("Context.ProductType", [], ProductType, () => [[], [], []]);
}

export function ProductTypeModule_toKind(productType) {
    switch (productType.tag) {
        case 1: {
            return new Kind(2);
        }
        case 2: {
            return new Kind(0);
        }
        default: {
            return new Kind(1);
        }
    }
}

export class SetupStep extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["NotStarted", "ConnectWallet", "EnterDeposit", "ConfirmDeposit", "FollowPosition", "WithdrawPosition", "Finished"];
    }
}

export function SetupStep$reflection() {
    return union_type("Context.SetupStep", [], SetupStep, () => [[], [], [], [], [], [], []]);
}

export class SetupState extends Record {
    constructor(Step, Amount, ConfirmedDeposit, Positions) {
        super();
        this.Step = Step;
        this.Amount = Amount;
        this.ConfirmedDeposit = ConfirmedDeposit;
        this.Positions = Positions;
    }
}

export function SetupState$reflection() {
    return record_type("Context.SetupState", [], SetupState, () => [["Step", SetupStep$reflection()], ["Amount", string_type], ["ConfirmedDeposit", bool_type], ["Positions", list_type(tuple_type(Position_Position$reflection(), FlexProduct$reflection(), class_type("System.TimeSpan"), class_type("System.Decimal"), class_type("System.Decimal"), class_type("Token.TokenInfo"), class_type("Token.TokenInfo")))]]);
}

export function SetupState__IsActive(this$) {
    if (!equals(this$.Step, new SetupStep(0))) {
        return !equals(this$.Step, new SetupStep(6));
    }
    else {
        return false;
    }
}

export class SelectedProductGroup extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["FlexProductGroupSelected", "ExoticProductGroupSelected", "InjectiveProductGroupSelected", "NoneSelected"];
    }
}

export function SelectedProductGroup$reflection() {
    return union_type("Context.SelectedProductGroup", [], SelectedProductGroup, () => [[["Item1", PublicKey$reflection()], ["Item2", Kind$reflection()]], [["Item1", array_type(ExoticUnderlying$reflection())], ["Item2", SpecificProduct$reflection()]], [["Item1", string_type], ["Item2", InjectiveProductKind$reflection()]], []]);
}

export class WhiteListedWallet extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Yes", "No", "NoWallet"];
    }
}

export function WhiteListedWallet$reflection() {
    return union_type("Context.WhiteListedWallet", [], WhiteListedWallet, () => [[], [], []]);
}

export const assistant = (() => {
    const effects = void 0;
    const persistence = void 0;
    const dangerouslyAllowMutability = void 0;
    return Recoil_atom_4E60E31B(createObj(toList(delay(() => append(singleton(["key", "assistant"]), delay(() => append(singleton(["default", void 0]), delay(() => append(((effects != null) ? (!isEmpty(value(effects))) : false) ? singleton(["effects_UNSTABLE", Array.from(value(effects))]) : empty(), delay(() => {
        let settings;
        return append((persistence != null) ? singleton(["persistence_UNSTABLE", (settings = value(persistence), createObj(toList(delay(() => append(singleton(["type", settings.Type]), delay(() => append((settings.Backbutton != null) ? singleton(["backbutton", value(settings.Backbutton)]) : empty(), delay(() => singleton(["validator", (tupledArg) => {
            const matchValue = settings.Validator(tupledArg[0]);
            return (matchValue == null) ? tupledArg[1] : value(matchValue);
        }])))))))))]) : empty(), delay(() => ((dangerouslyAllowMutability != null) ? singleton(["dangerouslyAllowMutability", value(dangerouslyAllowMutability)]) : empty())));
    }))))))))));
})();

export const appTab = (() => {
    const effects = void 0;
    const persistence = void 0;
    const dangerouslyAllowMutability = void 0;
    return Recoil_atom_4E60E31B(createObj(toList(delay(() => append(singleton(["key", "appTab"]), delay(() => append(singleton(["default", new ApplicationTab(0)]), delay(() => append(((effects != null) ? (!isEmpty(value(effects))) : false) ? singleton(["effects_UNSTABLE", Array.from(value(effects))]) : empty(), delay(() => {
        let settings;
        return append((persistence != null) ? singleton(["persistence_UNSTABLE", (settings = value(persistence), createObj(toList(delay(() => append(singleton(["type", settings.Type]), delay(() => append((settings.Backbutton != null) ? singleton(["backbutton", value(settings.Backbutton)]) : empty(), delay(() => singleton(["validator", (tupledArg) => {
            const matchValue = settings.Validator(tupledArg[0]);
            return (matchValue == null) ? tupledArg[1] : matchValue;
        }])))))))))]) : empty(), delay(() => ((dangerouslyAllowMutability != null) ? singleton(["dangerouslyAllowMutability", value(dangerouslyAllowMutability)]) : empty())));
    }))))))))));
})();

export const selectedProductType = (() => {
    const effects = void 0;
    const persistence = void 0;
    const dangerouslyAllowMutability = void 0;
    return Recoil_atom_4E60E31B(createObj(toList(delay(() => append(singleton(["key", "productType"]), delay(() => append(singleton(["default", new ProductType(0)]), delay(() => append(((effects != null) ? (!isEmpty(value(effects))) : false) ? singleton(["effects_UNSTABLE", Array.from(value(effects))]) : empty(), delay(() => {
        let settings;
        return append((persistence != null) ? singleton(["persistence_UNSTABLE", (settings = value(persistence), createObj(toList(delay(() => append(singleton(["type", settings.Type]), delay(() => append((settings.Backbutton != null) ? singleton(["backbutton", value(settings.Backbutton)]) : empty(), delay(() => singleton(["validator", (tupledArg) => {
            const matchValue = settings.Validator(tupledArg[0]);
            return (matchValue == null) ? tupledArg[1] : matchValue;
        }])))))))))]) : empty(), delay(() => ((dangerouslyAllowMutability != null) ? singleton(["dangerouslyAllowMutability", value(dangerouslyAllowMutability)]) : empty())));
    }))))))))));
})();

export const cardTab = (() => {
    const effects = void 0;
    const persistence = void 0;
    const dangerouslyAllowMutability = void 0;
    return Recoil_atom_4E60E31B(createObj(toList(delay(() => append(singleton(["key", "cardTab"]), delay(() => append(singleton(["default", new CardTab(0)]), delay(() => append(((effects != null) ? (!isEmpty(value(effects))) : false) ? singleton(["effects_UNSTABLE", Array.from(value(effects))]) : empty(), delay(() => {
        let settings;
        return append((persistence != null) ? singleton(["persistence_UNSTABLE", (settings = value(persistence), createObj(toList(delay(() => append(singleton(["type", settings.Type]), delay(() => append((settings.Backbutton != null) ? singleton(["backbutton", value(settings.Backbutton)]) : empty(), delay(() => singleton(["validator", (tupledArg) => {
            const matchValue = settings.Validator(tupledArg[0]);
            return (matchValue == null) ? tupledArg[1] : matchValue;
        }])))))))))]) : empty(), delay(() => ((dangerouslyAllowMutability != null) ? singleton(["dangerouslyAllowMutability", value(dangerouslyAllowMutability)]) : empty())));
    }))))))))));
})();

export const selectedProductPublicKey = (() => {
    const effects = void 0;
    const persistence = void 0;
    const dangerouslyAllowMutability = void 0;
    return Recoil_atom_4E60E31B(createObj(toList(delay(() => append(singleton(["key", "selectedProductPublicKey"]), delay(() => append(singleton(["default", void 0]), delay(() => append(((effects != null) ? (!isEmpty(value(effects))) : false) ? singleton(["effects_UNSTABLE", Array.from(value(effects))]) : empty(), delay(() => {
        let settings;
        return append((persistence != null) ? singleton(["persistence_UNSTABLE", (settings = value(persistence), createObj(toList(delay(() => append(singleton(["type", settings.Type]), delay(() => append((settings.Backbutton != null) ? singleton(["backbutton", value(settings.Backbutton)]) : empty(), delay(() => singleton(["validator", (tupledArg) => {
            const matchValue = settings.Validator(tupledArg[0]);
            return (matchValue == null) ? tupledArg[1] : value(matchValue);
        }])))))))))]) : empty(), delay(() => ((dangerouslyAllowMutability != null) ? singleton(["dangerouslyAllowMutability", value(dangerouslyAllowMutability)]) : empty())));
    }))))))))));
})();

export const selectedInjectiveProductAddress = (() => {
    const effects = void 0;
    const persistence = void 0;
    const dangerouslyAllowMutability = void 0;
    return Recoil_atom_4E60E31B(createObj(toList(delay(() => append(singleton(["key", "selectedInjectiveProductAddress"]), delay(() => append(singleton(["default", void 0]), delay(() => append(((effects != null) ? (!isEmpty(value(effects))) : false) ? singleton(["effects_UNSTABLE", Array.from(value(effects))]) : empty(), delay(() => {
        let settings;
        return append((persistence != null) ? singleton(["persistence_UNSTABLE", (settings = value(persistence), createObj(toList(delay(() => append(singleton(["type", settings.Type]), delay(() => append((settings.Backbutton != null) ? singleton(["backbutton", value(settings.Backbutton)]) : empty(), delay(() => singleton(["validator", (tupledArg) => {
            const matchValue = settings.Validator(tupledArg[0]);
            return (matchValue == null) ? tupledArg[1] : value(matchValue);
        }])))))))))]) : empty(), delay(() => ((dangerouslyAllowMutability != null) ? singleton(["dangerouslyAllowMutability", value(dangerouslyAllowMutability)]) : empty())));
    }))))))))));
})();

export const selectedProductGroup = (() => {
    const effects = void 0;
    const persistence = void 0;
    const dangerouslyAllowMutability = void 0;
    return Recoil_atom_4E60E31B(createObj(toList(delay(() => append(singleton(["key", "selectedProductGroup"]), delay(() => append(singleton(["default", new SelectedProductGroup(3)]), delay(() => append(((effects != null) ? (!isEmpty(value(effects))) : false) ? singleton(["effects_UNSTABLE", Array.from(value(effects))]) : empty(), delay(() => {
        let settings;
        return append((persistence != null) ? singleton(["persistence_UNSTABLE", (settings = value(persistence), createObj(toList(delay(() => append(singleton(["type", settings.Type]), delay(() => append((settings.Backbutton != null) ? singleton(["backbutton", value(settings.Backbutton)]) : empty(), delay(() => singleton(["validator", (tupledArg) => {
            const matchValue = settings.Validator(tupledArg[0]);
            return (matchValue == null) ? tupledArg[1] : matchValue;
        }])))))))))]) : empty(), delay(() => ((dangerouslyAllowMutability != null) ? singleton(["dangerouslyAllowMutability", value(dangerouslyAllowMutability)]) : empty())));
    }))))))))));
})();

export const profile = (() => {
    const effects = void 0;
    const persistence = void 0;
    const dangerouslyAllowMutability = void 0;
    return Recoil_atom_4E60E31B(createObj(toList(delay(() => append(singleton(["key", "profile"]), delay(() => append(singleton(["default", void 0]), delay(() => append(((effects != null) ? (!isEmpty(value(effects))) : false) ? singleton(["effects_UNSTABLE", Array.from(value(effects))]) : empty(), delay(() => {
        let settings;
        return append((persistence != null) ? singleton(["persistence_UNSTABLE", (settings = value(persistence), createObj(toList(delay(() => append(singleton(["type", settings.Type]), delay(() => append((settings.Backbutton != null) ? singleton(["backbutton", value(settings.Backbutton)]) : empty(), delay(() => singleton(["validator", (tupledArg) => {
            const matchValue = settings.Validator(tupledArg[0]);
            return (matchValue == null) ? tupledArg[1] : value(matchValue);
        }])))))))))]) : empty(), delay(() => ((dangerouslyAllowMutability != null) ? singleton(["dangerouslyAllowMutability", value(dangerouslyAllowMutability)]) : empty())));
    }))))))))));
})();

export const tokens = (() => {
    const defaultValue = ofList(empty_1());
    const effects = void 0;
    const persistence = void 0;
    const dangerouslyAllowMutability = void 0;
    return Recoil_atom_4E60E31B(createObj(toList(delay(() => append(singleton(["key", "tokens"]), delay(() => append(singleton(["default", defaultValue]), delay(() => append(((effects != null) ? (!isEmpty(value(effects))) : false) ? singleton(["effects_UNSTABLE", Array.from(value(effects))]) : empty(), delay(() => {
        let settings;
        return append((persistence != null) ? singleton(["persistence_UNSTABLE", (settings = value(persistence), createObj(toList(delay(() => append(singleton(["type", settings.Type]), delay(() => append((settings.Backbutton != null) ? singleton(["backbutton", value(settings.Backbutton)]) : empty(), delay(() => singleton(["validator", (tupledArg) => {
            const matchValue = settings.Validator(tupledArg[0]);
            return (matchValue == null) ? tupledArg[1] : matchValue;
        }])))))))))]) : empty(), delay(() => ((dangerouslyAllowMutability != null) ? singleton(["dangerouslyAllowMutability", value(dangerouslyAllowMutability)]) : empty())));
    }))))))))));
})();

export const maturities = (() => {
    const effects = void 0;
    const persistence = void 0;
    const dangerouslyAllowMutability = void 0;
    return Recoil_atom_4E60E31B(createObj(toList(delay(() => append(singleton(["key", "maturities"]), delay(() => append(singleton(["default", empty_1()]), delay(() => append(((effects != null) ? (!isEmpty(value(effects))) : false) ? singleton(["effects_UNSTABLE", Array.from(value(effects))]) : empty(), delay(() => {
        let settings;
        return append((persistence != null) ? singleton(["persistence_UNSTABLE", (settings = value(persistence), createObj(toList(delay(() => append(singleton(["type", settings.Type]), delay(() => append((settings.Backbutton != null) ? singleton(["backbutton", value(settings.Backbutton)]) : empty(), delay(() => singleton(["validator", (tupledArg) => {
            const matchValue = settings.Validator(tupledArg[0]);
            return (matchValue == null) ? tupledArg[1] : matchValue;
        }])))))))))]) : empty(), delay(() => ((dangerouslyAllowMutability != null) ? singleton(["dangerouslyAllowMutability", value(dangerouslyAllowMutability)]) : empty())));
    }))))))))));
})();

export const filteredView = (() => {
    const effects = void 0;
    const persistence = void 0;
    const dangerouslyAllowMutability = void 0;
    return Recoil_atom_4E60E31B(createObj(toList(delay(() => append(singleton(["key", "filteredView"]), delay(() => append(singleton(["default", true]), delay(() => append(((effects != null) ? (!isEmpty(value(effects))) : false) ? singleton(["effects_UNSTABLE", Array.from(value(effects))]) : empty(), delay(() => {
        let settings;
        return append((persistence != null) ? singleton(["persistence_UNSTABLE", (settings = value(persistence), createObj(toList(delay(() => append(singleton(["type", settings.Type]), delay(() => append((settings.Backbutton != null) ? singleton(["backbutton", value(settings.Backbutton)]) : empty(), delay(() => singleton(["validator", (tupledArg) => {
            const matchValue = settings.Validator(tupledArg[0]);
            return (matchValue == null) ? tupledArg[1] : matchValue;
        }])))))))))]) : empty(), delay(() => ((dangerouslyAllowMutability != null) ? singleton(["dangerouslyAllowMutability", value(dangerouslyAllowMutability)]) : empty())));
    }))))))))));
})();

export const setup = (() => {
    const defaultValue = new SetupState(new SetupStep(0), "0", false, empty_1());
    const effects = void 0;
    const persistence = void 0;
    const dangerouslyAllowMutability = void 0;
    return Recoil_atom_4E60E31B(createObj(toList(delay(() => append(singleton(["key", "setup"]), delay(() => append(singleton(["default", defaultValue]), delay(() => append(((effects != null) ? (!isEmpty(value(effects))) : false) ? singleton(["effects_UNSTABLE", Array.from(value(effects))]) : empty(), delay(() => {
        let settings;
        return append((persistence != null) ? singleton(["persistence_UNSTABLE", (settings = value(persistence), createObj(toList(delay(() => append(singleton(["type", settings.Type]), delay(() => append((settings.Backbutton != null) ? singleton(["backbutton", value(settings.Backbutton)]) : empty(), delay(() => singleton(["validator", (tupledArg) => {
            const matchValue = settings.Validator(tupledArg[0]);
            return (matchValue == null) ? tupledArg[1] : matchValue;
        }])))))))))]) : empty(), delay(() => ((dangerouslyAllowMutability != null) ? singleton(["dangerouslyAllowMutability", value(dangerouslyAllowMutability)]) : empty())));
    }))))))))));
})();

export const config = (() => {
    const effects = void 0;
    const persistence = void 0;
    const dangerouslyAllowMutability = void 0;
    return Recoil_atom_4E60E31B(createObj(toList(delay(() => append(singleton(["key", "config"]), delay(() => append(singleton(["default", void 0]), delay(() => append(((effects != null) ? (!isEmpty(value(effects))) : false) ? singleton(["effects_UNSTABLE", Array.from(value(effects))]) : empty(), delay(() => {
        let settings;
        return append((persistence != null) ? singleton(["persistence_UNSTABLE", (settings = value(persistence), createObj(toList(delay(() => append(singleton(["type", settings.Type]), delay(() => append((settings.Backbutton != null) ? singleton(["backbutton", value(settings.Backbutton)]) : empty(), delay(() => singleton(["validator", (tupledArg) => {
            const matchValue = settings.Validator(tupledArg[0]);
            return (matchValue == null) ? tupledArg[1] : value(matchValue);
        }])))))))))]) : empty(), delay(() => ((dangerouslyAllowMutability != null) ? singleton(["dangerouslyAllowMutability", value(dangerouslyAllowMutability)]) : empty())));
    }))))))))));
})();

export const productSourcePage = (() => {
    const effects = void 0;
    const persistence = void 0;
    const dangerouslyAllowMutability = void 0;
    return Recoil_atom_4E60E31B(createObj(toList(delay(() => append(singleton(["key", "productSourcePage"]), delay(() => append(singleton(["default", "products-list"]), delay(() => append(((effects != null) ? (!isEmpty(value(effects))) : false) ? singleton(["effects_UNSTABLE", Array.from(value(effects))]) : empty(), delay(() => {
        let settings;
        return append((persistence != null) ? singleton(["persistence_UNSTABLE", (settings = value(persistence), createObj(toList(delay(() => append(singleton(["type", settings.Type]), delay(() => append((settings.Backbutton != null) ? singleton(["backbutton", value(settings.Backbutton)]) : empty(), delay(() => singleton(["validator", (tupledArg) => {
            const matchValue = settings.Validator(tupledArg[0]);
            return (matchValue == null) ? tupledArg[1] : matchValue;
        }])))))))))]) : empty(), delay(() => ((dangerouslyAllowMutability != null) ? singleton(["dangerouslyAllowMutability", value(dangerouslyAllowMutability)]) : empty())));
    }))))))))));
})();

export const themeConfig = (() => {
    const defaultValue = localStorage.getItem("themeConfig");
    const effects = void 0;
    const persistence = void 0;
    const dangerouslyAllowMutability = void 0;
    return Recoil_atom_4E60E31B(createObj(toList(delay(() => append(singleton(["key", "themeConfig"]), delay(() => append(singleton(["default", defaultValue]), delay(() => append(((effects != null) ? (!isEmpty(value(effects))) : false) ? singleton(["effects_UNSTABLE", Array.from(value(effects))]) : empty(), delay(() => {
        let settings;
        return append((persistence != null) ? singleton(["persistence_UNSTABLE", (settings = value(persistence), createObj(toList(delay(() => append(singleton(["type", settings.Type]), delay(() => append((settings.Backbutton != null) ? singleton(["backbutton", value(settings.Backbutton)]) : empty(), delay(() => singleton(["validator", (tupledArg) => {
            const matchValue = settings.Validator(tupledArg[0]);
            return (matchValue == null) ? tupledArg[1] : matchValue;
        }])))))))))]) : empty(), delay(() => ((dangerouslyAllowMutability != null) ? singleton(["dangerouslyAllowMutability", value(dangerouslyAllowMutability)]) : empty())));
    }))))))))));
})();

export const generalMessageShown = (() => {
    const effects = void 0;
    const persistence = void 0;
    const dangerouslyAllowMutability = void 0;
    return Recoil_atom_4E60E31B(createObj(toList(delay(() => append(singleton(["key", "generalMessageShown"]), delay(() => append(singleton(["default", false]), delay(() => append(((effects != null) ? (!isEmpty(value(effects))) : false) ? singleton(["effects_UNSTABLE", Array.from(value(effects))]) : empty(), delay(() => {
        let settings;
        return append((persistence != null) ? singleton(["persistence_UNSTABLE", (settings = value(persistence), createObj(toList(delay(() => append(singleton(["type", settings.Type]), delay(() => append((settings.Backbutton != null) ? singleton(["backbutton", value(settings.Backbutton)]) : empty(), delay(() => singleton(["validator", (tupledArg) => {
            const matchValue = settings.Validator(tupledArg[0]);
            return (matchValue == null) ? tupledArg[1] : matchValue;
        }])))))))))]) : empty(), delay(() => ((dangerouslyAllowMutability != null) ? singleton(["dangerouslyAllowMutability", value(dangerouslyAllowMutability)]) : empty())));
    }))))))))));
})();

export const warningMessageShown = (() => {
    const effects = void 0;
    const persistence = void 0;
    const dangerouslyAllowMutability = void 0;
    return Recoil_atom_4E60E31B(createObj(toList(delay(() => append(singleton(["key", "warningMessageShown"]), delay(() => append(singleton(["default", false]), delay(() => append(((effects != null) ? (!isEmpty(value(effects))) : false) ? singleton(["effects_UNSTABLE", Array.from(value(effects))]) : empty(), delay(() => {
        let settings;
        return append((persistence != null) ? singleton(["persistence_UNSTABLE", (settings = value(persistence), createObj(toList(delay(() => append(singleton(["type", settings.Type]), delay(() => append((settings.Backbutton != null) ? singleton(["backbutton", value(settings.Backbutton)]) : empty(), delay(() => singleton(["validator", (tupledArg) => {
            const matchValue = settings.Validator(tupledArg[0]);
            return (matchValue == null) ? tupledArg[1] : matchValue;
        }])))))))))]) : empty(), delay(() => ((dangerouslyAllowMutability != null) ? singleton(["dangerouslyAllowMutability", value(dangerouslyAllowMutability)]) : empty())));
    }))))))))));
})();

export const injTestnetWhiteListed = (() => {
    const effects = void 0;
    const persistence = void 0;
    const dangerouslyAllowMutability = void 0;
    return Recoil_atom_4E60E31B(createObj(toList(delay(() => append(singleton(["key", "injTestnetWhiteListed"]), delay(() => append(singleton(["default", new WhiteListedWallet(2)]), delay(() => append(((effects != null) ? (!isEmpty(value(effects))) : false) ? singleton(["effects_UNSTABLE", Array.from(value(effects))]) : empty(), delay(() => {
        let settings;
        return append((persistence != null) ? singleton(["persistence_UNSTABLE", (settings = value(persistence), createObj(toList(delay(() => append(singleton(["type", settings.Type]), delay(() => append((settings.Backbutton != null) ? singleton(["backbutton", value(settings.Backbutton)]) : empty(), delay(() => singleton(["validator", (tupledArg) => {
            const matchValue = settings.Validator(tupledArg[0]);
            return (matchValue == null) ? tupledArg[1] : matchValue;
        }])))))))))]) : empty(), delay(() => ((dangerouslyAllowMutability != null) ? singleton(["dangerouslyAllowMutability", value(dangerouslyAllowMutability)]) : empty())));
    }))))))))));
})();

export const injTestnetWhiteListedMessageGreen = (() => {
    const effects = void 0;
    const persistence = void 0;
    const dangerouslyAllowMutability = void 0;
    return Recoil_atom_4E60E31B(createObj(toList(delay(() => append(singleton(["key", "injTestnetWhiteListedMessageGreen"]), delay(() => append(singleton(["default", void 0]), delay(() => append(((effects != null) ? (!isEmpty(value(effects))) : false) ? singleton(["effects_UNSTABLE", Array.from(value(effects))]) : empty(), delay(() => {
        let settings;
        return append((persistence != null) ? singleton(["persistence_UNSTABLE", (settings = value(persistence), createObj(toList(delay(() => append(singleton(["type", settings.Type]), delay(() => append((settings.Backbutton != null) ? singleton(["backbutton", value(settings.Backbutton)]) : empty(), delay(() => singleton(["validator", (tupledArg) => {
            const matchValue = settings.Validator(tupledArg[0]);
            return (matchValue == null) ? tupledArg[1] : value(matchValue);
        }])))))))))]) : empty(), delay(() => ((dangerouslyAllowMutability != null) ? singleton(["dangerouslyAllowMutability", value(dangerouslyAllowMutability)]) : empty())));
    }))))))))));
})();

