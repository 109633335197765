import { createElement } from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.62.0/Interop.fs.js";

export function MainTitle(mainTitleInputProps) {
    const content = mainTitleInputProps.content;
    return createElement("h1", {
        className: "py-4\n                text-center\n                font-title\n                lg:text-8xl\n                md:text-7xl\n                text-6xl\n                font-extrabold\n                exo-background\n                inline-block\n                text-transparent\n                bg-clip-text",
        children: Interop_reactApi.Children.toArray(Array.from(content)),
    });
}

export function SubTitle(subTitleInputProps) {
    const content = subTitleInputProps.content;
    return createElement("h2", {
        className: "py-4\n                font-title\n                lg:text-6xl\n                md:text-4xl\n                text-3xl\n                font-extrabold\n                exo-background\n                inline-block\n                text-transparent\n                bg-clip-text",
        children: content,
    });
}

export function OptionTitle(optionTitleInputProps) {
    const content = optionTitleInputProps.content;
    return createElement("h2", {
        className: "py-4\n                font-title\n                lg:text-5xl\n                md:text-4xl\n                text-3xl\n                font-extrabold\n                exo-background\n                inline-block\n                text-transparent\n                bg-clip-text",
        children: Interop_reactApi.Children.toArray(Array.from(content)),
    });
}

