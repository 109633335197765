import { Record, Union } from "../../../app/client/src/.fable/fable-library.3.1.16/Types.js";
import { record_type, option_type, class_type, string_type, union_type } from "../../../app/client/src/.fable/fable-library.3.1.16/Reflection.js";
import { InjectiveProductKind$reflection } from "./InjectiveProduct.fs.js";
import { equals } from "../../../app/client/src/.fable/fable-library.3.1.16/Util.js";
import { utcNow } from "../../../app/client/src/.fable/fable-library.3.1.16/DateOffset.js";
import { compare } from "../../../app/client/src/.fable/fable-library.3.1.16/Date.js";

export class InjectiveProductType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Yield", "Option"];
    }
}

export function InjectiveProductType$reflection() {
    return union_type("ExoticMarkets.Domain.InjectivePointMultiplier.InjectiveProductType", [], InjectiveProductType, () => [[], []]);
}

export class InjectivePointMultiplier extends Record {
    constructor(CampaignName, Multiplier, ProductType, OptionKind, Currency, StartTime, EndTime) {
        super();
        this.CampaignName = CampaignName;
        this.Multiplier = Multiplier;
        this.ProductType = ProductType;
        this.OptionKind = OptionKind;
        this.Currency = Currency;
        this.StartTime = StartTime;
        this.EndTime = EndTime;
    }
}

export function InjectivePointMultiplier$reflection() {
    return record_type("ExoticMarkets.Domain.InjectivePointMultiplier.InjectivePointMultiplier", [], InjectivePointMultiplier, () => [["CampaignName", string_type], ["Multiplier", class_type("System.Decimal")], ["ProductType", option_type(InjectiveProductType$reflection())], ["OptionKind", option_type(InjectiveProductKind$reflection())], ["Currency", option_type(string_type)], ["StartTime", option_type(class_type("System.DateTimeOffset"))], ["EndTime", option_type(class_type("System.DateTimeOffset"))]]);
}

export function InjectivePointMultiplier__CheckIfApplied(this$, productType, optionKind, currency) {
    let matchValue_3;
    let matchProductType;
    const matchValue = this$.ProductType;
    matchProductType = ((matchValue == null) ? true : equals(matchValue, productType));
    let matchOptionKind;
    const matchValue_1 = this$.OptionKind;
    matchOptionKind = ((matchValue_1 == null) ? true : equals(matchValue_1, optionKind));
    let matchCurrency;
    const matchValue_2 = this$.Currency;
    matchCurrency = ((matchValue_2 == null) ? true : (matchValue_2.toLocaleLowerCase() === currency.toLocaleLowerCase()));
    const now = utcNow();
    if (((matchProductType ? matchOptionKind : false) ? matchCurrency : false) ? (matchValue_3 = this$.StartTime, (matchValue_3 == null) ? true : (compare(matchValue_3, now) <= 0)) : false) {
        const matchValue_4 = this$.EndTime;
        if (matchValue_4 == null) {
            return true;
        }
        else {
            return compare(matchValue_4, now) >= 0;
        }
    }
    else {
        return false;
    }
}

