import { Record } from "../../../app/client/src/.fable/fable-library.3.1.16/Types.js";
import { record_type, float64_type, option_type, uint8_type, string_type } from "../../../app/client/src/.fable/fable-library.3.1.16/Reflection.js";

export class InjectiveUiFormat extends Record {
    constructor(TokenSymbol, RoundingDigitDisplay, RoundingDigitCalc, PriceDigit, PriceStep) {
        super();
        this.TokenSymbol = TokenSymbol;
        this.RoundingDigitDisplay = RoundingDigitDisplay;
        this.RoundingDigitCalc = RoundingDigitCalc;
        this.PriceDigit = PriceDigit;
        this.PriceStep = PriceStep;
    }
}

export function InjectiveUiFormat$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveUiFormat.InjectiveUiFormat", [], InjectiveUiFormat, () => [["TokenSymbol", string_type], ["RoundingDigitDisplay", option_type(uint8_type)], ["RoundingDigitCalc", option_type(uint8_type)], ["PriceDigit", option_type(uint8_type)], ["PriceStep", option_type(float64_type)]]);
}

