import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "./.fable/Feliz.1.62.0/Interop.fs.js";
import { SubTitle, MainTitle } from "./Components/Title.fs.js";
import { ringBackground, investors, pythV2, injectiveV2 } from "./Img.fs.js";
import { LinkToExternalButton, LinkToPageButton } from "./Components/Button.fs.js";
import { Section } from "./Components/Section.fs.js";
import { Card } from "./Components/Card.fs.js";
import { CardLogo6, CardLogo5, CardLogo4, CardLogo3, CardLogo2, CardLogo1 } from "./Components/Icon/Card.fs.js";
import { TelegramIcon } from "./Components/Icon/Telegram.fs.js";
import { DiscordIcon } from "./Components/Icon/Discord.fs.js";
import { useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "./.fable/Feliz.1.62.0/React.fs.js";
import { equals } from "./.fable/fable-library.3.1.16/Util.js";

export function MainSection(mainSectionInputProps) {
    const goToCommunityToggle = mainSectionInputProps.goToCommunityToggle;
    const setGoToCommunityToggle = mainSectionInputProps.setGoToCommunityToggle;
    const setGoToCommunity = mainSectionInputProps.setGoToCommunity;
    const content = react.createElement(react.Fragment, {}, createElement("div", {
        className: "text-center",
        children: Interop_reactApi.Children.toArray([createElement(MainTitle, {
            content: ["The only option", createElement("br", {}), "for options"],
        }), createElement("div", {
            className: "flex gap-4 items-center justify-center mt-4",
            children: Interop_reactApi.Children.toArray([createElement("span", {
                className: "text-exo-primary",
                children: "Powered by",
            }), createElement("img", {
                src: injectiveV2,
                alt: "Injective Logo",
            }), createElement("img", {
                src: pythV2,
                alt: "Pyth Logo",
            })]),
        })]),
    }), createElement("div", {
        className: "flex gap-5 flex-col lg:flex-row md:flex-row self-center",
        children: Interop_reactApi.Children.toArray([createElement(LinkToPageButton, {
            text: "Earn Yield",
            setGoToCommunity: setGoToCommunity,
            setGoToCommunityToggle: setGoToCommunityToggle,
            goToCommunityToggle: goToCommunityToggle,
        }), createElement(LinkToPageButton, {
            text: "Buy Options",
            setGoToCommunity: setGoToCommunity,
            setGoToCommunityToggle: setGoToCommunityToggle,
            goToCommunityToggle: goToCommunityToggle,
        })]),
    }), createElement("img", {
        className: "self-center lg:w-1/2",
        src: investors,
        alt: "Investor Logos",
    }));
    return react.createElement(react.Fragment, {}, createElement(Section, {
        children: content,
        id: void 0,
    }));
}

export function CardSection() {
    const content = react.createElement(react.Fragment, {}, createElement("div", {
        className: "lg:w-1/3 flex flex-col gap-4 justify-center items-center",
        children: Interop_reactApi.Children.toArray([createElement(SubTitle, {
            content: "Flexibility Fully On‑Chain",
        }), createElement("p", {
            className: "text-exo-primary text-center lg:text-left text-nowrap",
            children: Interop_reactApi.Children.toArray(["Using the low gas fees of Injective chain.", createElement("br", {}), "We create custom products matching different risk profiles.", createElement("br", {}), "All products are settled on chain, fully DeFi."]),
        })]),
    }), createElement("div", {
        className: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5",
        children: Interop_reactApi.Children.toArray([createElement(Card, {
            children: react.createElement(react.Fragment, {}, createElement(CardLogo1, null), createElement("p", {
                children: ["Sustainable Yield"],
            })),
        }), createElement(Card, {
            children: react.createElement(react.Fragment, {}, createElement(CardLogo2, null), createElement("p", {
                children: ["Trade on-chain Options"],
            })),
        }), createElement(Card, {
            children: react.createElement(react.Fragment, {}, createElement(CardLogo3, null), createElement("p", {
                children: ["Flexibility \u0026 Security"],
            })),
        }), createElement(Card, {
            children: react.createElement(react.Fragment, {}, createElement(CardLogo4, null), createElement("p", {
                children: ["Short \u0026 Long Tenor"],
            })),
        }), createElement(Card, {
            children: react.createElement(react.Fragment, {}, createElement(CardLogo5, null), createElement("p", {
                children: ["Low Gas Fee"],
            })),
        }), createElement(Card, {
            children: react.createElement(react.Fragment, {}, createElement(CardLogo6, null), createElement("p", {
                children: ["More to Come"],
            })),
        })]),
    }));
    return createElement(Section, {
        children: content,
        id: void 0,
    });
}

export function CommunitySection() {
    const content = react.createElement(react.Fragment, {}, createElement("img", {
        id: "community-img",
        className: "absolute -top-6 self-center",
        src: ringBackground,
    }), createElement("div", {
        className: "flex flex-col items-center gap-3 self-center justify-center",
        children: Interop_reactApi.Children.toArray([createElement(SubTitle, {
            content: "Join The Community",
        }), createElement("p", {
            className: "text-exo-primary text-center text-nowrap",
            children: Interop_reactApi.Children.toArray(["Become part of the Exotic Community. Join us to discuss", createElement("br", {}), "about market trends, product releases and trading", createElement("br", {}), "opportunities with options."]),
        })]),
    }), createElement("div", {
        className: "flex lg:gap-5 gap-3 flex-col lg:flex-row md:flex-row self-center",
        children: Interop_reactApi.Children.toArray([createElement(LinkToExternalButton, {
            children: react.createElement(react.Fragment, {}, createElement("span", {
                children: ["Join our Telegram"],
            }), createElement(TelegramIcon, null)),
            url: "https://t.me/exoticmarkets",
        }), createElement(LinkToExternalButton, {
            children: react.createElement(react.Fragment, {}, "Join our Discord", createElement(DiscordIcon, null)),
            url: "https://discord.com/invite/yfCMb9j35Y",
        })]),
    }));
    return createElement(Section, {
        children: content,
        id: "community-section",
    });
}

export function Landing(landingInputProps) {
    const goToCommunityToggleNavbar = landingInputProps.goToCommunityToggleNavbar;
    const goToCommunityNavbar = landingInputProps.goToCommunityNavbar;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const goToCommunityButton = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const goToCommunityButtonToggle = patternInput_1[0];
    useReact_useEffect_Z101E1A95(() => {
        if (goToCommunityNavbar === true) {
            const s = document.getElementById("community-section");
            if (!equals(s, null)) {
                s.scrollIntoView(true);
            }
        }
    }, [goToCommunityNavbar, goToCommunityToggleNavbar]);
    useReact_useEffect_Z101E1A95(() => {
        if (goToCommunityButton) {
            const s_1 = document.getElementById("community-section");
            if (!equals(s_1, null)) {
                s_1.scrollIntoView(true);
            }
        }
    }, [goToCommunityButton, goToCommunityButtonToggle]);
    return react.createElement(react.Fragment, {}, createElement("div", {
        className: "flex flex-col lg:gap-y-32 gap-y-14 lg:p-32 p-5",
        children: Interop_reactApi.Children.toArray([createElement(MainSection, {
            setGoToCommunity: patternInput[1],
            setGoToCommunityToggle: patternInput_1[1],
            goToCommunityToggle: goToCommunityButtonToggle,
        }), createElement(CardSection, null), createElement(CommunitySection, null)]),
    }));
}

