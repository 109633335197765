import { createElement } from "react";
import { isNullOrEmpty, interpolate, toText, join } from "./.fable/fable-library.3.1.16/String.js";
import setup$002Emodule from "./styles/setup.module.scss";
import { Interop_reactApi } from "./.fable/Feliz.1.62.0/Interop.fs.js";
import utils$002Emodule from "./styles/utils.module.scss";
import { Col } from "./UtilComponents.fs.js";
import { empty as empty_1, ofArray, singleton } from "./.fable/fable-library.3.1.16/List.js";
import { empty, singleton as singleton_1, append, delay, toList } from "./.fable/fable-library.3.1.16/Seq.js";
import { Recoil_useRecoilState_Z74A95619 } from "./.fable/Feliz.Recoil.0.19.2/Bindings.fs.js";
import { setCompletedSetup, SetupStep, SetupState, setup as setup_1 } from "./Context.fs.js";
import { useWallet } from "@solana/wallet-adapter-react";
import { useReact_useCallback_93353E } from "./.fable/Feliz.1.62.0/React.fs.js";
import { parse } from "./.fable/fable-library.3.1.16/Double.js";
import { RouterModule_encodeQueryString, RouterModule_nav } from "./.fable/Feliz.Router.3.8.0/Router.fs.js";

export function StepContainer(stepContainerInputProps) {
    const onSkip = stepContainerInputProps.onSkip;
    const skipEnabled = stepContainerInputProps.skipEnabled;
    const onNext = stepContainerInputProps.onNext;
    const nextText = stepContainerInputProps.nextText;
    const disabled = stepContainerInputProps.disabled;
    const text = stepContainerInputProps.text;
    const title = stepContainerInputProps.title;
    const totalSteps = stepContainerInputProps.totalSteps;
    const stepNumber = stepContainerInputProps.stepNumber;
    const stepClass = stepContainerInputProps.stepClass;
    return createElement("div", {
        className: join(" ", [setup$002Emodule.modal, stepClass, "card"]),
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: join(" ", [utils$002Emodule.center, utils$002Emodule["text-m"]]),
            children: Interop_reactApi.Children.toArray([createElement(Col, {
                classes: singleton(utils$002Emodule["gap-m"]),
                children: toList(delay(() => {
                    let children;
                    return append(singleton_1(createElement("div", {
                        className: join(" ", [utils$002Emodule.center, utils$002Emodule["text-s"]]),
                        children: Interop_reactApi.Children.toArray([(children = ofArray(["Step ", createElement("b", {
                            children: [stepNumber],
                        }), " of ", createElement("b", {
                            children: [totalSteps],
                        })]), createElement("span", {
                            children: Interop_reactApi.Children.toArray(Array.from(children)),
                        }))]),
                    })), delay(() => append(singleton_1(createElement("b", {
                        className: join(" ", [utils$002Emodule.center]),
                        children: Interop_reactApi.Children.toArray([toText(interpolate("Step %P(): ", [stepNumber])), title]),
                    })), delay(() => append(singleton_1(createElement("p", {
                        className: join(" ", [utils$002Emodule["text-center"]]),
                        children: text,
                    })), delay(() => append(singleton_1(createElement("button", {
                        className: "button-primary",
                        children: Interop_reactApi.Children.toArray([nextText]),
                        disabled: disabled,
                        onClick: (_arg1) => {
                            onNext();
                        },
                    })), delay(() => append(skipEnabled ? singleton_1(createElement("button", {
                        className: join(" ", [setup$002Emodule["skip-button"], utils$002Emodule["text-light"], utils$002Emodule.center]),
                        children: Interop_reactApi.Children.toArray(["Skip"]),
                        onClick: (_arg2) => {
                            onSkip();
                        },
                    })) : empty(), delay(() => {
                        let percent;
                        return singleton_1((percent = ((stepNumber / totalSteps) * 100), createElement("div", {
                            className: join(" ", [setup$002Emodule["progress-bar"], utils$002Emodule["margin-m-top"]]),
                            style: {
                                width: percent + "%",
                            },
                        })));
                    }))))))))));
                })),
            })]),
        })]),
    });
}

export function Setup() {
    const patternInput = Recoil_useRecoilState_Z74A95619(setup_1);
    const setup = patternInput[0];
    const totalSteps = 5;
    const walletCtx = useWallet();
    const setStep = (step) => {
        patternInput[1](new SetupState(step, setup.Amount, setup.ConfirmedDeposit, setup.Positions));
    };
    const skip = useReact_useCallback_93353E(() => {
        setStep(new SetupStep(6));
        setCompletedSetup(true);
    }, []);
    const matchValue = setup.Step;
    switch (matchValue.tag) {
        case 1: {
            return createElement(StepContainer, {
                stepClass: setup$002Emodule["step-1"],
                stepNumber: 1,
                totalSteps: totalSteps,
                title: "Connect Wallet",
                text: "Connecting your wallet will allow you to access your dashboard and start earning yield!",
                disabled: walletCtx.wallet == null,
                nextText: "Next",
                onNext: () => {
                    setStep(new SetupStep(2));
                },
                skipEnabled: true,
                onSkip: skip,
            });
        }
        case 2: {
            return createElement(StepContainer, {
                stepClass: setup$002Emodule["step-2"],
                stepNumber: 2,
                totalSteps: totalSteps,
                title: "Enter your amount to deposit",
                text: "Connecting your wallet will allow you to access your dashboard and start earning yield!",
                disabled: isNullOrEmpty(setup.Amount) ? true : (parse(setup.Amount) <= 0),
                nextText: "Next",
                onNext: () => {
                    setStep(new SetupStep(3));
                },
                skipEnabled: true,
                onSkip: skip,
            });
        }
        case 3: {
            return createElement(StepContainer, {
                stepClass: setup$002Emodule["step-3"],
                stepNumber: 3,
                totalSteps: totalSteps,
                title: "Confirm your Deposit",
                text: "Confirming your desposit is final and cannot be canceled.",
                disabled: !setup.ConfirmedDeposit,
                nextText: "Next",
                onNext: () => {
                    setStep(new SetupStep(4));
                    RouterModule_nav(singleton("dashboard"), 1, 1);
                },
                skipEnabled: true,
                onSkip: skip,
            });
        }
        case 4: {
            return createElement(StepContainer, {
                stepClass: setup$002Emodule["step-4"],
                stepNumber: 4,
                totalSteps: totalSteps,
                title: "Follow your Position",
                text: "On your Dashboard you will find all the important information regarding your positions. Their size, yield, and TBE, all in one place!",
                disabled: false,
                nextText: "Next",
                onNext: () => {
                    setStep(new SetupStep(5));
                },
                skipEnabled: true,
                onSkip: skip,
            });
        }
        case 5: {
            return createElement(StepContainer, {
                stepClass: setup$002Emodule["step-5"],
                stepNumber: 5,
                totalSteps: totalSteps,
                title: "Withdraw your Position",
                text: "At subscription expiration, you will be able to withdraw your potential gains.",
                disabled: false,
                nextText: "Get Started",
                onNext: () => {
                    setStep(new SetupStep(6));
                    setCompletedSetup(true);
                    RouterModule_nav(singleton("products" + RouterModule_encodeQueryString(empty_1())), 2, 1);
                },
                skipEnabled: false,
                onSkip: skip,
            });
        }
        case 6: {
            return null;
        }
        default: {
            return null;
        }
    }
}

