import { createElement } from "react";
import * as react from "react";
import { displayRiskLevel, displayMaturity, TextColumn, ModalCloseButton, Column, ErrorText, ButtonProgressLoader, Col, Row } from "./UtilComponents.fs.js";
import single$002Dproducts$002Emodule from "./styles/single-products.module.scss";
import { sumBy, append as append_1, tryFind, filter, mapIndexed, sortBy, map, length, item, ofArray, empty, singleton } from "./.fable/fable-library.3.1.16/List.js";
import { substring, join, printf, toConsole, interpolate, toText } from "./.fable/fable-library.3.1.16/String.js";
import utils$002Emodule from "./styles/utils.module.scss";
import { Recoil_useRecoilState_Z74A95619, Recoil_useRecoilValue_5D3EEEEA } from "./.fable/Feliz.Recoil.0.19.2/Bindings.fs.js";
import { tokens as tokens_2, SelectedProductGroup, SetupState, completedSetup, selectedProductGroup, SetupStep, setup as setup_2, themeConfig as themeConfig_1, config as config_2, solanaEnvironment } from "./Context.fs.js";
import { Deferred$1, useFeliz_React__React_useDeferred_Static_2344FC52 } from "./.fable/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { singleton as singleton_1 } from "./.fable/fable-library.3.1.16/AsyncBuilder.js";
import { Api } from "./Server.fs.js";
import { publicKey as publicKey_2, publicKeyBackEndToSolana } from "../../../lib/Solana/Solana.fs.js";
import { convertToRealOnes } from "../../../lib/Solana/SolanaTokenRegistry.fs.js";
import { convertToDecimal, PublicKey, PublicKey_Value_Z1B21ACD5 } from "../../../lib/Domain/Types/Common.fs.js";
import { PKey } from "../../shared/Api.fs.js";
import { FSharpRef, toString } from "./.fable/fable-library.3.1.16/Types.js";
import { some } from "./.fable/fable-library.3.1.16/Option.js";
import { toNumber as toNumber_1, round, op_Subtraction as op_Subtraction_1, op_Division, op_Multiply, equals as equals_1, compare as compare_1, tryParse, fromParts } from "./.fable/fable-library.3.1.16/Decimal.js";
import Decimal from "./.fable/fable-library.3.1.16/Decimal.js";
import { utcNow, op_Subtraction } from "./.fable/fable-library.3.1.16/DateOffset.js";
import { fromValue, op_Addition, fromInt, toNumber, fromInteger, compare } from "./.fable/fable-library.3.1.16/Long.js";
import { totalSeconds, ticks } from "./.fable/fable-library.3.1.16/TimeSpan.js";
import { TimeCountdown } from "./DisplayTime.fs.js";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { useReact_useEffectOnce_3A5B6456, useReact_useCallback_93353E, useReact_useRef_1505, useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "./.fable/Feliz.1.62.0/React.fs.js";
import { startImmediate } from "./.fable/fable-library.3.1.16/Async.js";
import { DepositButton, AmountAndMaxButton2, FlexStatus_GetStatus_Z2D3563D0, getTokenBalance } from "./FlexProductUtils.fs.js";
import { check } from "./BlockChainInteraction/CheckTransactions.fs.js";
import { Interop_reactApi } from "./.fable/Feliz.1.62.0/Interop.fs.js";
import { CurrencyInput, CurrencyIcon } from "./Currency.fs.js";
import { round as round_1, createObj, fastStructuralHash, equals } from "./.fable/fable-library.3.1.16/Util.js";
import { getUserProductPositionPda } from "./BlockChainInteraction/DepositFlexProductV2.fs.js";
import { empty as empty_1, singleton as singleton_2, append, delay, toList } from "./.fable/fable-library.3.1.16/Seq.js";
import { ProductInfoCell, ProductDetailHeader } from "./GroupDetailCommon.fs.js";
import { SettlementRulesGroup } from "./SettlementRules.fs.js";
import { List_groupBy } from "./.fable/fable-library.3.1.16/Seq2.js";
import { RiskLevel as RiskLevel_1, riskLevelFromFlexProduct } from "./RiskLevel.fs.js";
import { MuiHelpers_createElement } from "./.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Modal from "@material-ui/core/Modal";
import { times2 } from "./Img.fs.js";
import { FlexProduct_GetProductNameDescription, RiskLevel } from "../../../lib/Domain/Types/FlexProduct.fs.js";
import { ConnectWalletMessageSmall } from "./ConnectWalletMessage.fs.js";
import { size } from "../../../lib/Domain/Types/Products.fs.js";
import LinearProgress from "@material-ui/core/LinearProgress";
import { errorBoundary } from "./Error.fs.js";

export function productDetailRow(value, label) {
    let value_1, value_2;
    return createElement(Row, {
        classes: singleton(single$002Dproducts$002Emodule["modal-product-info-row-value"]),
        children: ofArray([createElement(Col, {
            classes: empty(),
            children: singleton((value_1 = toText(interpolate("%P()", [value])), createElement("span", {
                children: [value_1],
            }))),
        }), createElement(Col, {
            classes: ofArray([utils$002Emodule["margin-xs-left"], single$002Dproducts$002Emodule["modal-product-info-label"]]),
            children: singleton((value_2 = toText(interpolate("%P()", [label])), createElement("span", {
                children: [value_2],
            }))),
        })]),
    });
}

export function productRecapRow(value, label, classes) {
    let value_1, value_2;
    return createElement(Row, {
        classes: singleton(single$002Dproducts$002Emodule["modal-product-info-row-recap"]),
        children: ofArray([createElement(Col, {
            classes: ofArray([utils$002Emodule["margin-xs-right"], single$002Dproducts$002Emodule["modal-product-info-label"]]),
            children: singleton((value_1 = toText(interpolate("%P()", [label])), createElement("span", {
                children: [value_1],
            }))),
        }), createElement(Col, {
            classes: classes,
            children: singleton((value_2 = toText(interpolate("%P()", [value])), createElement("span", {
                children: [value_2],
            }))),
        })]),
    });
}

export function SpotPrice(product) {
    const environment = Recoil_useRecoilValue_5D3EEEEA(solanaEnvironment);
    const spot_2 = useFeliz_React__React_useDeferred_Static_2344FC52(singleton_1.Delay(() => singleton_1.Bind(Api.GetSpotLevel(environment, new PKey(publicKeyBackEndToSolana(new PublicKey(0, convertToRealOnes(PublicKey_Value_Z1B21ACD5(product.UnderlyingMint)))).toBase58())), (_arg1_1) => {
        const spot = _arg1_1;
        if (spot.tag === 1) {
            console.error(some(("Error getting spot for " + toString(product.UnderlyingMint)) + ": "), spot.fields[0]);
            return singleton_1.Return(fromParts(0, 0, 0, false, 0));
        }
        else {
            return singleton_1.Return(spot.fields[0]);
        }
    })), [environment]);
    return productRecapRow(toText(interpolate("%P()", [(spot_2.tag === 2) ? spot_2.fields[0] : fromParts(0, 0, 0, false, 0)])), "$", empty());
}

export function TimerCountdown(product) {
    let value;
    const durationDeposits = op_Subtraction(product.EndDeposits, utcNow());
    const durationSettlements = op_Subtraction(product.StartSettlement, utcNow());
    const durationDepositsActive = compare(ticks(durationDeposits), fromInteger(0, false, 2)) > 0;
    return createElement(Row, {
        classes: singleton(single$002Dproducts$002Emodule["modal-product-info-row-recap"]),
        children: ofArray([createElement(Col, {
            classes: singleton(single$002Dproducts$002Emodule["modal-product-info-label"]),
            children: singleton((value = toText(interpolate("", [])), createElement("span", {
                children: [value],
            }))),
        }), createElement(Col, {
            classes: empty(),
            children: singleton(createElement(TimeCountdown, {
                seconds: ~(~totalSeconds(durationDeposits)),
            })),
        })]),
    });
}

export function AvailableBalance(availableBalanceInputProps) {
    let copyOfStruct;
    const isProcessing = availableBalanceInputProps.isProcessing;
    const depositToken = availableBalanceInputProps.depositToken;
    const product = availableBalanceInputProps.product;
    const walletCtx = useWallet();
    const connection = useConnection().connection;
    const patternInput = useFeliz_React__React_useState_Static_1505("0");
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(getTokenBalance(connection, walletCtx, patternInput[1], depositToken));
    }, [walletCtx, isProcessing]);
    return productDetailRow(patternInput[0], toText(interpolate("%P()", [(copyOfStruct = depositToken, copyOfStruct.symbol)])));
}

export function UserProvisionalPosition(userProvisionalPositionInputProps) {
    let value_2;
    const depositSignature = userProvisionalPositionInputProps.depositSignature;
    const depositToken = userProvisionalPositionInputProps.depositToken;
    const product = userProvisionalPositionInputProps.product;
    const walletCtx = useWallet();
    const connection = useConnection().connection;
    const environment = Recoil_useRecoilValue_5D3EEEEA(solanaEnvironment);
    const config = Recoil_useRecoilValue_5D3EEEEA(config_2);
    const patternInput = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const setProvisionalPositions = patternInput[1];
    const provisionalPositions = patternInput[0];
    const counter = useReact_useRef_1505(0);
    const interval = useReact_useRef_1505(0);
    const getPositionsCallback = useReact_useCallback_93353E(() => {
        startImmediate(singleton_1.Delay(() => {
            const matchValue = walletCtx.publicKey;
            if (matchValue == null) {
                console.log(some("No wallet"));
                setProvisionalPositions(new Deferred$1(2, void 0));
                return singleton_1.Zero();
            }
            else {
                return singleton_1.Bind(check(environment, connection, matchValue, product.PublicKey), (_arg1) => {
                    setProvisionalPositions(new Deferred$1(2, _arg1));
                    return singleton_1.Zero();
                });
            }
        }));
    }, [provisionalPositions]);
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton_1.Delay(() => {
            toConsole(printf("getProvisionalUserPosition() %A"))(depositSignature);
            const timeout = setTimeout(() => {
                getPositionsCallback();
            }, 800) | 0;
            interval.current = (setInterval(() => {
                if (counter.current < 10) {
                    getPositionsCallback();
                }
                counter.current = ((counter.current + 1) | 0);
            }, 1500) | 0);
            return singleton_1.Combine((counter.current > 10) ? (clearInterval(interval.current), singleton_1.Zero()) : singleton_1.Zero(), singleton_1.Delay(() => {
                clearTimeout(timeout);
                return singleton_1.Zero();
            }));
        }));
    }, [walletCtx.publicKey, config, product, depositSignature]);
    let pattern_matching_result;
    if (provisionalPositions.tag === 0) {
        pattern_matching_result = 0;
    }
    else if (provisionalPositions.tag === 1) {
        pattern_matching_result = 1;
    }
    else if (provisionalPositions.tag === 2) {
        if (provisionalPositions.fields[0] != null) {
            if (equals(provisionalPositions.fields[0], empty())) {
                pattern_matching_result = 2;
            }
            else {
                pattern_matching_result = 3;
            }
        }
        else {
            pattern_matching_result = 3;
        }
    }
    else {
        pattern_matching_result = 3;
    }
    switch (pattern_matching_result) {
        case 0: {
            return null;
        }
        case 1: {
            return null;
        }
        case 2: {
            return null;
        }
        case 3: {
            if (provisionalPositions.tag === 2) {
                if (provisionalPositions.fields[0] == null) {
                    return null;
                }
                else {
                    const provList_3 = provisionalPositions.fields[0];
                    const balance = toNumber(item(0, provList_3).BuyAmount) / Math.pow(10, depositToken.decimals);
                    return createElement("div", {
                        className: join(" ", [single$002Dproducts$002Emodule["provisionalpos-label"]]),
                        children: Interop_reactApi.Children.toArray([createElement("div", {
                            className: join(" ", [utils$002Emodule["margin-xs-right"], utils$002Emodule["boldness-400"]]),
                            children: Interop_reactApi.Children.toArray([(value_2 = toText(interpolate("%P()", [balance])), createElement("span", {
                                children: [value_2],
                            }))]),
                        }), createElement(CurrencyIcon, {
                            depositMint: depositToken,
                        }), createElement("div", {
                            className: join(" ", [single$002Dproducts$002Emodule["deposits-label"], utils$002Emodule["margin-xs-left"]]),
                            children: Interop_reactApi.Children.toArray([createElement("span", {
                                children: ["PENDING"],
                            })]),
                        })]),
                    });
                }
            }
            else if (provisionalPositions.tag === 3) {
                console.log(some(provisionalPositions.fields[0].message));
                return null;
            }
            else {
                throw (new Error("Match failure: Feliz.UseDeferred.Deferred`1"));
            }
        }
    }
}

export function UserDeposits(userDepositsInputProps) {
    let position_2, value_2, provList_4, provList_5, balance_1, value_10;
    const depositSignature = userDepositsInputProps.depositSignature;
    const depositToken = userDepositsInputProps.depositToken;
    const product = userDepositsInputProps.product;
    const walletCtx = useWallet();
    const connection = useConnection().connection;
    const environment = Recoil_useRecoilValue_5D3EEEEA(solanaEnvironment);
    const config = Recoil_useRecoilValue_5D3EEEEA(config_2);
    const patternInput = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const setPositionsOfUser = patternInput[1];
    const positionsOfUser = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const setProvisionalPositions = patternInput_1[1];
    const provisionalPositions = patternInput_1[0];
    const counter = useReact_useRef_1505(0);
    const interval = useReact_useRef_1505(0);
    const getUserPosition = () => singleton_1.Delay(() => {
        const matchValue = [walletCtx.publicKey, config];
        let pattern_matching_result, config_1, publicKey;
        if (matchValue[0] != null) {
            if (matchValue[1] != null) {
                pattern_matching_result = 0;
                config_1 = matchValue[1];
                publicKey = matchValue[0];
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                return singleton_1.Bind(getUserProductPositionPda(publicKey, publicKeyBackEndToSolana(product.PublicKey), publicKey_2(config_1.VaultProgramId)), (_arg1) => singleton_1.Bind(Api.GetUserPosition(environment, new PKey(_arg1[0].toBase58())), (_arg2) => {
                    const position = _arg2;
                    if (position.tag === 1) {
                        setPositionsOfUser(new Deferred$1(2, void 0));
                        return singleton_1.Zero();
                    }
                    else {
                        const position_1 = position.fields[0];
                        setPositionsOfUser(new Deferred$1(2, [position_1, toNumber(position_1.BuyAmount) / Math.pow(10, depositToken.decimals)]));
                        return singleton_1.Zero();
                    }
                }));
            }
            case 1: {
                console.log(some("No wallet or config"));
                setPositionsOfUser(new Deferred$1(2, void 0));
                return singleton_1.Zero();
            }
        }
    });
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(getUserPosition());
    }, [walletCtx.publicKey, config, product]);
    const getUserPositionsCallback = useReact_useCallback_93353E(() => {
        startImmediate(getUserPosition());
    }, [walletCtx.publicKey, config, product]);
    const getProvPositionsCallback = useReact_useCallback_93353E(() => {
        startImmediate(singleton_1.Delay(() => {
            const matchValue_1 = walletCtx.publicKey;
            if (matchValue_1 == null) {
                console.log(some("No wallet"));
                setProvisionalPositions(new Deferred$1(2, void 0));
                return singleton_1.Zero();
            }
            else {
                return singleton_1.Bind(check(environment, connection, matchValue_1, product.PublicKey), (_arg3) => {
                    const provList = _arg3;
                    setProvisionalPositions(new Deferred$1(2, provList));
                    let pattern_matching_result_1;
                    if (provList != null) {
                        if (equals(provList, empty())) {
                            pattern_matching_result_1 = 0;
                        }
                        else {
                            pattern_matching_result_1 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_1 = 1;
                    }
                    switch (pattern_matching_result_1) {
                        case 0: {
                            getUserPositionsCallback();
                            return singleton_1.Zero();
                        }
                        case 1: {
                            return singleton_1.Zero();
                        }
                    }
                });
            }
        }));
    }, [walletCtx.publicKey, config, product, depositSignature]);
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton_1.Delay(() => {
            clearInterval(interval.current);
            counter.current = 0;
            const timeout = setTimeout(() => {
                getProvPositionsCallback();
            }, 200) | 0;
            interval.current = (setInterval(() => {
                getProvPositionsCallback();
                if (counter.current > 15) {
                    clearInterval(interval.current);
                }
                counter.current = ((counter.current + 1) | 0);
            }, 2000) | 0);
            clearTimeout(timeout);
            return singleton_1.Zero();
        }));
    }, [walletCtx.publicKey, config, product, depositSignature]);
    const buyAmount = (positionsOfUser.tag === 2) ? ((positionsOfUser.fields[0] != null) ? (position_2 = positionsOfUser.fields[0][0], toNumber(position_2.BuyAmount) / Math.pow(10, depositToken.decimals)) : 0) : 0;
    return react.createElement(react.Fragment, {}, createElement("div", {
        className: join(" ", [single$002Dproducts$002Emodule["deposits-row"]]),
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: join(" ", [utils$002Emodule["margin-xs-right"], utils$002Emodule["boldness-400"]]),
            children: Interop_reactApi.Children.toArray([(value_2 = toText(interpolate("%P()", [buyAmount])), createElement("span", {
                children: [value_2],
            }))]),
        }), createElement(CurrencyIcon, {
            depositMint: depositToken,
        }), createElement("div", {
            className: join(" ", [single$002Dproducts$002Emodule["deposits-label"], utils$002Emodule["margin-xs-left"]]),
            children: Interop_reactApi.Children.toArray([createElement("span", {
                children: ["CONFIRMED"],
            })]),
        })]),
    }), (provisionalPositions.tag === 0) ? null : ((provisionalPositions.tag === 1) ? null : ((provisionalPositions.tag === 2) ? ((provisionalPositions.fields[0] != null) ? (equals(provisionalPositions.fields[0], empty()) ? (provList_4 = provisionalPositions.fields[0], null) : ((provisionalPositions.tag === 2) ? ((provisionalPositions.fields[0] == null) ? null : (provList_5 = provisionalPositions.fields[0], (balance_1 = (toNumber(item(0, provList_5).BuyAmount) / Math.pow(10, depositToken.decimals)), createElement("div", {
        className: join(" ", [single$002Dproducts$002Emodule["deposits-row"]]),
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: join(" ", [utils$002Emodule["margin-xs-right"], utils$002Emodule["boldness-400"]]),
            children: Interop_reactApi.Children.toArray([(value_10 = toText(interpolate("%P()", [balance_1])), createElement("span", {
                children: [value_10],
            }))]),
        }), createElement(CurrencyIcon, {
            depositMint: depositToken,
        }), createElement("div", {
            className: join(" ", [single$002Dproducts$002Emodule["provisionalpos-label"], utils$002Emodule["margin-xs-left"]]),
            children: Interop_reactApi.Children.toArray([createElement("span", {
                children: ["PENDING"],
            })]),
        })]),
    })))) : ((provisionalPositions.tag === 3) ? (console.log(some(provisionalPositions.fields[0].message)), null) : (() => {
        throw (new Error("Match failure: Feliz.UseDeferred.Deferred`1"));
    })()))) : ((provisionalPositions.tag === 2) ? ((provisionalPositions.fields[0] == null) ? null : (provList_5 = provisionalPositions.fields[0], (balance_1 = (toNumber(item(0, provList_5).BuyAmount) / Math.pow(10, depositToken.decimals)), createElement("div", {
        className: join(" ", [single$002Dproducts$002Emodule["deposits-row"]]),
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: join(" ", [utils$002Emodule["margin-xs-right"], utils$002Emodule["boldness-400"]]),
            children: Interop_reactApi.Children.toArray([(value_10 = toText(interpolate("%P()", [balance_1])), createElement("span", {
                children: [value_10],
            }))]),
        }), createElement(CurrencyIcon, {
            depositMint: depositToken,
        }), createElement("div", {
            className: join(" ", [single$002Dproducts$002Emodule["provisionalpos-label"], utils$002Emodule["margin-xs-left"]]),
            children: Interop_reactApi.Children.toArray([createElement("span", {
                children: ["PENDING"],
            })]),
        })]),
    })))) : ((provisionalPositions.tag === 3) ? (console.log(some(provisionalPositions.fields[0].message)), null) : (() => {
        throw (new Error("Match failure: Feliz.UseDeferred.Deferred`1"));
    })()))) : ((provisionalPositions.tag === 2) ? ((provisionalPositions.fields[0] == null) ? null : (provList_5 = provisionalPositions.fields[0], (balance_1 = (toNumber(item(0, provList_5).BuyAmount) / Math.pow(10, depositToken.decimals)), createElement("div", {
        className: join(" ", [single$002Dproducts$002Emodule["deposits-row"]]),
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: join(" ", [utils$002Emodule["margin-xs-right"], utils$002Emodule["boldness-400"]]),
            children: Interop_reactApi.Children.toArray([(value_10 = toText(interpolate("%P()", [balance_1])), createElement("span", {
                children: [value_10],
            }))]),
        }), createElement(CurrencyIcon, {
            depositMint: depositToken,
        }), createElement("div", {
            className: join(" ", [single$002Dproducts$002Emodule["provisionalpos-label"], utils$002Emodule["margin-xs-left"]]),
            children: Interop_reactApi.Children.toArray([createElement("span", {
                children: ["PENDING"],
            })]),
        })]),
    })))) : ((provisionalPositions.tag === 3) ? (console.log(some(provisionalPositions.fields[0].message)), null) : (() => {
        throw (new Error("Match failure: Feliz.UseDeferred.Deferred`1"));
    })())))));
}

export function infoRow(label, children, classes, textClasses) {
    return createElement("div", {
        className: join(" ", classes),
        children: Interop_reactApi.Children.toArray([createElement("div", {
            children: Interop_reactApi.Children.toArray([label]),
        }), createElement("div", {
            className: join(" ", textClasses),
            children: Interop_reactApi.Children.toArray([children]),
        })]),
    });
}

export function CollapsedPanel(collapsedPanelInputProps) {
    const contents = collapsedPanelInputProps.contents;
    const classes = collapsedPanelInputProps.classes;
    const header = collapsedPanelInputProps.header;
    const patternInput = useFeliz_React__React_useState_Static_1505(true);
    const isCollapsed = patternInput[0];
    const themeConfig = Recoil_useRecoilValue_5D3EEEEA(themeConfig_1);
    return createElement("div", {
        className: join(" ", [single$002Dproducts$002Emodule["modal-card"], single$002Dproducts$002Emodule["modal-card-bkg"], single$002Dproducts$002Emodule["modal-card-margin"], single$002Dproducts$002Emodule["modal-card-padding-s"], single$002Dproducts$002Emodule["collapsible-panel"]]),
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_2(createElement("div", {
            onClick: (_arg1) => {
                patternInput[1](!isCollapsed);
            },
            className: join(" ", [single$002Dproducts$002Emodule["modal-header"]]),
            children: Interop_reactApi.Children.toArray([createElement(Row, {
                classes: singleton(single$002Dproducts$002Emodule["collapsible-panel-header"]),
                children: ofArray([header, createElement("div", {
                    className: join(" ", toList(delay(() => append(singleton_2(single$002Dproducts$002Emodule["collapsible-panel-header-button"]), delay(() => (isCollapsed ? singleton_2("") : singleton_2(single$002Dproducts$002Emodule["collapsible-panel-header-button-open"]))))))),
                    children: "\u003e",
                })]),
            })]),
        })), delay(() => ((!isCollapsed) ? singleton_2(createElement("div", {
            className: join(" ", classes),
            children: Interop_reactApi.Children.toArray(Array.from(contents)),
        })) : empty_1()))))))),
    });
}

export function CollapsedPanelAlwaysOpen(collapsedPanelAlwaysOpenInputProps) {
    const contents = collapsedPanelAlwaysOpenInputProps.contents;
    const classes = collapsedPanelAlwaysOpenInputProps.classes;
    const header = collapsedPanelAlwaysOpenInputProps.header;
    const themeConfig = Recoil_useRecoilValue_5D3EEEEA(themeConfig_1);
    return createElement("div", {
        className: join(" ", [single$002Dproducts$002Emodule["modal-card"], single$002Dproducts$002Emodule["modal-card-bkg"], single$002Dproducts$002Emodule["modal-card-margin"], single$002Dproducts$002Emodule["modal-card-padding-s"], single$002Dproducts$002Emodule["collapsible-panel"]]),
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: join(" ", [single$002Dproducts$002Emodule["modal-header"]]),
            children: Interop_reactApi.Children.toArray([createElement(Row, {
                classes: singleton(single$002Dproducts$002Emodule["collapsible-panel-header"]),
                children: singleton(header),
            })]),
        }), createElement("div", {
            className: join(" ", classes),
            children: Interop_reactApi.Children.toArray(Array.from(contents)),
        })]),
    });
}

export function ContentDetailedView(contentDetailedViewInputProps) {
    const isCollapsedProductRecap = contentDetailedViewInputProps.isCollapsedProductRecap;
    const amount = contentDetailedViewInputProps.amount;
    const setDetailExpanded = contentDetailedViewInputProps.setDetailExpanded;
    const closeModal = contentDetailedViewInputProps.closeModal;
    const selProduct = contentDetailedViewInputProps.selProduct;
    const environment = Recoil_useRecoilValue_5D3EEEEA(solanaEnvironment);
    const setup = Recoil_useRecoilState_Z74A95619(setup_2)[0];
    const setupMode = equals(setup.Step, new SetupStep(2)) ? true : equals(setup.Step, new SetupStep(3));
    const spot_2 = useFeliz_React__React_useDeferred_Static_2344FC52(singleton_1.Delay(() => {
        if (selProduct == null) {
            return singleton_1.Return(fromParts(0, 0, 0, false, 0));
        }
        else {
            const tokens = selProduct[1];
            const product = selProduct[0];
            return singleton_1.Bind(Api.GetSpotLevel(environment, new PKey(publicKeyBackEndToSolana(new PublicKey(0, convertToRealOnes(PublicKey_Value_Z1B21ACD5(product.UnderlyingMint)))).toBase58())), (_arg1_1) => {
                const spot = _arg1_1;
                if (spot.tag === 1) {
                    console.error(some(("Error getting spot for " + toString(product.UnderlyingMint)) + ": "), spot.fields[0]);
                    return singleton_1.Return(fromParts(0, 0, 0, false, 0));
                }
                else {
                    return singleton_1.Return(spot.fields[0]);
                }
            });
        }
    }), [environment]);
    if (selProduct == null) {
        return null;
    }
    else {
        const tokens_1 = selProduct[1];
        const product_1 = selProduct[0];
        return react.createElement(react.Fragment, {}, createElement("button", {
            className: join(" ", ["button-primary", single$002Dproducts$002Emodule["modal-btn-hide"]]),
            onClick: (_arg1_2) => {
                setDetailExpanded(false);
            },
            children: Interop_reactApi.Children.toArray(["\u003c Hide Detailed View"]),
        }), createElement("button", {
            className: join(" ", ["button-primary", single$002Dproducts$002Emodule["modal-btn-close"]]),
            onClick: (_arg2) => {
                closeModal();
            },
            children: Interop_reactApi.Children.toArray(["Close Product View"]),
        }), createElement("div", {
            className: join(" ", [single$002Dproducts$002Emodule["products-detail-modal"]]),
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_2(createElement("div", {
                className: join(" ", [single$002Dproducts$002Emodule["modal-card"], single$002Dproducts$002Emodule["modal-card-bkg"], single$002Dproducts$002Emodule["modal-card-padding-s"]]),
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_2(createElement(ProductDetailHeader, {
                    heading: "Product Details",
                    subheading: "",
                })), delay(() => {
                    switch (spot_2.tag) {
                        case 1: {
                            return singleton_2(createElement(Row, {
                                classes: singleton(utils$002Emodule.center),
                                children: singleton(createElement(ButtonProgressLoader, {
                                    classes: empty(),
                                })),
                            }));
                        }
                        case 3: {
                            return singleton_2(createElement(Row, {
                                classes: singleton(utils$002Emodule.center),
                                children: singleton(createElement(ErrorText, {
                                    text: spot_2.fields[0].message,
                                    classes: empty(),
                                })),
                            }));
                        }
                        case 2: {
                            const durationDeposits = op_Subtraction(product_1.EndDeposits, utcNow());
                            const durationSettlements = op_Subtraction(product_1.StartSettlement, utcNow());
                            const duration = (compare(ticks(durationDeposits), fromInteger(0, false, 2)) > 0) ? durationDeposits : durationSettlements;
                            let amount_1;
                            let matchValue;
                            let outArg = new Decimal(0);
                            matchValue = [tryParse(setupMode ? setup.Amount : amount, new FSharpRef(() => outArg, (v) => {
                                outArg = v;
                            })), outArg];
                            amount_1 = (matchValue[0] ? matchValue[1] : fromParts(0, 0, 0, false, 0));
                            return singleton_2(createElement(SettlementRulesGroup, {
                                product: product_1,
                                underlyingToken: tokens_1.Deposit,
                                spotToken: tokens_1.Spot,
                                spot: spot_2.fields[0],
                                depositAmount: amount_1,
                                duration: duration,
                            }));
                        }
                        default: {
                            return singleton_2(null);
                        }
                    }
                })))))),
            })), delay(() => {
                const performancePanel = singleton(createElement("h2", {
                    children: ["Coming Soon!"],
                }));
                return append(singleton_2(createElement(CollapsedPanel, {
                    header: "Past Performance",
                    classes: ofArray([single$002Dproducts$002Emodule["modal-past-performance"], utils$002Emodule["margin-s-top"]]),
                    contents: performancePanel,
                })), delay(() => {
                    const getKey = (pr) => pr.fields[0];
                    const getText = (pkey) => {
                        const txt = pkey.fields[0];
                        const len = txt.length | 0;
                        if (len > 6) {
                            return toText(interpolate("%P()...%P()", [substring(txt, 0, 3), substring(txt, len - 3, 3)]));
                        }
                        else {
                            return txt;
                        }
                    };
                    const smartContractDetails = ofArray([infoRow("Underlying Public Key", react.createElement(react.Fragment, {}, createElement("a", {
                        href: toText(interpolate("https://explorer.solana.com/address/%P()", [getKey(product_1.UnderlyingMint)])),
                        children: toText(interpolate("%P() \u003e", [getText(product_1.UnderlyingMint)])),
                        target: "_blank",
                    })), ofArray([single$002Dproducts$002Emodule["smart-contract-row"], utils$002Emodule["margin-m-top"]]), empty()), infoRow("Contract Public Key", react.createElement(react.Fragment, {}, createElement("a", {
                        href: toText(interpolate("https://explorer.solana.com/address/%P()", [getKey(product_1.PublicKey)])),
                        children: toText(interpolate("%P() \u003e", [getText(product_1.PublicKey)])),
                        target: "_blank",
                    })), ofArray([single$002Dproducts$002Emodule["smart-contract-row"], utils$002Emodule["margin-s-top"]]), empty())]);
                    return singleton_2(createElement(CollapsedPanel, {
                        header: "Smart Contract Details",
                        classes: singleton(single$002Dproducts$002Emodule["smart-contract-details"]),
                        contents: smartContractDetails,
                    }));
                }));
            })))))),
        }));
    }
}

export function ShowProducts(showProductsInputProps) {
    const hideProductSelection = showProductsInputProps.hideProductSelection;
    const onClose = showProductsInputProps.onClose;
    const isOpen = showProductsInputProps.isOpen;
    const products = showProductsInputProps.products;
    const themeConfig = Recoil_useRecoilValue_5D3EEEEA(themeConfig_1);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505((length(products) === 1) ? item(0, products)[0].PublicKey : (void 0));
    const setSelectedLocalProductPublicKey = patternInput_1[1];
    const selectedLocalProductPublicKey = patternInput_1[0];
    const patternInput_2 = Recoil_useRecoilState_Z74A95619(selectedProductGroup);
    const maturitiesInList = map((tupledArg_2) => tupledArg_2[0], List_groupBy((tupledArg_1) => tupledArg_1[0].Maturity, sortBy((tupledArg) => tupledArg[0].Maturity, products, {
        Compare: (x, y) => compare_1(x, y),
    }), {
        Equals: (x_1, y_1) => equals_1(x_1, y_1),
        GetHashCode: (x_1) => fastStructuralHash(x_1),
    }));
    const patternInput_3 = Recoil_useRecoilState_Z74A95619(setup_2);
    const setup = patternInput_3[0];
    const getProductsForCell = (riskLevel) => mapIndexed((i, m) => {
        const productsInCell = filter((tupledArg_3) => {
            const p_4 = tupledArg_3[0];
            if (equals(riskLevelFromFlexProduct(p_4.RiskLevel), riskLevel) ? equals_1(p_4.Maturity, m) : false) {
                return true;
            }
            else {
                return false;
            }
        }, products);
        if (length(productsInCell) > 0) {
            const productInCellInfo = item(0, productsInCell)[0];
            return createElement(Column, {
                children: singleton(createElement(ProductInfoCell, {
                    publicKey: productInCellInfo.PublicKey,
                    apy: productInCellInfo.EstimatedYieldHigh,
                    selectedProduct: selectedLocalProductPublicKey,
                    onSelected: setSelectedLocalProductPublicKey,
                })),
                classes: singleton(single$002Dproducts$002Emodule["modal-product-content-row"]),
            });
        }
        else {
            return createElement(Column, {
                children: singleton(null),
                classes: empty(),
            });
        }
    }, maturitiesInList);
    useReact_useEffectOnce_3A5B6456(() => {
        if (completedSetup === false) {
            patternInput_3[1](new SetupState(new SetupStep(0), setup.Amount, setup.ConfirmedDeposit, setup.Positions));
        }
    });
    const connection = useConnection().connection;
    const walletCtx = useWallet();
    const patternInput_4 = Recoil_useRecoilState_Z74A95619(setup_2);
    const setup_1 = patternInput_4[0];
    const setupMode = equals(setup_1.Step, new SetupStep(2)) ? true : equals(setup_1.Step, new SetupStep(3));
    const setSetupAmount = (amount) => {
        patternInput_4[1](new SetupState(setup_1.Step, amount, setup_1.ConfirmedDeposit, setup_1.Positions));
    };
    const patternInput_5 = useFeliz_React__React_useState_Static_1505("0");
    const setAmount = patternInput_5[1];
    const amount_1 = patternInput_5[0];
    const patternInput_6 = useFeliz_React__React_useState_Static_1505("0");
    const patternInput_7 = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const patternInput_8 = useFeliz_React__React_useState_Static_1505(false);
    const processing = patternInput_8[0];
    const patternInput_9 = useFeliz_React__React_useState_Static_1505(null);
    const patternInput_10 = useFeliz_React__React_useState_Static_1505(true);
    const setDetailExpanded = patternInput_10[1];
    const detailExpanded = patternInput_10[0];
    const patternInput_11 = useFeliz_React__React_useState_Static_1505(singleton(["", fromInteger(0, false, 2)]));
    const depositedAmount = patternInput_11[0];
    const closeModal = () => {
        patternInput_2[1](new SelectedProductGroup(3));
        setSelectedLocalProductPublicKey(void 0);
        onClose();
    };
    return MuiHelpers_createElement(Modal, [["open", isOpen], ["onClose", (_arg17, v) => {
        closeModal();
    }], ["children", react.createElement(react.Fragment, {}, createElement("div", {
        className: join(" ", [single$002Dproducts$002Emodule.modal, themeConfig]),
        children: Interop_reactApi.Children.toArray([createElement("div", createObj(toList(delay(() => {
            let pubkey_2;
            return append(singleton_2(["className", join(" ", [(selectedLocalProductPublicKey == null) ? "" : (pubkey_2 = selectedLocalProductPublicKey, detailExpanded ? single$002Dproducts$002Emodule["modal-detailed"] : "")])]), delay(() => singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append((!detailExpanded) ? singleton_2(createElement(ModalCloseButton, {
                img: times2,
                classes: singleton(single$002Dproducts$002Emodule["modal-close"]),
                onClick: () => {
                    closeModal();
                },
            })) : empty_1(), delay(() => append(singleton_2(createElement("div", {
                className: join(" ", [single$002Dproducts$002Emodule["products-modal"]]),
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append((!hideProductSelection) ? singleton_2(createElement("div", {
                    className: join(" ", [single$002Dproducts$002Emodule["modal-card"], single$002Dproducts$002Emodule["modal-card-bkg"], single$002Dproducts$002Emodule["modal-apy"], single$002Dproducts$002Emodule["modal-card-padding-m"]]),
                    children: Interop_reactApi.Children.toArray([createElement(ProductDetailHeader, {
                        heading: "Select APY",
                        subheading: "Choose the APY that suits you best based on Risk level and product maturity.",
                    }), createElement("div", {
                        className: join(" ", [single$002Dproducts$002Emodule["modal-product-row-container"], utils$002Emodule["margin-s-top"]]),
                        children: Interop_reactApi.Children.toArray([createElement(TextColumn, {
                            text: "Maturity Level",
                            classes: singleton(single$002Dproducts$002Emodule["modal-product-maturity-header"]),
                        })]),
                    }), createElement("div", {
                        className: join(" ", [single$002Dproducts$002Emodule["modal-product-row-container"], utils$002Emodule["margin-s-top"]]),
                        children: Interop_reactApi.Children.toArray([createElement(TextColumn, {
                            text: "Risk",
                            classes: ofArray([single$002Dproducts$002Emodule["modal-product-row"], single$002Dproducts$002Emodule["modal-product-row-risk-header"]]),
                        }), react.createElement(react.Fragment, {}, ...map((m_1) => createElement(Column, {
                            children: singleton(displayMaturity(m_1, empty(), true)),
                            classes: singleton(single$002Dproducts$002Emodule["modal-product-row"]),
                        }), maturitiesInList))]),
                    }), createElement("div", {
                        className: join(" ", [single$002Dproducts$002Emodule["modal-product-row-container"], utils$002Emodule["margin-m-top"]]),
                        children: Interop_reactApi.Children.toArray([createElement(Column, {
                            children: singleton(displayRiskLevel(new RiskLevel(0), empty())),
                            classes: singleton(single$002Dproducts$002Emodule["modal-product-content-row"]),
                        }), react.createElement(react.Fragment, {}, ...getProductsForCell(new RiskLevel_1(0)))]),
                    }), createElement("div", {
                        className: join(" ", [single$002Dproducts$002Emodule["modal-product-row-container"], utils$002Emodule["margin-s-top"]]),
                        children: Interop_reactApi.Children.toArray([createElement(Column, {
                            children: singleton(displayRiskLevel(new RiskLevel(1), empty())),
                            classes: singleton(single$002Dproducts$002Emodule["modal-product-content-row"]),
                        }), react.createElement(react.Fragment, {}, ...getProductsForCell(new RiskLevel_1(1)))]),
                    }), createElement("div", {
                        className: join(" ", [single$002Dproducts$002Emodule["modal-product-row-container"], utils$002Emodule["margin-s-top"]]),
                        children: Interop_reactApi.Children.toArray([createElement(Column, {
                            children: singleton(displayRiskLevel(new RiskLevel(2), empty())),
                            classes: singleton(single$002Dproducts$002Emodule["modal-product-content-row"]),
                        }), react.createElement(react.Fragment, {}, ...getProductsForCell(new RiskLevel_1(2)))]),
                    })]),
                })) : empty_1(), delay(() => {
                    let depositToken, product_1, amt, remaining, matchValue_2, maxProductAmount, totalDeposited, className, className_1;
                    if (selectedLocalProductPublicKey == null) {
                        return singleton_2(null);
                    }
                    else {
                        const pubkey_1 = selectedLocalProductPublicKey;
                        const product_2 = tryFind((tupledArg_6) => {
                            if (equals(tupledArg_6[0].PublicKey, pubkey_1)) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        }, products);
                        if (product_2 == null) {
                            return singleton_2(null);
                        }
                        else {
                            const tokens = product_2[1];
                            const product_3 = product_2[0];
                            return append(singleton_2(createElement("div", {
                                className: join(" ", [single$002Dproducts$002Emodule["modal-card"], single$002Dproducts$002Emodule["modal-grid"], single$002Dproducts$002Emodule["modal-card-bkg"], single$002Dproducts$002Emodule["modal-card-margin"], single$002Dproducts$002Emodule["modal-card-padding-m"]]),
                                children: Interop_reactApi.Children.toArray([createElement(Column, {
                                    children: toList(delay(() => append(singleton_2(createElement(ConnectWalletMessageSmall, {
                                        connection: connection,
                                        walletCtx: walletCtx,
                                    })), delay(() => {
                                        const matchValue_3 = walletCtx.publicKey;
                                        if (matchValue_3 == null) {
                                            return empty_1();
                                        }
                                        else {
                                            return append((FlexStatus_GetStatus_Z2D3563D0(product_3).tag === 0) ? append(singleton_2(createElement(AmountAndMaxButton2, {
                                                connection: connection,
                                                amount: setupMode ? setup_1.Amount : amount_1,
                                                setAmount: setupMode ? setSetupAmount : setAmount,
                                                setMaxLimit: patternInput_6[1],
                                                product: product_3,
                                                depositToken: tokens.Deposit,
                                                isProcessing: processing,
                                            })), delay(() => singleton_2(createElement(CurrencyInput, {
                                                mint: tokens.Deposit,
                                                value: setupMode ? setup_1.Amount : amount_1,
                                                dispatch: setupMode ? setSetupAmount : setAmount,
                                                error: void 0,
                                            })))) : (void 0, empty_1()), delay(() => append(singleton_2(createElement(DepositButton, {
                                                product: product_3,
                                                amount: convertToDecimal(amount_1),
                                                depositToken: tokens.Deposit,
                                                spotToken: tokens.Spot,
                                                processing: processing,
                                                setProcessing: patternInput_8[1],
                                                maxLimit: patternInput_6[0],
                                                callback: (tupledArg_7) => {
                                                    patternInput_9[1](tupledArg_7[0]);
                                                    patternInput_11[1](append_1(depositedAmount, singleton([product_3.PublicKey.fields[0], tupledArg_7[1]])));
                                                },
                                            })), delay(() => singleton_2(infoRow("Your Deposits", createElement(UserDeposits, {
                                                product: product_3,
                                                depositToken: tokens.Deposit,
                                                depositSignature: patternInput_9[0],
                                            }), ofArray([single$002Dproducts$002Emodule["modal-product-info-row-lg"], utils$002Emodule["margin-s-top"]]), singleton(utils$002Emodule["text-end"])))))));
                                        }
                                    })))),
                                    classes: empty(),
                                }), createElement(Column, {
                                    children: ofArray([infoRow("Available Balance", createElement(AvailableBalance, {
                                        product: product_3,
                                        depositToken: tokens.Deposit,
                                        isProcessing: processing,
                                    }), singleton(single$002Dproducts$002Emodule["modal-product-info-row"]), singleton(utils$002Emodule["text-end"])), infoRow("Selected APY", react.createElement(react.Fragment, {}, productDetailRow(toText(interpolate("%P()", [product_3.EstimatedYieldHigh])), "%")), ofArray([single$002Dproducts$002Emodule["modal-product-info-row"], single$002Dproducts$002Emodule["modal-row-margin"]]), singleton(utils$002Emodule["text-end"])), infoRow("Min. Deposit", react.createElement(react.Fragment, {}, productDetailRow(toText(interpolate("%P()", [size(product_3.MinOrderDeposit, tokens.Deposit.decimals)])), toText(interpolate("%P()", [tokens.Deposit.symbol])))), ofArray([single$002Dproducts$002Emodule["modal-product-info-row"], single$002Dproducts$002Emodule["modal-row-margin"]]), singleton(utils$002Emodule["text-end"])), infoRow("Max. Deposit", react.createElement(react.Fragment, {}, productDetailRow(toText(interpolate("%P()", [size(product_3.MaxOrderDeposit, tokens.Deposit.decimals)])), toText(interpolate("%P()", [tokens.Deposit.symbol])))), ofArray([single$002Dproducts$002Emodule["modal-product-info-row"], single$002Dproducts$002Emodule["modal-row-margin"]]), singleton(utils$002Emodule["text-end"])), (depositToken = tokens.Deposit, (product_1 = product_3, (amt = sumBy((tupledArg_5) => tupledArg_5[1], filter((tupledArg_4) => (tupledArg_4[0] === product_1.PublicKey.fields[0]), depositedAmount), {
                                        GetZero: () => fromInt(0),
                                        Add: (x_2, y_2) => op_Addition(x_2, y_2),
                                    }), (remaining = (matchValue_2 = product_1.MaxProductDeposit, (matchValue_2 == null) ? fromParts(0, 0, 0, false, 1) : (maxProductAmount = matchValue_2, op_Multiply(op_Division(op_Subtraction_1(new Decimal(toNumber(maxProductAmount)), new Decimal(toNumber(op_Addition(product_1.TotalBuyAmount, fromValue(amt, true))))), new Decimal(toNumber(maxProductAmount))), fromParts(1000, 0, 0, false, 1)))), (totalDeposited = round(op_Subtraction_1(fromParts(1000, 0, 0, false, 1), remaining), 2), createElement("div", {
                                        className: join(" ", [single$002Dproducts$002Emodule["modal-product-info-deposited-container"]]),
                                        children: Interop_reactApi.Children.toArray([createElement(TextColumn, {
                                            text: "Total Deposited",
                                            classes: ofArray([utils$002Emodule["boldness-400"], utils$002Emodule["text-xs"], utils$002Emodule["text-light"], single$002Dproducts$002Emodule["modal-product-info-deposited-item1"]]),
                                        }), createElement(TextColumn, {
                                            text: toText(interpolate("%P()%%", [totalDeposited])),
                                            classes: ofArray([utils$002Emodule["boldness-400"], utils$002Emodule["text-xs"], utils$002Emodule["text-dark"], utils$002Emodule["text-end"], single$002Dproducts$002Emodule["modal-product-info-deposited-item2"]]),
                                        }), createElement("div", {
                                            className: join(" ", [utils$002Emodule["margin-xs-top"], single$002Dproducts$002Emodule["modal-product-info-deposited-pbar"]]),
                                            children: Interop_reactApi.Children.toArray([createElement("div", {
                                                children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(LinearProgress, [(className = single$002Dproducts$002Emodule["modal-product-info-deposited-bar-root"], ["classes.root", className]), ["value", ~(~round_1(toNumber_1(totalDeposited)))], ["variant", "determinate"], (className_1 = single$002Dproducts$002Emodule["modal-product-info-deposited-amount"], ["classes.barColorPrimary", className_1])])]),
                                            })]),
                                        }), createElement(TextColumn, {
                                            text: "Max Capacity",
                                            classes: ofArray([utils$002Emodule["boldness-400"], utils$002Emodule["text-xs"], utils$002Emodule["text-light"], single$002Dproducts$002Emodule["modal-product-info-deposited-item1"]]),
                                        }), createElement(Row, {
                                            classes: singleton(single$002Dproducts$002Emodule["modal-product-info-deposited-item2"]),
                                            children: ofArray([createElement(TextColumn, {
                                                text: toText(interpolate("%P() ", [size(product_1.MaxOrderDeposit, depositToken.decimals)])),
                                                classes: ofArray([utils$002Emodule["boldness-400"], utils$002Emodule["text-xs"], utils$002Emodule["text-dark"], utils$002Emodule["text-end"]]),
                                            }), createElement(TextColumn, {
                                                text: toText(interpolate("%P()", [depositToken.symbol])),
                                                classes: ofArray([utils$002Emodule["boldness-400"], utils$002Emodule["text-xs"], utils$002Emodule["text-light"], utils$002Emodule["text-end"], utils$002Emodule["margin-xs-left"]]),
                                            })]),
                                        })]),
                                    }))))))]),
                                    classes: empty(),
                                })]),
                            })), delay(() => {
                                let product, matchValue, arg20;
                                return singleton_2(createElement("div", {
                                    className: join(" ", [single$002Dproducts$002Emodule["modal-card"], single$002Dproducts$002Emodule["modal-card-bkg"], single$002Dproducts$002Emodule["modal-grid"], single$002Dproducts$002Emodule["modal-card-margin"], single$002Dproducts$002Emodule["modal-card-padding-s"]]),
                                    children: Interop_reactApi.Children.toArray([createElement(Column, {
                                        children: ofArray([createElement(ProductDetailHeader, {
                                            heading: "Product recap",
                                            subheading: "",
                                        }), infoRow("Product Pair", react.createElement(react.Fragment, {}, productRecapRow(toText(interpolate("%P()-%P()", [tokens.ProductPairLeft.symbol, tokens.ProductPairRight.symbol])), "", empty())), ofArray([single$002Dproducts$002Emodule["modal-product-recap-row"], single$002Dproducts$002Emodule["modal-row-margin"]]), singleton(utils$002Emodule["text-end"])), infoRow("Product Type", react.createElement(react.Fragment, {}, productRecapRow(toText(interpolate("%P()", [FlexProduct_GetProductNameDescription(tokens.Spot.symbol, product_3)])), "", ofArray([single$002Dproducts$002Emodule["text-purple"], utils$002Emodule["boldness-400"]]))), ofArray([single$002Dproducts$002Emodule["modal-product-recap-row"], single$002Dproducts$002Emodule["modal-row-margin"]]), singleton(utils$002Emodule["text-end"])), infoRow(toText(interpolate("Current %P() Price", [tokens.ProductPairLeft.symbol])), createElement(SpotPrice, product_3), ofArray([single$002Dproducts$002Emodule["modal-product-recap-row"], single$002Dproducts$002Emodule["modal-row-margin"]]), singleton(utils$002Emodule["text-end"])), infoRow(toText(interpolate("Strike Price", [])), react.createElement(react.Fragment, {}, productRecapRow(toText(interpolate("%P()", [(product = product_3, (matchValue = product.Strike, (matchValue.tag === 1) ? toString(product.Strike) : ((matchValue.tag === 2) ? "" : (arg20 = tokens.ProductPairRight.symbol, toText(printf("$ %.2f (%s)"))(matchValue.fields[0])(arg20)))))])), "", empty())), ofArray([single$002Dproducts$002Emodule["modal-product-recap-row"], single$002Dproducts$002Emodule["modal-row-margin"]]), singleton(utils$002Emodule["text-end"])), infoRow(toText(interpolate("Offers Valid For", [])), createElement(TimerCountdown, product_3), ofArray([single$002Dproducts$002Emodule["modal-product-recap-row"], single$002Dproducts$002Emodule["modal-row-margin"]]), singleton(utils$002Emodule["text-end"]))]),
                                        classes: empty(),
                                    }), createElement(Column, {
                                        children: toList(delay(() => ((!detailExpanded) ? singleton_2(createElement("button", {
                                            disabled: false,
                                            className: join(" ", ["button-primary"]),
                                            onClick: (_arg7) => {
                                                setDetailExpanded(!detailExpanded);
                                            },
                                            children: Interop_reactApi.Children.toArray(["Detailed View \u003e"]),
                                        })) : singleton_2(createElement("div", {
                                            className: join(" ", [single$002Dproducts$002Emodule["modal-detailed-view-status"]]),
                                            children: Interop_reactApi.Children.toArray([createElement("div", {
                                                className: join(" ", [single$002Dproducts$002Emodule["modal-detailed-view-status-bkg"], utils$002Emodule["margin-s-bottom"]]),
                                            }), createElement("span", {
                                                children: ["Detailed view is open!"],
                                            })]),
                                        }))))),
                                        classes: ofArray([single$002Dproducts$002Emodule["modal-center"], utils$002Emodule["padding-m"]]),
                                    })]),
                                }));
                            }));
                        }
                    }
                })))))),
            })), delay(() => {
                if (selectedLocalProductPublicKey == null) {
                    return singleton_2(null);
                }
                else {
                    const pubkey_3 = selectedLocalProductPublicKey;
                    const product_4 = tryFind((tupledArg_8) => equals(tupledArg_8[0].PublicKey, pubkey_3), products);
                    return detailExpanded ? singleton_2(createElement(ContentDetailedView, {
                        selProduct: product_4,
                        closeModal: closeModal,
                        setDetailExpanded: setDetailExpanded,
                        amount: amount_1,
                        isCollapsedProductRecap: true,
                    })) : empty_1();
                }
            }))))))))])));
        }))))]),
    }))]]);
}

export function FlexProductGroup(flexProductGroupInputProps) {
    let prs;
    const hideProductSelection = flexProductGroupInputProps.hideProductSelection;
    const onClose = flexProductGroupInputProps.onClose;
    const kind = flexProductGroupInputProps.kind;
    const underlyingMint = flexProductGroupInputProps.underlyingMint;
    const products = flexProductGroupInputProps.products;
    const environment = Recoil_useRecoilValue_5D3EEEEA(solanaEnvironment);
    const tokens = Recoil_useRecoilValue_5D3EEEEA(tokens_2);
    const patternInput = useFeliz_React__React_useState_Static_1505(true);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const productsi = patternInput_1[0];
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton_1.Delay(() => {
            patternInput_1[1](filter((tupledArg) => {
                const p = tupledArg[0];
                if (equals(p.UnderlyingMint, underlyingMint)) {
                    return equals(p.Kind, kind);
                }
                else {
                    return false;
                }
            }, products));
            return singleton_1.Zero();
        }));
    }, [tokens, underlyingMint, environment]);
    return errorBoundary((productsi == null) ? createElement(ButtonProgressLoader, {
        classes: empty(),
    }) : (prs = productsi, createElement(ShowProducts, {
        products: prs,
        isOpen: patternInput[0],
        onClose: () => {
            onClose();
            patternInput[1](false);
        },
        hideProductSelection: hideProductSelection,
    })));
}

