import { createElement } from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.62.0/Interop.fs.js";

export function Card(cardInputProps) {
    const children = cardInputProps.children;
    return createElement("div", {
        className: "font-title\n                gap-4\n                font-bold\n                text-exo-primary\n                text-2xl\n                lg:p-10\n                p-10\n                md:px-2\n                md:py-5\n                flex\n                flex-col\n                items-center\n                justify-center\n                outline\n                outline-exo-primary\n                outline-1\n                rounded-md\n                hover:text-exo-background\n                hover:outline-none\n                hover:hover:exo-background\n                transition-colors",
        children: children,
    });
}

export function OptionCard(optionCardInputProps) {
    const childrenHover = optionCardInputProps.childrenHover;
    const childrenStatic = optionCardInputProps.childrenStatic;
    return createElement("div", {
        className: "text-exo-primary\n                lg:p-10\n                p-5\n                md:px-2\n                md:py-5\n                flex\n                flex-col\n                items-center\n                justify-center\n                outline\n                outline-exo-primary\n                outline-1\n                rounded-md\n                hover:text-exo-background\n                hover:outline-none\n                hover:hover:exo-background\n                transition-colors\n                max-h-28\n                group",
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: "flex flex-col items-center opacity-100 group-hover:opacity-0 group-hover:hidden duration-300",
            children: childrenStatic,
        }), createElement("div", {
            className: "flex flex-col items-center opacity-0 group-hover:opacity-100 hidden group-hover:flex duration-300 text-xs md:text-base",
            children: childrenHover,
        })]),
    });
}

