import { Union, Record } from "../../../app/client/src/.fable/fable-library.3.1.16/Types.js";
import { uint32_type, option_type, class_type, union_type, record_type, bool_type, string_type } from "../../../app/client/src/.fable/fable-library.3.1.16/Reflection.js";
import { nativeOptionGenericBalanceDecoder, FPDECIMAL_ONE, nativeDenomDecoder, OptionGenericBalance$reflection, Denom$reflection } from "./Common.fs.js";
import { InjectiveProductKind, InjectiveProductKind$reflection } from "./InjectiveProduct.fs.js";
import { toInt64, toDecimal, get_Zero } from "../../../app/client/src/.fable/fable-library.3.1.16/BigInt.js";
import { keyValuePairs, fromString, uint32, map, oneOf, bigint, bool, string, object } from "../../../app/client/src/.fable/Thoth.Json.10.1.0/Decode.fs.js";
import { uncurry } from "../../../app/client/src/.fable/fable-library.3.1.16/Util.js";
import { parse, op_Multiply } from "../../../app/client/src/.fable/fable-library.3.1.16/Decimal.js";
import DateOffset from "../../../app/client/src/.fable/fable-library.3.1.16/DateOffset.js";
import { fromInteger, op_Division, toNumber } from "../../../app/client/src/.fable/fable-library.3.1.16/Long.js";
import { toArray, ofArray } from "../../../app/client/src/.fable/fable-library.3.1.16/List.js";
import { map as map_1 } from "../../../app/client/src/.fable/fable-library.3.1.16/Option.js";
import { Result_Map } from "../../../app/client/src/.fable/fable-library.3.1.16/Choice.js";
import { map as map_2 } from "../../../app/client/src/.fable/fable-library.3.1.16/Array.js";

export class AddressPosition extends Record {
    constructor(Address, Validated) {
        super();
        this.Address = Address;
        this.Validated = Validated;
    }
}

export function AddressPosition$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveOption.AddressPosition", [], AddressPosition, () => [["Address", string_type], ["Validated", bool_type]]);
}

export class OptionPositionType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Address", "Token"];
    }
}

export function OptionPositionType$reflection() {
    return union_type("ExoticMarkets.Domain.InjectiveOption.OptionPositionType", [], OptionPositionType, () => [[["Item", AddressPosition$reflection()]], []]);
}

export class Option extends Record {
    constructor(Address, Creator, Base, Quote, BaseOracle, QuoteOracle, OptionKind, Strike, OptionExpiry, PositionType, CollateralAmount, NbOption, SettlementPrice, RemainingCollateral, CurrentCollateralAmount, CollateralCollected, NbOptionRemaining, SettlementPriceThreshold) {
        super();
        this.Address = Address;
        this.Creator = Creator;
        this.Base = Base;
        this.Quote = Quote;
        this.BaseOracle = BaseOracle;
        this.QuoteOracle = QuoteOracle;
        this.OptionKind = OptionKind;
        this.Strike = Strike;
        this.OptionExpiry = OptionExpiry;
        this.PositionType = PositionType;
        this.CollateralAmount = CollateralAmount;
        this.NbOption = NbOption;
        this.SettlementPrice = SettlementPrice;
        this.RemainingCollateral = RemainingCollateral;
        this.CurrentCollateralAmount = CurrentCollateralAmount;
        this.CollateralCollected = CollateralCollected;
        this.NbOptionRemaining = NbOptionRemaining;
        this.SettlementPriceThreshold = SettlementPriceThreshold;
    }
}

export function Option$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveOption.Option", [], Option, () => [["Address", string_type], ["Creator", string_type], ["Base", Denom$reflection()], ["Quote", Denom$reflection()], ["BaseOracle", string_type], ["QuoteOracle", string_type], ["OptionKind", InjectiveProductKind$reflection()], ["Strike", class_type("System.Decimal")], ["OptionExpiry", class_type("System.DateTimeOffset")], ["PositionType", OptionPositionType$reflection()], ["CollateralAmount", OptionGenericBalance$reflection()], ["NbOption", class_type("System.Decimal")], ["SettlementPrice", option_type(class_type("System.Decimal"))], ["RemainingCollateral", class_type("System.Decimal")], ["CurrentCollateralAmount", class_type("System.Decimal")], ["CollateralCollected", bool_type], ["NbOptionRemaining", class_type("System.Decimal")], ["SettlementPriceThreshold", uint32_type]]);
}

export function Option__GetCollateralAmount(this$) {
    const matchValue = this$.CollateralAmount;
    if (matchValue.tag === 0) {
        return matchValue.fields[0].Amount;
    }
    else {
        return get_Zero;
    }
}

export class OptionRaw extends Record {
    constructor(Creator, Base, Quote, BaseOracle, QuoteOracle, OptionKind, Strike, OptionExpiry, PositionType, CollateralAmount, NbOption, SettlementPrice, RemainingCollateral, CurrentCollateralAmount, CollateralCollected, NbOptionRemaining, SettlementPriceThreshold) {
        super();
        this.Creator = Creator;
        this.Base = Base;
        this.Quote = Quote;
        this.BaseOracle = BaseOracle;
        this.QuoteOracle = QuoteOracle;
        this.OptionKind = OptionKind;
        this.Strike = Strike;
        this.OptionExpiry = OptionExpiry;
        this.PositionType = PositionType;
        this.CollateralAmount = CollateralAmount;
        this.NbOption = NbOption;
        this.SettlementPrice = SettlementPrice;
        this.RemainingCollateral = RemainingCollateral;
        this.CurrentCollateralAmount = CurrentCollateralAmount;
        this.CollateralCollected = CollateralCollected;
        this.NbOptionRemaining = NbOptionRemaining;
        this.SettlementPriceThreshold = SettlementPriceThreshold;
    }
}

export function OptionRaw$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveOption.OptionRaw", [], OptionRaw, () => [["Creator", string_type], ["Base", Denom$reflection()], ["Quote", Denom$reflection()], ["BaseOracle", string_type], ["QuoteOracle", string_type], ["OptionKind", InjectiveProductKind$reflection()], ["Strike", class_type("System.Decimal")], ["OptionExpiry", class_type("System.DateTimeOffset")], ["PositionType", OptionPositionType$reflection()], ["CollateralAmount", OptionGenericBalance$reflection()], ["NbOption", class_type("System.Decimal")], ["SettlementPrice", option_type(class_type("System.Decimal"))], ["RemainingCollateral", class_type("System.Decimal")], ["CurrentCollateralAmount", class_type("System.Decimal")], ["CollateralCollected", bool_type], ["NbOptionRemaining", class_type("System.Decimal")], ["SettlementPriceThreshold", uint32_type]]);
}

export function OptionRaw__ToOption_Z721C83C5(this$, address) {
    return new Option(address, this$.Creator, this$.Base, this$.Quote, this$.BaseOracle, this$.QuoteOracle, this$.OptionKind, this$.Strike, this$.OptionExpiry, this$.PositionType, this$.CollateralAmount, this$.NbOption, this$.SettlementPrice, this$.RemainingCollateral, this$.CurrentCollateralAmount, this$.CollateralCollected, this$.NbOptionRemaining, this$.SettlementPriceThreshold);
}

export const optionAddresssPositionDecoder = (path_3) => ((v_2) => object((get$_1) => (new OptionPositionType(0, get$_1.Required.Field("address", (path_2, v_1) => object((get$) => (new AddressPosition(get$.Required.Field("address", (path, value) => string(path, value)), get$.Required.Field("validated", (path_1, value_1) => bool(path_1, value_1)))), path_2, v_1)))), path_3, v_2));

export function optionTokenPositionDecoder(s) {
    if (s === "token") {
        return new OptionPositionType(1);
    }
    else {
        throw (new Error("Unknown position type"));
    }
}

export const injectiveOptionRawDecoder = (path_15) => ((v) => object((get$) => {
    let x;
    return new OptionRaw(get$.Required.Field("creator", (path, value) => string(path, value)), get$.Required.Field("base", uncurry(2, nativeDenomDecoder)), get$.Required.Field("quote", uncurry(2, nativeDenomDecoder)), get$.Required.Field("base_oracle", (path_1, value_1) => string(path_1, value_1)), get$.Required.Field("quote_oracle", (path_2, value_2) => string(path_2, value_2)), (x = get$.Required.Field("option_kind", (path_3, value_3) => string(path_3, value_3)), (x === "call") ? (new InjectiveProductKind(0)) : ((x === "put") ? (new InjectiveProductKind(1)) : (() => {
        throw (new Error("Error in deserializing injective product kind"));
    })())), op_Multiply(parse(get$.Required.Field("strike", (path_4, value_4) => string(path_4, value_4))), toDecimal(FPDECIMAL_ONE)), DateOffset(toNumber(op_Division(toInt64(get$.Required.Field("option_expiry", (path_5, value_5) => bigint(path_5, value_5))), fromInteger(1000000000, false, 2))) * 1000, 0), get$.Required.Field("position_type", (path_8, value_8) => oneOf(ofArray([(path_7) => ((value_7) => map((x_2) => optionTokenPositionDecoder(x_2), (path_6, value_6) => string(path_6, value_6), path_7, value_7)), optionAddresssPositionDecoder]), path_8, value_8)), get$.Required.Field("collateral_amount", uncurry(2, nativeOptionGenericBalanceDecoder)), op_Multiply(parse(get$.Required.Field("nb_option", (path_9, value_9) => string(path_9, value_9))), toDecimal(FPDECIMAL_ONE)), map_1((p) => op_Multiply(parse(p), toDecimal(FPDECIMAL_ONE)), get$.Optional.Field("settlement_price", (path_10, value_10) => string(path_10, value_10))), op_Multiply(parse(get$.Required.Field("remaining_collateral", (path_11, value_11) => string(path_11, value_11))), toDecimal(FPDECIMAL_ONE)), op_Multiply(parse(get$.Required.Field("current_collateral_amount", (path_12, value_12) => string(path_12, value_12))), toDecimal(FPDECIMAL_ONE)), get$.Required.Field("collateral_collected", (path_13, value_13) => bool(path_13, value_13)), op_Multiply(parse(get$.Required.Field("nb_option_remaining", (path_14, value_14) => string(path_14, value_14))), toDecimal(FPDECIMAL_ONE)), get$.Required.Field("settlement_price_threshold_seconds", uncurry(2, uint32)));
}, path_15, v));

export function deserializeOption(address, data) {
    const raw = fromString(uncurry(2, injectiveOptionRawDecoder), data);
    if (raw.tag === 1) {
        throw (new Error("failed in option deserialization"));
    }
    else {
        return OptionRaw__ToOption_Z721C83C5(raw.fields[0], address);
    }
}

export function deserializeOptionArray(jsonString) {
    const res = Result_Map((x) => toArray(x), fromString((path, value) => keyValuePairs(uncurry(2, injectiveOptionRawDecoder), path, value), jsonString));
    if (res.tag === 1) {
        throw (new Error("failed in option array deserialization"));
    }
    else {
        return map_2((tupledArg) => OptionRaw__ToOption_Z721C83C5(tupledArg[1], tupledArg[0]), res.fields[0]);
    }
}

