import { Record, Union } from "../../../app/client/src/.fable/fable-library.3.1.16/Types.js";
import { uint8_type, array_type, uint32_type, record_type, option_type, union_type, string_type, class_type } from "../../../app/client/src/.fable/fable-library.3.1.16/Reflection.js";
import { coinDecoder, FPDECIMAL_ONE, convertToAPRFromRateU64, Coin$reflection } from "./Common.fs.js";
import { op_Multiply, parse, fromParts, op_Addition, op_Division } from "../../../app/client/src/.fable/fable-library.3.1.16/Decimal.js";
import Decimal from "../../../app/client/src/.fable/fable-library.3.1.16/Decimal.js";
import { toInt64, toDecimal, toDouble } from "../../../app/client/src/.fable/fable-library.3.1.16/BigInt.js";
import { Ids$reflection, Id$reflection, InjectiveProduct__GetKind } from "./InjectiveProduct.fs.js";
import { map, defaultArg } from "../../../app/client/src/.fable/fable-library.3.1.16/Option.js";
import { fromString, uint32, array, int16, map as map_1, option as option_2, bigint, oneOf, string, uint64, object } from "../../../app/client/src/.fable/Thoth.Json.10.1.0/Decode.fs.js";
import { uncurry } from "../../../app/client/src/.fable/fable-library.3.1.16/Util.js";
import { ofArray } from "../../../app/client/src/.fable/fable-library.3.1.16/List.js";
import DateOffset from "../../../app/client/src/.fable/fable-library.3.1.16/DateOffset.js";
import { fromInteger, op_Division as op_Division_1, toNumber } from "../../../app/client/src/.fable/fable-library.3.1.16/Long.js";

export class ContractState extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Undeployed", "Deployed"];
    }
}

export function ContractState$reflection() {
    return union_type("ExoticMarkets.Domain.InjectiveHolding.ContractState", [], ContractState, () => [[["Item", class_type("System.UInt64")]], [["Item", string_type]]]);
}

export class HoldingState extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Unchecked", "Validated"];
    }
}

export function HoldingState$reflection() {
    return union_type("ExoticMarkets.Domain.InjectiveHolding.HoldingState", [], HoldingState, () => [[], []]);
}

export class OneBid extends Record {
    constructor(Address, Bid) {
        super();
        this.Address = Address;
        this.Bid = Bid;
    }
}

export function OneBid$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveHolding.OneBid", [], OneBid, () => [["Address", string_type], ["Bid", option_type(class_type("System.Decimal"))]]);
}

export class BidType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Rfq", "One"];
    }
}

export function BidType$reflection() {
    return union_type("ExoticMarkets.Domain.InjectiveHolding.BidType", [], BidType, () => [[["Item", ContractState$reflection()]], [["Item", OneBid$reflection()]]]);
}

export class PositionType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Token", "Address"];
    }
}

export function PositionType$reflection() {
    return union_type("ExoticMarkets.Domain.InjectiveHolding.PositionType", [], PositionType, () => [[], []]);
}

export class PriceVolatility extends Record {
    constructor(Volatility, Price) {
        super();
        this.Volatility = Volatility;
        this.Price = Price;
    }
}

export function PriceVolatility$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveHolding.PriceVolatility", [], PriceVolatility, () => [["Volatility", class_type("System.Decimal")], ["Price", class_type("System.Decimal")]]);
}

export class Price extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["AbsolutePrice", "ImpliedVolatility", "PriceVolatility"];
    }
}

export function Price$reflection() {
    return union_type("ExoticMarkets.Domain.InjectiveHolding.Price", [], Price, () => [[["Item", class_type("System.Decimal")]], [["Item", class_type("System.Decimal")]], [["Item", PriceVolatility$reflection()]]]);
}

export class YieldProductType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Call", "Put", "CallWithStaking"];
    }
}

export function YieldProductType$reflection() {
    return union_type("ExoticMarkets.Domain.InjectiveHolding.YieldProductType", [], YieldProductType, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]]]);
}

export function YieldProductType__GetBaseDenom(this$) {
    switch (this$.tag) {
        case 1: {
            return this$.fields[0];
        }
        case 2: {
            return this$.fields[0];
        }
        default: {
            return this$.fields[0];
        }
    }
}

export class InjectiveHolding extends Record {
    constructor(Id, Creator, ProductId, OptionContract, StakingContract, Balance, TotalDepositedAmount, StartCollection, EndCollection, StartOption, MaxTotalDeposit, MinDepositPerUser, State, BidType, PositionType, LimitPrice, FairPrice, Fees, RiskLevel) {
        super();
        this.Id = Id;
        this.Creator = Creator;
        this.ProductId = ProductId;
        this.OptionContract = OptionContract;
        this.StakingContract = StakingContract;
        this.Balance = Balance;
        this.TotalDepositedAmount = TotalDepositedAmount;
        this.StartCollection = StartCollection;
        this.EndCollection = EndCollection;
        this.StartOption = StartOption;
        this.MaxTotalDeposit = MaxTotalDeposit;
        this.MinDepositPerUser = MinDepositPerUser;
        this.State = State;
        this.BidType = BidType;
        this.PositionType = PositionType;
        this.LimitPrice = LimitPrice;
        this.FairPrice = FairPrice;
        this.Fees = Fees;
        this.RiskLevel = RiskLevel;
    }
}

export function InjectiveHolding$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveHolding.InjectiveHolding", [], InjectiveHolding, () => [["Id", string_type], ["Creator", string_type], ["ProductId", string_type], ["OptionContract", ContractState$reflection()], ["StakingContract", option_type(string_type)], ["Balance", Coin$reflection()], ["TotalDepositedAmount", class_type("System.Numerics.BigInteger")], ["StartCollection", class_type("System.DateTimeOffset")], ["EndCollection", class_type("System.DateTimeOffset")], ["StartOption", class_type("System.DateTimeOffset")], ["MaxTotalDeposit", option_type(class_type("System.Numerics.BigInteger"))], ["MinDepositPerUser", class_type("System.Numerics.BigInteger")], ["State", HoldingState$reflection()], ["BidType", BidType$reflection()], ["PositionType", PositionType$reflection()], ["LimitPrice", Price$reflection()], ["FairPrice", Price$reflection()], ["Fees", array_type(uint32_type)], ["RiskLevel", uint8_type]]);
}

export function InjectiveHolding__Remaining(this$) {
    let matchValue, maxProductAmount;
    return new Decimal((matchValue = this$.MaxTotalDeposit, (matchValue == null) ? 0 : (maxProductAmount = matchValue, (toDouble(maxProductAmount) - (toDouble(this$.TotalDepositedAmount) / toDouble(maxProductAmount))) * 100)));
}

export function InjectiveHolding__GetYieldProductType_Z4C3DC210(this$, product) {
    const matchValue = InjectiveProduct__GetKind(product);
    if (matchValue.tag === 1) {
        return new YieldProductType(1, product.BaseDenom);
    }
    else if (this$.StakingContract == null) {
        return new YieldProductType(0, product.BaseDenom);
    }
    else {
        return new YieldProductType(2, product.BaseDenom);
    }
}

export function estimatedAverageYield(maturity, lowYield, highYield) {
    return op_Division(op_Addition(convertToAPRFromRateU64(maturity, lowYield), convertToAPRFromRateU64(maturity, highYield)), fromParts(2, 0, 0, false, 0));
}

export function size(deposit, decimals) {
    return defaultArg(map((x) => op_Division(toDecimal(x), new Decimal(Math.pow(10, decimals))), deposit), fromParts(0, 0, 0, false, 1));
}

export function positionSize(deposit, decimals) {
    try {
        return op_Division(parse(deposit), new Decimal(Math.pow(10, decimals)));
    }
    catch (matchValue) {
        return fromParts(0, 0, 0, false, 0);
    }
}

export class QueryHolding extends Record {
    constructor(GetHolding) {
        super();
        this.GetHolding = GetHolding;
    }
}

export function QueryHolding$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveHolding.QueryHolding", [], QueryHolding, () => [["GetHolding", Id$reflection()]]);
}

export class QueryHoldings extends Record {
    constructor(GetHoldings) {
        super();
        this.GetHoldings = GetHoldings;
    }
}

export function QueryHoldings$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveHolding.QueryHoldings", [], QueryHoldings, () => [["GetHoldings", Ids$reflection()]]);
}

export class DepositMsg extends Record {
    constructor(Deposit) {
        super();
        this.Deposit = Deposit;
    }
}

export function DepositMsg$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveHolding.DepositMsg", [], DepositMsg, () => [["Deposit", string_type]]);
}

export class WithdrawMsg extends Record {
    constructor(Withdraw) {
        super();
        this.Withdraw = Withdraw;
    }
}

export function WithdrawMsg$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveHolding.WithdrawMsg", [], WithdrawMsg, () => [["Withdraw", string_type]]);
}

export class ByBatchMsg extends Record {
    constructor(BatchNumber) {
        super();
        this.BatchNumber = BatchNumber;
    }
}

export function ByBatchMsg$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveHolding.ByBatchMsg", [], ByBatchMsg, () => [["BatchNumber", uint32_type]]);
}

export class QueryOptionsByIds extends Record {
    constructor(GetOptionsByIds) {
        super();
        this.GetOptionsByIds = GetOptionsByIds;
    }
}

export function QueryOptionsByIds$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveHolding.QueryOptionsByIds", [], QueryOptionsByIds, () => [["GetOptionsByIds", Ids$reflection()]]);
}

export class QueryRfqsByIds extends Record {
    constructor(GetRfqsByIds) {
        super();
        this.GetRfqsByIds = GetRfqsByIds;
    }
}

export function QueryRfqsByIds$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveHolding.QueryRfqsByIds", [], QueryRfqsByIds, () => [["GetRfqsByIds", Ids$reflection()]]);
}

export class DepositTx extends Record {
    constructor(Time, Address, HoldingId, Amount, Currency, TxHash) {
        super();
        this.Time = Time;
        this.Address = Address;
        this.HoldingId = HoldingId;
        this.Amount = Amount;
        this.Currency = Currency;
        this.TxHash = TxHash;
    }
}

export function DepositTx$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveHolding.DepositTx", [], DepositTx, () => [["Time", string_type], ["Address", string_type], ["HoldingId", string_type], ["Amount", class_type("System.Decimal")], ["Currency", string_type], ["TxHash", string_type]]);
}

export class HoldingPnl extends Record {
    constructor(Id, Pnl, StartCollection) {
        super();
        this.Id = Id;
        this.Pnl = Pnl;
        this.StartCollection = StartCollection;
    }
}

export function HoldingPnl$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveHolding.HoldingPnl", [], HoldingPnl, () => [["Id", string_type], ["Pnl", class_type("System.Decimal")], ["StartCollection", class_type("System.UInt64")]]);
}

export class HoldingDepositData extends Record {
    constructor(HoldingId, Address, Amount, Currency, Pnl, StartCollection) {
        super();
        this.HoldingId = HoldingId;
        this.Address = Address;
        this.Amount = Amount;
        this.Currency = Currency;
        this.Pnl = Pnl;
        this.StartCollection = StartCollection;
    }
}

export function HoldingDepositData$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveHolding.HoldingDepositData", [], HoldingDepositData, () => [["HoldingId", string_type], ["Address", string_type], ["Amount", class_type("System.Decimal")], ["Currency", string_type], ["Pnl", class_type("System.Decimal")], ["StartCollection", class_type("System.UInt64")]]);
}

export class LeaderBoardRow extends Record {
    constructor(User, TotalDeposited, Pnl, Apy) {
        super();
        this.User = User;
        this.TotalDeposited = TotalDeposited;
        this.Pnl = Pnl;
        this.Apy = Apy;
    }
}

export function LeaderBoardRow$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveHolding.LeaderBoardRow", [], LeaderBoardRow, () => [["User", string_type], ["TotalDeposited", class_type("System.Decimal")], ["Pnl", class_type("System.Decimal")], ["Apy", class_type("System.Decimal")]]);
}

export class InjectiveHoldingRaw extends Record {
    constructor(Creator, ProductId, OptionContract, StakingContract, Balance, TotalDepositedAmount, StartCollection, EndCollection, StartOption, MaxTotalDeposit, MinDepositPerUser, State, BidType, PositionType, LimitPrice, FairPrice, Fees, RiskLevel) {
        super();
        this.Creator = Creator;
        this.ProductId = ProductId;
        this.OptionContract = OptionContract;
        this.StakingContract = StakingContract;
        this.Balance = Balance;
        this.TotalDepositedAmount = TotalDepositedAmount;
        this.StartCollection = StartCollection;
        this.EndCollection = EndCollection;
        this.StartOption = StartOption;
        this.MaxTotalDeposit = MaxTotalDeposit;
        this.MinDepositPerUser = MinDepositPerUser;
        this.State = State;
        this.BidType = BidType;
        this.PositionType = PositionType;
        this.LimitPrice = LimitPrice;
        this.FairPrice = FairPrice;
        this.Fees = Fees;
        this.RiskLevel = RiskLevel;
    }
}

export function InjectiveHoldingRaw$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveHolding.InjectiveHoldingRaw", [], InjectiveHoldingRaw, () => [["Creator", string_type], ["ProductId", string_type], ["OptionContract", ContractState$reflection()], ["StakingContract", option_type(string_type)], ["Balance", Coin$reflection()], ["TotalDepositedAmount", class_type("System.Numerics.BigInteger")], ["StartCollection", class_type("System.DateTimeOffset")], ["EndCollection", class_type("System.DateTimeOffset")], ["StartOption", class_type("System.DateTimeOffset")], ["MaxTotalDeposit", option_type(class_type("System.Numerics.BigInteger"))], ["MinDepositPerUser", class_type("System.Numerics.BigInteger")], ["State", HoldingState$reflection()], ["BidType", BidType$reflection()], ["PositionType", PositionType$reflection()], ["LimitPrice", Price$reflection()], ["FairPrice", Price$reflection()], ["Fees", array_type(uint32_type)], ["RiskLevel", uint8_type]]);
}

export function InjectiveHoldingRaw__ToHolding_Z721C83C5(this$, id) {
    return new InjectiveHolding(id, this$.Creator, this$.ProductId, this$.OptionContract, this$.StakingContract, this$.Balance, this$.TotalDepositedAmount, this$.StartCollection, this$.EndCollection, this$.StartOption, this$.MaxTotalDeposit, this$.MinDepositPerUser, this$.State, this$.BidType, this$.PositionType, this$.LimitPrice, this$.FairPrice, this$.Fees, this$.RiskLevel);
}

export const contractStateUndeployedDecoder = (path) => ((v) => object((get$) => (new ContractState(0, get$.Required.Field("undeployed", uncurry(2, uint64)))), path, v));

export const contractStateDeployedDecoder = (path_1) => ((v) => object((get$) => (new ContractState(1, get$.Required.Field("deployed", (path, value) => string(path, value)))), path_1, v));

export function holdingStateDecoder(s) {
    if (s === "unchecked") {
        return new HoldingState(0);
    }
    else if (s === "validated") {
        return new HoldingState(1);
    }
    else {
        throw (new Error("Unknown holding state"));
    }
}

export const bidTypeRfqDecoder = (path_1) => ((v) => object((get$) => (new BidType(0, get$.Required.Field("r_f_q", (path, value) => oneOf(ofArray([(arg00) => {
    const clo1 = contractStateUndeployedDecoder(arg00);
    return (arg10) => clo1(arg10);
}, (arg00_1) => {
    const clo1_1 = contractStateDeployedDecoder(arg00_1);
    return (arg10_1) => clo1_1(arg10_1);
}]), path, value)))), path_1, v));

export const bidTypeAddressDecoder = (path_2) => ((v) => object((get$) => (new BidType(1, new OneBid(get$.Required.Field("address", (path, value) => string(path, value)), map((b) => parse(b), get$.Optional.Field("bid", (path_1, value_1) => string(path_1, value_1)))))), path_2, v));

export function positionTypeDecoder(s) {
    if (s === "token") {
        return new PositionType(0);
    }
    else if (s === "address") {
        return new PositionType(1);
    }
    else {
        throw (new Error("Unknown position type"));
    }
}

export const absolutePriceDecoder = (path_2) => ((v_1) => object((get$) => {
    try {
        return new Price(0, op_Multiply(parse(get$.Required.Field("absolute_price", (path, value) => string(path, value))), toDecimal(FPDECIMAL_ONE)));
    }
    catch (matchValue) {
        return new Price(1, op_Multiply(parse(get$.Required.Field("implied_volatility", (path_1, value_1) => string(path_1, value_1))), toDecimal(FPDECIMAL_ONE)));
    }
}, path_2, v_1));

export const impliedVolatilityDecoder = (path_1) => ((v_1) => object((get$) => {
    try {
        return new Price(1, op_Multiply(parse(get$.Required.Field("implied_volatility", (path, value) => string(path, value))), toDecimal(FPDECIMAL_ONE)));
    }
    catch (matchValue) {
        throw (new Error("failed in impliedVolatilityDecoder"));
    }
}, path_1, v_1));

export const priceVolatilityDecoder = (path_3) => ((v_2) => object((get$_1) => (new Price(2, get$_1.Required.Field("price_volatility", (path_2, v_1) => object((get$) => (new PriceVolatility(op_Multiply(parse(get$.Required.Field("volatility", (path, value) => string(path, value))), toDecimal(FPDECIMAL_ONE)), op_Multiply(parse(get$.Required.Field("price", (path_1, value_1) => string(path_1, value_1))), toDecimal(FPDECIMAL_ONE)))), path_2, v_1)))), path_3, v_2));

export const injectiveHoldingRawDecoder = (path_19) => ((v) => object((get$) => {
    const Creator = get$.Required.Field("creator", (path, value) => string(path, value));
    const ProductId = get$.Required.Field("product_id", (path_1, value_1) => string(path_1, value_1));
    const OptionContract = get$.Required.Field("option_contract", (path_2, value_2) => oneOf(ofArray([(arg00_2) => {
        const clo1 = contractStateUndeployedDecoder(arg00_2);
        return (arg10_2) => clo1(arg10_2);
    }, (arg00_3) => {
        const clo1_1 = contractStateDeployedDecoder(arg00_3);
        return (arg10_3) => clo1_1(arg10_3);
    }]), path_2, value_2));
    const Balance = get$.Required.Field("balance", uncurry(2, coinDecoder));
    const TotalDepositedAmount = get$.Required.Field("total_deposited_amount", (path_3, value_3) => bigint(path_3, value_3));
    const StartCollection = DateOffset(toNumber(op_Division_1(toInt64(get$.Required.Field("start_collection", (path_4, value_4) => bigint(path_4, value_4))), fromInteger(1000000000, false, 2))) * 1000, 0);
    const EndCollection = DateOffset(toNumber(op_Division_1(toInt64(get$.Required.Field("end_collection", (path_5, value_5) => bigint(path_5, value_5))), fromInteger(1000000000, false, 2))) * 1000, 0);
    const StartOption = DateOffset(toNumber(op_Division_1(toInt64(get$.Required.Field("start_option", (path_6, value_6) => bigint(path_6, value_6))), fromInteger(1000000000, false, 2))) * 1000, 0);
    const MaxTotalDeposit = get$.Required.Field("max_total_deposit", (path_8, value_8) => option_2((path_7, value_7) => bigint(path_7, value_7), path_8, value_8));
    const MinDepositPerUser = get$.Required.Field("min_deposit_per_user", (path_9, value_9) => bigint(path_9, value_9));
    const State = get$.Required.Field("state", (path_11, value_11) => map_1((x_3) => holdingStateDecoder(x_3), (path_10, value_10) => string(path_10, value_10), path_11, value_11));
    const BidType_1 = get$.Required.Field("bid_type", (path_12, value_12) => oneOf(ofArray([(arg00_13) => {
        const clo1_2 = bidTypeRfqDecoder(arg00_13);
        return (arg10_13) => clo1_2(arg10_13);
    }, (arg00_14) => {
        const clo1_3 = bidTypeAddressDecoder(arg00_14);
        return (arg10_14) => clo1_3(arg10_14);
    }]), path_12, value_12));
    const PositionType_1 = get$.Required.Field("position_type", (path_14, value_14) => map_1((x_4) => positionTypeDecoder(x_4), (path_13, value_13) => string(path_13, value_13), path_14, value_14));
    const LimitPrice = get$.Required.Field("limit_price", (path_15, value_15) => oneOf(ofArray([(arg00_17) => {
        const clo1_4 = absolutePriceDecoder(arg00_17);
        return (arg10_17) => clo1_4(arg10_17);
    }, (arg00_18) => {
        const clo1_5 = impliedVolatilityDecoder(arg00_18);
        return (arg10_18) => clo1_5(arg10_18);
    }, (arg00_19) => {
        const clo1_6 = priceVolatilityDecoder(arg00_19);
        return (arg10_19) => clo1_6(arg10_19);
    }]), path_15, value_15));
    const FairPrice = get$.Required.Field("fair_price", (path_16, value_16) => oneOf(ofArray([(arg00_21) => {
        const clo1_7 = absolutePriceDecoder(arg00_21);
        return (arg10_21) => clo1_7(arg10_21);
    }, (arg00_22) => {
        const clo1_8 = impliedVolatilityDecoder(arg00_22);
        return (arg10_22) => clo1_8(arg10_22);
    }, (arg00_23) => {
        const clo1_9 = priceVolatilityDecoder(arg00_23);
        return (arg10_23) => clo1_9(arg10_23);
    }]), path_16, value_16));
    let RiskLevel;
    const value_17 = get$.Required.Field("risk_level", uncurry(2, int16)) | 0;
    RiskLevel = (value_17 & 0xFF);
    return new InjectiveHoldingRaw(Creator, ProductId, OptionContract, (() => {
        try {
            return get$.Optional.Field("staking_contract", (path_17, value_18) => string(path_17, value_18));
        }
        catch (matchValue) {
            return void 0;
        }
    })(), Balance, TotalDepositedAmount, StartCollection, EndCollection, StartOption, MaxTotalDeposit, MinDepositPerUser, State, BidType_1, PositionType_1, LimitPrice, FairPrice, get$.Required.Field("fees", (path_18, value_19) => array(uncurry(2, uint32), path_18, value_19)), RiskLevel);
}, path_19, v));

export function deserializeHolding(id, data) {
    const raw = fromString(uncurry(2, injectiveHoldingRawDecoder), data);
    if (raw.tag === 1) {
        throw (new Error("failed in holding deserialization"));
    }
    else {
        return InjectiveHoldingRaw__ToHolding_Z721C83C5(raw.fields[0], id);
    }
}

