import { Record } from "../client/src/.fable/fable-library.3.1.16/Types.js";
import { enum_type, int32_type, record_type, uint32_type, class_type, string_type } from "../client/src/.fable/fable-library.3.1.16/Reflection.js";

export function EnvironmentModule_fromDomain(environment) {
    if (environment.tag === 1) {
        return 2;
    }
    else {
        return 0;
    }
}

export function EnvironmentModule_fromInjective(environment) {
    if (environment.tag === 0) {
        return 0;
    }
    else {
        return 2;
    }
}

export class NetworkSettings extends Record {
    constructor(Currencies, VaultProgramId, HoldingProgramId, ExoticVaultProgramId, ExoticAdminProgramId, ExoticStakeProgramId, InjectiveHoldingContract, InjectiveEscrowCodeId, SolanaUrl, RpcClientPythUrl) {
        super();
        this.Currencies = Currencies;
        this.VaultProgramId = VaultProgramId;
        this.HoldingProgramId = HoldingProgramId;
        this.ExoticVaultProgramId = ExoticVaultProgramId;
        this.ExoticAdminProgramId = ExoticAdminProgramId;
        this.ExoticStakeProgramId = ExoticStakeProgramId;
        this.InjectiveHoldingContract = InjectiveHoldingContract;
        this.InjectiveEscrowCodeId = InjectiveEscrowCodeId;
        this.SolanaUrl = SolanaUrl;
        this.RpcClientPythUrl = RpcClientPythUrl;
    }
}

export function NetworkSettings$reflection() {
    return record_type("ExoticMarkets.Shared.NetworkSettings", [], NetworkSettings, () => [["Currencies", class_type("System.Collections.Generic.Dictionary`2", [string_type, string_type])], ["VaultProgramId", string_type], ["HoldingProgramId", string_type], ["ExoticVaultProgramId", string_type], ["ExoticAdminProgramId", string_type], ["ExoticStakeProgramId", string_type], ["InjectiveHoldingContract", string_type], ["InjectiveEscrowCodeId", uint32_type], ["SolanaUrl", string_type], ["RpcClientPythUrl", string_type]]);
}

export class SharedConfig extends Record {
    constructor(NetworkSettings) {
        super();
        this.NetworkSettings = NetworkSettings;
    }
}

export function SharedConfig$reflection() {
    return record_type("ExoticMarkets.Shared.SharedConfig", [], SharedConfig, () => [["NetworkSettings", class_type("System.Collections.Generic.Dictionary`2", [enum_type("ExoticMarkets.Shared.Environment", int32_type, [["Devnet", 0], ["Mainnet", 2]]), NetworkSettings$reflection()])]]);
}

