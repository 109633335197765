import { FSharpRef, Union, Record } from "./.fable/fable-library.3.1.16/Types.js";
import { union_type, record_type, float64_type, string_type } from "./.fable/fable-library.3.1.16/Reflection.js";
import { createElement } from "react";
import { printf, toText, join } from "./.fable/fable-library.3.1.16/String.js";
import utils$002Emodule from "./styles/utils.module.scss";
import { toNumber as toNumber_1, op_Subtraction, op_Addition, compare, op_Division, op_Multiply, fromParts, tryParse } from "./.fable/fable-library.3.1.16/Decimal.js";
import Decimal from "./.fable/fable-library.3.1.16/Decimal.js";
import { head, map } from "./.fable/fable-library.3.1.16/Array.js";
import { useReact_useMemo_CF4EA67, useFeliz_React__React_useState_Static_1505 } from "./.fable/Feliz.1.62.0/React.fs.js";
import { toNumber } from "./.fable/fable-library.3.1.16/Long.js";
import { SpecificProduct__GetStrikes } from "../../../lib/Domain/Types/Product.fs.js";
import { Point as Point_1 } from "./FixedStrikedChart.fs.js";
import { empty, singleton, toArray, ofArray } from "./.fable/fable-library.3.1.16/List.js";
import { equals, createObj, int32ToString, max } from "./.fable/fable-library.3.1.16/Util.js";
import { Interop_reactApi } from "./.fable/Feliz.1.62.0/Interop.fs.js";
import { ResponsiveContainer, Area, CartesianGrid, Tooltip, YAxis, XAxis, AreaChart } from "recharts";
import { defaultArg } from "./.fable/fable-library.3.1.16/Option.js";
import single$002Dproducts$002Emodule from "./styles/single-products.module.scss";
import { Col, Row } from "./UtilComponents.fs.js";
import { SimulateSpotLevel } from "./SpotLevel.fs.js";
import { CurrencyInput } from "./Currency.fs.js";
import { singleton as singleton_1, append, delay, toList } from "./.fable/fable-library.3.1.16/Seq.js";
import * as accounting from "accounting";
import { ProductStart, ProductLifeCycle } from "./ProductLifecyle.fs.js";
import { subtract } from "./.fable/fable-library.3.1.16/DateOffset.js";
import { fromDays } from "./.fable/fable-library.3.1.16/TimeSpan.js";

export class Point extends Record {
    constructor(name, uv) {
        super();
        this.name = name;
        this.uv = uv;
    }
}

export function Point$reflection() {
    return record_type("ExoticProductSettlementRules.Point", [], Point, () => [["name", string_type], ["uv", float64_type]]);
}

export class Scenario extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["AboveSpot", "BelowSpot"];
    }
}

export function Scenario$reflection() {
    return union_type("ExoticProductSettlementRules.Scenario", [], Scenario, () => [[], []]);
}

export function text(t) {
    return createElement("span", {
        className: join(" ", [utils$002Emodule["text-dark"]]),
        children: t,
    });
}

export function textBold(t) {
    return createElement("span", {
        className: join(" ", [utils$002Emodule["text-dark"], utils$002Emodule["boldness-500"]]),
        children: t,
    });
}

export function parseDecimal(str) {
    let matchValue;
    let outArg = new Decimal(0);
    matchValue = [tryParse(str, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (matchValue[0]) {
        return matchValue[1];
    }
    else {
        return fromParts(100, 0, 0, false, 0);
    }
}

export function SettlementRulesGroup(settlementRulesGroupInputProps) {
    let x_2, deposit, children, color, value_55, arg10, arg10_1;
    const duration = settlementRulesGroupInputProps.duration;
    const depositAmount = settlementRulesGroupInputProps.depositAmount;
    const spot = settlementRulesGroupInputProps.spot;
    const underlyings = settlementRulesGroupInputProps.underlyings;
    const underlyingToken = settlementRulesGroupInputProps.underlyingToken;
    const product = settlementRulesGroupInputProps.product;
    const spotToken = underlyings[0];
    const getTokenString = (undelyings) => {
        if (underlyings.length === 1) {
            return underlyings[0].symbol;
        }
        else {
            return join("/ ", map((x) => x.symbol, underlyings));
        }
    };
    const patternInput = useFeliz_React__React_useState_Static_1505(spot);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("100");
    const amount = patternInput_1[0];
    const amountSimulation = useReact_useMemo_CF4EA67(() => parseDecimal(amount), [amount]);
    const currentPrice = spot;
    const fixedStrike = op_Multiply(op_Division(new Decimal(toNumber(head(SpecificProduct__GetStrikes(product.Product.SpecificProduct)))), fromParts(100000000, 0, 0, false, 0)), currentPrice);
    const initialStrikeSlider = fromParts(100, 0, 0, false, 0);
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(initialStrikeSlider);
    const currentStrikeSlider = patternInput_2[0];
    const settlementPrice = useReact_useMemo_CF4EA67(() => op_Multiply(currentPrice, op_Division(currentStrikeSlider, fromParts(1000, 0, 0, false, 1))), [currentStrikeSlider, product]);
    const amountSimulationChecked = useReact_useMemo_CF4EA67(() => ((compare(amountSimulation, fromParts(0, 0, 0, false, 1)) <= 0) ? fromParts(100, 0, 0, false, 0) : amountSimulation), [amountSimulation, product]);
    const chooseScenario = useReact_useMemo_CF4EA67(() => ((compare(settlementPrice, fixedStrike) >= 0) ? (new Scenario(0)) : (new Scenario(1))), [currentStrikeSlider]);
    let data;
    const spotLevel_2 = patternInput[0];
    data = ofArray([new Point_1("Start Product", spotLevel_2, spotLevel_2), new Point_1("", op_Multiply(spotLevel_2, fromParts(111, 0, 0, false, 2)), op_Multiply(spotLevel_2, fromParts(115, 0, 0, false, 2))), new Point_1("", op_Multiply(fixedStrike, fromParts(97, 0, 0, false, 2)), op_Multiply(spotLevel_2, fromParts(93, 0, 0, false, 2))), new Point_1("Product End", op_Multiply(fixedStrike, fromParts(107, 0, 0, false, 2)), op_Multiply(fixedStrike, fromParts(96, 0, 0, false, 2)))]);
    const amountToBeReceived = () => {
        const spot_1 = fromParts(100, 0, 0, false, 0);
        const premium = op_Division(op_Multiply(amountSimulationChecked, new Decimal(toNumber(product.Holding.MaxYield))), fromParts(1000000, 0, 0, false, 0));
        const strike = new Decimal(toNumber(head(SpecificProduct__GetStrikes(product.Product.SpecificProduct))) / 100000000);
        const fakeAbsStrike = op_Multiply(spot_1, strike);
        const currentExpirySlider = op_Division(currentStrikeSlider, fromParts(100, 0, 0, false, 0));
        const fakeAbsSpot = op_Multiply(spot_1, currentExpirySlider);
        if (product.Product.SpecificProduct.tag === 0) {
            return [premium, op_Addition(premium, op_Multiply(amountSimulationChecked, op_Subtraction(fromParts(10, 0, 0, false, 1), max((x_4, y) => compare(x_4, y), fromParts(0, 0, 0, false, 1), op_Subtraction(fromParts(1, 0, 0, false, 0), op_Division(currentExpirySlider, strike))))))];
        }
        else {
            return [fromParts(0, 0, 0, false, 0), fromParts(0, 0, 0, false, 0)];
        }
    };
    const amountRetrieved = useReact_useMemo_CF4EA67(() => amountToBeReceived()[1], [currentStrikeSlider, amount, product]);
    const premiumRetrieved = useReact_useMemo_CF4EA67(() => amountToBeReceived()[0], [currentStrikeSlider, amount, product]);
    const chart = Interop_reactApi.createElement(AreaChart, {
        width: 512,
        height: 120,
        data: toArray((x_2 = premiumRetrieved, (deposit = amountSimulationChecked, (product.Product.SpecificProduct.tag === 0) ? ofArray([new Point("Below Strike", (toNumber_1(deposit) * 0.4) + toNumber_1(x_2)), new Point("Below Strike", (toNumber_1(deposit) * 0.6) + toNumber_1(x_2)), new Point("Below Strike", (toNumber_1(deposit) * 0.8) + toNumber_1(x_2)), new Point("At Strike", toNumber_1(deposit) + toNumber_1(x_2)), new Point("Above Strike", toNumber_1(deposit) + toNumber_1(x_2)), new Point("Above Strike", toNumber_1(deposit) + toNumber_1(x_2)), new Point("Above Strike", toNumber_1(deposit) + toNumber_1(x_2)), new Point("Above Strike", toNumber_1(deposit) + toNumber_1(x_2))]) : ofArray([new Point("Below Strike", (toNumber_1(deposit) * 0.4) + toNumber_1(x_2)), new Point("Below Strike", (toNumber_1(deposit) * 0.6) + toNumber_1(x_2)), new Point("Below Strike", (toNumber_1(deposit) * 0.8) + toNumber_1(x_2)), new Point("At Strike", toNumber_1(deposit) + toNumber_1(x_2)), new Point("Above Strike", toNumber_1(deposit) + toNumber_1(x_2)), new Point("Above Strike", toNumber_1(deposit) + toNumber_1(x_2)), new Point("Above Strike", toNumber_1(deposit) + toNumber_1(x_2)), new Point("Above Strike", toNumber_1(deposit) + toNumber_1(x_2))])))),
        margin: {
            top: defaultArg(10, 0),
            right: defaultArg(30, 0),
            left: defaultArg(void 0, 0),
            bottom: defaultArg(void 0, 0),
        },
        children: Interop_reactApi.Children.toArray([(children = singleton((color = "#8884d8", createElement("linearGradient", {
            id: "colorUv",
            x1: 0,
            x2: 1,
            y1: 0,
            y2: 1,
            children: Interop_reactApi.Children.toArray([createElement("stop", {
                offset: 0 + "%",
                stopColor: color,
                stopOpacity: 0.8,
            }), createElement("stop", {
                offset: 100 + "%",
                stopColor: color,
                stopOpacity: 0,
            })]),
        }))), createElement("defs", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        })), Interop_reactApi.createElement(XAxis, {
            dataKey: (point) => point.name,
            hide: true,
        }), Interop_reactApi.createElement(YAxis, {}), Interop_reactApi.createElement(Tooltip, {}), Interop_reactApi.createElement(CartesianGrid, {
            strokeDasharray: join(" ", map((value_38) => int32ToString(value_38), new Int32Array([3, 3]))),
        }), Interop_reactApi.createElement(Area, {
            type: "monotone",
            dataKey: (point_1) => point_1.uv,
            stroke: "#8884d8",
            fillOpacity: 1,
            fill: "url(#colorUv)",
        })]),
    });
    return createElement("div", {
        className: join(" ", [utils$002Emodule["margin-m-top"]]),
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: join(" ", [utils$002Emodule["margin-s-top"], single$002Dproducts$002Emodule["modal-chart"]]),
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: join(" ", [utils$002Emodule["margin-xs-left"]]),
                children: Interop_reactApi.Children.toArray([createElement(Row, {
                    classes: ofArray([utils$002Emodule["gap-s"], utils$002Emodule["text-xs"]]),
                    children: singleton("Payoff at expiry"),
                }), Interop_reactApi.createElement(ResponsiveContainer, createObj(ofArray([["height", 100 + "%"], (value_55 = (100 + "%"), (equals(value_55, 100 + "%") ? true : equals(value_55, 100 + "%")) ? ["width", 99 + "%"] : ["width", value_55]), ["minWidth", 512], ["minHeight", 110], ["children", chart]]))), createElement(Row, {
                    classes: ofArray([utils$002Emodule["gap-s"], utils$002Emodule["text-xs"], utils$002Emodule["margin-xl-left"]]),
                    children: singleton((arg10 = getTokenString(underlyings), toText(printf("Worst of %s Price at Expiry"))(arg10))),
                })]),
            })]),
        }), createElement(Row, {
            classes: ofArray([utils$002Emodule["text-m"], utils$002Emodule["boldness-600"], utils$002Emodule["margin-s-bottom"], utils$002Emodule["margin-m-top"], single$002Dproducts$002Emodule["modal-simulate-spotlevel"]]),
            children: singleton((arg10_1 = getTokenString(underlyings), toText(printf("Simulate worst of %s Price at Expiry"))(arg10_1))),
        }), createElement(SimulateSpotLevel, {
            classes: [utils$002Emodule["margin-l-bottom"], utils$002Emodule["margin-s-top"]],
            spot: spot,
            setSpotLevel: patternInput[1],
            initial: initialStrikeSlider,
            expirtSpot: currentStrikeSlider,
            setExpirySpot: patternInput_2[1],
        }), createElement("div", {
            className: join(" ", [single$002Dproducts$002Emodule["modal-simulate"]]),
            children: Interop_reactApi.Children.toArray([createElement(Col, {
                classes: singleton(utils$002Emodule["align-center"]),
                children: singleton("Amount deposited:"),
            }), createElement("div", {
                children: Interop_reactApi.Children.toArray([createElement(CurrencyInput, {
                    mint: underlyingToken,
                    value: amount,
                    dispatch: patternInput_1[1],
                    error: void 0,
                })]),
            }), createElement("div", {}), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(createElement("div", {
                    children: Interop_reactApi.Children.toArray(["At expiry, you will get:"]),
                })), delay(() => {
                    let arg10_2, arg20, arg10_3;
                    const strike_1 = head(SpecificProduct__GetStrikes(product.Product.SpecificProduct));
                    return singleton_1(createElement(Row, {
                        classes: empty(),
                        children: ofArray([createElement(Col, {
                            classes: ofArray([utils$002Emodule["boldness-500"], utils$002Emodule["margin-xs-top"]]),
                            children: singleton((arg10_2 = accounting.formatMoney(toNumber_1(amountRetrieved), ""), toText(printf("%s"))(arg10_2))),
                        }), createElement(Col, {
                            classes: ofArray([utils$002Emodule["margin-xs-left"], utils$002Emodule["margin-xs-top"]]),
                            children: singleton(underlyingToken.symbol),
                        }), createElement(Col, {
                            classes: ofArray([utils$002Emodule["margin-xs-left"], utils$002Emodule["margin-xs-top"]]),
                            children: singleton((arg20 = underlyingToken.symbol, (arg10_3 = accounting.formatMoney(toNumber_1(premiumRetrieved), ""), toText(printf(" (%s %s as yield)"))(arg10_3)(arg20)))),
                        })]),
                    }));
                })))))),
            })]),
        }), createElement(Row, {
            classes: ofArray([utils$002Emodule["text-m"], utils$002Emodule["boldness-600"], utils$002Emodule["margin-s-bottom"], utils$002Emodule["margin-l-top"], single$002Dproducts$002Emodule["modal-simulate-spotlevel"]]),
            children: singleton("Product Lifecycle (Local Time)"),
        }), createElement(Row, {
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["boldness-500"], utils$002Emodule["margin-s-top"]]),
            children: singleton(createElement(ProductLifeCycle, {
                subscriptionStart: subtract(product.Holding.EndCollection, fromDays(2)),
                subscriptionEnd: product.Holding.EndCollection,
                productStart: new ProductStart(0, product.Holding.EndCollection),
                productEnd: product.Holding.ProductExpiry,
            })),
        })]),
    });
}

