import { singleton } from "../.fable/fable-library.3.1.16/AsyncBuilder.js";
import { MsgType, coin, msgExecuteContractCompatParams, msgExecuteContractCompat as msgExecuteContractCompat_1, createBroadcast, getDenomBalance, createMsgBroadcastClient } from "../../../../lib/Injective/Injective.fs.js";
import { WithdrawMsg } from "../../../../lib/Domain/Types/InjectiveHolding.fs.js";
import { some } from "../.fable/fable-library.3.1.16/Option.js";
import { interpolate, toText } from "../.fable/fable-library.3.1.16/String.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.1.16/Choice.js";
import { toString } from "../.fable/fable-library.3.1.16/Types.js";

export function withdraw(network, holdingContract, walletStrategy, api, address, holding) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        const client = createMsgBroadcastClient(network, walletStrategy);
        const withdrawMsg = new WithdrawMsg(holding.Id);
        const msg = {
            withdraw: withdrawMsg.Withdraw,
        };
        console.log(some(msg));
        const positionTokenDenom = toText(interpolate("factory/%P()/%P()", [holdingContract, holding.Id]));
        return singleton.Bind(getDenomBalance(api, address, positionTokenDenom), (_arg1) => {
            const userPositionTokenBalance = _arg1;
            return (userPositionTokenBalance === "0") ? singleton.Return(new FSharpResult$2(1, "User has no product position")) : singleton.Bind(createBroadcast(client, new MsgType(1, msgExecuteContractCompat_1(msgExecuteContractCompatParams([coin(positionTokenDenom, userPositionTokenBalance)], address, holdingContract, msg))), address), (_arg2) => singleton.Return(new FSharpResult$2(0, _arg2)));
        });
    }), (_arg3) => singleton.Return(new FSharpResult$2(1, toString(_arg3)))));
}

