import { tryFind } from "../../app/client/src/.fable/fable-library.3.1.16/Map.js";
import { convertToRealOnes } from "./SolanaTokenRegistry.fs.js";
import { PublicKey_Value_Z1B21ACD5 } from "../Domain/Types/Common.fs.js";
import { some } from "../../app/client/src/.fable/fable-library.3.1.16/Option.js";
import { singleton } from "../../app/client/src/.fable/fable-library.3.1.16/AsyncBuilder.js";
import { encodeU64, encodeString2NoLength, encodePublicKey2 } from "./Layout.fs.js";
import { bn, findProgramAddress } from "./Solana.fs.js";
import { toInt } from "../../app/client/src/.fable/fable-library.3.1.16/Long.js";

export function fetchToken(key, tokens) {
    return tryFind(convertToRealOnes(PublicKey_Value_Z1B21ACD5(key)), tokens);
}

export function fetchInjectiveToken(address, tokens) {
    return tryFind(address, tokens);
}

export function getBugBountyUserPDA(bugBountyAccount, walletPublicKey, bugBountyProgram) {
    console.log(some("getBugBountyUserPDA"));
    return singleton.Delay(() => {
        const b1 = encodePublicKey2(bugBountyAccount);
        const b2 = encodePublicKey2(walletPublicKey);
        const b3 = encodeString2NoLength("remark-counter");
        console.log(some("b1: " + b1.toLocaleString()));
        console.log(some("b2: " + b2.toLocaleString()));
        console.log(some("b3: " + b3.toLocaleString()));
        return singleton.Bind(findProgramAddress([b1, b2, b3], bugBountyProgram), (_arg1) => singleton.Return(_arg1));
    });
}

export function getBugBountyRemarkPDA(bugBountyAccount, walletPublicKey, id, bugBountyProgram) {
    return singleton.Delay(() => {
        const b1 = encodePublicKey2(bugBountyAccount);
        console.log(some("remark b1: " + b1.toLocaleString()));
        const b2 = encodePublicKey2(walletPublicKey);
        console.log(some("remark b2: " + b2.toLocaleString()));
        console.log(some("remark b3: " + encodeU64(bn(toInt(id) >>> 0)).toLocaleString()));
        return singleton.Bind(findProgramAddress([b1, b2], bugBountyProgram), (_arg1) => singleton.Return(_arg1));
    });
}

export function getDevNetProfilePDA(walletPublicKey, profileProgram) {
    return singleton.Delay(() => {
        const b1 = encodePublicKey2(walletPublicKey);
        return singleton.Bind(findProgramAddress([encodeString2NoLength("devnet-profile"), b1], profileProgram), (_arg1) => singleton.Return(_arg1));
    });
}

export function getFaucetPDA(faucetProgram) {
    return singleton.Delay(() => singleton.Bind(findProgramAddress([encodeString2NoLength("devnet-profile")], faucetProgram), (_arg1) => singleton.Return(_arg1)));
}

export function getMintAuthorityPDA(faucetProgram, mint) {
    return singleton.Delay(() => singleton.Bind(findProgramAddress([encodePublicKey2(mint)], faucetProgram), (_arg1) => singleton.Return(_arg1)));
}

export function getProfilePDA(profileProgram, wallet) {
    return singleton.Delay(() => singleton.Bind(findProgramAddress([encodeString2NoLength("devnet-profile"), encodePublicKey2(wallet)], profileProgram), (_arg1) => singleton.Return(_arg1)));
}

export function String_contains(v, s) {
    return s.indexOf(v) >= 0;
}

export function String_toLower(s) {
    return s.toLowerCase();
}

