import { singleton } from "../../app/client/src/.fable/fable-library.3.1.16/AsyncBuilder.js";
import { awaitPromise } from "../../app/client/src/.fable/fable-library.3.1.16/Async.js";
import { SystemProgram, sendAndConfirmRawTransaction } from "@solana/web3.js";
import { FSharpResult$2 } from "../../app/client/src/.fable/fable-library.3.1.16/Choice.js";
import { some } from "../../app/client/src/.fable/fable-library.3.1.16/Option.js";
import { createTransaction } from "./Solana.fs.js";
import { printf, toConsole } from "../../app/client/src/.fable/fable-library.3.1.16/String.js";

export function getRecentBlockHash(connection) {
    return singleton.Delay(() => singleton.Bind(awaitPromise(connection.getRecentBlockhash("max")), (_arg1) => singleton.Return(_arg1.blockhash)));
}

export function sendRawTransaction(connection, transaction) {
    return singleton.Delay(() => singleton.Bind(awaitPromise(connection.sendRawTransaction(transaction, {
        skipPreflight: false,
        preflightCommitment: "confirmed",
    })), (_arg1) => singleton.Return(_arg1)));
}

export function sendAndConfirmTransactionSignedByWallet(wallet, connection, transaction) {
    return singleton.Delay(() => singleton.Bind(awaitPromise(wallet.signTransaction(transaction)), (_arg1) => {
        const options = {
            skipPreflight: false,
        };
        return singleton.Bind(awaitPromise(sendAndConfirmRawTransaction(connection, _arg1.serialize(), options)), (_arg2) => singleton.Return(_arg2));
    }));
}

export function signAndSendTransaction(wallet, pubKey, connection, signers, transaction) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(getRecentBlockHash(connection), (_arg1) => {
        transaction.recentBlockhash = _arg1;
        transaction.feePayer = pubKey;
        return singleton.Combine(singleton.For(signers, (_arg2) => {
            transaction.partialSign(_arg2);
            return singleton.Zero();
        }), singleton.Delay(() => singleton.Bind(awaitPromise(wallet.signTransaction(transaction)), (_arg3) => singleton.Bind(sendRawTransaction(connection, _arg3.serialize()), (_arg4) => singleton.Return(new FSharpResult$2(0, _arg4))))));
    })), (_arg5) => {
        const e = _arg5;
        console.log(some(e));
        return singleton.Return(new FSharpResult$2(1, e.message));
    }));
}

export function nativeTransfer(wallet, pubKey, connection, destination, amount) {
    return singleton.Delay(() => {
        const transaction = createTransaction();
        transaction.add(SystemProgram.transfer({
            amount: amount,
            fromPubkey: pubKey,
            toPubkey: destination,
        }));
        return singleton.ReturnFrom(signAndSendTransaction(wallet, pubKey, connection, [], transaction));
    });
}

export function getTransactionBySignature(connection, signature) {
    return singleton.Delay(() => singleton.Bind(awaitPromise(connection.getTransaction(signature, "finalized")), (_arg1) => {
        const signature_1 = _arg1;
        const arg10 = signature_1.slot | 0;
        toConsole(printf("confirmTransactionBySignature %A"))(arg10);
        return singleton.Return(signature_1);
    }));
}

