import { createElement } from "react";
import { equals, createObj } from "../.fable/fable-library.3.1.16/Util.js";
import { empty, singleton, append, delay, toList } from "../.fable/fable-library.3.1.16/Seq.js";
import { interpolate, toText } from "../.fable/fable-library.3.1.16/String.js";
import { value as value_9 } from "../.fable/fable-library.3.1.16/Option.js";
import { singleton as singleton_1 } from "../.fable/fable-library.3.1.16/List.js";
import { Interop_reactApi } from "../.fable/Feliz.1.62.0/Interop.fs.js";

export function Modal(modalInputProps) {
    const extraStyleAttribute = modalInputProps.extraStyleAttribute;
    const onClose = modalInputProps.onClose;
    const children = modalInputProps.children;
    const id = "m1";
    return createElement("div", createObj(toList(delay(() => append(singleton(["id", id]), delay(() => append(singleton(["className", toText(interpolate("overflow-hidden\n                fixed\n                inset-0\n                z-50\n                flex\n                items-center\n                justify-center\n                size-full\n                bg-slate-400/20\n                backdrop-blur-md", []))]), delay(() => append((extraStyleAttribute != null) ? singleton(["style", createObj(singleton_1(value_9(extraStyleAttribute)))]) : empty(), delay(() => append(singleton(["children", children]), delay(() => singleton(["onClick", (e) => {
        if (equals(document.getElementById(id), e.target)) {
            onClose();
        }
    }])))))))))))));
}

export function OpenBurgerMenu(openBurgerMenuInputProps) {
    const onClose = openBurgerMenuInputProps.onClose;
    const children = openBurgerMenuInputProps.children;
    return createElement("div", {
        id: "mb",
        className: "overflow-hidden\n                fixed\n                inset-0\n                z-50\n                items-center\n                justify-center\n                size-full\n                backdrop-blur-md\n                lg:hidden\n                text-exo-primary\n                flex",
        children: Interop_reactApi.Children.toArray([createElement("ul", {
            className: "gap-20 flex flex-col text-center",
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        })]),
        onClick: (_arg1) => {
            onClose();
        },
    });
}

