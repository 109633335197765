import { Union } from "./.fable/fable-library.3.1.16/Types.js";
import { union_type, class_type } from "./.fable/fable-library.3.1.16/Reflection.js";
import { fromInteger, compare } from "./.fable/fable-library.3.1.16/Long.js";
import { totalSeconds, ticks } from "./.fable/fable-library.3.1.16/TimeSpan.js";
import { now, utcNow, op_Subtraction } from "./.fable/fable-library.3.1.16/DateOffset.js";
import { useCountdown } from "../../../lib/CustomHooks/CustomHooks.fs.js";
import { equals, comparePrimitives, max } from "./.fable/fable-library.3.1.16/Util.js";
import { useFeliz_React__React_useState_Static_1505, useReact_useElementRef, useReact_useMemo_CF4EA67 } from "./.fable/Feliz.1.62.0/React.fs.js";
import { createElement } from "react";
import * as react from "react";
import { join } from "./.fable/fable-library.3.1.16/String.js";
import single$002Dproducts$002Emodule from "./styles/single-products.module.scss";
import utils$002Emodule from "./styles/utils.module.scss";
import { Interop_reactApi } from "./.fable/Feliz.1.62.0/Interop.fs.js";
import { Row, Col } from "./UtilComponents.fs.js";
import { empty as empty_1, append, ofArray, singleton } from "./.fable/fable-library.3.1.16/List.js";
import { format as format_3 } from "date-fns";
import { empty, singleton as singleton_1, delay, toList } from "./.fable/fable-library.3.1.16/Seq.js";
import { TimeCountdown } from "./DisplayTime.fs.js";

export class ProductStart extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Date", "Triggered"];
    }
}

export function ProductStart$reflection() {
    return union_type("ProductLifeCycle.ProductStart", [], ProductStart, () => [[["Item", class_type("System.DateTimeOffset")]], []]);
}

export class ProductStep extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["None", "SubscriptionStart", "SubscriptionEnd", "ProductStart", "ProductEnd"];
    }
}

export function ProductStep$reflection() {
    return union_type("ProductLifeCycle.ProductStep", [], ProductStep, () => [[], [], [], [], []]);
}

export function RemainingTimeLine(remainingTimeLineInputProps) {
    let copyOfStruct;
    const productEnd = remainingTimeLineInputProps.productEnd;
    const subscriptionEnd = remainingTimeLineInputProps.subscriptionEnd;
    const subscriptionStart = remainingTimeLineInputProps.subscriptionStart;
    const halfWidth = 595 * 0.5;
    const hasSubscriptionEnded = compare(ticks(op_Subtraction(subscriptionEnd, utcNow())), fromInteger(0, false, 2)) <= 0;
    const patternInput = hasSubscriptionEnded ? [op_Subtraction(productEnd, subscriptionEnd), productEnd] : [op_Subtraction(subscriptionEnd, subscriptionStart), subscriptionEnd];
    const totalTime = patternInput[0];
    const remainingTimeInSeconds = useCountdown(~(~max((x, y) => comparePrimitives(x, y), 0, (copyOfStruct = op_Subtraction(patternInput[1], now()), totalSeconds(copyOfStruct))))) | 0;
    const remainingTimePercentage = useReact_useMemo_CF4EA67(() => ((totalSeconds(totalTime) - remainingTimeInSeconds) / totalSeconds(totalTime)), [remainingTimeInSeconds]);
    const divRef = useReact_useElementRef();
    const width = useReact_useMemo_CF4EA67(() => {
        const w = remainingTimePercentage * halfWidth;
        return hasSubscriptionEnded ? (w + halfWidth) : w;
    }, [remainingTimePercentage]);
    return createElement("div", {
        ref: divRef,
        className: join(" ", [single$002Dproducts$002Emodule["product-lifecycle-line"]]),
        style: {
            backgroundColor: "#ffac8f",
            width: (~(~width)) + "px",
        },
    });
}

export function ProductLifeCycle(productLifeCycleInputProps) {
    const productEnd = productLifeCycleInputProps.productEnd;
    const productStart = productLifeCycleInputProps.productStart;
    const subscriptionEnd = productLifeCycleInputProps.subscriptionEnd;
    const subscriptionStart = productLifeCycleInputProps.subscriptionStart;
    const productStep = useFeliz_React__React_useState_Static_1505(new ProductStep(0))[0];
    const timelineHeader = (top, bottom, alignmentClass) => createElement("div", {
        className: join(" ", [utils$002Emodule["margin-m-top"], alignmentClass]),
        children: Interop_reactApi.Children.toArray([createElement(Col, {
            classes: singleton(utils$002Emodule["gap-xxs"]),
            children: ofArray([createElement("div", {
                children: [top],
            }), createElement("div", {
                children: [bottom],
            })]),
        })]),
    });
    const timelineIcon = (iconsAlignment, iconClasses) => createElement("div", {
        className: join(" ", append(singleton(single$002Dproducts$002Emodule["product-lifecycle-icon"]), iconsAlignment)),
        children: Interop_reactApi.Children.toArray([createElement("span", {
            className: join(" ", append(singleton("lifecyle-dot"), iconClasses)),
        })]),
    });
    const displayFormattedDate = (date, classes) => createElement("div", {
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: join(" ", [utils$002Emodule["gap-xs"]]),
            children: Interop_reactApi.Children.toArray([createElement("span", {
                className: join(" ", [utils$002Emodule["boldness-500"]]),
                children: format_3(date, "dd"),
            }), createElement("span", {
                className: join(" ", [single$002Dproducts$002Emodule["product-lifecycle-month"], utils$002Emodule["boldness-400"], utils$002Emodule["text-light"]]),
                children: format_3(date, "MMM").toLocaleUpperCase(),
            })]),
        }), createElement("div", {
            className: join(" ", append(singleton(utils$002Emodule["boldness-400"]), classes)),
            children: Interop_reactApi.Children.toArray([format_3(date, "HH:mm")]),
        })]),
    });
    return createElement("div", {
        className: join(" ", [single$002Dproducts$002Emodule["product-lifecycle"], utils$002Emodule["gap-s"]]),
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: join(" ", []),
            children: Interop_reactApi.Children.toArray([timelineHeader("Subscription", "Start", "")]),
        }), createElement("div", {
            className: join(" ", []),
            children: Interop_reactApi.Children.toArray([react.createElement(react.Fragment, {}, createElement("div", {
                className: join(" ", [utils$002Emodule["margin-m-top"], utils$002Emodule["text-end"]]),
                children: Interop_reactApi.Children.toArray([createElement(Col, {
                    classes: singleton(utils$002Emodule["gap-xxs"]),
                    children: ofArray([createElement("div", {
                        children: ["Subscription"],
                    }), createElement("div", {
                        children: ["End"],
                    })]),
                })]),
            }), createElement("div", {
                className: join(" ", [utils$002Emodule["margin-m-top"], utils$002Emodule["text-start"]]),
                children: Interop_reactApi.Children.toArray([createElement(Col, {
                    classes: singleton(utils$002Emodule["gap-xxs"]),
                    children: ofArray([createElement("div", {
                        children: ["Product"],
                    }), createElement("div", {
                        children: ["Start"],
                    })]),
                })]),
            }))]),
        }), createElement("div", {
            className: join(" ", [single$002Dproducts$002Emodule["product-lifecycle-icon-end"]]),
            children: Interop_reactApi.Children.toArray([timelineHeader("Product", "End", utils$002Emodule["text-end"])]),
        }), createElement("div", {
            className: join(" ", []),
            children: Interop_reactApi.Children.toArray([timelineIcon(singleton(utils$002Emodule["align-start"]), toList(delay(() => (equals(productStep, new ProductStep(1)) ? singleton_1("lifecyle-dot-blue") : empty()))))]),
        }), createElement("div", {
            className: join(" ", []),
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: join(" ", append(singleton(single$002Dproducts$002Emodule["product-lifecycle-icon"]), singleton(single$002Dproducts$002Emodule["product-lifecycle-icon-center"]))),
                children: Interop_reactApi.Children.toArray([createElement(Row, {
                    classes: empty_1(),
                    children: ofArray([createElement("div", {
                        className: join(" ", ["lifecycle-subscription-end"]),
                    }), createElement("div", {
                        className: join(" ", ["lifecycle-product-start"]),
                    })]),
                })]),
            })]),
        }), createElement("div", {
            className: join(" ", [single$002Dproducts$002Emodule["product-lifecycle-icon-end"]]),
            children: Interop_reactApi.Children.toArray([timelineIcon(singleton(single$002Dproducts$002Emodule["product-lifecycle-icon-end"]), toList(delay(() => (equals(productStep, new ProductStep(4)) ? singleton_1("lifecyle-dot-blue") : empty()))))]),
        }), createElement("div", {
            className: join(" ", []),
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: join(" ", []),
                children: Interop_reactApi.Children.toArray([displayFormattedDate(subscriptionStart, empty_1())]),
            })]),
        }), createElement("div", {
            className: join(" ", []),
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: join(" ", [utils$002Emodule.center]),
                children: Interop_reactApi.Children.toArray([displayFormattedDate(subscriptionEnd, singleton(utils$002Emodule.center))]),
            })]),
        }), createElement("div", {
            className: join(" ", [single$002Dproducts$002Emodule["product-lifecycle-icon-end"]]),
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: join(" ", [utils$002Emodule["text-end"]]),
                children: Interop_reactApi.Children.toArray([displayFormattedDate(productEnd, singleton(utils$002Emodule["text-end"]))]),
            })]),
        }), createElement("div", {
            className: join(" ", [single$002Dproducts$002Emodule.line]),
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: join(" ", [single$002Dproducts$002Emodule["product-lifecycle-line"]]),
            })]),
        }), createElement("div", {
            className: join(" ", [single$002Dproducts$002Emodule.line]),
            children: Interop_reactApi.Children.toArray([createElement(RemainingTimeLine, {
                subscriptionStart: subscriptionStart,
                subscriptionEnd: subscriptionEnd,
                productEnd: productEnd,
            })]),
        })]),
    });
}

export function RemainingTime(remainingTimeInputProps) {
    const duration = remainingTimeInputProps.duration;
    return createElement(Row, {
        classes: ofArray([utils$002Emodule["gap-xs"], utils$002Emodule["text-s"]]),
        children: ofArray([createElement("span", {
            className: join(" ", ["text-color-green"]),
            children: "Remaining Time: ",
        }), createElement("div", {
            className: join(" ", []),
            children: Interop_reactApi.Children.toArray([createElement(TimeCountdown, {
                seconds: ~(~totalSeconds(duration)),
            })]),
        })]),
    });
}

