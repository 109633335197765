import wallet_1 from "./imgs/wallet.svg";
import risk$002Dlow from "./imgs/risk-low.png";
import risk$002Dmedium from "./imgs/risk-medium.png";
import risk$002Dhigh from "./imgs/risk-high.png";
import BTC from "./imgs/BTC.svg";
import ETH from "./imgs/ETH.svg";
import SOL from "./imgs/SOL.svg";
import USDC from "./imgs/USDC.svg";
import monkey_1 from "./imgs/monkey.png";
import Close from "./imgs/Close.png";
import StatCard1 from "./imgs/StatCard1.svg";
import StatCard2 from "./imgs/StatCard2.svg";
import StatCard3 from "./imgs/StatCard3.svg";
import risk_triangle from "./imgs/risk_triangle.svg";
import bug_1 from "./imgs/bug.svg";
import modal_close from "./imgs/modal_close.svg";
import exotic_logo from "./imgs/exotic_logo.svg";
import exotic_logo_footer from "./imgs/exotic_logo_footer.svg";
import login_ from "./imgs/login_.svg";
import portfolio_1 from "./imgs/portfolio.jpg";
import arrow_right from "./imgs/arrow_right.svg";
import macbook_1 from "./imgs/macbook.svg";
import iphone_1 from "./imgs/iphone.svg";
import assistant_landing from "./imgs/assistant_landing.svg";
import Product$0020Card$00201 from "./imgs/Product Card 1.svg";
import Product$0020Card$00202 from "./imgs/Product Card 2.svg";
import Product$0020Card$00203 from "./imgs/Product Card 3.svg";
import plus_1 from "./imgs/plus.svg";
import plus$002Ddark from "./imgs/plus-dark.svg";
import minus_1 from "./imgs/minus.svg";
import times_1 from "./imgs/times.svg";
import discord_1 from "./imgs/discord.svg";
import monogram_1 from "./imgs/monogram.svg";
import gitbook_1 from "./imgs/gitbook.svg";
import diagram_1 from "./imgs/diagram.png";
import mail_1 from "./imgs/mail.svg";
import times2_1 from "./imgs/times2.svg";
import market$002Dup from "./imgs/market-up.png";
import market$002Ddown from "./imgs/market-down.png";
import market$002Dstable from "./imgs/market-stable.png";
import filter$002Dicon$002Dleopard from "./imgs/filter-icon-leopard.png";
import manage_liquidity from "./imgs/manage_liquidity.svg";
import background$002Dfruits from "./imgs/background-fruits.png";
import stat_icon1 from "./imgs/stat_icon1.svg";
import stat_icon2 from "./imgs/stat_icon2.svg";
import stat_icon3 from "./imgs/stat_icon3.svg";
import Solana from "./imgs/Solana.png";
import logo_inj from "./imgs/logo_inj.png";
import pythblack from "./imgs/pythblack.png";
import stat_icon_fee1 from "./imgs/stat_icon_fee1.svg";
import stat_icon_deposits from "./imgs/stat_icon_deposits.svg";
import stat_icon_report from "./imgs/stat_icon_report.svg";
import stat_icon_yield from "./imgs/stat_icon_yield.svg";
import box_1 from "./imgs/box.svg";
import satellite_1 from "./imgs/satellite.svg";
import rocket_1 from "./imgs/rocket.svg";
import coding_1 from "./imgs/coding.svg";
import timeline_1 from "./imgs/timeline.svg";
import timeline_vertical from "./imgs/timeline_vertical.svg";
import expired_1 from "./imgs/expired.svg";
import deposited_not_started from "./imgs/deposited_not_started.svg";
import money_safe from "./imgs/money_safe.svg";
import maturity_7days from "./imgs/maturity_7days.png";
import maturity_7days_active from "./imgs/maturity_7days_active.png";
import maturity_14days from "./imgs/maturity_14days.png";
import maturity_14days_active from "./imgs/maturity_14days_active.png";
import maturity_30days from "./imgs/maturity_30days.png";
import maturity_30days_active from "./imgs/maturity_30days_active.png";
import market_up from "./imgs/market_up.png";
import market_up_active from "./imgs/market_up_active.png";
import market_down from "./imgs/market_down.png";
import market_down_active from "./imgs/market_down_active.png";
import market_stable from "./imgs/market_stable.png";
import market_stable_active from "./imgs/market_stable_active.png";
import risk_high from "./imgs/risk_high.png";
import risk_high_active from "./imgs/risk_high_active.png";
import risk_medium from "./imgs/risk_medium.png";
import risk_medium_active from "./imgs/risk_medium_active.png";
import risk_low from "./imgs/risk_low.png";
import risk_low_active from "./imgs/risk_low_active.png";
import sort_1 from "./imgs/sort.svg";
import wizard_error from "./imgs/wizard_error.svg";
import not_found from "./imgs/not_found.svg";
import star_1 from "./imgs/star.svg";
import exclamation_circle from "./imgs/exclamation_circle.svg";
import disclaimer_elipse from "./imgs/disclaimer_elipse.svg";
import ascensiveassets from "./imgs/ascensiveassets.svg";
import multicoincapital from "./imgs/multicoincapital.svg";
import bitcoindotcom from "./imgs/bitcoindotcom.svg";
import $0033commas from "./imgs/3commas.png";
import Auros_1 from "./imgs/Auros.png";
import Coban_1 from "./imgs/Coban.png";
import Evernew_Capital_1 from "./imgs/Evernew_Capital.png";
import Mgnr_1 from "./imgs/Mgnr.png";
import Paradigm_1 from "./imgs/Paradigm.png";
import TPS_Capital_1 from "./imgs/TPS_Capital.png";
import AnimocaBrands from "./imgs/AnimocaBrands.png";
import AstronautCapital from "./imgs/AstronautCapital.png";
import MorningStar from "./imgs/MorningStar.png";
import RaceCapital from "./imgs/RaceCapital.png";
import SkyVisionCapital from "./imgs/SkyVisionCapital.png";
import Solana$002DVentures from "./imgs/Solana-Ventures.png";
import fmfwio_1 from "./imgs/fmfwio.png";
import dual$002Dcurrency$002Ddownside from "./imgs/dual-currency-downside.svg";
import dual$002Dcurrency$002Dproduct from "./imgs/dual-currency-product.svg";
import vault$002Dcoming$002Dsoon from "./imgs/vault-coming-soon.png";
import info$002Dicon from "./imgs/info-icon.svg";
import cointelegraph_1 from "./imgs/cointelegraph.svg";
import coindesk_1 from "./imgs/coindesk.svg";
import box1_1 from "./imgs/box1.jpeg";
import box2_1 from "./imgs/box2.jpeg";
import box3_1 from "./imgs/box3.jpeg";
import box4_1 from "./imgs/box4.png";
import box5_1 from "./imgs/box5.jpeg";
import box6_1 from "./imgs/box6.jpeg";
import box7_1 from "./imgs/box7.png";
import box8_1 from "./imgs/box8.jpeg";
import educationalLinkImage1_1 from "./imgs/educationalLinkImage1.png";
import educationalLinkImage2_1 from "./imgs/educationalLinkImage2.png";
import educationalLinkImage3_1 from "./imgs/educationalLinkImage3.png";
import educationalLinkImage4_1 from "./imgs/educationalLinkImage4.png";
import resourcesLinkImage1_1 from "./imgs/resourcesLinkImage1.png";
import resourcesLinkImage2_1 from "./imgs/resourcesLinkImage2.png";
import resourcesLinkImage3_1 from "./imgs/resourcesLinkImage3.png";
import resourcesLinkImage4_1 from "./imgs/resourcesLinkImage4.png";
import resource1_1 from "./imgs/resource1.jpeg";
import resource2_1 from "./imgs/resource2.jpeg";
import resource3_1 from "./imgs/resource3.jpg";
import resource4_1 from "./imgs/resource4.jpg";
import resource5_1 from "./imgs/resource5.jpg";
import danger_symbol from "./imgs/danger_symbol.png";
import how$002Dit$002Dworks$002Dslide1 from "./imgs/how-it-works-slide1.png";
import how$002Dit$002Dworks$002Dslide2 from "./imgs/how-it-works-slide2.png";
import how$002Dit$002Dworks$002Dslide3 from "./imgs/how-it-works-slide3.png";
import how$002Dit$002Dworks$002Dslide4 from "./imgs/how-it-works-slide4.png";
import how$002Dit$002Dworks$002Dslide5 from "./imgs/how-it-works-slide5.png";
import tryOurAssistant_1 from "./imgs/tryOurAssistant.png";
import account_balance_wallet from "./imgs/account_balance_wallet.svg";
import investors_1 from "./imgs/investors.png";
import inj_logo_v2 from "./imgs/inj_logo_v2.svg";
import pyth_logo_v2 from "./imgs/pyth_logo_v2.svg";
import ring_background from "./imgs/ring_background.svg";
import laptop_background from "./imgs/laptop_background.png";

export const wallet = wallet_1;

export const lowRisk = risk$002Dlow;

export const mediumRisk = risk$002Dmedium;

export const highRisk = risk$002Dhigh;

export const btc = BTC;

export const eth = ETH;

export const sol = SOL;

export const usdc = USDC;

export const monkey = monkey_1;

export const close = Close;

export const statCard1 = StatCard1;

export const statCard2 = StatCard2;

export const statCard3 = StatCard3;

export const riskTriangle = risk_triangle;

export const bug = bug_1;

export const modalClose = modal_close;

export const exoticLogo = exotic_logo;

export const exoticLogoFooter = exotic_logo_footer;

export const login = login_;

export const portfolio = portfolio_1;

export const arrowRight = arrow_right;

export const macbook = macbook_1;

export const iphone = iphone_1;

export const assistantLanding = assistant_landing;

export const card1 = Product$0020Card$00201;

export const card2 = Product$0020Card$00202;

export const card3 = Product$0020Card$00203;

export const plus = plus_1;

export const plusDark = plus$002Ddark;

export const minus = minus_1;

export const times = times_1;

export const discord = discord_1;

export const monogram = monogram_1;

export const gitbook = gitbook_1;

export const diagram = diagram_1;

export const mail = mail_1;

export const times2 = times2_1;

export const marketUp = market$002Dup;

export const marketDown = market$002Ddown;

export const marketStable = market$002Dstable;

export const filterIconLeopard = filter$002Dicon$002Dleopard;

export const manageLiquidity = manage_liquidity;

export const backgroundFruits = background$002Dfruits;

export const statIcon1 = stat_icon1;

export const statIcon2 = stat_icon2;

export const statIcon3 = stat_icon3;

export const solana = Solana;

export const injective = logo_inj;

export const pyth = pythblack;

export const statIconFee1 = stat_icon_fee1;

export const statIconDeposits = stat_icon_deposits;

export const statIconReport = stat_icon_report;

export const statIconYield = stat_icon_yield;

export const box = box_1;

export const satellite = satellite_1;

export const rocket = rocket_1;

export const coding = coding_1;

export const timeline = timeline_1;

export const timelineVertical = timeline_vertical;

export const expired = expired_1;

export const depositedNotStarted = deposited_not_started;

export const moneySafe = money_safe;

export const maturity7days = maturity_7days;

export const maturity7daysActive = maturity_7days_active;

export const maturity14days = maturity_14days;

export const maturity14daysActive = maturity_14days_active;

export const maturity30days = maturity_30days;

export const maturity30daysActive = maturity_30days_active;

export const marketViewUp = market_up;

export const marketViewUpActive = market_up_active;

export const marketViewDown = market_down;

export const marketViewDownActive = market_down_active;

export const marketViewStable = market_stable;

export const marketViewStableActive = market_stable_active;

export const riskHigh = risk_high;

export const riskHighActive = risk_high_active;

export const riskMedium = risk_medium;

export const riskMediumActive = risk_medium_active;

export const riskLow = risk_low;

export const riskLowActive = risk_low_active;

export const sort = sort_1;

export const wizardError = wizard_error;

export const notFound = not_found;

export const star = star_1;

export const exclamationCircle = exclamation_circle;

export const disclaimerElipse = disclaimer_elipse;

export const ascensiveAssets = ascensiveassets;

export const multicoinCapital = multicoincapital;

export const bitcoinDotCom = bitcoindotcom;

export const threeCommas = $0033commas;

export const Auros = Auros_1;

export const Coban = Coban_1;

export const Evernew_Capital = Evernew_Capital_1;

export const Mgnr = Mgnr_1;

export const Paradigm = Paradigm_1;

export const TPS_Capital = TPS_Capital_1;

export const animocaBrands = AnimocaBrands;

export const astronautCapital = AstronautCapital;

export const morningStar = MorningStar;

export const raceCapital = RaceCapital;

export const skyVisionCapital = SkyVisionCapital;

export const solanaVentures = Solana$002DVentures;

export const fmfwio = fmfwio_1;

export const dualCurrencyUpside = dual$002Dcurrency$002Ddownside;

export const dualCurrencyDownside = dual$002Dcurrency$002Ddownside;

export const dualCurrency = dual$002Dcurrency$002Dproduct;

export const VaultComingSoon = vault$002Dcoming$002Dsoon;

export const TooltipIcon = info$002Dicon;

export const cointelegraph = cointelegraph_1;

export const coindesk = coindesk_1;

export const box1 = box1_1;

export const box2 = box2_1;

export const box3 = box3_1;

export const box4 = box4_1;

export const box5 = box5_1;

export const box6 = box6_1;

export const box7 = box7_1;

export const box8 = box8_1;

export const educationalLinkImage1 = educationalLinkImage1_1;

export const educationalLinkImage2 = educationalLinkImage2_1;

export const educationalLinkImage3 = educationalLinkImage3_1;

export const educationalLinkImage4 = educationalLinkImage4_1;

export const resourcesLinkImage1 = resourcesLinkImage1_1;

export const resourcesLinkImage2 = resourcesLinkImage2_1;

export const resourcesLinkImage3 = resourcesLinkImage3_1;

export const resourcesLinkImage4 = resourcesLinkImage4_1;

export const resource1 = resource1_1;

export const resource2 = resource2_1;

export const resource3 = resource3_1;

export const resource4 = resource4_1;

export const resource5 = resource5_1;

export const dangerSymbol = danger_symbol;

export const HowItWorksSlide1 = how$002Dit$002Dworks$002Dslide1;

export const HowItWorksSlide2 = how$002Dit$002Dworks$002Dslide2;

export const HowItWorksSlide3 = how$002Dit$002Dworks$002Dslide3;

export const HowItWorksSlide4 = how$002Dit$002Dworks$002Dslide4;

export const HowItWorksSlide5 = how$002Dit$002Dworks$002Dslide5;

export const tryOurAssistant = tryOurAssistant_1;

export const accountBalanceWallet = account_balance_wallet;

export const investors = investors_1;

export const injectiveV2 = inj_logo_v2;

export const pythV2 = pyth_logo_v2;

export const ringBackground = ring_background;

export const laptopBackground = laptop_background;

