import { useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "./.fable/Feliz.1.62.0/React.fs.js";
import { RouterModule_router, RouterModule_urlSegments } from "./.fable/Feliz.Router.3.8.0/Router.fs.js";
import { Recoil_useRecoilState_Z74A95619 } from "./.fable/Feliz.Recoil.0.19.2/Bindings.fs.js";
import { selectedProductType, selectedProductPublicKey, appTab } from "./Context.fs.js";
import { ApplicationTab } from "./Types.fs.js";
import { some } from "./.fable/fable-library.3.1.16/Option.js";
import { tail, head, isEmpty } from "./.fable/fable-library.3.1.16/List.js";
import { createElement } from "react";
import * as react from "react";
import { singleton, delay, toList } from "./.fable/fable-library.3.1.16/Seq.js";
import { App } from "./App.fs.js";
import { NotFound } from "./NotFound.fs.js";

export function Router() {
    const patternInput = useFeliz_React__React_useState_Static_1505(RouterModule_urlSegments(window.location.hash, 1));
    const currentUrl = patternInput[0];
    const setAppTab = Recoil_useRecoilState_Z74A95619(appTab)[1];
    const patternInput_2 = Recoil_useRecoilState_Z74A95619(selectedProductPublicKey);
    const patternInput_3 = Recoil_useRecoilState_Z74A95619(selectedProductType);
    useReact_useEffect_Z101E1A95(() => {
        let pattern_matching_result;
        if (!isEmpty(currentUrl)) {
            if (head(currentUrl) === "products") {
                if (isEmpty(tail(currentUrl))) {
                    pattern_matching_result = 1;
                }
                else {
                    pattern_matching_result = 4;
                }
            }
            else if (head(currentUrl) === "optionality") {
                if (isEmpty(tail(currentUrl))) {
                    pattern_matching_result = 2;
                }
                else {
                    pattern_matching_result = 4;
                }
            }
            else if (head(currentUrl) === "dashboard") {
                if (isEmpty(tail(currentUrl))) {
                    pattern_matching_result = 3;
                }
                else {
                    pattern_matching_result = 4;
                }
            }
            else if (head(currentUrl) === "dashboard-solana") {
                if (isEmpty(tail(currentUrl))) {
                    pattern_matching_result = 3;
                }
                else {
                    pattern_matching_result = 4;
                }
            }
            else {
                pattern_matching_result = 4;
            }
        }
        else {
            pattern_matching_result = 0;
        }
        switch (pattern_matching_result) {
            case 0: {
                break;
            }
            case 1: {
                setAppTab(new ApplicationTab(1));
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
                break;
            }
            case 2: {
                setAppTab(new ApplicationTab(2));
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
                break;
            }
            case 3: {
                patternInput_2[1](void 0);
                setAppTab(new ApplicationTab(3));
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
                break;
            }
            case 4: {
                console.log(some("Route not found"));
                break;
            }
        }
    }, [currentUrl]);
    return RouterModule_router({
        onUrlChanged: patternInput[1],
        application: react.createElement(react.Fragment, {}, ...toList(delay(() => ((!isEmpty(currentUrl)) ? ((head(currentUrl) === "products") ? (isEmpty(tail(currentUrl)) ? singleton(createElement(App, {
            isSolana: false,
        })) : singleton(createElement(NotFound, null))) : ((head(currentUrl) === "vaults") ? (isEmpty(tail(currentUrl)) ? singleton(createElement(App, {
            isSolana: false,
        })) : singleton(createElement(NotFound, null))) : ((head(currentUrl) === "dashboard") ? (isEmpty(tail(currentUrl)) ? singleton(createElement(App, {
            isSolana: false,
        })) : singleton(createElement(NotFound, null))) : ((head(currentUrl) === "leaderboard") ? (isEmpty(tail(currentUrl)) ? singleton(createElement(App, {
            isSolana: false,
        })) : singleton(createElement(NotFound, null))) : ((head(currentUrl) === "optionality") ? (isEmpty(tail(currentUrl)) ? singleton(createElement(App, {
            isSolana: false,
        })) : singleton(createElement(NotFound, null))) : ((head(currentUrl) === "dashboard-solana") ? (isEmpty(tail(currentUrl)) ? singleton(createElement(App, {
            isSolana: true,
        })) : singleton(createElement(NotFound, null))) : singleton(createElement(NotFound, null)))))))) : singleton(createElement(App, {
            isSolana: false,
        })))))),
    });
}

