import { Record, Union } from "../../fable-library.3.1.16/Types.js";
import { record_type, bool_type, option_type, list_type, class_type, union_type, string_type } from "../../fable-library.3.1.16/Reflection.js";
import { PersistenceSettings$2$reflection, AtomEffect$2$reflection } from "../Types.fs.js";
import { cons, empty } from "../../fable-library.3.1.16/List.js";

export class AtomState_Key extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Value"];
    }
}

export function AtomState_Key$reflection() {
    return union_type("Feliz.Recoil.AtomCE.AtomState.Key", [], AtomState_Key, () => [[["Item", string_type]]]);
}

export class AtomState_ReadWrite$3 extends Record {
    constructor(Key, Def, Effects, Persist, DangerouslyAllowMutability) {
        super();
        this.Key = Key;
        this.Def = Def;
        this.Effects = Effects;
        this.Persist = Persist;
        this.DangerouslyAllowMutability = DangerouslyAllowMutability;
    }
}

export function AtomState_ReadWrite$3$reflection(gen0, gen1, gen2) {
    return record_type("Feliz.Recoil.AtomCE.AtomState.ReadWrite`3", [gen0, gen1, gen2], AtomState_ReadWrite$3, () => [["Key", string_type], ["Def", gen0], ["Effects", list_type(AtomEffect$2$reflection(gen1, class_type("Feliz.Recoil.ReadWrite")))], ["Persist", option_type(PersistenceSettings$2$reflection(gen1, gen2))], ["DangerouslyAllowMutability", option_type(bool_type)]]);
}

export class AtomBuilder {
    constructor() {
    }
}

export function AtomBuilder$reflection() {
    return class_type("Feliz.Recoil.AtomCE.AtomBuilder", void 0, AtomBuilder);
}

export function AtomBuilder_$ctor() {
    return new AtomBuilder();
}

export function AtomBuilder__Yield_1505(_, _arg1) {
    return void 0;
}

export function AtomBuilder__Key_Z620645C8(_, state, value) {
    return new AtomState_Key(0, value);
}

export function AtomBuilder__Default_31D70B0A(_, _arg2, v) {
    return new AtomState_ReadWrite$3(_arg2.fields[0], v, empty(), void 0, void 0);
}

export function AtomBuilder__Default_Z1DB285F2(_, _arg3, v) {
    return new AtomState_ReadWrite$3(_arg3.fields[0], v, empty(), void 0, void 0);
}

export function AtomBuilder__Default_29F6F2EB(_, _arg4, v) {
    return new AtomState_ReadWrite$3(_arg4.fields[0], v, empty(), void 0, void 0);
}

export function AtomBuilder__Effect_Z3BD225E2(_, state, f) {
    return new AtomState_ReadWrite$3(state.Key, state.Def, cons(f, state.Effects), state.Persist, state.DangerouslyAllowMutability);
}

export function AtomBuilder__Effect_236AF85D(_, state, f) {
    return new AtomState_ReadWrite$3(state.Key, state.Def, cons(f.Dispose, state.Effects), state.Persist, state.DangerouslyAllowMutability);
}

export function AtomBuilder__Persist_Z983019F(_, state, settings) {
    return new AtomState_ReadWrite$3(state.Key, state.Def, state.Effects, settings, state.DangerouslyAllowMutability);
}

export function AtomBuilder__DangerouslyAllowMutability_Z71F318D4(_, state) {
    return new AtomState_ReadWrite$3(state.Key, state.Def, state.Effects, state.Persist, true);
}

export const atom = AtomBuilder_$ctor();

