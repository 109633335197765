import { Union } from "../.fable/fable-library.3.1.16/Types.js";
import { union_type } from "../.fable/fable-library.3.1.16/Reflection.js";
import { createTransaction, TransactionInstructionInput, TransactionInstructionKey, transactionInstruction, bn, getAssociatedTokenAddress, publicKeyBackEndToSolana } from "../../../../lib/Solana/Solana.fs.js";
import { PublicKey } from "../../../../lib/Domain/Types/Common.fs.js";
import { singleton } from "../.fable/fable-library.3.1.16/AsyncBuilder.js";
import { Api } from "../Server.fs.js";
import { some } from "../.fable/fable-library.3.1.16/Option.js";
import { getProfilePDA, getMintAuthorityPDA, getFaucetPDA } from "../../../../lib/Solana/Utils.fs.js";
import { encodeU32, encodeU64 } from "../../../../lib/Solana/Layout.fs.js";
import { toString } from "../.fable/fable-library.3.1.16/Long.js";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { signAndSendTransaction } from "../../../../lib/Solana/Transactions.fs.js";

export class ConvertToken extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["BTC", "SOL", "SRM", "RAY"];
    }
}

export function ConvertToken$reflection() {
    return union_type("DevNetConvert.ConvertToken", [], ConvertToken, () => [[], [], [], []]);
}

export function ConvertToken__GetMint(this$) {
    switch (this$.tag) {
        case 1: {
            return publicKeyBackEndToSolana(new PublicKey(0, "So11111111111111111111111111111111111111112"));
        }
        case 2: {
            return publicKeyBackEndToSolana(new PublicKey(0, "SRMuApVNdxXokk5GT7XD5cUUgXMBCoAz2LHeuAoKWRt"));
        }
        case 3: {
            return publicKeyBackEndToSolana(new PublicKey(0, "4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R"));
        }
        default: {
            return publicKeyBackEndToSolana(new PublicKey(0, "9n4nbM75f5Ui33ZbPYXn59EwSgE8CGsHtAeTH5YFeJ9E"));
        }
    }
}

export function ConvertToken__GetOracle(this$) {
    switch (this$.tag) {
        case 1: {
            return publicKeyBackEndToSolana(new PublicKey(0, "J83w4HKfqxwcq3BEMMkPFSppX3gqekLyLJBexebFVkix"));
        }
        case 2: {
            return publicKeyBackEndToSolana(new PublicKey(0, "992moaMQKs32GKZ9dxi8keyM2bUmbrwBZpK4p2K6X5Vs"));
        }
        case 3: {
            return publicKeyBackEndToSolana(new PublicKey(0, "EhgAdTrgxi4ZoVZLQx1n93vULucPpiFi2BQtz9RJr1y6"));
        }
        default: {
            return publicKeyBackEndToSolana(new PublicKey(0, "HovQMDrbAgAYPCmHVSrezcSmkMtXSSUsLDFANExrZh2J"));
        }
    }
}

export function convertToken(connection, walletPublicKey, wallet, amount, crypto, buyCrypto) {
    return singleton.Delay(() => singleton.Bind(Api.GetDevNetProfileProgram(), (_arg1) => {
        const profileProgram_1 = publicKeyBackEndToSolana(_arg1);
        console.log(some("profileProgram"));
        return singleton.Bind(Api.GetDevNetFaucetProgram(), (_arg2) => {
            const faucetProgram_1 = publicKeyBackEndToSolana(_arg2);
            console.log(some("faucetProgram"));
            console.log(some("faucetAccount"));
            return singleton.Bind(getFaucetPDA(faucetProgram_1), (_arg3) => {
                console.log(some("faucetPda"));
                const cryptoMint = ConvertToken__GetMint(crypto);
                console.log(some("cryptoMint"));
                const cryptoOracle = ConvertToken__GetOracle(crypto);
                console.log(some("cryptoOracle"));
                return singleton.Bind(getAssociatedTokenAddress(cryptoMint, walletPublicKey), (_arg4) => {
                    console.log(some("cryptoTokenAcc"));
                    return singleton.Bind(getMintAuthorityPDA(faucetProgram_1, cryptoMint), (_arg5) => {
                        console.log(some("cryptoAuth"));
                        const usdMint = publicKeyBackEndToSolana(new PublicKey(0, "525FPP6HX1gEEBG3zDkAHBjYxH3HVPKF4Gn32LKkDm7r"));
                        console.log(some("usdMint"));
                        return singleton.Bind(getAssociatedTokenAddress(usdMint, walletPublicKey), (_arg6) => {
                            console.log(some("usdTokenAcc"));
                            return singleton.Bind(getMintAuthorityPDA(faucetProgram_1, usdMint), (_arg7) => {
                                console.log(some("usdAuth"));
                                return singleton.Bind(getProfilePDA(profileProgram_1, walletPublicKey), (_arg8) => {
                                    console.log(some("profile"));
                                    const bufDir = buyCrypto ? (Buffer.alloc(1, 1)) : (Buffer.alloc(1, 0));
                                    const bufAmount = encodeU64(bn(toString(amount)));
                                    const bufPayload = Buffer.concat([bufAmount, bufDir]);
                                    console.log(some("bufPayload"));
                                    const n1 = -95207601 >>> 0;
                                    const n2 = -445601362 >>> 0;
                                    const instxBuf1 = encodeU32(bn(n1));
                                    const instxBuf2 = encodeU32(bn(n2));
                                    const buffer = Buffer.concat([instxBuf1, instxBuf2, bufPayload]);
                                    console.log(some("buffer"));
                                    let instructionConvert;
                                    const rent = publicKeyBackEndToSolana(new PublicKey(0, "SysvarRent111111111111111111111111111111111"));
                                    instructionConvert = transactionInstruction(new TransactionInstructionInput(faucetProgram_1, buffer, [new TransactionInstructionKey(_arg6, false, true), new TransactionInstructionKey(_arg4, false, true), new TransactionInstructionKey(usdMint, false, true), new TransactionInstructionKey(cryptoMint, false, true), new TransactionInstructionKey(_arg7[0], false, false), new TransactionInstructionKey(_arg5[0], false, false), new TransactionInstructionKey(_arg8[0], false, true), new TransactionInstructionKey(walletPublicKey, true, true), new TransactionInstructionKey(_arg3[0], false, false), new TransactionInstructionKey(TOKEN_PROGRAM_ID, false, false), new TransactionInstructionKey(profileProgram_1, false, false), new TransactionInstructionKey(cryptoOracle, false, false)]));
                                    const transaction = createTransaction();
                                    transaction.add(instructionConvert);
                                    const matchValue = wallet.wallet;
                                    if (matchValue == null) {
                                        return singleton.Return((() => {
                                            throw (new Error("Wallet Not Found."));
                                        })());
                                    }
                                    else {
                                        console.log(some("sending to wallet"));
                                        console.log(some(walletPublicKey.toBase58()));
                                        console.log(some("connection"));
                                        console.log(some(connection.toString()));
                                        return singleton.Bind(signAndSendTransaction(wallet, walletPublicKey, connection, [], transaction), (_arg9) => {
                                            const result = _arg9;
                                            console.log(some(result));
                                            return singleton.Return(result);
                                        });
                                    }
                                });
                            });
                        });
                    });
                });
            });
        });
    }));
}

