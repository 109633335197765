import { Record } from "../../../app/client/src/.fable/fable-library.3.1.16/Types.js";
import { PublicKey$reflection } from "./Common.fs.js";
import { uint8_type, record_type, string_type, class_type } from "../../../app/client/src/.fable/fable-library.3.1.16/Reflection.js";
import { op_Division } from "../../../app/client/src/.fable/fable-library.3.1.16/Decimal.js";
import Decimal from "../../../app/client/src/.fable/fable-library.3.1.16/Decimal.js";
import { toNumber } from "../../../app/client/src/.fable/fable-library.3.1.16/Long.js";

export class ProvisionalPosition extends Record {
    constructor(User, Product, BuyAmount, SellAmount, TransactionId) {
        super();
        this.User = User;
        this.Product = Product;
        this.BuyAmount = BuyAmount;
        this.SellAmount = SellAmount;
        this.TransactionId = TransactionId;
    }
}

export function ProvisionalPosition$reflection() {
    return record_type("ExoticMarkets.Domain.ProvisionalPosition.ProvisionalPosition", [], ProvisionalPosition, () => [["User", PublicKey$reflection()], ["Product", PublicKey$reflection()], ["BuyAmount", class_type("System.UInt64")], ["SellAmount", class_type("System.UInt64")], ["TransactionId", string_type]]);
}

export function ProvisionalPosition__Size_Z524259A4(this$, decimals) {
    return op_Division(new Decimal(toNumber(this$.BuyAmount)), new Decimal(Math.pow(10, decimals)));
}

export class ProvisionalHoldingPosition extends Record {
    constructor(PublicKey, User, Holding, BuyAmount, SellAmount, Bump, TransactionId) {
        super();
        this.PublicKey = PublicKey;
        this.User = User;
        this.Holding = Holding;
        this.BuyAmount = BuyAmount;
        this.SellAmount = SellAmount;
        this.Bump = Bump;
        this.TransactionId = TransactionId;
    }
}

export function ProvisionalHoldingPosition$reflection() {
    return record_type("ExoticMarkets.Domain.ProvisionalPosition.ProvisionalHoldingPosition", [], ProvisionalHoldingPosition, () => [["PublicKey", PublicKey$reflection()], ["User", PublicKey$reflection()], ["Holding", PublicKey$reflection()], ["BuyAmount", class_type("System.UInt64")], ["SellAmount", class_type("System.UInt64")], ["Bump", uint8_type], ["TransactionId", string_type]]);
}

export function ProvisionalHoldingPosition__Size_Z524259A4(this$, decimals) {
    return op_Division(new Decimal(toNumber(this$.BuyAmount)), new Decimal(Math.pow(10, decimals)));
}

