import { singleton } from "../.fable/fable-library.3.1.16/AsyncBuilder.js";
import { PublicKeyDefault, getTokenAccountBalance, createTransaction, TransactionInstructionInput, TransactionInstructionKey, transactionInstruction, bn, getAssociatedTokenAddress, publicKeyBackEndToSolana, findProgramAddress } from "../../../../lib/Solana/Solana.fs.js";
import { encodeU8, encodeU32, encodePublicKey2, encodeString2NoLength } from "../../../../lib/Solana/Layout.fs.js";
import { ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { PublicKey } from "../../../../lib/Domain/Types/Common.fs.js";
import { some } from "../.fable/fable-library.3.1.16/Option.js";
import { signAndSendTransaction } from "../../../../lib/Solana/Transactions.fs.js";

export function getSingleStringPda(string, program) {
    return singleton.Delay(() => singleton.Bind(findProgramAddress([encodeString2NoLength(string)], program), (_arg1) => singleton.Return(_arg1)));
}

export function getStringKeyPda(string, key, program) {
    return singleton.Delay(() => singleton.Bind(findProgramAddress([encodeString2NoLength(string), encodePublicKey2(key)], program), (_arg1) => singleton.Return(_arg1)));
}

export function getVaultStatePda(vaultProgram) {
    return getSingleStringPda("state", vaultProgram);
}

export function getVaultAuthorityPda(vaultProgram) {
    return getSingleStringPda("vault-authority", vaultProgram);
}

export function getVaultCollateralTokenAccountPda(vault, vaultProgram) {
    return getStringKeyPda("vault-collateral", vault, vaultProgram);
}

export function getFlexProductProgramPda(vaultProgram) {
    return getSingleStringPda("devnet-profile", vaultProgram);
}

export function getPositionPda(walletPublicKey, vault, profileProgram) {
    return singleton.Delay(() => singleton.Bind(findProgramAddress([encodeString2NoLength("devnet-profile"), encodePublicKey2(walletPublicKey), encodePublicKey2(vault)], profileProgram), (_arg1) => singleton.Return(_arg1)));
}

export function getUserProductPositionPda(walletPublicKey, vault, productProgram) {
    return singleton.Delay(() => singleton.Bind(findProgramAddress([encodePublicKey2(walletPublicKey), encodePublicKey2(vault)], productProgram), (_arg1) => singleton.Return(_arg1)));
}

export function withdraw(connection, vaultProgramPubkey, walletPublicKey, wallet, product) {
    return singleton.Delay(() => {
        const vaultProgram = publicKeyBackEndToSolana(vaultProgramPubkey);
        const vaultAccount_1 = publicKeyBackEndToSolana(product.PublicKey);
        return singleton.Bind(getVaultStatePda(vaultProgram), (_arg1) => {
            const collateralMint = publicKeyBackEndToSolana(product.CollateralMint);
            return singleton.Bind(getAssociatedTokenAddress(collateralMint, walletPublicKey), (_arg2) => {
                const userCollateralTokenAcc = _arg2;
                return singleton.Bind(getVaultAuthorityPda(vaultProgram), (_arg3) => singleton.Bind(getVaultCollateralTokenAccountPda(vaultAccount_1, vaultProgram), (_arg4) => singleton.Bind(getUserProductPositionPda(walletPublicKey, vaultAccount_1, vaultProgram), (_arg5) => {
                    const userProductPositionPda = _arg5;
                    const n1 = 2028799879 >>> 0;
                    const n2 = 123690645 >>> 0;
                    const instxBuf1 = encodeU32(bn(n1));
                    const instxBuf2 = encodeU32(bn(n2));
                    const userProductPositionBump = encodeU8(bn(userProductPositionPda[1]));
                    const bufPayload = Buffer.concat([userProductPositionBump]);
                    const instructionWithdraw = transactionInstruction(new TransactionInstructionInput(vaultProgram, Buffer.concat([instxBuf1, instxBuf2, bufPayload]), [new TransactionInstructionKey(_arg1[0], false, false), new TransactionInstructionKey(walletPublicKey, true, true), new TransactionInstructionKey(userCollateralTokenAcc, false, true), new TransactionInstructionKey(vaultAccount_1, false, true), new TransactionInstructionKey(_arg3[0], false, false), new TransactionInstructionKey(collateralMint, false, false), new TransactionInstructionKey(_arg4[0], false, true), new TransactionInstructionKey(TOKEN_PROGRAM_ID, false, false), new TransactionInstructionKey(userProductPositionPda[0], false, true)]));
                    const transaction = createTransaction();
                    return singleton.Combine(singleton.TryWith(singleton.Delay(() => singleton.Bind(getTokenAccountBalance(connection, userCollateralTokenAcc), (_arg6) => {
                        return singleton.Zero();
                    })), (_arg7) => {
                        let rent;
                        transaction.add((rent = publicKeyBackEndToSolana(new PublicKey(0, "SysvarRent111111111111111111111111111111111")), transactionInstruction(new TransactionInstructionInput(ASSOCIATED_TOKEN_PROGRAM_ID, Buffer.alloc(0), [new TransactionInstructionKey(walletPublicKey, true, true), new TransactionInstructionKey(userCollateralTokenAcc, false, true), new TransactionInstructionKey(walletPublicKey, false, false), new TransactionInstructionKey(collateralMint, false, false), new TransactionInstructionKey(PublicKeyDefault, false, false), new TransactionInstructionKey(TOKEN_PROGRAM_ID, false, false), new TransactionInstructionKey(rent, false, false)]))));
                        return singleton.Zero();
                    }), singleton.Delay(() => {
                        transaction.add(instructionWithdraw);
                        const matchValue = wallet.wallet;
                        if (matchValue == null) {
                            return singleton.Return((() => {
                                throw (new Error("Wallet Not Found."));
                            })());
                        }
                        else {
                            console.log(some("sending to wallet"));
                            console.log(some(walletPublicKey.toBase58()));
                            console.log(some("connection"));
                            console.log(some(connection.toString()));
                            return singleton.Bind(signAndSendTransaction(wallet, walletPublicKey, connection, [], transaction), (_arg8) => {
                                const result = _arg8;
                                console.log(some(result));
                                return singleton.Return(result);
                            });
                        }
                    }));
                })));
            });
        });
    });
}

