import { toNumber, fromValue, fromInteger } from "../../../app/client/src/.fable/fable-library.3.1.16/Long.js";
import { Record, Union } from "../../../app/client/src/.fable/fable-library.3.1.16/Types.js";
import { record_type, uint32_type, option_type, string_type, union_type, class_type } from "../../../app/client/src/.fable/fable-library.3.1.16/Reflection.js";
import { coinDecoder, Coin$reflection } from "./Common.fs.js";
import { printf, toConsole, endsWith } from "../../../app/client/src/.fable/fable-library.3.1.16/String.js";
import { compare, op_Multiply, fromParts, pow, op_Division } from "../../../app/client/src/.fable/fable-library.3.1.16/Decimal.js";
import Decimal from "../../../app/client/src/.fable/fable-library.3.1.16/Decimal.js";
import { fromInt32, op_Division as op_Division_1, toUInt64, toDecimal } from "../../../app/client/src/.fable/fable-library.3.1.16/BigInt.js";
import { InjectiveProduct__GetKind } from "./InjectiveProduct.fs.js";
import { Option__GetCollateralAmount } from "./InjectiveOption.fs.js";
import { fromString, oneOf, string, bigint, uint64, object } from "../../../app/client/src/.fable/Thoth.Json.10.1.0/Decode.fs.js";
import { uncurry } from "../../../app/client/src/.fable/fable-library.3.1.16/Util.js";
import { ofArray } from "../../../app/client/src/.fable/fable-library.3.1.16/List.js";

export const VOL_MULTIPLIER = fromInteger(100000, true, 2);

export class Price extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Volatility", "Fixed"];
    }
}

export function Price$reflection() {
    return union_type("ExoticMarkets.Domain.InjectiveEscrow.Price", [], Price, () => [[["Item", class_type("System.Decimal")]], [["Item", class_type("System.Numerics.BigInteger")]]]);
}

export class Side extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Sell", "Buy"];
    }
}

export function Side$reflection() {
    return union_type("ExoticMarkets.Domain.InjectiveEscrow.Side", [], Side, () => [[], []]);
}

export class Expiration extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["AtHeight", "AtTime", "Never"];
    }
}

export function Expiration$reflection() {
    return union_type("ExoticMarkets.Domain.InjectiveEscrow.Expiration", [], Expiration, () => [[["Item", class_type("System.UInt64")]], [["Item", class_type("System.UInt64")]], []]);
}

export class Exchange extends Record {
    constructor(Quantity, Price, Slippage) {
        super();
        this.Quantity = Quantity;
        this.Price = Price;
        this.Slippage = Slippage;
    }
}

export function Exchange$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveEscrow.Exchange", [], Exchange, () => [["Quantity", string_type], ["Price", option_type(string_type)], ["Slippage", option_type(uint32_type)]]);
}

export class ExchangeMsg extends Record {
    constructor(Exchange) {
        super();
        this.Exchange = Exchange;
    }
}

export function ExchangeMsg$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveEscrow.ExchangeMsg", [], ExchangeMsg, () => [["Exchange", Exchange$reflection()]]);
}

export class Escrow extends Record {
    constructor(ContractAddress, Creator, HoldingContract, HoldingId, TokenPosition, Price, Expiration, BuyDenom, MinQuantity, Balance) {
        super();
        this.ContractAddress = ContractAddress;
        this.Creator = Creator;
        this.HoldingContract = HoldingContract;
        this.HoldingId = HoldingId;
        this.TokenPosition = TokenPosition;
        this.Price = Price;
        this.Expiration = Expiration;
        this.BuyDenom = BuyDenom;
        this.MinQuantity = MinQuantity;
        this.Balance = Balance;
    }
}

export function Escrow$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveEscrow.Escrow", [], Escrow, () => [["ContractAddress", string_type], ["Creator", string_type], ["HoldingContract", string_type], ["HoldingId", string_type], ["TokenPosition", Coin$reflection()], ["Price", Price$reflection()], ["Expiration", Expiration$reflection()], ["BuyDenom", string_type], ["MinQuantity", class_type("System.Numerics.BigInteger")], ["Balance", class_type("System.Numerics.BigInteger")]]);
}

export function Escrow__GetExpirationInt64(this$) {
    const matchValue = this$.Expiration;
    if (matchValue.tag === 1) {
        return fromValue(matchValue.fields[0], false);
    }
    else {
        throw (new Error("Unknown Expiration Type in GetExpirationInt64"));
    }
}

export function Escrow__GetBalanceDecimal_Z524259A4(this$, collateralTokenDecimals) {
    if (endsWith(this$.TokenPosition.Denom, "position")) {
        return op_Division(toDecimal(this$.Balance), pow(fromParts(10, 0, 0, false, 0), 18));
    }
    else {
        return op_Division(toDecimal(this$.Balance), pow(fromParts(10, 0, 0, false, 0), collateralTokenDecimals));
    }
}

export function Escrow__GetNbOptionDecimal(this$, product, option, collateralTokenDecimals) {
    if (endsWith(this$.TokenPosition.Denom, "position")) {
        const matchValue = InjectiveProduct__GetKind(product);
        if (matchValue.tag === 1) {
            return op_Division(op_Multiply(op_Division(toDecimal(this$.Balance), option.NbOption), toDecimal(Option__GetCollateralAmount(option))), pow(fromParts(10, 0, 0, false, 0), collateralTokenDecimals));
        }
        else {
            return op_Division(toDecimal(this$.Balance), pow(fromParts(10, 0, 0, false, 0), 18));
        }
    }
    else {
        return op_Division(toDecimal(this$.Balance), pow(fromParts(10, 0, 0, false, 0), collateralTokenDecimals));
    }
}

export function Escrow__GetTotalNbOptionDecimal(this$, product, option, collateralTokenDecimals) {
    if (endsWith(this$.TokenPosition.Denom, "position")) {
        const matchValue = InjectiveProduct__GetKind(product);
        if (matchValue.tag === 1) {
            return op_Division(op_Multiply(op_Division(toDecimal(this$.TokenPosition.Amount), option.NbOption), toDecimal(Option__GetCollateralAmount(option))), pow(fromParts(10, 0, 0, false, 0), collateralTokenDecimals));
        }
        else {
            return op_Division(toDecimal(this$.TokenPosition.Amount), pow(fromParts(10, 0, 0, false, 0), 18));
        }
    }
    else {
        return op_Division(toDecimal(this$.Balance), pow(fromParts(10, 0, 0, false, 0), collateralTokenDecimals));
    }
}

export function Escrow__GetUserNbOptionDecimal(this$, product, option, collateralTokenDecimals, userBalance) {
    if (endsWith(this$.TokenPosition.Denom, "position")) {
        const matchValue = InjectiveProduct__GetKind(product);
        if (matchValue.tag === 1) {
            return op_Division(op_Multiply(op_Division(userBalance, option.NbOption), toDecimal(Option__GetCollateralAmount(option))), pow(fromParts(10, 0, 0, false, 0), collateralTokenDecimals));
        }
        else {
            return op_Division(userBalance, pow(fromParts(10, 0, 0, false, 0), 18));
        }
    }
    else {
        return op_Division(userBalance, pow(fromParts(10, 0, 0, false, 0), collateralTokenDecimals));
    }
}

export function Escrow__GetTotalNumberOfPositionTokenMintedDecimal(this$, holding, option) {
    if (endsWith(this$.TokenPosition.Denom, "position")) {
        return op_Division(option.NbOption, pow(fromParts(10, 0, 0, false, 0), 18));
    }
    else {
        return op_Division(toDecimal(holding.TotalDepositedAmount), pow(fromParts(10, 0, 0, false, 0), 18));
    }
}

export function Escrow__GetTotalNumberOfPositionTokenMinted(this$, holding, option) {
    if (endsWith(this$.TokenPosition.Denom, "position")) {
        return option.NbOption;
    }
    else {
        return toDecimal(holding.TotalDepositedAmount);
    }
}

export function Escrow__GetMinOrderQuantity(this$, product, option, collateralTokenDecimals) {
    let bal;
    if (endsWith(this$.TokenPosition.Denom, "position")) {
        const matchValue = InjectiveProduct__GetKind(product);
        bal = ((matchValue.tag === 1) ? op_Division(op_Multiply(op_Division(toDecimal(this$.Balance), option.NbOption), toDecimal(Option__GetCollateralAmount(option))), pow(fromParts(10, 0, 0, false, 0), collateralTokenDecimals)) : op_Division(toDecimal(this$.Balance), pow(fromParts(10, 0, 0, false, 0), 18)));
    }
    else {
        bal = op_Division(toDecimal(this$.Balance), pow(fromParts(10, 0, 0, false, 0), collateralTokenDecimals));
    }
    const minQty = op_Division(toDecimal(this$.MinQuantity), pow(fromParts(10, 0, 0, false, 0), collateralTokenDecimals));
    if (compare(bal, op_Multiply(minQty, fromParts(2, 0, 0, false, 0))) < 0) {
        return bal;
    }
    else {
        return minQty;
    }
}

export function Escrow__GetExchangeMsg(this$, quantityDecimal, priceQueried) {
    if (this$.Price.tag === 1) {
        return new Exchange(quantityDecimal, void 0, void 0);
    }
    else {
        return new Exchange(quantityDecimal, priceQueried, 5000 >>> 0);
    }
}

export function Escrow__GetPositionTokenDecimal_Z524259A4(this$, collateralTokenDecimals) {
    if (endsWith(this$.TokenPosition.Denom, "position")) {
        return 18;
    }
    else {
        return collateralTokenDecimals | 0;
    }
}

export class QueryPriceForQuantityMsg extends Record {
    constructor(GetPriceForQuantity) {
        super();
        this.GetPriceForQuantity = GetPriceForQuantity;
    }
}

export function QueryPriceForQuantityMsg$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveEscrow.QueryPriceForQuantityMsg", [], QueryPriceForQuantityMsg, () => [["GetPriceForQuantity", string_type]]);
}

export class EscrowRaw extends Record {
    constructor(Creator, HoldingContract, HoldingId, TokenPosition, Price, Expiration, BuyDenom, MinQuantity) {
        super();
        this.Creator = Creator;
        this.HoldingContract = HoldingContract;
        this.HoldingId = HoldingId;
        this.TokenPosition = TokenPosition;
        this.Price = Price;
        this.Expiration = Expiration;
        this.BuyDenom = BuyDenom;
        this.MinQuantity = MinQuantity;
    }
}

export function EscrowRaw$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveEscrow.EscrowRaw", [], EscrowRaw, () => [["Creator", string_type], ["HoldingContract", string_type], ["HoldingId", string_type], ["TokenPosition", Coin$reflection()], ["Price", Price$reflection()], ["Expiration", Expiration$reflection()], ["BuyDenom", string_type], ["MinQuantity", class_type("System.Numerics.BigInteger")]]);
}

export function EscrowRaw__ToEscrow_Z2478DF7(this$, address, balance) {
    return new Escrow(address, this$.Creator, this$.HoldingContract, this$.HoldingId, this$.TokenPosition, this$.Price, this$.Expiration, this$.BuyDenom, this$.MinQuantity, balance);
}

export const volatilityPriceDecoder = (path) => ((v_1) => object((get$) => (new Price(0, op_Division(new Decimal(toNumber(get$.Required.Field("volatility", uncurry(2, uint64)))), new Decimal(toNumber(VOL_MULTIPLIER))))), path, v_1));

export const fixedPriceDecoder = (path_1) => ((v) => object((get$) => (new Price(1, get$.Required.Field("fixed", (path, value) => bigint(path, value)))), path_1, v));

export const atHeightDecoder = (path) => ((v) => object((get$) => (new Expiration(0, get$.Required.Field("at_height", uncurry(2, uint64)))), path, v));

export const atTimeDecoder = (path_1) => ((v) => object((get$) => (new Expiration(1, toUInt64(op_Division_1(get$.Required.Field("at_time", (path, value) => bigint(path, value)), fromInt32(1000000000))))), path_1, v));

export const injectiveEscrowRawDecoder = (path_7) => ((v) => object((get$) => (new EscrowRaw(get$.Required.Field("creator", (path, value) => string(path, value)), get$.Required.Field("holding_contract", (path_1, value_1) => string(path_1, value_1)), get$.Required.Field("holding_id", (path_2, value_2) => string(path_2, value_2)), get$.Required.Field("token_position", uncurry(2, coinDecoder)), get$.Required.Field("price", (path_3, value_3) => oneOf(ofArray([(arg00_4) => {
    const clo1 = fixedPriceDecoder(arg00_4);
    return (arg10_4) => clo1(arg10_4);
}, (arg00_5) => {
    const clo1_1 = volatilityPriceDecoder(arg00_5);
    return (arg10_5) => clo1_1(arg10_5);
}]), path_3, value_3)), get$.Required.Field("expiration", (path_4, value_4) => oneOf(ofArray([(arg00_7) => {
    const clo1_2 = atHeightDecoder(arg00_7);
    return (arg10_7) => clo1_2(arg10_7);
}, (arg00_8) => {
    const clo1_3 = atTimeDecoder(arg00_8);
    return (arg10_8) => clo1_3(arg10_8);
}]), path_4, value_4)), get$.Required.Field("buy_denom", (path_5, value_5) => string(path_5, value_5)), get$.Required.Field("min_quantity", (path_6, value_6) => bigint(path_6, value_6)))), path_7, v));

export const injectiveEscrowResponseDecoder = (path_1) => ((v) => object((get$) => [get$.Required.Field("state", uncurry(2, injectiveEscrowRawDecoder)), get$.Required.Field("amount", (path, value) => bigint(path, value))], path_1, v));

export function deserializeEscrow(address, data) {
    const raw = fromString(uncurry(2, injectiveEscrowResponseDecoder), data);
    if (raw.tag === 1) {
        toConsole(printf("err: %s"))(raw.fields[0]);
        throw (new Error("failed in escrow deserialization"));
    }
    else {
        return EscrowRaw__ToEscrow_Z2478DF7(raw.fields[0][0], address, raw.fields[0][1]);
    }
}

export function deserializePrice(data) {
    const priceRaw = fromString((path, value) => bigint(path, value), data);
    if (priceRaw.tag === 1) {
        toConsole(printf("err: %s"))(priceRaw.fields[0]);
        throw (new Error("failed in escrow deserialization"));
    }
    else {
        return priceRaw.fields[0];
    }
}

