import { class_type } from "../fable-library.3.1.16/Reflection.js";
import { waitForNone, waitForAny, waitForAll, useRecoilBridgeAcrossReactRoots_UNSTABLE, useSetUnvalidatedAtomValues_UNSTABLE, useTransactionObservation_UNSTABLE, useRecoilSnapshot, useGotoRecoilSnapshot, useRecoilTransactionObserver_UNSTABLE, useRecoilCallback, useResetRecoilState, useSetRecoilState, useRecoilState, useRecoilValueLoadable, useRecoilValue, selectorFamily, selector, noWait, constSelector, atomFamily, atom, RecoilRoot } from "recoil";

export class Recoil {
    constructor() {
    }
}

export function Recoil$reflection() {
    return class_type("Feliz.Recoil.Bindings.Recoil", void 0, Recoil);
}

export const Recoil_RecoilRoot_4E60E31B = RecoilRoot;

export const Recoil_atom_4E60E31B = atom;

export const Recoil_atomFamily_4E60E31B = atomFamily;

export const Recoil_constSelector_1505 = constSelector;

export const Recoil_noWait_5D3EEEEA = noWait;

export const Recoil_selector_4E60E31B = selector;

export const Recoil_selectorFamily_4E60E31B = selectorFamily;

export const Recoil_useRecoilValue_5D3EEEEA = useRecoilValue;

export const Recoil_useRecoilValueLoadable_5D3EEEEA = useRecoilValueLoadable;

export const Recoil_useRecoilState_Z74A95619 = useRecoilState;

export const Recoil_useRecoilStateLoadable_Z74A95619 = useRecoilState;

export const Recoil_useSetRecoilState_Z74A95619 = useSetRecoilState;

export const Recoil_useSetRecoilState_49CA6AE1 = useSetRecoilState;

export const Recoil_useResetRecoilState_Z74A95619 = useResetRecoilState;

export const Recoil_useResetRecoilState_49CA6AE1 = useResetRecoilState;

export const Recoil_useRecoilCallback_13B17D9F = useRecoilCallback;

export const Recoil_useRecoilTransactionObserver_61A0B331 = useRecoilTransactionObserver_UNSTABLE;

export const Recoil_useGotoRecoilSnapshot = useGotoRecoilSnapshot;

export const Recoil_useRecoilSnapshot = useRecoilSnapshot;

export const Recoil_useTransactionObservation_Z567B278D = useTransactionObservation_UNSTABLE;

export const Recoil_useSetUnvalidatedAtomValues_Z5871B1D7 = useSetUnvalidatedAtomValues_UNSTABLE;

export const Recoil_useRecoilBridgeAcrossReactRoots = useRecoilBridgeAcrossReactRoots_UNSTABLE;

export const Recoil_waitForAll_159C0B60 = waitForAll;

export const Recoil_waitForAny_159C0B60 = waitForAny;

export const Recoil_waitForNone_159C0B60 = waitForNone;

