import { interpolate, toText } from "../../app/client/src/.fable/fable-library.3.1.16/String.js";
import { singleton } from "../../app/client/src/.fable/fable-library.3.1.16/AsyncBuilder.js";
import { map as map_1, defaultArg, some } from "../../app/client/src/.fable/fable-library.3.1.16/Option.js";
import { awaitPromise } from "../../app/client/src/.fable/fable-library.3.1.16/Async.js";
import { Connection, Transaction, TransactionInstruction, Keypair, PublicKey } from "@solana/web3.js";
import { BN } from "bn.js";
import { Record } from "../../app/client/src/.fable/fable-library.3.1.16/Types.js";
import { array_type, bool_type, record_type, class_type } from "../../app/client/src/.fable/fable-library.3.1.16/Reflection.js";
import { map, delay, toArray } from "../../app/client/src/.fable/fable-library.3.1.16/Seq.js";
import { TOKEN_PROGRAM_ID, ASSOCIATED_TOKEN_PROGRAM_ID, Token } from "@solana/spl-token";
import { convertToRealOnes, getMainNetTokens } from "./SolanaTokenRegistry.fs.js";
import { choose } from "../../app/client/src/.fable/fable-library.3.1.16/Array.js";
import { tryFind } from "../../app/client/src/.fable/fable-library.3.1.16/Map.js";
import { parse } from "../../app/client/src/.fable/fable-library.3.1.16/BigInt.js";
import { Coin98WalletAdapter, SolongWalletAdapter, SolflareWalletAdapter, PhantomWalletAdapter } from "@solana/wallet-adapter-wallets";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../app/client/src/.fable/Fable.Promise.2.2.2/Promise.fs.js";
import { promise } from "../../app/client/src/.fable/Fable.Promise.2.2.2/PromiseImpl.fs.js";
import DateOffset from "../../app/client/src/.fable/fable-library.3.1.16/DateOffset.js";
import { fromValue, toNumber } from "../../app/client/src/.fable/fable-library.3.1.16/Long.js";

export function shortenedKey(publicKey_1) {
    const base58 = publicKey_1.toBase58();
    return toText(interpolate("%P()..%P()", [base58.slice(0, 3 + 1), base58.slice(-4, base58.length)]));
}

export function findProgramAddress(seeds, programID) {
    return singleton.Delay(() => {
        console.log(some("findProgramAddress"));
        return singleton.ReturnFrom(awaitPromise(PublicKey.findProgramAddress(seeds, programID)));
    });
}

export function bn(x) {
    return new BN(x);
}

export function keypairFromSecretKey(secretKey) {
    return Keypair.fromSecretKey(secretKey);
}

export function keypairFromSeed(seed) {
    return Keypair.fromSeed(seed);
}

export function generateRandomKeypair() {
    return Keypair.generate();
}

export class SplTokenBalance extends Record {
    constructor(token, pubkey, account, amount) {
        super();
        this.token = token;
        this.pubkey = pubkey;
        this.account = account;
        this.amount = amount;
    }
}

export function SplTokenBalance$reflection() {
    return record_type("Solana.SplTokenBalance", [], SplTokenBalance, () => [["token", class_type("Token.TokenInfo")], ["pubkey", class_type("Solana.PublicKey")], ["account", class_type("Solana.AccountInfo")], ["amount", class_type("System.Numerics.BigInteger")]]);
}

export class TransactionInstructionKey extends Record {
    constructor(pubkey, isSigner, isWritable) {
        super();
        this.pubkey = pubkey;
        this.isSigner = isSigner;
        this.isWritable = isWritable;
    }
}

export function TransactionInstructionKey$reflection() {
    return record_type("Solana.TransactionInstructionKey", [], TransactionInstructionKey, () => [["pubkey", class_type("Solana.PublicKey")], ["isSigner", bool_type], ["isWritable", bool_type]]);
}

export class TransactionInstructionInput extends Record {
    constructor(programId, data, keys) {
        super();
        this.programId = programId;
        this.data = data;
        this.keys = keys;
    }
}

export function TransactionInstructionInput$reflection() {
    return record_type("Solana.TransactionInstructionInput", [], TransactionInstructionInput, () => [["programId", class_type("Solana.PublicKey")], ["data", class_type("Borsh.Buffer")], ["keys", array_type(TransactionInstructionKey$reflection())]]);
}

export function transactionInstruction(input) {
    const keysInJs = toArray(delay(() => map((key) => ({
        pubkey: key.pubkey,
        isSigner: key.isSigner,
        isWritable: key.isWritable,
    }), input.keys)));
    const inputInJs = {
        programId: input.programId,
        data: input.data,
        keys: keysInJs,
    };
    return new TransactionInstruction(inputInJs);
}

export function createTransaction() {
    return new Transaction();
}

export function publicKey(key) {
    return new PublicKey(key);
}

export function connection(url) {
    return new Connection(url);
}

export function connectionWithCommitment(url, commitment) {
    return new Connection(url, commitment);
}

export function requestAirdrop(connection_1, wallet, amount) {
    return awaitPromise(connection_1.requestAirdrop(wallet, amount));
}

export function getTokenAccountBalance(connection_1, tokenAccount) {
    return awaitPromise(connection_1.getTokenAccountBalance(tokenAccount));
}

export function getBalance(connection_1, key) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(awaitPromise(connection_1.getBalance(key)), (_arg1) => singleton.Return(defaultArg(_arg1, 0)))), (_arg2) => {
        console.error(some(_arg2));
        return singleton.Return(0);
    }));
}

export const PublicKeyDefault = publicKey("11111111111111111111111111111111");

export function getAssociatedTokenAddress(mint, owner) {
    return singleton.Delay(() => singleton.ReturnFrom(awaitPromise(Token.getAssociatedTokenAddress(ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, mint, owner))));
}

export function getTokenBalances(connection_1, ownerPublicKey, programId) {
    return singleton.Delay(() => singleton.Bind(getMainNetTokens(), (_arg1) => {
        const request = {
            programId: programId,
        };
        return singleton.Bind(awaitPromise(connection_1.getTokenAccountsByOwner(ownerPublicKey, request)), (_arg2) => singleton.Return(choose((account) => {
            const accountInfo = Token.decodeAccountInfo(account);
            const foundToken = tryFind(convertToRealOnes(accountInfo.mint.toBase58()), _arg1);
            if (foundToken == null) {
                return void 0;
            }
            else {
                return new SplTokenBalance(foundToken, account.pubkey, accountInfo, parse(accountInfo.amount.toString()));
            }
        }, defaultArg(_arg2, []))));
    }));
}

export function disconnectWallet(wallet) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        void awaitPromise(wallet.disconnect());
        return singleton.Zero();
    }), (_arg1) => {
        console.log(some(_arg1));
        return singleton.Zero();
    }));
}

export function getPhantomWallet() {
    return new PhantomWalletAdapter();
}

export function getSolflareWallet() {
    return new SolflareWalletAdapter();
}

export function getSolongWallet() {
    return new SolongWalletAdapter();
}

export function getCoin98() {
    return new Coin98WalletAdapter();
}

export function publicKeyBackEndToSolana(_arg1) {
    return publicKey(_arg1.fields[0]);
}

export function clusterTime(clusterEndpoint) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const connection_1 = connectionWithCommitment(clusterEndpoint, "finalized");
        return connection_1.getSlot("finalized").then(((_arg1) => (connection_1.getBlock(_arg1).then(((_arg2) => {
            const time = map_1((block_1) => DateOffset(toNumber(fromValue(block_1.blockTime, false)) * 1000, 0), _arg2);
            return Promise.resolve(time);
        })))));
    }));
}

