import { Record } from "../../../app/client/src/.fable/fable-library.3.1.16/Types.js";
import { record_type, bool_type, option_type, class_type, string_type } from "../../../app/client/src/.fable/fable-library.3.1.16/Reflection.js";

export class DelegationData extends Record {
    constructor(HoldingId, StakingContract, OptionExpiry, DelegationTime, UndelegationTime, Withdrawn) {
        super();
        this.HoldingId = HoldingId;
        this.StakingContract = StakingContract;
        this.OptionExpiry = OptionExpiry;
        this.DelegationTime = DelegationTime;
        this.UndelegationTime = UndelegationTime;
        this.Withdrawn = Withdrawn;
    }
}

export function DelegationData$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveDelegationData.DelegationData", [], DelegationData, () => [["HoldingId", string_type], ["StakingContract", string_type], ["OptionExpiry", class_type("System.DateTimeOffset")], ["DelegationTime", class_type("System.DateTimeOffset")], ["UndelegationTime", option_type(class_type("System.DateTimeOffset"))], ["Withdrawn", bool_type]]);
}

